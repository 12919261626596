export const STATE = {
    JOHOR: {
      name: "Johor",
      code: "JHR",
    },
    KEDAH: {
      name: "Kedah",
      code: "KDH",
    },
    KELANTAN: {
      name: "Kelantan",
      code: "KTN",
    },
    KUALA_LUMPUR: {
      name: "WP Kuala Lumpur",
      code: "KUL",
    },
    SELANGOR: {
      name: "Selangor",
      code: "SGR",
    },
    LABUAN: {
      name: "WP Labuan",
      code: "LBN",
    },
    MELAKA: {
      name: "Melaka",
      code: "MLK",
    },
    NEGERI_SEMBILAN: {
      name: "Negeri Sembilan",
      code: "NSN",
    },
    PAHANG: {
      name: "Pahang",
      code: "PHG",
    },
    PENANG: {
      name: "Penang",
      code: "PNG",
    },
    PERAK: {
      name: "Perak",
      code: "PRK",
    },
    PERLIS: {
      name: "Perlis",
      code: "PLS",
    },
    PURTAJAYA: {
      name: "WP Putrajaya",
      code: "PJY",
    },
    SABAH: {
      name: "Sabah",
      code: "SBH",
    },
    SARAWAK: {
      name: "Sarawak",
      code: "SWK",
    },
    TERENGGANU: {
      name: "Terengganu",
      code: "TRG",
    },
  };
  
  export const STATE_EX = {
    OTHERS: {
      name: "Others",
      code: "OTH",
    },
  };
  
  export const STATE_WITH_EX = {
    ...STATE,
    ...STATE_EX,
  };
  
  export const STATE_NAME = Object.fromEntries(
    Object.keys(STATE_WITH_EX).map((k) => [k, k])
  );
  