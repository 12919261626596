// React Thirty Party Library
import { useState, useEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tag, Table, message, Dropdown } from "antd";

// Constants Library
import { TEMPLATE } from "../../../../../../constants";

// Components Library
import { TableList, ButtonLink } from "../../../../../../components";
import { ActionItems } from "./action";

// Handler Library  
import { userListHDLR, tableHDLR } from "../../../../../../handlers";

// Services Library

// Redux Actions
import {  REDUX as REDUX_UTIL} from "../../../../../../services/util";
import * as REDUX_ACTION from "../../../../../../services/redux/actions";

// Styles Library

const {
  TABLE_ACCOUNT_MANAGER_TITLE, 
  TABLE_ACCOUNT_MANAGER_INDEX,
  SELECT_OPTION_DAYS,
} = TEMPLATE.USERLIST;
const { ACCOUNTMANAGER_SUMMARY_COL, EXPANDED_CUSTOMER_LIST } = TEMPLATE.REPORT.ACCOUNTMANAGER;
const { reduxStoreWrapper } = REDUX_UTIL;

const TableAccManagerList = (props) => {
  const { dataSource, filteredData, setFilteredData, loading, setIsOpenRemark, setData, setIsOpenEditAccManager,
    loadingCust, customerData, selectedRow,
    setSelectedRow
  } = props; 
  
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  let custByCm = useSelector(reduxStoreWrapper("customer.byCM"))

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const columns = [
    ...ACCOUNTMANAGER_SUMMARY_COL({redux: {customerData}, func:{handleSearch}}),
    {
      title: TABLE_ACCOUNT_MANAGER_TITLE.Action,
      dataIndex: TABLE_ACCOUNT_MANAGER_INDEX.Action,
      key: TABLE_ACCOUNT_MANAGER_INDEX.Action,
      fixed: "right",
      render: (_, record) => {
        return (
          <Dropdown
            menu={{
              items: ActionItems(record, dispatch, setIsOpenRemark, setData, setIsOpenEditAccManager),
            }}
          >
            <ButtonLink>Action</ButtonLink>
          </Dropdown>
        );
      },
    },
  ];

  
  const expandedRowRender = (record) => {
    let loadTable = false
    
    const { hierLevel, hierCode, memid } = record;
    
    if (Object.values(custByCm).length === 0 || !custByCm[memid]) {

      dispatch(
        REDUX_ACTION.v1_customerList.get_customer_list_thru_cm_request({
          hierLevel,
          hierCode,
          memid
        }));

        loadTable = true
      }

    return (
      <>
      <Table
        style={{ width: 1000, marginLeft: 50 }}
        columns={EXPANDED_CUSTOMER_LIST}
        dataSource={custByCm[memid]}
        pagination={false}
        loading={loadTable}
      />
      </>
    );
  };

  const sortedData = filteredData.sort((a, b) => b.createdAt - a.createdAt);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const sortFunctions = {
    createdAt: (a, b) => a.createdAt - b.createdAt,
  };
  
  const filtered = sortedData.filter((record) => {
    let include = true;
  
    Object.keys(filteredInfo).forEach((key) => {
      if (filteredInfo[key] && filteredInfo[key].length > 0) {
        include = include && filteredInfo[key].includes(record[key]);
      }
    });
  
    if (searchText) {
      if(searchedColumn === "memid") {
        if ((typeof record.memid === 'string' && record.memid.includes(searchText)) || 
            (typeof record.name === 'string' && record.name.includes(searchText))) {
          include = true;
        }
      }
      if(searchedColumn === "email") {
        if ((typeof record.email === 'string' && record.email.includes(searchText))) {
          include = true;
        }
      }
    }     

    return include;

  }).sort((a, b) => {
    const { columnKey } = sortedInfo;
    return sortFunctions[columnKey] ? sortFunctions[columnKey](a, b) : 0;
  });

  const onSelectRow = (record, selected) => {
    tableHDLR.selectRow(setSelectedRowKeys, setSelectedRow, selectedRow, selectedRowKeys, record, selected)
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelectRow
  };


  return (
    <div>
      {contextHolder}
      <TableList.DataList
        dataSource={dataSource}
        filteredData={filtered}
        setFilteredData={setFilteredData}
        optionDay={SELECT_OPTION_DAYS}
        optionFirst={userListHDLR.userListStatus()}
        dataDate={TABLE_ACCOUNT_MANAGER_INDEX.createdAt}
        defaultValue="CREATED AT"
        text1="USER STATUS"
        columnData={columns}
        showFirstFilter={true}
        rowSelection={rowSelection}
        expandedRowRender={expandedRowRender}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  );
};
export default TableAccManagerList;
