import { Col, Image } from "antd"

import { IMG } from "../../../../../../../../constants";

const { IMG_MY_ACCOUNT_DEFAULT } = IMG.PIC;

import styles from "../../index.module.css";

const CommentProfile = ({name}) => {
    return (
        <Col className={styles["img-profile"]}>
            <Image src={IMG_MY_ACCOUNT_DEFAULT} width={50} />
            <label htmlFor="img">{name}</label>
        </Col>
    );
};

export default CommentProfile;
