// React Thirty Party Library
import { Button } from "antd";

// Constants Library
import { ROOT_CONSTANT } from "../../../../constants";

// Components Library

import { BtnSubmit, ButtonLink } from "../../../../components";

// Handler Library

// Services Library

// Redux Actions

// Styles Library
import classes from "../../../../components/index.module.css";

const { icon_arrow } = ROOT_CONSTANT.IMG.ICON;

const ManageCreditHeader = (props) => {
  const { 
    handleButtonBack,
    loading,
    handleComplete,
    transactionType,
    updatedAmount,
    currentBalance,
    filteredData,
  } = props;

  const disableComplete =
    transactionType === "DCT" &&
    updatedAmount > currentBalance &&
    filteredData?.paymentModel === "PM00"
    
  return (
    <>
      <div className={classes.headerPage}>
        <div>
          <ButtonLink
            onClick={handleButtonBack}
            style={{ display: "inline-block" }}
          >
            <img
              src={icon_arrow}
              className={classes.filterIcon}
              alt=""
              style={{ marginBottom: "-2px" }}
            />
          </ButtonLink>
          Manage Credit
        </div>
        <div>
          <BtnSubmit
            display="inline-block"
            text="Cancel"
            bgColor="transparent"
            color="var(--bs-gray-600)"
            padding="9px 18px"
            onClick={handleButtonBack}
            showIcon={false}
          />
          <Button type="primary" loading={loading} onClick={handleComplete} disabled={disableComplete}>
            Complete
          </Button>
        </div>
      </div>
    </>
  );
};

export default ManageCreditHeader;
