import { Form, Input, message, Space, Drawer, Spin, Select, InputNumber, Row, Col } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { BtnSubmit } from "../../../../../components";

import { REDUX as REDUX_UTIL, TIME } from "../../../../../services/util";

import * as REDUX_ACTION from "../../../../../services/redux/actions";

import styles from "../../../../common.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;

const DrawerEditZone = (props) => {
  const { isOpen, setIsOpen, record } = props
  const [messageApi, contextHolder] = message.useMessage();

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const memid = useSelector(reduxStoreWrapper("user.memid"));
  const zones = Object.values(useSelector(reduxStoreWrapper("zone.byID")));
  const priceList = useSelector(reduxStoreWrapper("zone.byZoneid"));
  const loading = useSelector(reduxStoreWrapper("style.loading.priceList"));

  const zoneid = record && record.zoneid

  const price = priceList[zoneid];
  let zonePricelist = [];
  
  if (price) {
    zonePricelist = price.map(p => {
      return {
        ...p
      };
    });
  }
    
  useEffect(() => {
    if (isOpen) {
      if (!priceList[zoneid]) {
        dispatch(REDUX_ACTION.v1_zone.get_price_list_thru_zonid_request(zoneid));
      }

    }
  }, [isOpen, zoneid, zonePricelist, dispatch]);

  const handleSave = () => {

    messageApi.open({
      type: "loading",
      content: "updating...",
      duration: 0,
    });

  const formData = form.getFieldsValue()

  const priceListForm = formData.zonePrice.map(p => {
    const matchedZone = zonePricelist.find(z => z.dest === p.dest)
    return p.origin && p.dest ? {
      ...p,
      status: matchedZone ? matchedZone.status : 1,
      ...(matchedZone && { updatedAt: matchedZone.updatedAt }),
    } : null
  }).filter(Boolean)

    dispatch(REDUX_ACTION.v1_zone.update_zone_price_list_request({
      zoneid: formData.zoneid,
      name: formData.name,
      adpMemid: memid,
      zonePrices: priceListForm
    },
    () => messageApi.destroy()));
  }

  const ActionButton = (
    <Form.Item style={{ paddingTop: "20px" }}>
      <div>
        <BtnSubmit
          display="inline-block"
          text="Save"
          bgColor="var(--themeBackgroundColor)"
          color="var(--creamColor)"
          padding="9px 18px"
          onClick={() => {
            handleSave();
            setIsOpen(false);
            form.resetFields();
          }}
        />
        <BtnSubmit
          display="inline-block"
          text="Cancel"
          bgColor="transparent"
          color="var(--bs-gray-600)"
          padding="9px 18px"
          onClick={() => {
            setIsOpen(false);
            form.resetFields();
          }}
        />
      </div>
    </Form.Item>
  );

  const zoneOrg = zones
  .filter(z => z.zoneid === zoneid)
  .map(z => ({ label: z.name, value: z.zoneid }));


  const zoneOptions = zones
  .filter(z => z.zoneid !== zoneid)
  .map(z => ({ label: z.name, value: z.zoneid }));

  const initialValues = zonePricelist.map((item) => ({
    origin: item.origin,
    dest: item.dest,
    price: item.price,
    status: item.status
  }));

  const firstPricelistData = [{
    origin: zoneid,
  }];
  
  useEffect(() => {
    if (record) {
      if (zonePricelist.length > 0) {
        form.setFieldsValue({...record, zonePrice: initialValues});
      } else {
        form.setFieldsValue({...record, zonePrice: firstPricelistData});
      }
    }
  }, [record, zonePricelist, initialValues, firstPricelistData]);
  
  const PriceListForm = ({ name, add, remove, ...restField }) => {
  
    return (
        <Space
          style={{
            display: 'flex',
            marginBottom: 8,
            gap: "4px"
          }}
          align="baseline"
          size={6}
        >
        <Row gutter={16}>
          <Col span={12}>
          <div className={styles.groupLabel} style={{ width: "90px"}}>From Zone</div>
            <Form.Item
              name={[name, 'origin']}
              rules={[
                {
                  required: true,
                  message: 'Missing origin Zone',
                },
              ]}
            >
              <Select 
              style={{
                position:"relative",
                marginTop:"11px",
                borderRadius: "var(--br-3xs)",
                height:"40px",
                width:"145px",
                textAlign: "left"
              }} 
              size='large'
              options={zoneOrg}/>
            </Form.Item>
          </Col>
          </Row>

          <Row gutter={16}>
          <Col span={12}>
            <div className={styles.groupLabel} style={{ width: "65px"}}>To Zone</div>
            <Form.Item
              name={[name, 'dest']}
              rules={[
                {
                  required: true,
                  message: 'Missing destination zone',
                },
              ]}
            >
              <Select 
              style={{
                position:"relative",
                marginTop:"11px",
                borderRadius: "var(--br-3xs)",
                height:"40px",
                width:"145px",
                textAlign: "left",
              }} 
              size='large'
              options={zoneOptions}/>
            </Form.Item>
          </Col>
          </Row>

          <Row gutter={16}>
          <Col span={12}>
          <div className={styles.groupLabel} style={{ width: "110px"}}>Zone Price(RM)</div>
            <Form.Item
              name={[name, 'price']}
              rules={[
                {
                  required: true,
                  message: 'Missing price zone',
                },
              ]}
            >
              <InputNumber  
              style={{
                position:"relative",
                marginTop:"11px",
                borderRadius: "5px",
                height:"40px",
                width:"130px",
                textAlign: "left",
                lineHeight: "40px"
              }} 
              />
            </Form.Item>
            </Col>
            </Row>
          <Form.Item name={[name, 'status']} />
          <span style={{ display: 'flex', fontSize: '16px', gap: '3px' }}>
            <PlusOutlined onClick={() => add()} />
            <DeleteOutlined onClick={() => remove(name)} />
          </span>
        </Space>
    );
  }

  return (
    <>
    {contextHolder}
    <Drawer
      title="Edit Zone"
      placement="right"
      closable={false}
      width={550}
      onClose={() => setIsOpen(false)}
      footer={ActionButton}
      open={isOpen}
      style={{
        textAlign: "center",
        fontSize: "20px",
      }}
    >
    {loading ? <Spin /> :
    
    <Form
      name="zone_price_list"
      form={form}
      layout="vertical"
    >
      <Row gutter={16}>
      <Col span={12}>
        <div className={styles.groupLabel}>Zone ID</div>
          <Form.Item
            name="zoneid"
            rules={[
              {
                required: true,
                message: "input the zone id",
              },
            ]}
          >
              <Input className={styles.inputForm} disabled/>
          </Form.Item>
        </Col>
        </Row>

        <Row gutter={16}>
        <Col span={12}>
        <div className={styles.groupLabel}>Zone Name</div>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "input the zone name",
            },
          ]}
        >
          <Input className={styles.inputForm} />
        </Form.Item>
        </Col>
        </Row>

        <Row gutter={16}>
        <Col span={12}>
        <div style={{ color: "gray", display:"flex", margin: "12px 0"}}>Price Zone</div>
        <Form.List name="zonePrice">
          {(fields, { add, remove }) => (
          <>
          {fields.length === 0 ? (
            <PriceListForm add={add} remove={remove} name={0} />
          ) : (
            fields.map(({ name, ...restField }, index) => (
              <PriceListForm
                add={add}
                remove={remove}
                name={name}
                restField={restField}
              />
            ))
          )}
          </>
          )}
        </Form.List>
        </Col>
        </Row>
      </Form>}
    </Drawer>
    </>
  );
};

export default DrawerEditZone;
