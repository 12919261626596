import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { ROUTES, COMMON_FEATURES } from "../../constants"

import DrawerAddZone from "./components/DrawerAddZone";

import { REDUX as REDUX_UTIL} from "../../services/util";

import * as REDUX_ACTION from "../../services/redux/actions";

// Styles Library
import styles from "../common.module.css";

import {SearchZone, TableZone} from "./components";

const { reduxStoreWrapper } = REDUX_UTIL;

const ZoneList = () => {
    const dispatch = useDispatch()
    
    const zones = useSelector(reduxStoreWrapper("zone.byID"));
    const priceList = useSelector(reduxStoreWrapper("zone.byZoneid"))
    const loading = useSelector(reduxStoreWrapper("style.loading.zone"));
    const [isOpenAdd, setIsOpenAdd] = useState(false)


    const [selectedRow, setSelectedRow] = useState([]);

    const dataSource = Object.values(zones).map((z) => {

      const active = priceList[z.zoneid] && priceList[z.zoneid].filter(p => p.status === 1)
      const inactive = priceList[z.zoneid] && priceList[z.zoneid].filter(p => p.status === 0)

      return { 
          ...z,
          key: z.zoneid,
          active: (active ? active.length : z.active) || 0,
          inactive: (inactive ? inactive.length : z.inactive) || 0
      }});
  
      useEffect(() => {
          dispatch(REDUX_ACTION.v1_zone.get_zone_all_request());
        return () => {};
      }, [dispatch]);

    const [ filteredData, setFilteredData ] = useState(dataSource);  

    useEffect(() => {
      if (filteredData !== dataSource) {
          setFilteredData(dataSource);
      }
  }, [dataSource]);

  useEffect(() => {
      setFilteredData(filteredData);
  }, [filteredData]);

    return(
      <>
      <DrawerAddZone isOpen={isOpenAdd} setIsOpen={setIsOpenAdd} />
      <div className={styles.container}>
        <SearchZone
          dataSource={dataSource}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          setIsOpenAdd={setIsOpenAdd}
          selectedRow={selectedRow}
          />
        <TableZone 
          dataSource={dataSource}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          loading={loading}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          />
        </div>
        </>
    )
}

export default ZoneList
