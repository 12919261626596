import * as ActionTypes from "../action-types";

const transaction_defaultState = {
  byID: {},
  allIDs: [],
};

export const transaction = (state = transaction_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state)); 

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.GET_TRANSACTION_LIST: {
      const transactions = action.transaction;

      transactions &&
        transactions.length &&
        transactions.map((txn) => {
          const { txnid } = txn;

          newState.byID[txnid] = txn;

          if (!newState.allIDs.includes(txnid)) {
            newState.allIDs.push(txnid);
          }

          return null;
        });

      return newState;
    }
    case ActionTypes.CREATE_TRANSACTION: {
      const txn = action.transaction;

      const { txnid } = txn;

      if (txnid) {
        newState.byID[txnid] = txn;

        if (!newState.allIDs.includes(txnid)) newState.allIDs.push(txnid);
      }

      return newState;
    }
    default: {
      return state;
    }
  }
};
