import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

// Components

// Container
import AppV1 from "../../../v1";

// Container Redux Action
import { v1_user } from "../../../v1/services/redux/actions";

const SSOPanel = () => {
	const loadingAuthState = useSelector(
		(state) => state.containers.main.style.loading.auth
	);

	const auth = useSelector((state) => state.root.auth);
	const user = useSelector((state) => state.containers.main.user);
	const userV1 = useSelector((state) => state.containers.v1.user);

	const userStages = user && user.stages && user.stages[0];

	const stage = userStages || "UNKNOWN";

	const uid =
		(auth.currentUser && auth.currentUser.uid) || (user && user.uid);

	const dispatch = useDispatch();

	const redux_get_user_v1 = useCallback(
		(uid) => dispatch(v1_user.user_sign_in_request(uid)),
		[dispatch]
	);

	useEffect(() => {
		switch (stage) {
			case "V1": {
				redux_get_user_v1(uid);
				break;
			}
			default: {
				break;
			}
		}

		return () => {};
	}, [dispatch, auth, uid, userV1.uid, stage, redux_get_user_v1]);

  const renderContainer = () => {
    if (!loadingAuthState && auth) {
      switch (stage) {
        case "V1": {
          return <AppV1/>;
        }
        default: {
          return <></>;
        }
      }
    } else {
      return <></>;
    }
  };

	return (
		<>
			{renderContainer()}
			<Outlet />
		</>
	);
};

export default React.memo(SSOPanel);
