// Third-party Library 
import { Tag } from 'antd'

// Constants 
import { TEMPLATE } from "../..";

import { filter } from "../../../handlers"

import { ButtonLink } from '../../../components';

// Service
import { TIME } from "../../../services/util";

const { 
    parseTimeMinDayFirst 
} = TIME;

const {
    STATUS_LOG_TABLE_LIST_INDEX,
    STATUS_LOG_TABLE_LIST_TITLE,
    SCAN_LOG_TABLE_LIST_INDEX,
    SCAN_LOG_TABLE_LIST_TITLE,
    SCAN_TYPE, 
    SCAN_STATUS
} = TEMPLATE.REPORT_HISTORY

const { searchHDLR, filterHDLR } = filter

export const STATUS_LOG_COLUMN = ({
    redux = {},
    exportState = false,
    func = {}
}) => {

    const {
        hub = {},
        point ={},
        orderStatuses = {},
        user = {},
        hubOperator ={},
        pointOperator = {}
    } = redux

    const {NavigateOrderSummary, handleSearch} = func

    return [
        {
            title: STATUS_LOG_TABLE_LIST_TITLE.hubid,
            key: STATUS_LOG_TABLE_LIST_INDEX.hubid,
            width: 160,
            fixed: "left",
            render: (record) => {

                const { hubid } = record

                const HP = (hubid?.includes('hub') ? 'H' : 'PMBE' || '') + (hub[hubid]?.code || point[hubid]?.code || hubid)

                return exportState ? HP :  (
                    <p>{HP}</p>
                )
                
            },
            ...searchHDLR.getColumnSearchProps('hubid', handleSearch) 
        },
        {
            title: STATUS_LOG_TABLE_LIST_TITLE.ordid,
            key: STATUS_LOG_TABLE_LIST_INDEX.ordid,
            width: 160,
            fixed: "left",
            render: (record) => {

                const { ordid } = record

                return exportState ? ordid :
                <ButtonLink onClick={() => NavigateOrderSummary(ordid)}>
                {ordid}
              </ButtonLink>
                
            },
            sorter: (a, b) => {
                a = parseInt(a.ordid.replace('P', ''));
                b = parseInt(b.ordid.replace('P', ''));
              
                return a - b;
            },
        },
        {
            title: STATUS_LOG_TABLE_LIST_TITLE.status,
            key: STATUS_LOG_TABLE_LIST_INDEX.status,
            width: 350,
            render: (record) => {

                const { status } = record

                const label = orderStatuses[status]?.display || ''

                return exportState ? label : (
                    <Tag color={orderStatuses[status]?.colorTag}>
                        {label}
                    </Tag>
                )
            },
            filters: filterHDLR.getColumnsFilterStatus(orderStatuses),
            onFilter: (value, record) => record.status === value,
        },
        {
            title: STATUS_LOG_TABLE_LIST_TITLE.intiatedAt,
            key: STATUS_LOG_TABLE_LIST_INDEX.intiatedAt,
            width: 160,
            render: (record) => {

                const { initiatedAt } = record

                return exportState ? parseTimeMinDayFirst(initiatedAt) :
                    <p>{parseTimeMinDayFirst(initiatedAt)}</p>
                
            },
            sorter: (a, b) => a.initiatedAt - b.initiatedAt,
        },
        {
            title: STATUS_LOG_TABLE_LIST_TITLE.completedAt,
            key: STATUS_LOG_TABLE_LIST_INDEX.completedAt,
            width: 160,
            render: (record) => {

                const { completedAt } = record

                return exportState ? parseTimeMinDayFirst(completedAt) : 
                    <p>{parseTimeMinDayFirst(completedAt)}</p>
                
            },
            sorter: (a, b) => a.completedAt - b.completedAt,
        },
        {
            title: STATUS_LOG_TABLE_LIST_TITLE.updatedAt,
            key: STATUS_LOG_TABLE_LIST_INDEX.updatedAt,
            width: 160,
            render: (record) => {

                const { updatedAt } = record

                return exportState ? parseTimeMinDayFirst(updatedAt) :
                    <p>{parseTimeMinDayFirst(updatedAt)}</p>
                
            },
            sorter: (a, b) => a.updatedAt - b.updatedAt,
        },
        {
            title: STATUS_LOG_TABLE_LIST_TITLE.createdAt,
            key: STATUS_LOG_TABLE_LIST_INDEX.createdAt,
            width: 160,
            render: (record) => {

                const { createdAt } = record

                return exportState ? parseTimeMinDayFirst(createdAt) : 
                    <p>{parseTimeMinDayFirst(createdAt)}</p>
                
            },
            sorter: (a, b) => a.createdAt - b.createdAt,
        },
        {
            title: STATUS_LOG_TABLE_LIST_TITLE.createdBy,
            key: STATUS_LOG_TABLE_LIST_INDEX.createdBy,
            width: 220,
            render: (record) => {

                const { createdBy, hubid } = record

                const didBy = `${createdBy}/ 
                 ${createdBy?.includes('IPA')? user?.name : createdBy?.includes('IPHOP') ? hubOperator[createdBy]?.name : pointOperator[createdBy]?.name}/ 
                 ${createdBy?.includes('IPA')? 'Admin' : createdBy?.includes('IPHOP') ? `Hub Operator ${hub[hubid]?.code}` : `Point Operator ${point[hubid]?.code}`}`

                return exportState ? didBy :
                    <p>{didBy}</p>
                
            },
            ...searchHDLR.getColumnSearchProps('createdBy', handleSearch) 
        },
    ]
}

export const SCAN_LOG_COLUMN = ({
    redux = {},
    exportState = false,
    func ={}
}) => {

    const {
        point ={},
        hub = {},
        orderStatuses = {},
        hubOperator = {},
        pointOperator = {},
    } = redux

    const {
        NavigateOrderSummary,
        handleSearch
    } = func

    return [
        {
            title: SCAN_LOG_TABLE_LIST_TITLE.hubid,
            key: SCAN_LOG_TABLE_LIST_INDEX.hubid,
            width: 160,
            fixed: "left",
            render: (record) => {

                const { hubid } = record

                const HP = (hubid?.includes('hub') ? 'H' : 'PMBE' || '') + (hub[hubid]?.code || point[hubid]?.code || hubid)

                return exportState ? HP :  (
                    <p>{HP}</p>
                )
            },
            ...searchHDLR.getColumnSearchProps('hubid', handleSearch) 
        },
        {
            title: SCAN_LOG_TABLE_LIST_TITLE.ordid,
            key: SCAN_LOG_TABLE_LIST_INDEX.ordid,
            width: 160,
            fixed: "left",
            render: (record) => {

                const { ordid } = record

                return exportState ? ordid :
                <ButtonLink onClick={() => NavigateOrderSummary(ordid)}>
                {ordid}
              </ButtonLink>
                
            },
            sorter: (a, b) => {
                a = parseInt(a.ordid.replace('P', ''));
                b = parseInt(b.ordid.replace('P', ''));
              
                return a - b;
            },
        },
        {
            title: SCAN_LOG_TABLE_LIST_TITLE.type,
            key: SCAN_LOG_TABLE_LIST_INDEX.type,
            width: 100,
            fixed: "left",
            render: (record) => {

                const { type } = record

                return exportState ? type :
                    <p>{type}</p>
                
            },
            filters: SCAN_TYPE,
            onFilter: (value, record) => record.type === value,
        },
        {
            title: SCAN_LOG_TABLE_LIST_TITLE.scanStatus,
            key: SCAN_LOG_TABLE_LIST_INDEX.scanStatus,
            width: 160,
            fixed: "left",
            render: (record) => {

                const { scanStatus } = record

                const status = scanStatus ? 'SUCCESS' : 'FAIL'

                return exportState ? status :
                    <Tag color={scanStatus ? 'success' : 'error'}>
                        {status}
                    </Tag>
                
                
            },
            filters: SCAN_STATUS.map(status => ({ text: <Tag color={status.color}>{status.text}</Tag>, value: status.value })),
            onFilter: (value, record) => record.scanStatus === value,
        },
        {
            title: SCAN_LOG_TABLE_LIST_TITLE.scannedAt,
            key: SCAN_LOG_TABLE_LIST_INDEX.scannedAt,
            width: 160,
            fixed: "left",
            render: (record) => {

                const { clearedAt } = record

                const time = parseTimeMinDayFirst(clearedAt)

                return exportState ? time  :
                    <p>{time}</p>
                
            },
            sorter: (a, b) => a.clearedAt - b.clearedAt,
        },
        {
            title: SCAN_LOG_TABLE_LIST_TITLE.updatedBy,
            key: SCAN_LOG_TABLE_LIST_INDEX.updatedBy,
            width: 160,
            render: (record) => {

                const { createdBy, hubid } = record

                const didBy = `${createdBy}/ 
                ${createdBy?.includes('IPHOP') ? hubOperator[createdBy]?.name : pointOperator[createdBy]?.name}/ 
                ${createdBy?.includes('IPHOP') ? `Hub Operator ${hub[hubid]?.code}` : `Point Operator ${point[hubid]?.code}`}`
                

                return exportState ? didBy :
                    <p>{didBy}</p>
                
            },...searchHDLR.getColumnSearchProps('createdBy', handleSearch) 
        },
        {
            title: SCAN_LOG_TABLE_LIST_TITLE.remark,
            key: SCAN_LOG_TABLE_LIST_INDEX.remark,
            render: (record) => {

                const { remark } = record

                return exportState ? remark :
                    <p>{remark}</p>
                
            }
        },
    ]
}