import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Space, Result, FloatButton, message, Button } from "antd";
import { useLocation, useNavigate } from "react-router";

// Components
import { OrderHeader, OrderSubmissionForm } from "./components";

// Constant
import { TEMPLATE, ROUTES, COMMON_FEATURES } from "../../../../constants";

// Service
import { TIME, REDUX as REDUX_UTIL } from "../../../../services/util";

// Redux Action
import * as REDUX_ACTION from "../../../../services/redux/actions";

import "./index.css";

const { ACTIONS_LABEL_TITLE, ACTIONS_LABEL, DATA_FIELD } = TEMPLATE.FORMORDER;
const { SpecialFieldHandler, SpecialFieldHandlerSort, ORDER_STATUS } = TEMPLATE.ORDER;
const { reduxStoreWrapper, usePrevious } = REDUX_UTIL;
const { ROUTE_URL } = ROUTES;
const { FEATURE_NAME } = COMMON_FEATURES;
const { dayJS, isDayJS } = TIME;

const OrderSort = (props) => {
  const { ordid = "", label } = props;

  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [messageApi, contextHolder] = message.useMessage();

  const order = useSelector(reduxStoreWrapper(`order.byID.${ordid}`));
  const user = useSelector(reduxStoreWrapper("user"));
  const customers = Object.values(useSelector(reduxStoreWrapper("customer.byID")));
  const hubs = useSelector(reduxStoreWrapper("hub.byStatus"))
  const points = useSelector(reduxStoreWrapper("point.byStatus"))
  const hubsAll = useSelector(reduxStoreWrapper("hub.allIDs"))
  const allPoints = Object.values(useSelector(reduxStoreWrapper("point.byID")))

  const admid = user.memid;
  const doorPick = order && Array.isArray(order.add_on) && order.add_on.every(value => value !== null)

  const matchedCustomer = customers.find(c => c.memid === order.memid);

  const preOrder = usePrevious(order);

  const proceed = ACTIONS_LABEL_TITLE[ACTIONS_LABEL.process_order];
  const sort = ACTIONS_LABEL_TITLE[ACTIONS_LABEL.sort_receiver_order];

  const [ statusinitiatedAttachment, setStatusinitiatedAttachment ] = useState(order.parcel?.img || []) 

  const onBackHDLR = () => {
    navigate(-1);
  };

  const onSaveDraft = () => {
    // await form
    // .validateFields()
    // .then(() => {
      const formData = form.getFieldValue();

      let isDataChanged = false

      let newSrcData = {
        ...order,
        currency: "MYR",
      };
  
      Object.keys(formData).forEach((k) => {
        const v = formData[k];
      
        if (k.includes("_")) {
          const ka = k.split("_");

          if (k === 'add_on') {
            newSrcData[k] = v;
            if (JSON.stringify(order[k]) !== JSON.stringify(newSrcData[k])) {
              isDataChanged = true;
            }
          }
  
          if (SpecialFieldHandler.includes(ka[0])) {
            const pk = ka[0];
            const ck = ka[1];
  
            if (!Object.prototype.hasOwnProperty.call(newSrcData, pk)) {
                newSrcData[pk] = {};
            }

            if(Object.prototype.hasOwnProperty.call(newSrcData, pk)) {

              // if(label === proceed) {
                newSrcData[pk] = { ...newSrcData[pk], [ck]: v };
                if (v !== undefined && v !== null) {
                  if (JSON.stringify(order[pk]) !== JSON.stringify(newSrcData[pk])) {
                    isDataChanged = true;
                  } else if (JSON.stringify(order[pk][ck]) !== JSON.stringify(newSrcData[pk][ck])) {
                    if (Array.isArray(newSrcData[pk][ck])) {
                      if (newSrcData[pk][ck].length > 0) {
                        if (typeof newSrcData[pk][ck] === 'object') {
                          const objectsAreEqual = newSrcData[pk][ck].every(obj =>
                            Object.values(obj).every(val =>
                              JSON.stringify(val) === JSON.stringify(order[pk][ck][obj[Object.keys(obj)]])
                            )
                          );
              
                          if (!objectsAreEqual) {
                            isDataChanged = true;
                          } 
                        } else if (typeof newSrcData[pk][ck] === 'string') {
                          const stringsAreEqual = newSrcData[pk][ck].every((str, index) =>
                            str === order[pk][ck][index]
                          );
                        
                          if (!stringsAreEqual) {
                            isDataChanged = true;
                          }
                        }
                    }
                    }
                  }
              } 
            }
          } 
        } else {
          if (isDayJS(v)) {
            newSrcData[k] = v.utc().valueOf();
            if (order[k] !== undefined && typeof order[k] !== 'object' && newSrcData[k] !== undefined && typeof newSrcData[k] !== 'object') {
              if (order[k] !== newSrcData[k]) {
                isDataChanged = true;
              }
            }
          } else {
            newSrcData[k] = v;
            if (order[k] !== undefined && typeof order[k] !== 'object' && newSrcData[k] !== undefined && typeof newSrcData[k] !== 'object') {
              if (order[k] !== newSrcData[k]) {
                isDataChanged = true;
              } 
            } 
          }
        }
      })

      if (isDataChanged) {
        messageApi.open({
          type: "loading",
          content: "Save order...",
          duration: 0,
        });

        newSrcData.parcel.img = statusinitiatedAttachment
        delete newSrcData.parcel.total

        dispatch(
          REDUX_ACTION.v1_order.update_order_request(
            {
              order: {
                ...newSrcData,
                sorted: label === proceed ? 0 : 0,
                deliveryType: newSrcData.deliveryType,
              },
              statusChange: false,
              orderState: {},
              update: {
                updatedAt: TIME.currentTimeMilliSecond(),
                updatedBy: admid
              },
              
            },
            () => {
              messageApi.destroy();
              onBackHDLR();
            }
          )
        );
      } else {
          message.error("No changes made to the data");
      }

    // })
    // .catch(() => {
    //   message.error('fill the blank');
    // });
  };

  const onOk = async (value) => {
    await form
    .validateFields()
    .then(() => {
      const formData = form.getFieldValue();

      let newSrcData = {
        ...order,
        currency: "MYR",
      };
  
      Object.keys(formData).forEach((k) => {
        const v = formData[k];
  
        if (k.includes("_")) {
          const ka = k.split("_");
  
          if (SpecialFieldHandler.includes(ka[0])) {
            const pk = ka[0];
            const ck = ka[1];
  
            if (!Object.prototype.hasOwnProperty.call(newSrcData, pk)) {
                newSrcData[pk] = {};
            }

            newSrcData[pk] = { ...newSrcData[pk], [ck]: v };

          } 
        } else {
          if (isDayJS(v)) {
            newSrcData[k] = v.utc().valueOf();
          } else {
            newSrcData[k] = v;
          }
        }
        
      })
      
      
      messageApi.open({
        type: "loading",
        content: "Update order...",
        duration: 0,
      });

      newSrcData.parcel.img = statusinitiatedAttachment
      delete newSrcData.parcel.total

      dispatch(
        REDUX_ACTION.v1_order.update_order_request(
          {
            order: {
              ...newSrcData,
              deliveryType: newSrcData.deliveryType,
              sorted: (label === sort || order.status > ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER && order.sorted === 0) ? 1 : 0,
            },
            statusChange:
              order.status > ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER && order.sorted === 0
                ? false
                : order.status <= ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER
                ? true
                : false,
            orderState: {
              ordid,
              sorted: (label === sort || order.status > ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER && order.sorted === 0) ? 1 : 0,
              status:
              label === proceed
                ? (order.deliveryType === 'p2p' ? ORDER_STATUS.PENDING_SELF_LODGE_POINT : (doorPick ? ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER : ORDER_STATUS.PENDING_SELF_LODGE ))
                : (order.deliveryType === 'p2p' ? ORDER_STATUS.ARRIVED_AT_ORIGIN_POINT : ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB),
              remark: value && value.remark,
            },
            update: {
              updatedAt: value && value.initiatedAt,
              updatedBy: admid
            },
            customer: matchedCustomer,
            attachment: value.attachment,
            sorting: order.status > ORDER_STATUS.SUBMITTED && order.sorted === 0 ? true : false
          },
          () => {
            messageApi.destroy();
            onBackHDLR();
          } 
        )
      );

    }).catch(() => {
      message.error('fill the blank');
    });
  };

  useEffect(() => {
    messageApi.open({
      type: "loading",
      content: "get active hubs...",
      duration: 0,
    });

    dispatch(
      REDUX_ACTION.v1_hub.get_active_hubs_request(() => 
        messageApi.destroy())
    )
    dispatch(
      REDUX_ACTION.v1_point.get_active_points_request(() => 
        messageApi.destroy())
    );

    return () => {};
  }, [dispatch]);

  const matchedPointOrig = allPoints.find(p => p.hubid === order.origpid)
  const origid = matchedPointOrig && matchedPointOrig.parentHub
  const matchedPointDest = allPoints.find(p => p.hubid === order.dstpid || order.destpid)
  const dstid = matchedPointDest && matchedPointDest.parentHub 

  const newPrice = order.price || 0

  useEffect(() => {
    if (order && preOrder !== order && hubs) {
      const { parcel = {}, receiver = {}, sender = {}, priceInfo = {} } = order;

      const orderForm = { ...order };
      delete orderForm.parcel;
      delete orderForm.receiver;
      delete orderForm.sender;
      delete orderForm.priceInfo;

      const priceInfoUnsort = 
      Object.values(priceInfo).length === 0 ? 
      [{ addon: 0 }]  : 
      priceInfo.unsort

      const priceInfoSort = priceInfo.sort || priceInfoUnsort;
  
        form.setFieldsValue({
          ...orderForm,
          ...Object.fromEntries(
            Object.entries(parcel).map(([k, v]) => [`parcel_${k}`, v])
          ),
          ...Object.fromEntries(
            Object.entries(receiver).map(([k, v]) => [`receiver_${k}`, v])
          ),
          ...Object.fromEntries(
            Object.entries(sender).map(([k, v]) => [`sender_${k}`, v])
          ),
          priceInfo_unsort: priceInfoUnsort,
          origid: orderForm.origpid ? origid : orderForm.origid,
          dstid: orderForm.dstpid ? dstid : orderForm.dstid,
          price: newPrice && newPrice,
          eta: dayJS(orderForm.eta),
          etd: dayJS(orderForm.etd),
          ...(label === sort && { priceInfo_sort: priceInfoSort })
        });

    }
  }, [order, preOrder, ordid, newPrice]);
  

  return (
    <Space.Compact direction={"vertical"}>
      {contextHolder}
      {(ordid && (
        <>
          <OrderHeader
            form={form}
            ordid={ordid}
            label={label}
            actionLabel={location.state && location.state.label}
            onBack={() => onBackHDLR()}
            progressBTNAction={onOk}
            saveDraft={onSaveDraft}
            doorPick={doorPick}
            order={order}
          />
          <OrderSubmissionForm
            form={form}
            label={label}
            hubs={hubs}
            hubsAll={hubsAll}
            order={order}
            points={points}
            setImgAttach={setStatusinitiatedAttachment}
          />
          <FloatButton.BackTop type={"primary"} />
        </>
      )) || (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, order not found."
          extra={
            <Button
              type="primary"
              onClick={() => navigate(ROUTE_URL[FEATURE_NAME.DASHBOARD])}
            >
              Back Home
            </Button>
          }
        />
      )}
    </Space.Compact>
  );
};

export default OrderSort;
