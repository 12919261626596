import { Modal } from "antd";

// eslint-disable-next-line import/namespace

import styles from "../../../../../../components/ButtonModal/modules/common.module.css";

const ModalImage = (props) => {
  const { isModalOpen, setIsModalOpen, dataOrder } = props;

  return (
    <Modal
      open={isModalOpen}
      footer={null}
      onCancel={setIsModalOpen}
      width={400}
    >
      <div className={styles["content-container"]}>
        <img
          src={dataOrder}
          alt="the logo"
          style={{ width: 290, height: 400, marginLeft: -10 }}
        />
        <p>{dataOrder}</p>
      </div>
    </Modal>
  );
};

export default ModalImage;
