import * as API from "../../api";
import * as ActionTypes from "../action-types";

import { message } from "antd";

import { TIME } from '../../util'

// Constants and Config

// Services

// Redux Action
import {setLoadingAccountManagerList, unsetLoadingAccountManagerList} from "./style";

// Neccessary Actions

export const get_account_manager_list_request =
  () => (dispatch) => {
    dispatch(setLoadingAccountManagerList());


    API.getAccountManagerInfoAll()
      .then((info) => {
        const { status, msg, user } = info;

        if (status === 200) {
          dispatch(get_account_manager_list_success(user));
          message.success("Get Account Manager List Data Successfully");
        } else {
          message.warning(msg);
        }
      })
      .finally(() => {
        dispatch(unsetLoadingAccountManagerList());
      });
  };

  export const create_user_list_remark_request = ({value: remark, newData: user}, setData, cb) => dispatch => {
    remark.createdAt = TIME.currentTimeMilliSecond()
    
    if(!Array.isArray(user.remark)) user.remark = []
    user.remark.push(remark)
    
    API.updateCustomerInfo({
      uid: user.uid, 
      items: user
    }).then(info => {
        const { status, msg, user: newUser} = info

        if(status === 200) {
          dispatch(update_manager_account_success(newUser))
          setData(newUser)
          message.success(msg)
        } else {
          message.warning(msg)
        }
        
      }).finally(() => {
        cb()
      })
  }

  export const update_user_list_remark_request = ({editValue: remark, newData: user}, setData, cb)  => dispatch => {

    const remarks = user.remark.map(p => {
      let newRemark
      if(p.createdAt === remark.createdAt) {
        newRemark = remark
      } else newRemark = p
  
      return newRemark
    })
  
    API.updateCustomerInfo({
      uid: user.uid,
      items: {
        ...user,
        remark: remarks
      }
    }).then(info => {
      const { msg, status, user: newUser} = info
  
      if(status === 200) {
        dispatch(update_manager_account_success(newUser))
        setData(newUser)
        message.success(msg)
      } else {
        message.warning(msg)
      }
    }).finally(() => cb())
  }


  export const delete_user_list_remark_request = ({cel: remark, newData: user}, setData, cb) => dispatch => {
    const newRemark = user.remark.filter(r => r.createdAt !== remark.createdAt)

    API.updateCustomerInfo({
      uid: user.uid,
      items: {
        ...user,
        remark: newRemark
      }
    }).then(info => {
      const { status, msg, user: newUser } = info
      
      if(status === 200) {
        dispatch(update_manager_account_success(newUser))
        setData(newUser)
        message.success("Comment deleted successfully")
      } else {
        message.warning(msg)
      }
    }).finally(() => cb())
  }

  export const generate_qr_cm_request = (uid, cb = () => {}) => dispatch => {

    API.generateQRCM(uid)
    .then(info => {
        const { status, msg, user} = info

        if(status === 200) {
          dispatch(update_manager_account_success(user))
          message.success(msg)
        } else {
          message.warning(msg)
        }
        
      })
      .finally(() => {
        cb()
      })
  }

  export const create_cm_request = ({name, email, contact}, cb) => dispatch => {

    API.createCM({name, email, contact})
    .then(info => {
        const { status, msg, user} = info

        if(status === 200) {
          dispatch(create_cm_success(user))
          message.success(msg)
        } else {
          message.warning(msg)
        }
        
      }).finally(() => { cb() })
  }

  
  export const edit_manager_account_info_request = (user, cb) => dispatch => {

    delete user.key 
  
    API.updateCustomerInfo({
      uid: user.uid,
      items: user
    }).then(info => {
      const { msg, status, user: newUser} = info
  
      if(status === 200) {
        dispatch(update_manager_account_success(newUser))
        message.success(msg)
      } else {
        message.warning(msg)
      }
    }).finally(() => { cb() })
  }



  export const update_manager_account_success = (manager) => {
    return {
      type: ActionTypes.UPDATE_ACCOUNT_MANAGER_INFO,
      manager
    }
  }


const get_account_manager_list_success = (manager) => {
  return {
    type: ActionTypes.GET_ACCOUNT_MANAGER_LIST,
    manager,
  };
};

const create_cm_success = (manager) => {
  return {
    type: ActionTypes.CREATE_ACCOUNT_MANAGER,
    manager
  }
}
