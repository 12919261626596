import * as ActionTypes from "../action-types";

const order_defaultState = {
  byID: {},
  allIDs: [],
  byComment: {}
};

export const order = (state = order_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.ORDER_GET_BY_ORDID: {
      const order = action.order;
      const { ordid } = order;

      if (ordid) {
        newState.byID[ordid] = { ...newState.byID[ordid], ...order };

        if (!newState.allIDs.includes(ordid)) newState.allIDs.push(ordid);
      }

      return newState;
    }
    case ActionTypes.ORDER_UPDATE: {
      const order = action.order;
      const { ordid } = order;

      if (ordid) {
        newState.byID[ordid] = order;

        if (!newState.allIDs.includes(ordid)) newState.allIDs.push(ordid);
      }

      return newState;
    }
    case ActionTypes.ORDER_TIMEFRAME_GET_TRU_PORGID:
    case ActionTypes.ORDER_BULK_SUBMIT: {
      const orders = action.orders;
      
      orders &&
        orders.length &&
        orders.map((order) => {
          const { ordid } = order;

          newState.byID[ordid] = order;
          if (!newState.allIDs.includes(ordid)) {
            newState.allIDs.push(ordid);
          }

          return null;
        });

      return newState;
    }
    case ActionTypes.GET_ORDER_COMMENT_THRU_ORDID: {

      const orderComment = action.orderComment  
      const ordid = action.ordid
      
      newState.byComment[ordid] = orderComment

      return newState
    }

    case ActionTypes.ADD_ORDER_COMMENT: {

      const orderComment = action.orderComment
      const {ordid} = orderComment

      if(!Array.isArray(newState.byComment[ordid])) {
        newState.byComment[ordid] = []
      }
      
      newState.byComment[ordid].push(orderComment)

      return newState
    }

    case ActionTypes.UPDATE_ORDER_COMMENT: {
      const orderComment = action.orderComment
      const { ordid } = orderComment

      newState.byComment[ordid].map((ocomment , i) => {
        
        if(ocomment.createdAt === orderComment.createdAt) {
            newState.byComment[ordid][i] = orderComment
          }
        })
      return newState
    }

    case ActionTypes.ORDER_ASSIGN_VEHICLE: {
      const orders = action.orders;
    
      // Pastikan orders adalah array
      if (Array.isArray(orders)) {
        orders.forEach(order => {
          const { ordid } = order;
    
          // Update newState.byID untuk setiap order
          newState.byID[ordid] = { ...newState.byID[ordid], ...order };
        });
      }
    
      return newState;
    }
    

    default: {
      return state;
    }
  }
};
