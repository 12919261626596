import * as API from "../../api";
import * as ActionTypes from "../action-types";

import { message } from "antd";

import { TIME } from '../../util'



export const generate_report_request = (items, type, cb) => dispatch => {

    API.generateReport({
      ...items,
      type
  })
    .then(info => {
        const { status, msg, reports} = info

        if(status === 200) {
          dispatch(generate_report_success(reports, type))
          message.success("Report has been succesfully generated")
          cb(reports)
        } else {
          message.warning(msg)
        }
        
      }).catch(error => {
        message.error("An error occurred while generating the report. Please try again."); // Display error message
    })
    // .finally(() => { 
    //     cb(); // Callback function
    // });
  }

  const generate_report_success = (reports, rtype) => {
    return {
      type: ActionTypes.GET_REPORT_LIST,
      reports, rtype
    }
  }

  export const reset_report =()=>{
    return{
      type: ActionTypes.RESET_REPORT
    }
  }

  // export const get_reports_request = (cb = () => {}) => dispatch => {
  //   API.generateReport()
  //   .then(info => {
  //       const { status, msg, reports} = info

  //       if(status === 200) {
  //         dispatch(get_reports_success(reports))
  //         message.success(msg)
  //       } else {
  //         message.warning(msg)
  //       }
        
  //     }).finally(() => { cb() })
  // }
  
  // const get_reports_success = (reports) => {
  //   return {
  //       type: ActionTypes.GET_REPORTS,
  //       reports
  //   };
  // }