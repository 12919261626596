// Components

// Styles
import styles from "../../../../common.module.css";

const HeaderSidebar = (props) => {
  const { name, memid, profilePic } = props;

  return (
    <div className={styles["profile"]}>
      <img src={profilePic} alt="" />
      <h1 className={styles["profile-content"]}>{name || "Lee"}</h1>
      <p className={styles["profile-content"]}>{memid || "IPHQ00001"} </p>
    </div>
  );
};

export default HeaderSidebar;
