import * as API from "../../api";
import { getJournalNoteTimeFrameAll } from "../../api";
import * as ActionTypes from "../action-types";
import { batch } from "react-redux";

import { message } from "antd";

// Constants and Config

// Services
import * as Auth from "../../auth";
import { TIME } from "../../util";

// Redux Action
import { get_all_hubs_request } from "./hub";
import { get_all_points_request } from "./point"
import { get_all_order_statuses_request } from "./orderStatus";
import { user_role_get_request } from "./userRoles";
import { get_customer_all_request } from "./customer";
import { setLoadingGeneral, unsetLoadingGeneral } from "./style";

// Neccessary Actions
import { auth_user_sign_out } from "../../../../main/services/redux/actions/user";

const { lastNDay, latestTime } = TIME;

export const user_sign_in_request = (uid) => (dispatch) => {
  dispatch(setLoadingGeneral());
 
  Promise.all([
    API.getInfoByUser(uid),
    getJournalNoteTimeFrameAll({
      startTime: lastNDay(90),
      endTime: latestTime,
    }),
  ])
    .then(([infoUser, infoJournal]) => {
      if (infoUser.errorType === "ValidationException") {
        message.error("Error. Refresh page?");
      } else {
        const { user } = infoUser;
        const { journalNote } = infoJournal;

        batch(() => {
          if (user) {
            dispatch(user_sign_in_success(user));
            dispatch(user_role_get_request(user.uroleid));
            dispatch(journal_note_success(journalNote));
            dispatch(get_customer_all_request());
            // Data Requiring
            dispatch(get_all_order_statuses_request());
            dispatch(get_all_hubs_request());
            dispatch(get_all_points_request())
          } else {
            message.warning("No Verified User Found!");
            dispatch(user_sign_out_request());
          }
        });
      }
    })
    .finally(() => {
      dispatch(unsetLoadingGeneral());
    });
};

const user_sign_in_success = (user) => {
  return {
    type: ActionTypes.USER_SIGN_IN,
    user,
  };
};

export const journal_note_success = (journalNote) => {
  return {
    type: ActionTypes.GET_JOURNAL_NOTE,
    journalNote,
  };
};

export const user_sign_out_request = () => (dispatch) => {
  batch(() => {
    dispatch(auth_user_sign_out());
    dispatch(user_sign_out_success());
  });
};

const user_sign_out_success = () => {
  return {
    type: ActionTypes.USER_SIGN_OUT,
  };
};

export const user_change_password_request =
  (email, oldPassword, newPassword, cb = () => {}) =>
  (dispatch) => {
    Auth.change_password(email, oldPassword, newPassword)
    .then((response) => {
      const { status } = response;
      if (status === 200) {
        cb({ status: 200 });
        batch(() => {
          dispatch(user_change_password_success());
          dispatch(user_sign_out_request());
        });
      } else {
        cb({ status: 404 });
      }
    })
    .catch((err) => {
      cb({});
    });
  };

const user_change_password_success = () => {
  return {
    type: ActionTypes.USER_UPDATE_PASSWORD,
  };
};

export const user_update_data_request =
  (uid, data, cb = () => {}) =>
  (dispatch) => {
    // console.log(uid)
    API.updateUserInfo(uid, data).then((info) => {
      const { status, user, msg } = info;
      if (status === 200) {
        dispatch(user_update_data_success(user));
      } else {
        message.warning(msg);
      }
    });
  };

  const user_update_data_success = (user) => {
    return {
      type: ActionTypes.USER_UPDATE_DATA,
      user,
    };
  };
