import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Select
} from 'antd'

// Constants
import { TEMPLATE } from '../../../../../../constants';

// Style
import classes from "../../../../common.module.css"

const {
  STATE
} = TEMPLATE.FORMORDER

const HubLocation = () => {
    return (
        <Card className={classes['card-container']}>
            <h1 className={classes['card-title']}>HUB LOCATION</h1>

            <Row gutter={15}>
                <Col span={10}>
                    <Form.Item label="Latitude" name={["coordinate", "lat"]}
                        rules={[{required: true}]}
                        // hasFeedback
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item label="Longitude" name={["coordinate", "lon"]}
                        rules={[{required: true}]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <Col span={20}>
                <Form.Item label="Address" name="address"
                    rules={[{required: true}]}
                    hasFeedback
                >
                    <Input.TextArea 
                        placeholder=''
                        autoSize={{
                            minRows: 3,
                            maxRows: 4,
                        }}
                    />
                </Form.Item>
            </Col>

            <Row gutter={15}>
                <Col span={6}>
                    <Form.Item label="Postcode" name="postcode"
                        rules={[{required: true}]}
                        hasFeedback
                    >
                        <Input type='number' placeholder='Eg. 50100'/>
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item label="City" name="city"
                       rules={[{required: true}]}
                        hasFeedback
                    >
                        <Input placeholder='Eg. WP Kuala Lumpur'/>
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item label="State" name="state"
                        rules={[{required: true}]}
                        hasFeedback
                    >
                        <Select
                            style={{
                              width: 170,
                            }}
                            placeholder='-- SELECT --'
                            options={Object.values(STATE).map(p => ({
                              value: p.name,
                              label: p.name
                            }))}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Col span={20}>
                <Form.Item label="Google Map Link" name="url"
                    rules={[{required: true}]}
                    hasFeedback
                >
                    <Input />
                </Form.Item>
            </Col>
        </Card>
    )
}

export default HubLocation

