export const TABLE_TRANSACTION_INDEX = {
  Transaction_Id: "txnid",
  Customer_Id: "memid",
  Name: "name",
  ordid: "ordid",
  Transacted_At: "createdAt",
  Transaction_Status: "status",
  Transaction_Type: "type",
  Before_Transaction: "amount_before",
  Amount: "amount",
  Wallet_Balance: "balance",
  Billing_ID: "jnid",
  Order_ID: "ordid",
  Attachment: "url",
  Transacted_By: "transactedBy",
  Remarks: "remark",
};

export const TABLE_TRANSACTION_TITLE = {
  key: "key",
  Transaction_Id: "Transaction ID",
  Customer_Id: "Customer ID/ Name",
  Transacted_At: "Transacted At",
  Transaction_Status: "Transaction Status",
  Transaction_Type: "Transaction Type",
  Before_Transaction: "Before Transaction",
  Amount: "Amount",
  Wallet_Balance: "Wallet Balance",
  Billing_ID: "Billing ID",
  Order_ID: "Order ID",
  Attachment: "Attachment",
  Transacted_By: "Transacted By",
  Remarks: "Remarks",
};

export const OPTION_DAYS = {
  LAST_30_DAYS: "Last 30 Days",
  LAST_60_DAYS: "Last 60 Days",
  LAST_90_DAYS: "Last 90 Days",
};

export const OPTION_DAYS_VALUE = {
  LAST_30_DAYS: 30,
  LAST_60_DAYS: 60,
  LAST_90_DAYS: 90,
};

export const OPTION_STATUS = {
  REJECTED: 404,
  SUCCESSFUL: 200,
};

export const OPTION_STATUS_TITLE = {
  [OPTION_STATUS.REJECTED]: { name: "Rejected", colorTag: "red", status: 404 },
  [OPTION_STATUS.SUCCESSFUL]: {
    name: "Successful",
    colorTag: "green",
    status: 200,
  },
};

export const OPTION_TYPE = {
  Topup: "TPN",
  Refund: "RFN",
  Payment: "INV",
  Deduction: "DCT"
};

export const OPTION_TYPE_TITLE = {
  [OPTION_TYPE.Topup]: "Top Up",
  [OPTION_TYPE.Refund]: "Refund",
  [OPTION_TYPE.Payment]: "Payment",
  [OPTION_TYPE.Deduction]: "Deduction",
};

export const OPTION_TYPE_TXN = {
  Topup: "TXN001",
  Refund: "TXN002",
  Payment: "TXN003",
  Deduction: "TXN004",
};

export const OPTION_TYPE_TITLE_TXN = {
  [OPTION_TYPE_TXN.Topup]: "Top Up",
  [OPTION_TYPE_TXN.Refund]: "Refund",
  [OPTION_TYPE_TXN.Payment]: "Payment",
  [OPTION_TYPE_TXN.Deduction]: "Deduction",
};

export const OPTION_STATUS_ALL = "ALL";
export const OPTION_TYPE_ALL = "ALL";

export const OPTION_ALL = "ALL";

export const SELECT_OPTION_DAYS = Object.keys(OPTION_DAYS).map((option) => ({
  label: OPTION_DAYS[option],
  value: OPTION_DAYS_VALUE[option],
}));
