// React Thirty Party Library
import React, { useState } from "react";
import { Tag } from "antd";
import { useNavigate } from "react-router";
 
// Constants Library
import { TEMPLATE, ROUTES, COMMON_FEATURES } from "../../../../../../constants";

// Components Library
import { ButtonLink, TableList } from "../../../../../../components";

// Handler Library
import { userListHDLR, tableHDLR } from "../../../../../../handlers";

// Services Library

// Redux Actions

// Styles Library

const {
  TABLE_CUSTOMER_WALLET_INDEX,
  TABLE_CUSTOMER_WALLET_TITLE,
  SELECT_OPTION_DAYS,
  SELECT_OPTION_WALLET,
} = TEMPLATE.CUSTOMERWALLET;
const { WALLET_SUMMARY_COL } = TEMPLATE.REPORT.CUSTOMERWALLET;

const { ROUTE_URL } = ROUTES;

const { FEATURE_NAME } = COMMON_FEATURES;

const TableCustomerWallet = (props) => {
  const {
    dataSourceWallet,
    filteredDataWallet,
    setFilteredDataWallet,
    loading,
    selectedRow,
    setSelectedRow
  } = props;
  const navigate = useNavigate()
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  function handleButtonNext(memid) {
    navigate(ROUTE_URL[FEATURE_NAME.MANAGE_CREDIT], { state: { memid } });
  }

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const columns = [
    ...WALLET_SUMMARY_COL({func: {handleSearch}}),
    {
      title: TABLE_CUSTOMER_WALLET_TITLE.Action,
      key: TABLE_CUSTOMER_WALLET_INDEX.Action,
      render: (record) => {
        const { memid } = record
        return (  
          <ButtonLink onClick={() => handleButtonNext(memid)}>
            Manage Credit
          </ButtonLink>
        )
      },
    },
  ];

  const onSelectRow = (record, selected) => {
    tableHDLR.selectRow(setSelectedRowKeys, setSelectedRow, selectedRow, selectedRowKeys, record, selected)
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelectRow
  };
  
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const sortFunctions = {
    lastTransaction: (a, b) => a.lastTransaction - b.lastTransaction
  };
  
  const filtered = filteredDataWallet?.filter((record) => {
    let include = true;
  
    Object.keys(filteredInfo).forEach((key) => {
      if (filteredInfo[key] && filteredInfo[key].length > 0) {
        include = include && filteredInfo[key].includes(record[key]);
      }
    });
  
    if(searchedColumn === "memid") {
      if ((typeof record.memid === 'string' && record.memid.includes(searchText)) || 
          (typeof record.name === 'string' && record.name.includes(searchText))) {
        include = true;
      }
    }  

    return include;

  }).sort((a, b) => {
    const { columnKey } = sortedInfo;
    return sortFunctions[columnKey] ? sortFunctions[columnKey](a, b) : 0;
  });

  return (
    <>
      <TableList.DataList
        dataSource={dataSourceWallet}
        filteredData={filtered}
        setFilteredData={setFilteredDataWallet}
        optionDay={SELECT_OPTION_DAYS}
        optionZero={SELECT_OPTION_WALLET}
        optionFirst={userListHDLR.userListStatusWallet()}
        dataDate={TABLE_CUSTOMER_WALLET_INDEX.Last_Transaction_Date}
        defaultValue="DATE"
        text0="Wallet Ballance"
        text1="User Status"
        showZeroFilter={true}
        showFirstFilter={true}
        columnData={columns}
        loading={loading}
        rowSelection={rowSelection}
        onChange={handleTableChange}
      />
    </>
  );
};

export default TableCustomerWallet;
