// React Thirty Party Library
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Constants Library
import { ROUTES, COMMON_FEATURES } from "../../../../constants";

// Components Library

import { DrawerRemark } from "../../../../components";
import {
  SearchAccManagerList,
  TableAccManagerList, 
  DrawerFormAddAccManager,
  DrawerFormEditAccManager
} from "./components";

// Handler Library

// Services Library
import { REDUX as REDUX_UTIL } from "../../../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../../../services/redux/actions";

// Styles Library
import classes from "../../../common.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;

const AccountManagerList = () => {
  const dispatch = useDispatch();

  const loading = useSelector(
    reduxStoreWrapper("style.loading.accountManager")
  );

  const loadingCust = useSelector(
    reduxStoreWrapper("style.loading.customer")
  );

  const customers = Object.values(useSelector(reduxStoreWrapper("customer.byID"))).map((c) => ({
    key: c.memid,
    ...c,
  }));

  const dataSource = Object.values(useSelector(reduxStoreWrapper("accountManager.byID"))).map((am) => {
    return {
      key: am.memid,
      ...am,
    };
  });
  
  const [filteredData, setFilteredData] = useState(dataSource);

  useEffect(() => {
    dispatch(
      REDUX_ACTION.v1_accountManager.get_account_manager_list_request()
    );

    return () => {};
  }, [dispatch]);
  

  useEffect(() => {
    if (filteredData !== dataSource) {
      setFilteredData(dataSource);
    }
  }, [dataSource]);

  useEffect(() => {
    setFilteredData(filteredData);
  }, [filteredData]);


  // since the 'edit remark' did not access different route, i created useState data for passing data once the 'edit remark' clicked
  const [data, setData] = useState({})

  const [isOpenAddAccManager, setIsOpenAddAccManager] = useState(false);
  const [isOpenRemark, setIsOpenRemark] = useState(false);
  const [isOpenEditAccManager, setIsOpenEditAccManager] = useState(false);

  const accManager = useSelector(reduxStoreWrapper("accountManager.byID"))
  const submitRemarkHDLR = (items, cb) => dispatch(REDUX_ACTION.v1_accountManager.create_user_list_remark_request(items, setData, cb()))
  const editRemarkHDLR = (items, cb) => dispatch(REDUX_ACTION.v1_accountManager.update_user_list_remark_request(items, setData, cb()))
  const deleteRemarkHDLR = (items, cb) => dispatch(REDUX_ACTION.v1_accountManager.delete_user_list_remark_request(items, setData, cb()))

  const remarks = () => {
    return (
      <>
        <DrawerFormAddAccManager isOpen={isOpenAddAccManager} setIsOpen={setIsOpenAddAccManager} />
        <DrawerRemark 
            isOpen={isOpenRemark} 
            setIsOpen={setIsOpenRemark} 
            data={data}
            reduxData2={accManager}
            submitRemarkHDLR={submitRemarkHDLR}
            editRemarkHDLR={editRemarkHDLR}
            deleteRemarkHDLR={deleteRemarkHDLR}
        />
        <DrawerFormEditAccManager isOpen={isOpenEditAccManager} setIsOpen={setIsOpenEditAccManager} data={data}/>
      </>
    )
  }

  const [selectedRow, setSelectedRow] = useState([]);

  return (
    <div className={classes.container}>
      {remarks()}
      <SearchAccManagerList
        setFilteredData={setFilteredData}
        setIsOpen={setIsOpenAddAccManager}
        dataSource={dataSource}
        filteredData={filteredData}
        selectedRow={selectedRow}
      />
      <TableAccManagerList
        setData={setData}
        dataSource={dataSource}
        setIsOpenRemark={setIsOpenRemark}
        setIsOpenEditAccManager={setIsOpenEditAccManager}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        loading={loading}
        loadingCust={loadingCust}
        customerData={customers}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </div>
  );
};

export default AccountManagerList;
