import { Drawer, Form, Input, Select, message} from "antd";
import { useDispatch} from "react-redux";

import { NumericInput, BtnSubmit } from "../../../../../../components";

import * as REDUX_ACTION from "../../../../../../services/redux/actions";

import classStyles from "./index.module.css";

const DrawerFormAddAccManager = ({ isOpen, setIsOpen }) => {

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const prefixContactSelector = (
    <Form.Item name="contactPrefix" noStyle>
      <Select style={{ width: 70 }}>
        <Select.Option value="+60">+60</Select.Option>
      </Select>
    </Form.Item>
  );

  const handleAddCM = () => {
    const formData = form.getFieldsValue()
    const nameCM = formData.name
    const emailCM = formData.email
    const contactCM = formData.contactPrefix + " " + formData.contact

    messageApi.open({
      type: "loading",
      content: "Creating...",
      duration: 0,
    });
    

    dispatch(REDUX_ACTION.v1_accountManager.create_cm_request({name : nameCM, email : emailCM, contact : contactCM}, () => messageApi.destroy()));
  }

  const ActionButton = (
    <Form.Item style={{ paddingTop: "20px" }}>
      <div>
        <BtnSubmit
          display="inline-block"
          text="Cancel"
          bgColor="transparent"
          color="var(--bs-gray-600)"
          padding="9px 18px"
          onClick={() => {
            setIsOpen(false);
          }}
        />

        <BtnSubmit
          display="inline-block"
          text="Add & create another"
          bgColor="transparent"
          color="var(--themeBackgroundColor)"
          padding="9px 18px"
          border="1px solid var(--themeBackgroundColor)"
          onClick={() => {
            handleAddCM()
            form.resetFields();
          }}
        />
        <BtnSubmit
          display="inline-block"
          text="Add"
          bgColor="var(--themeBackgroundColor)"
          color="var(--creamColor)"
          padding="9px 18px"
          onClick={() => {
            setIsOpen(false);
            handleAddCM()
          }}
        />
      </div>
    </Form.Item>
  );

  return (
    <>
    {contextHolder}
    <Drawer
      title="Add Account Manager"
      placement="right"
      closable={false}
      width={410}
      onClose={() => setIsOpen(false)}
      footer={ActionButton}
      open={isOpen}
      style={{
        textAlign: "center",
        fontSize: "20px",
      }}
    >
      <Form
        form={form} 
        name="form_login"
        layout="vertical"
        initialValues={{
          contactPrefix: "+60",
        }}
      >
        <Form.Item
          name="name"
          label={<span>Name</span>}
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
          hasFeedback
        >
          <Input className={classStyles.inputField} />
        </Form.Item>
        <Form.Item
          name="email"
          label={<span>Email</span>}
          rules={[
            {
              required: true,
              message: "Email is required",
              type: "email",
            },
          ]}
          hasFeedback
        >
          <Input className={classStyles.inputField} />
        </Form.Item>
        <Form.Item
          name="contact"
          label={<span>Contact</span>}
          rules={[
            {
              required: true,
              message: "Contact is required",
            },
          ]}
          hasFeedback
        >
          <NumericInput
            addonBefore={prefixContactSelector}
          />
        </Form.Item>
      </Form>
    </Drawer>
    </>
  );
};

export default DrawerFormAddAccManager;
