import * as ActionTypes from '../action-types'

const orderStatus_defaultState = {
    byID: {},
    allIDs: []
}

export const orderStatus = (state = orderStatus_defaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.ORDER_STATUS_GET_ALL: {
            const orderStatuses = action.orderStatuses;

            orderStatuses
            &&
            orderStatuses.length
            &&
            orderStatuses.map(os => {

                const { status } = os

                newState.byID[status] = os
                if(!newState.allIDs.includes(status)) newState.allIDs.push(status)

                return null
            })

            return newState;
        }
        default: {
            return state;
        }
    }
}