import { message } from "antd";

// Components
import { ButtonLink,ButtonModal } from "../../../../../../../../components";
import { TEMPLATE } from "../../../../../../../../constants";
import * as REDUX_ACTION from "../../../../../../../../services/redux/actions"

const { ACTIONS_LABEL, ACTIONS_LABEL_TITLE } = TEMPLATE.HUB
const { MODAL_ACTION } = TEMPLATE.MODAL;

const edit = ACTIONS_LABEL_TITLE[ACTIONS_LABEL.edit_hub]

  export const ActionItems = (record, setIsOpenAssign, setData, NavigateToPage, dispatch, userMemid) => {
    const { hubid, status } = record

    const recordHub = { ...record };
	delete recordHub.key;

    return [
        {
            key: 0,
            label: (
                <ButtonLink>
                    Edit
                </ButtonLink>
            ),
            onClick: async () => {
                  NavigateToPage(hubid, edit, record)
            }
        },
        {
            key: 1,
            label: (
                // If status 1 then needs to be 0 and vice versa 
                <ButtonModal
                    model='link'
                    extra={record}
                    setmodal={status === 1 ? MODAL_ACTION.hide_point : MODAL_ACTION.show_point}
                    onClick={() => {
                        message.loading('Updating the point status...', 0)

                        dispatch(REDUX_ACTION.v1_point.point_update_status_request({
                            hubid,
                            items: {
                                ...record, 
                                status: status === 1 ? 0 : 1, 
                                updatedBy: userMemid
                              }},
                            () => message.destroy()
                        ))
                    }}
                >
                    {(status === 1 ? 'Hide' : 'Show') + ' point' }
                </ButtonModal>
            )
        },
        {
            key: 2,
            label: (
                <ButtonLink>
                    Assign point operator
                </ButtonLink>
            ),
            onClick: () => {
              
                message.loading("Get operators data...", 0);
                setIsOpenAssign(true);
        
                try {
                    dispatch(REDUX_ACTION.v1_hubOperator.get_operator_thru_hubid_request(record.hubid, () => message.destroy()));
                    setData(record.hubid);
                } catch (error) {
                    message.error("An error occurred while fetching data.");
                }
            }
        }
    ]
}
