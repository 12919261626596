export const TABLE_VEHICLE_INDEX = {
    Plate: "plate",
    Status: "status",
    isTracking: "istracking",
    Driver: "driver",
    Movement_Status: "movement",
    Duration: "duration",
    Speed: "speed",
    Location: "location",
    Remarks: "remark",
    CreatedAt: "createdAt",
    UpdatedAt: "updatedAt",
  };
  
  export const TABLE_VEHICLE_TITLE = {
    key: "key",
    Plate: "Vehicle Plate",
    Status: "Status",
    isTracking: "Live Tracking",
    Driver: "Bus Operator",
    Movement_Status: "Movement Status",
    Duration: "Duration",
    Speed: "Speed",
    Location: "Location",
    Remarks: "Remarks",
    CreatedAt: "Created At",
    UpdatedAt: "Last Updated At",
    actions: "Actions"
  };
  
  export const OPTION_DAYS = {
    LAST_30_DAYS: "Last 30 Days",
    LAST_60_DAYS: "Last 60 Days",
    LAST_90_DAYS: "Last 90 Days",
  };
  
  export const OPTION_DAYS_VALUE = {
    LAST_30_DAYS: 30,
    LAST_60_DAYS: 60,
    LAST_90_DAYS: 90,
  };

  export const SELECT_OPTION_DAYS = Object.keys(OPTION_DAYS).map((option) => ({
    label: OPTION_DAYS[option],
    value: OPTION_DAYS_VALUE[option],
  }));

  export const OPTION_VEHICLE_STATUS = {
    Active: 1,
    Inactive: 0,
  } 

  export const OPTION_VEHICLE_STATUS_TITLE = {
    [OPTION_VEHICLE_STATUS.Active]: { name: "ACTIVE", colorTag: "success", status: 1 },
    [OPTION_VEHICLE_STATUS.Inactive]: { name: "INACTIVE", colorTag: "default", status: 0 },
  };
  
  export const OPTION_STATUS = {
    NULL: 404,
    MOVING: 200,
    IDLING: 201,
    PARKING: 202,
    DISCONNECTED: 203,
  };
  
  export const OPTION_STATUS_TITLE = {
    [OPTION_STATUS.DISCONNECTED]: { name: "Disconnected", colorTag: "red", status: 203},
    [OPTION_STATUS.IDLING]: { name: "Idling", colorTag: "yellow", status: 201},
    [OPTION_STATUS.PARKING]: { name: "Parking", colorTag: "blue", status: 202},
    [OPTION_STATUS.MOVING]: { name: "Moving", colorTag: "green", status: 200},
    [OPTION_STATUS.NULL]: { name: "Null", colorTag: "black", status: 404},
  };
  