// React Thirty Party Library
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Spin, message } from "antd";
 
// Constants Library
import { TEMPLATE } from "../../../../../../constants";

// Components Library
import { ButtonLink, TableList, DrawerComments } from "../../../../../../components";
import { ActionItemsFunc } from "./action";
import ProgressTable from "../ProgressTable";
import ModalImage from "../ModalImage";

// Handler Library
import { orderHDLR, tableHDLR } from "../../../../../../handlers";

// Services Library
import { REDUX as REDUX_UTIL } from "../../../../../../services/util";
import * as CONFIG from "../../../../../../config";

// Redux Actions
import * as REDUX_ACTION from "../../../../../../services/redux/actions";

// Styles Library
import classes from "../../../../../common.module.css";
import DrawerAssign from "../DrawerAssign";
import DrawerBulkAssign from "../DrawerBulkAssign";

const {
  TABLE_ORDERLIST_INDEX,
  TABLE_ORDERLIST_TITLE,
  SELECT_OPTION_DAYS,
} = TEMPLATE.ORDERLIST;

const { ORDER_SUMMARY_COL } = TEMPLATE.REPORT.ORDER;

const { reduxStoreWrapper } = REDUX_UTIL;


const TableOrderlist = ({
  filteredData,
  setFilteredData,
  dataSource,
  loading,
  SORenavigate,
  PrintSO,
  UpdateOrder,
  NavigateOrderSort,
  NavigateOrderSummary,
  NavigateOrderTimeline,
  selectedRow,
  setSelectedRow,
  isOpenAddBulkAssign,
  setIsOpenAddBulkAssign,
}) => {

  const dispatch = useDispatch()

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 

  const [isOpen, setIsOpen] = useState(false) 
  const [data, setData] = useState({})
	const [isOpenAdd, setIsOpenAdd] = useState(false)

  const orderStates = useSelector(reduxStoreWrapper("orderState.byID"));
  const orderSorted = useSelector(reduxStoreWrapper("orderState.bySorted"));
  const orderStatuses = useSelector(reduxStoreWrapper("orderStatus.byID"));
  const orderLogs = Object.values(useSelector(reduxStoreWrapper("orderLog.byID")));
  const hubs = useSelector(reduxStoreWrapper("hub.byID"));
  const points = useSelector(reduxStoreWrapper("point.byID"));
  const customers = Object.values(useSelector(reduxStoreWrapper("customer.byID")));
  const comments = useSelector(reduxStoreWrapper("order.byComment"))
  const orderLog = useSelector(reduxStoreWrapper('orderLog.byID'))
  const operators = useSelector(reduxStoreWrapper("hubOperator.byID"));
  const admins = useSelector(reduxStoreWrapper("user")); 
  const vehicle = useSelector(reduxStoreWrapper('vehicle.byPlate'))

  const handleMenuClick = (ordid) => {
    dispatch(REDUX_ACTION.v1_orderLog.get_order_logs_thru_ordid_request(ordid))
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        message.error(error);
      });
  };

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const navigateMap = (ordid) => {
    const map = `https://${CONFIG.APP_CONFIG.PT_URL}?ordid=${ordid}`;
    window.open(map, "_blank");
  };


  const columns = [ 
    ...ORDER_SUMMARY_COL({
      redux: {
        orderSorted,
        orderStates,
        orderStatuses,
        hubs,
        points,
        comments,
        orderLog,
        operators,
        admins,
        vehicle
      },
      func: {
        NavigateOrderSummary,
        navigateMap,
        SORenavigate,
        setIsOpen,
        setData,
        handleSearch,
      },
    }),
    {
      title: TABLE_ORDERLIST_TITLE.Actions,
      key: TABLE_ORDERLIST_INDEX.Actions,
      width: 100,
      fixed: "right",
      render: (record) => {
        const { ordid, deliveryType, memid } = record;


      const orderlog = Object.values(orderLogs)
          .flatMap(arr => arr.filter(item => item.ordid === record.ordid))
          .map(m => m.status);

	    const customer = customers.find(c => c.memid === memid);

      const commentDataHDLR = () => {
        !comments[ordid] 
        &&
        dispatch(REDUX_ACTION.v1_order.get_order_comment_request(ordid))
      }

      const menuItems = 
      isLoading ? (
        [
          {
            key: 'loading',
            label: (
              <div>
                <Spin size="small" />
              </div>
            ),
          },
        ]
      ) : (
        ActionItemsFunc({
          record,
          PrintSO,
          UpdateOrder,
          NavigateOrderSort,
          deliveryType,
          setData,
          setIsOpen,
          customer,
          orderlog,
          commentDataHDLR,
          admins,
          setIsOpenAdd,
          setSelectedRecord
        })
      );

        return (
          <Dropdown
            trigger={['click']}
            menu={{
              items: menuItems
            }}
            placement="bottom"
          >
              <ButtonLink onClick={() => handleMenuClick(ordid)}>Action</ButtonLink>
          </Dropdown>

        );
      },
    },
  ];

  const expandedRowRender = (record) => {
    const { ordid } = record;

    return (<>
    <ProgressTable record={record} hubs={hubs} NavigateOrderTimeline={NavigateOrderTimeline} points={points}/>
    <ModalImage
      isModalOpen={isModalOpen && selectedRecord}
      setIsModalOpen={() => setIsModalOpen(false)}
      dataOrder={selectedRecord}
    />
    </>
    );
  };

  const parcelNew = arr => {
        if (!Array.isArray(arr)) {
          return {};
        }
        
        return arr.reduce((acc, p) => {
          return { 
            length: p.length,
            width: p.width,
            height: p.height,
            weight: p.weight
          };
        }, {});
  };

  const OrderSummaryTotalPiecesEst = (srcData) =>
    srcData
      .reduce((sum, record) => {
        const { parcel = {} } = record;
        return sum + parseFloat(parcel.total_pieces || 1);
      }, 0)

  const OrderSummaryTotalWeightEst = (srcData) => 
    srcData
      .reduce((sum, record) => {
        const { parcel = {} } = record;
        const { unsort = [] } = parcel
        const par = unsort ? parcelNew(unsort) : parcel
        return sum + parseFloat(par.weight || 0);
      }, 0)


  const OrderSummaryTotalVolumeEst = (srcData) =>
    srcData 
      .reduce((sum, record) => {
        const { parcel = {} } = record;
        const { unsort = [] } = parcel
        const par = unsort ? parcelNew(unsort) : parcel
        return (
          sum +
          (parseFloat(par.length || 0) *
            parseFloat(par.width || 0) *
            parseFloat(par.height || 0)) 
            /
            1000000
        );
      }, 0)
      .toFixed(6);

      const OrderSummaryTotalPiecesSort = (srcData) =>
      srcData
        .reduce((sum, record) => {
          const { parcel = {} } = record;
          return sum + parseFloat(parcel.total_pieces || 1);
        }, 0)
  
    const OrderSummaryTotalWeightSort = (srcData) => 
      srcData
        .reduce((sum, record) => {
          const { parcel = {} } = record;
          const { sort: newSort = [], sort: oldSort = {} } = parcel
          const par = newSort ? parcelNew(newSort) : oldSort
          return sum + parseFloat(par.weight || 0);
        }, 0)
  
  
    const OrderSummaryTotalVolumeSort = (srcData) =>
      srcData 
        .reduce((sum, record) => {
          const { parcel = {} } = record;
          const { sort: newSort = [], sort: oldSort = {} } = parcel
          const par = newSort ? parcelNew(newSort) : oldSort
          return (
            sum +
            (parseFloat(par.length || 0) *
              parseFloat(par.width || 0) *
              parseFloat(par.height || 0)) 
              /
              1000000
          );
        }, 0)
        .toFixed(6);

      const onSelectRow = (record, selected) => {
        tableHDLR.selectRow(setSelectedRowKeys, setSelectedRow, selectedRow, selectedRowKeys, record, selected)
      };
 
      const rowSelection = {
        selectedRowKeys,
        onSelect: onSelectRow
      };
      

  const sortedData = filteredData?.sort((a, b) => b.updatedAt - a.updatedAt);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const sortFunctions = {
    printedAt: (a, b) => a.printedAt - b.printedAt,
    createdAt: (a, b) => a.createdAt - b.createdAt,
    updatedAt: (a, b) => a.updatedAt - b.updatedAt,
  };
  
  const filtered = sortedData?.filter((record) => {
    let include = true;
  
    Object.keys(filteredInfo).forEach((key) => {
      if (filteredInfo[key] && filteredInfo[key].length > 0) {
        include = include && filteredInfo[key].includes(record[key]);
      }
    });

    if (searchText) {
      if (searchedColumn === "memid") {
        if ((typeof record.memid === 'string' && record.memid.includes(searchText)) ||
          (typeof record.name === 'string' && record.name && record.name.includes(searchText)) || 
          (typeof record.memid === 'string' && record.contact && record.contact.includes(searchText))
        ) {
            include = true;
        }
      }
    }    

    return include;

  }).sort((a, b) => {
    const { columnKey } = sortedInfo;
    return sortFunctions[columnKey] ? sortFunctions[columnKey](a, b) : 0;
  });

  return (
    <>
    <DrawerComments data={data} isOpen={isOpen} setIsOpen={setIsOpen}/>
    <DrawerBulkAssign isOpen={isOpenAddBulkAssign} setIsOpen={setIsOpenAddBulkAssign} data={selectedRowKeys}/>
    <DrawerAssign isOpen={isOpenAdd} setIsOpen={setIsOpenAdd} data={selectedRecord} />
    {selectedRow.length > 0 &&
    <div style={{zIndex:"20", marginBottom:"0.8rem", backgroundColor:"white", marginTop:"-0.8rem"}}>
      <div
        className={classes.cardSummary}
      >
        <div className={classes.square}>
          <span>SELECT SUMMARY</span>
        </div>
        <div style={{ width: "60vw"}}>
        <div className={classes.totalSummary}>
          <span style={{ marginRight: 7 }}>
            Total Orders: {selectedRow.length}
          </span>
          <span style={{ marginRight: 7 }}>
            Total Est Pieces (kg): {OrderSummaryTotalPiecesEst(selectedRow)}
          </span>
          <span style={{ marginRight: 7 }}>
            Total Est Weight (kg): {OrderSummaryTotalWeightEst(selectedRow)}
          </span>
          <span>
            Total Est Volume (m3): {OrderSummaryTotalVolumeEst(selectedRow)}
          </span>
        </div>
        <div className={classes.totalSummary}>
          <span style={{ marginRight: 7 }}>
            Total Sorted Pieces (kg): {OrderSummaryTotalPiecesSort(selectedRow)}
          </span>
          <span style={{ marginRight: 7 }}>
            Total Sorted Weight (kg): {OrderSummaryTotalWeightSort(selectedRow)}
          </span>
          <span>
            Total Sorted Volume (m3): {OrderSummaryTotalVolumeSort(selectedRow)}
          </span>
        </div>
      </div>
      </div>
    </div>
 }

      <div style={{ marginTop: selectedRow.length > 0 ? "7.5rem" : "2rem" }}>
        <TableList.DataList
          dataSource={dataSource}
          filteredData={filtered}
          setFilteredData={setFilteredData}
          optionDay={SELECT_OPTION_DAYS}
          optionFirst={orderHDLR.orderStatusesTreeStructure(orderStatuses)}
          optionSecond={orderHDLR.orderOrigin(hubs)}
          optionThird={orderHDLR.orderDestination(hubs)}
          dataDate={TABLE_ORDERLIST_INDEX.Created_At}
          defaultValue="Date"
          text1="Status"
          text2="Origin"
          text3="Destination"
          showFirstFilter={true}
          showSecondFilter={true}
          showThirdFilter={true}
          columnData={columns}
          loading={loading}
          expandedRowRender={expandedRowRender}
          rowSelection={rowSelection}
          onChange={handleTableChange}
        />
        </div>
    </>
  );
};

export default TableOrderlist;
