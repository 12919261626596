import React from "react";

// Constants & Config

// Components
import Layout from "../v1/layout";
import MainRoutes from "../v1/services/navigation/route/main";

// Route

// Service

// styles
import "./index.css";

const App = (props) => {
  return (
    <>
      <Layout>
        <MainRoutes {...props} />
      </Layout>
    </>
  );
};

export default React.memo(App);
