import * as ActionTypes from '../action-types'

const user_defaultState = {
    accountPackages: []
}

export const user = (state = user_defaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.USER_SIGN_IN: 
        case ActionTypes.USER_UPDATE_DATA: {
            let user = action.user;

            return({
                ...newState,
                ...user
            });
        }
        case ActionTypes.USER_UPDATE_PASSWORD: {
            return newState
        }
        default: {
            return state;
        }
    }
}
