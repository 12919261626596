import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { SearchParkedList, TableParkedList } from "./components";

import { REDUX as REDUX_UTIL } from "../../services/util";

import * as REDUX_ACTION from "../../services/redux/actions";

import styles from "../common.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;

const OrderParkedList = () => {
  const dispatch = useDispatch();

  const parkedOrder = useSelector(reduxStoreWrapper("parkedOrder.byID"));
  const loading = useSelector(reduxStoreWrapper("style.loading.park_order"));

  const dataPO =  Object.values(parkedOrder).map((po) => ({
    key: po.oparkid,
    ...po,
  }))
  const dataSource = dataPO.sort((a, b) => b.createdAt - a.createdAt)

  const [filteredData, setFilteredData] = useState(dataSource);

  useEffect(() => {
    dispatch(REDUX_ACTION.v1_parkOrder.get_park_order_all_request());
    dispatch(
      REDUX_ACTION.v1_order.get_order_timeframe_thru_porgid_last_90_days_request(
          ["ipick"]
      ))

    return () => {};
  }, [dispatch]);

  useEffect(() => {
    if (filteredData !== dataSource) {
      setFilteredData(dataSource);
    }

  }, [dataSource]);

  useEffect(() => {
    setFilteredData(filteredData);

  }, [filteredData]);

  const [selectedRow, setSelectedRow] = useState([]);

  return (
    <div className={styles.container}>
      <SearchParkedList
        dataSource={dataSource}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        selectedRow={selectedRow}
      />
      <TableParkedList
        dataSource={dataSource}
        filteredData={filteredData}
        loading={loading}
        setFilteredData={setFilteredData}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </div>
  );
};

export default OrderParkedList;
