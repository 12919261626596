import { Drawer, Form, Input, message, Row } from "antd";
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { BtnSubmit } from "../../../../components";

import { REDUX as REDUX_UTIL, TIME } from "../../../../services/util";

import * as REDUX_ACTION from "../../../../services/redux/actions";

import styles from "../../../common.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;

const DrawerAddZone = (props) => {
  const { isOpen, setIsOpen } = props
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const memid = useSelector(reduxStoreWrapper("user.memid"));

  const handleSave = () => {

    const formData = form.getFieldsValue()

    messageApi.open({
      type: "loading",
      content: "creating...",
      duration: 0,
    });


    dispatch(REDUX_ACTION.v1_zone.create_zone_request({
      name: formData.name,
      adpMemid: memid
    }, () => messageApi.destroy()));
   
    setIsOpen(false);
  }

  const ActionButton = (
    <Form.Item style={{ paddingTop: "20px" }}>
      <div>
        <BtnSubmit
          display="inline-block"
          text="Add"
          bgColor="var(--themeBackgroundColor)"
          color="var(--creamColor)"
          padding="9px 18px"
          onClick={() => {
            handleSave();
            form.resetFields();
            setIsOpen(false);
          }}
        />
        <BtnSubmit
          display="inline-block"
          text="Add & create another"
          bgColor="transparent"
          color="var(--themeBackgroundColor)"
          padding="9px 18px"
          border="1px solid var(--themeBackgroundColor)"
          onClick={() => {
            handleSave();
            form.resetFields();
          }}
        />
        <BtnSubmit
          display="inline-block"
          text="Cancel"
          bgColor="transparent"
          color="var(--bs-gray-600)"
          padding="9px 18px"
          onClick={() => {
            setIsOpen(false);
            form.resetFields();
          }}
        />
      </div>
    </Form.Item>
  );

  return (
    <>
    {contextHolder}
    <Drawer
      title="Add Zone"
      placement="right"
      closable={false}
      width={410}
      onClose={() => setIsOpen(false)}
      footer={ActionButton}
      open={isOpen}
      style={{
        textAlign: "center",
        fontSize: "20px",
      }}
    >
    <Form
      form={form}
      layout="vertical"
    >
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "input the zone name",
            },
          ]}
        >
        <Row>
        <div className={styles.groupInput}>Zone Name</div>
        <Input className={styles.inputForm} />
        </Row>
        </Form.Item>
      </Form>
    </Drawer>
    </>
  );
};

export default DrawerAddZone;
