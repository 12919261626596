// React Thirty Party Library
import React, { useState } from "react";
import { Dropdown, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router"; 
import { ButtonLink } from "../../../../../v1/components";

// Constants Library
import { COMMON_FEATURES, TEMPLATE , ROUTES} from "../../../../constants";


// Components Library
import { TableList } from "../../../../components";
import { ActionItems } from "./actions"
import DrawerEditVehicle from "../DrawerEditVehicle"

// Handler Library
import { transactionHDLR, reactHDLR, vehicleHDLR, tableHDLR  } from "../../../../handlers";

// Services Library
import { REDUX as REDUX_UTIL} from "../../../../services/util";
import * as REDUX_ACTION from "../../../../services/redux/actions";

// Redux Actions
 
// Styles Library

const { TABLE_VEHICLE_INDEX, TABLE_VEHICLE_TITLE, SELECT_OPTION_DAYS,  } =
  TEMPLATE.VEHICLE_LIST
const { VEHICLE_SUMMARY_COL } = TEMPLATE.REPORT.VEHICLE;

const { ROUTE_URL } = ROUTES ;

const { reduxStoreWrapper } = REDUX_UTIL;

const TableVehicle = (props) => {
  const {
    dataSource, 
    filteredData,
    setfilteredData,
    loading, 
    selectedRow,
    setSelectedRow
  } = props;

  const dispatch = useDispatch();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [data, setData] = useState({})

  const handleModal = (record) => {
    setSelectedRecord(record);
    setIsModalOpen(true);
  };

  const navigate = useNavigate();

  const NavigateOrderSummary = (ordid) =>{
    navigate(ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.ORDER_SUMMARY],{
      state : {ordid},
    })
  }

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const navigateMapView = (plate, vid) => {
    navigate(ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.VEHICLE_MAP], {
      state: { plate, vid },
    })
  }

  const handleMark = (record, action) => {
    let updatedStatus;
    if (action === 'active' && record.status === 0) {
        updatedStatus = 1;
    } else if (action === 'inactive' && record.status === 1) {
        updatedStatus = 0;
    } else {
      console.error('Invalid action or status');
    }
    
    if (updatedStatus !== undefined) {
        const updatedRecord = { ...record, status: updatedStatus };
        dispatch(REDUX_ACTION.v1_vehicle.update_status_vehicle_request(updatedRecord));
    } else {
        console.error('Invalid action or status');
    }
  }; 

  const columns = [
    ...VEHICLE_SUMMARY_COL({
      func: {
        handleModal,
        NavigateOrderSummary,
        handleSearch
      }
    }),
    {
        title: TABLE_VEHICLE_TITLE.actions,
        width: 100,
        fixed: "right",
        render: (_, record) => {
        return(
            <Dropdown
              menu={{
                    items: ActionItems(record, setIsOpenEdit, setData, handleMark, navigateMapView)
              }}>
            <ButtonLink>Actions</ButtonLink>
            </Dropdown>
        )
        }
    },
    ]

  const onSelectRow = (record, selected) => {
    tableHDLR.selectRow(setSelectedRowKeys, setSelectedRow, selectedRow, selectedRowKeys, record, selected)
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelectRow
  };

  const sortedData = filteredData.sort(
    (a, b) => b.createdAt - a.createdAt
  );

  
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const sortFunctions = {
    createdAt: (a, b) => a.createdAt - b.createdAt,
  };
  
  const filtered = sortedData.filter((record) => {
    let include = true;
  
    Object.keys(filteredInfo).forEach((key) => {
      if (filteredInfo[key] && filteredInfo[key].length > 0) {
        include = include && filteredInfo[key].includes(record[key]);
      }
    }); 
  
    if (searchText) {
      if(searchedColumn === "plate") {
        if ((
          typeof record.plate === 'string' && 
          record.plate.toLowerCase().includes(searchText.toLowerCase()))) {
          include = true;
        }
      }
      if(searchedColumn === "operator") {
        if ((
          typeof record.operator === 'string' && 
          record.operator.toLowerCase().includes(searchText.toLowerCase()))) {
          include = true;
        }
      }
    }   

    return include;

  }).sort((a, b) => {
    const { columnKey } = sortedInfo;
    return sortFunctions[columnKey] ? sortFunctions[columnKey](a, b) : 0;
  });


  return (
    <>
    <DrawerEditVehicle isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} data={data}/>
      <TableList.DataList
        dataSource={dataSource}
        filteredData={filtered}
        setFilteredData={setfilteredData}
        optionDay={SELECT_OPTION_DAYS}
        optionFirst={vehicleHDLR.vecStatus()}
        dataDate={TABLE_VEHICLE_INDEX.CreatedAt}
        defaultValue="Date"
        text1="Vehicle Status"
        showFirstFilter={true}
        showSecondFilter={false}
        showThirdFilter={false}
        columnData={columns}
        loading={loading}
        rowSelection={rowSelection}
        onChange={handleTableChange}
      />
    </>
  );
};

export default TableVehicle;
