// Third-party Library
import { Tag, Image, Carousel } from "antd";

// Constants

// Components
import NoImage from "../NoImage";

import { TIME } from "../../../../../../services/util";

// Styles
import classes from "../../index.module.css";

const TimelineHightlight = ({
  orderLogs,
  orderStatuses,
  status,
  hubs,
  point,
  record,
}) => {
  const colorTag = orderStatuses[status] && orderStatuses[status].colorTag;
  const order = orderLogs && orderLogs.find((o) => o.status === status);

  const statusNameTitle =
    orderStatuses[status] && orderStatuses[status].display;
  
      const statusNameHub = () => {
        if (status === "O0300" || status === "O0203") {
          return `${statusNameTitle} (${
            hubs[record.origid] && hubs[record.origid].code
          })`;
        } else if (status === "O0400" || status === "O0503") {
          return `${statusNameTitle} (${
            hubs[record.dstid] && hubs[record.dstid].code
          })`;
        } else if (status === "O1200" || status === "O1300") {
          return `${statusNameTitle} (${
            point[record.origpid] && point[record.origpid].code
          })`;
        } else if (status === "O1400" || status === "O1500") {
          return `${statusNameTitle} (${
            point[record.dstpid] && point[record.dstpid].code
          })`;
        }
      };
  
      const hubName = statusNameHub();
      const statusName =
        status === "O0300" || status === "O0400" || status === "O1200" || status === "O1400" || status === "O0203" || status === "O1300" || status === "O0503" || status === "O1500" ? hubName : statusNameTitle;

  return (
    <div className={classes["highlight-timeline"]}>
      {order && order.attachment.length > 0 ? (
        <Carousel autoplay>
          {order &&
            order.attachment.map((a) => (
              <Image
                width={200}
                height={200}
                style={{ borderRadius: "8px" }}
                src={a.url}
              />
            ))}
        </Carousel>
      ) : (
        <NoImage />
      )}
      <div>
        <div style={{ marginBottom: "30px" }}>
          <h1 style={{ fontSize: "16px", marginBottom: "12px" }}>Status</h1>
          <Tag color={colorTag} key={status}>
            {statusName}
          </Tag>
        </div>
        <div style={{ marginBottom: "30px" }}>
          <h1 style={{ fontSize: "16px", marginBottom: "12px" }}>
            Status Remarks
          </h1>
          <p>{order && order.remark}</p>
        </div>
        <p style={{ fontSize: "12px" }}>
          Last Updated: {TIME.parseTimeMin(order && order.updatedAt)}
        </p>
      </div>
    </div>
  );
};

export default TimelineHightlight;
