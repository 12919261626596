import * as API from "../../api";

import { message } from "antd";

// Constants and Config

import {
    GET_PARKED_ORDER,
    UPDATE_PARKED_ORDER_STATUS,
    GET_PARKED_ORDER_COMMENT,
    CREATE_PARKED_ORDER_COMMENT,
    EDIT_PARKED_ORDER_COMMENT,
    ARCHIVE_PARKED_ORDER_COMMENT
} from "../action-types";
import { TIME } from "../../util";

import { setLoadingParkOrder, unsetLoadingParkOrder } from "./style";

const { lastNDay, currentTimeMilliSecond } = TIME;


export const get_park_order_all_request = () => dispatch => {
  const startTime = lastNDay(90);
  const endTime = currentTimeMilliSecond()
  dispatch(get_parked_order_timeframe_request(startTime, endTime))
}

export const get_parked_order_timeframe_request = (startTime, endTime) => (dispatch) => {

    dispatch(setLoadingParkOrder());

    API.getParkedOrderTimeFrameAll({
        startTime,
        endTime,
    })
        .then((info) => {
            const { status, msg, opark } = info;

            if (status === 200) {
                dispatch(get_parked_order_success(opark));
                message.success("get Parked Order List Data successfully");
            } else {
                message.warning(msg);
            }
        })
        .finally(() => {
            dispatch(unsetLoadingParkOrder());
        }
    );
};

export const get_parked_order_comment_request = (oparkid) => (dispatch) => {
    API.getParkedOrderComment({
        oparkid,
    }).then((info) => {
        const { status, msg, oparkcmt } = info;

        if (status === 200) {
            dispatch(get_parked_order_comment_success(oparkcmt));
            message.success("successfully get comments")
        } else {
            message.warning(msg);
        }
    })
};

export const create_parked_order_comment_data = (oparkcmt, cb) => (dispatch) => {

    API.setParkedOrderComment({
        oparkid: oparkcmt.oparkid,
        action: "C",
        oparkcmt
    }).then((info) => {
        const { status, oparkcmt, msg } = info;

        if (status === 200) {
            dispatch(create_parked_order_comment_success(oparkcmt));
            message.success(msg)
        } else {
            message.warning(msg);
        }
    }).finally(() => { cb() })
};

export const edit_parked_order_comment_data = (items, cb) => (dispatch) => {


    API.setParkedOrderComment({
        oparkid: items.oparkid,
        action: "U",
        oparkcmtid: items.oparkcmtid,
        items,
    }).then((info) => {
        const { status, oparkcmt, msg } = info;

        if (status === 200) {
            message.success("successfully edit comment data")
            dispatch(edit_parked_order_comment_success({...items, status: 1}))
        } else {
            message.warning(msg);
        }
    }).finally(() => { cb() })
};

export const archive_parked_order_comment_data = (items) => (dispatch) => {
    
    const newItems = {
        ...items,
        status: 0
    }
    
    API.setParkedOrderComment({
        oparkid: items.oparkid,
        action: "U",
        oparkcmtid: items.oparkcmtid,
        items: newItems
    }).then((info) => {
        const { status, oparkcmt, msg } = info;

        if (status === 200) {
          dispatch(archive_parked_order_comment_success(newItems));
          message.success("successfully delete comment data")
        } else {
            message.warning(msg);
        }
    });
}

export const update_parked_order_status = (items, cb) => (dispatch) => {

  delete items.origid 
  delete items.dstid

    const { oparkid, updatedBy, remark } = items;
    
    const newItems = {
      ...items,
      status: "PRK002"
    }

    API.updateParkedOrder({
        oparkid,
        updatedBy,
        items: newItems
    }).then((info) => {
        const { status, msg } = info;

        if (status === 200) {
            dispatch(update_parked_order_status_success(newItems));
            message.success("successfully update Order List status");
        } else {
            message.warning(msg);
        }
    })
    .finally(cb)
};

export const get_parked_order_success = (parkedOrder) => {
    return {
        type: GET_PARKED_ORDER,
        parkedOrder,
    };
};

export const update_parked_order_status_success = (parkedOrder) => {
    return {
        type: UPDATE_PARKED_ORDER_STATUS,
        parkedOrder,
    };
};

export const get_parked_order_comment_success = (oparkcmt) => {
    return {
        type: GET_PARKED_ORDER_COMMENT,
        oparkcmt,
    };
};

export const create_parked_order_comment_success = (oparkcmt) => {
    return {
        type: CREATE_PARKED_ORDER_COMMENT,
        oparkcmt,
    };
};

export const edit_parked_order_comment_success = (oparkcmt) => {
    return {
        type: EDIT_PARKED_ORDER_COMMENT,
        oparkcmt
    }
}

export const archive_parked_order_comment_success = (oparkcmt) => {
    return {
        type: ARCHIVE_PARKED_ORDER_COMMENT,
        oparkcmt
    }
}
