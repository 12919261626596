// Third-party Library 
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Card,
    Form,
    Select,
    Col,
    Skeleton
} from 'antd'

// Services 
import { REDUX as REDUX_UTIL} from "../../../../../../services/util";

// Actions 
import * as REDUX_ACTION from "../../../../../../services/redux/actions";

// Styles 
import classes from "../../../../common.module.css"

const { reduxStoreWrapper } = REDUX_UTIL

const PointOperators = ({
    hubid
}) => {

    const dispatch = useDispatch()

    const hubOperator = Object.values(useSelector(reduxStoreWrapper('hubOperator.byRole'))).flatMap(sublist=>sublist)
    const hubOperatorByHubid = useSelector(reduxStoreWrapper(`hubOperator.byHubid.${hubid}`))

    const loading = hubOperatorByHubid || !hubid ? false : true

    useEffect(() => {

        dispatch(REDUX_ACTION.v1_hubOperator.get_all_hub_operators_request(1))

        hubid 
        && 
        dispatch(REDUX_ACTION.v1_hubOperator.get_operator_thru_hubid_request(hubid));

    }, [hubid])
    
    const hubOperatorList = hubOperator?.map(ho => ({
        label: `${ho.memid} / ${ho.name}`,
        value: ho.uid
    }))

    let init = hubOperatorByHubid?.map(ho => ho.uid) || []

    return (
        <Card className={classes['card-container']}>
            <h1 className={classes['card-title']}>POINT OPERATORS</h1>

            <Skeleton loading={loading} active>
                <Col span={20}>
                    <Form.Item 
                        name="operator"
                        initialValue={init}
                    >
                        <Select
                            mode='multiple'
                            placeholder='-- SELECT --'
                            options={hubOperatorList}
                        />
                    </Form.Item>
                </Col>
            </Skeleton>
        </Card>
    )
}

export default PointOperators
