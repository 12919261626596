import {
    Row,
    Col,
    Button
} from "antd"

const BtnModal = ({
    setIsModalOpen,
    handleOkButton,
    text1,
    text2,
    htmlType=""
}) => {
    return (
        <Row style={{margin: "20px 0"}} justify={"center"}> 
            <Col span={4}>
                <Button onClick={setIsModalOpen} style={{padding: "15px 15px 35px 15px"}}>
                    {text1}
                </Button>
            </Col>
            <span style={{padding: "0 5px"}}></span>
            <Col span={4}>
                <Button
                    style={{padding: "15px 15px 35px 15px"}}
                    type="primary"
                    htmlType={htmlType}
                    onClick={handleOkButton}
                >
                    {text2}
                </Button>
            </Col>
        </Row>
    )
}
export default BtnModal
