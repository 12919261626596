import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {WarningFilled} from "@ant-design/icons"
import { Form, Radio, Row, Col, Checkbox, Button, Tooltip, Select, Alert } from "antd";
// Constants
import { TEMPLATE } from "../../../../../../../../constants";

// Services Library
import { 
    REDUX as REDUX_UTIL
} from "../../../../../../../../services/util"

// Styles
import coms from "../common.module.css"; 

const { DATA_FIELD, DATA_FIELD_TITLE, DATA_FIELD_VALUES } = TEMPLATE.ORDER
const { Option } = Select;

const { reduxStoreWrapper } = REDUX_UTIL

const DeliveryInstruction = ({ form, formData, onClick, label, sort, hubs, points, order, handlePointChange }) => {

  const hubsActive = Object.values(hubs);
  const pointsActive = Object.values(points);

  const [dstid, setDstid] = useState(''); 
  const [origid, setOrigid] = useState(''); 
  const [dstpid, setDstpid] = useState(''); 
  const [origpid, setOrigpid] = useState('');
  

  const [pointDestOptions, setPointDestOptions] = useState([]);

  useEffect(() => {
    if (!origid && !dstid && !origpid && !dstpid) {
      setOrigid(formData.origid);
      setDstid(formData.dstid);
      setOrigpid(formData.origpid);
      setDstpid(formData.dstpid);
    }
  
    setPointDestOptions(pointsActive.filter((p) => p.zone !== points[formData.origpid]?.zone));
  }, [origid, dstid, origpid, dstpid, form, formData.origid, formData.dstid, formData.origpid, formData.dstpid, points]);
  

  const handlePointHubChange = (e, type) => {
    if (type === 'origid') {
      setOrigid(e);
      form.setFieldValue('origid', e);
    } else if (type === 'dstid') {
      setDstid(e);
      form.setFieldValue('dstid', e);
    } else if (type === 'origpid') {
      setOrigpid(e);
      form.setFieldValue('origpid', e);
      setPointDestOptions(pointsActive.filter((p) => p.zone !== points[e]?.zone));
      const orig = type === 'origpid' ? e : order.origpid;
      const origZone = points[orig];
      setOrigid(origZone.parentHub);
      form.setFieldValue('origid', origZone.parentHub);
    } else if (type === 'dstpid') {
      setDstpid(e);
      form.setFieldValue('dstpid', e);
      const dest = type === 'dstpid' ? e : order.dstpid
      const dstZone = points[dest];
      setDstid(dstZone.parentHub);
      form.setFieldValue('dstid', dstZone.parentHub);
    }
  };

  // const options = Object.keys(DATA_FIELD_VALUES[DATA_FIELD.add_on]).map(
  //   (k) => ({
  //     key: k,
  //     label: DATA_FIELD_VALUES[DATA_FIELD.add_on][k],
  //     value: k,
  //   })
  // );
  
  const findAddress = (e) => {
    const hubIndex = hubsActive.findIndex(obj => obj.hubid === e);
    const pointIndex = pointsActive.findIndex(obj => obj.hubid === e);

    if (hubIndex !== -1) {
        return hubsActive[hubIndex]?.address ?? 'Invalid hub/point';
    } else if (pointIndex !== -1) {
        return pointsActive[pointIndex]?.address ?? 'Invalid hub/point';
    } else {
        return 'Invalid hub/point';
    }
};

  return (
    <>
      <Row gutter={20}>
          {order.deliveryType === 'p2p' &&
          <Col span={6}>
              <Form.Item label={"Origin Point"} name='origpid'>
                  <Select
                    disabled={label === sort ? true : false}
                    onChange={(e) => {
                      handlePointHubChange(e, 'origpid');
                      handlePointChange(e, "org");
                    }}
                  >
                    {pointsActive.map((point) => (
                    <Option value={point.hubid}>
                      {point.code}
                    </Option>
                  ))}
                  </Select>
              </Form.Item>
              {pointsActive.some(obj => obj.hubid === origpid && origpid) ? <div style={{height:'80px'}}></div> : 
                <Alert message="The selected point is inactive. Please choose another point" type="warning" showIcon style={{marginBottom:'10px'}} />
                }
              <>
                <h1 style={{ marginBottom: '10px' }}>Origin Point</h1>
                
                <p>{findAddress(origpid && origpid)}</p>
              </>
          </Col>
        }

          <Col span={order.deliveryType === 'p2p' ? 6 : 10}>           
                <Form.Item label={"Origin Hub"} name='origid' style={{width:'90%', marginBottom:'10px'}}>
                    <Select
                        onChange={(e) => handlePointHubChange(e, 'origid')}
                        disabled={label === sort ? true : false}
                      >
                        {hubsActive.map((hub) => (
                          <Option value={hub.hubid}>
                        {hub.code}
                      </Option>
                    ))}
                    </Select>
                </Form.Item>
                {hubsActive.some(obj => obj.hubid === origid && origid) ? <div style={{height: order.deliveryType === 'p2p' ?'90px':'40px'}}></div> : 
                <Alert message="The selected hub is inactive. Please choose another hub" type="warning" showIcon style={{marginBottom:'10px'}} />
                }
              
              <>
                <h1 style={{ marginBottom: '10px' }}>Origin Hub</h1>
                <p>{findAddress(origid && origid)}</p>
              </>
          </Col>

          <Col span={order.deliveryType === 'p2p' ? 6 : 10}>    
                <Form.Item label={"Destination Hub"} name='dstid' style={{width:'90%', marginBottom:'10px'}}>
                  
                    <Select
                        disabled={label === sort ? true : false}
                        onChange={(e) => handlePointHubChange(e, 'dstid')}
                      >
                        {hubsActive.map((hub) => (
                      <Option value={hub.hubid}>
                        {hub.code}
                      </Option>
                    ))}
                    </Select>
                </Form.Item>
                {hubsActive.some(obj => obj.hubid === dstid && dstid) ? <div style={{height: order.deliveryType === 'p2p' ? '90px':'40px'}}></div> : 
                <Alert message="The selected hub is inactive. Please choose another hub" type="warning" showIcon style={{marginBottom:'10px'}} />
                }
              
              <>
                <h1 style={{ marginBottom: '10px' }}>Destination Hub</h1>
                <p>{findAddress(dstid && dstid)}</p>
              </>
          </Col>

          {order.deliveryType === 'p2p' &&
          <Col span={6}>
              <Form.Item label={"Destination Point"} name='dstpid'>
                  <Select
                      disabled={label === sort ? true : false}
                      onChange={(e) => {
                        handlePointHubChange(e, 'dstpid');
                        handlePointChange(e, "dest");
                    }}
                  >
                    {pointDestOptions.map((point) => (
                    <Option value={point.hubid}>
                      {point.code}
                    </Option>
                  ))}
                  </Select>
              </Form.Item>
              {pointsActive.some(obj => obj.hubid === dstpid && dstpid) ? <div style={{height:'80px'}}></div> : 
                <Alert message="The selected point is inactive. Please choose another point" type="warning" showIcon style={{marginBottom:'10px'}} />
                }
              <>
                <h1 style={{ marginBottom: '10px' }}>Destination Point</h1>
                <p>{findAddress(dstpid && dstpid)}</p>
              </>
          </Col>
        }
      </Row>



      <div className={coms.nextBTN}>
        <Button type="primary" onClick={() => onClick()}>
          Next
        </Button>
      </div>
    </>
  );
};

export default DeliveryInstruction;
