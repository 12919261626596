import React from "react";
import { Row, Col, Space, message } from "antd";
import { LeftOutlined } from "@ant-design/icons";

// Components
import {
  ButtonLink,
  ButtonModal,
  BtnSubmit,
} from "../../../../../../components";

import { TEMPLATE } from "../../../../../../constants";

// Styles
import cs from "./index.module.css";

const { MODAL_ACTION } = TEMPLATE.MODAL;
const { ACTIONS_LABEL_TITLE, ACTIONS_LABEL } = TEMPLATE.FORMORDER;

const OrderHeader = (props) => {
  const {
    form,
    ordid,
    label,
    actionLabel,
    progressBTNAction,
    saveDraft,
    onBack,
    doorPick,
    order
  } = props;

  const sort = ACTIONS_LABEL_TITLE[ACTIONS_LABEL.sort_receiver_order];
  const deliveryType = order.deliveryType

  return (
    <div className={cs.headerContainer}>
      <Row>
        <Row className={cs.headerRow}>
          <Col className={cs.headerLeftColContainer} span={12}>
            <Space direction="horizontal">
              <LeftOutlined className={cs.back} onClick={() => onBack()} />
              <div className={cs.headerTitle}>{actionLabel} ({ordid})</div>
            </Space>
          </Col>
          <Col className={cs.headerRightColContainer} span={12}>
            <Space direction="horizontal" size={"large"} >
              {
              <>
                <ButtonLink className={cs.cancel} onClick={() => onBack()}>
                  {"Cancel"}
                </ButtonLink>
                
                <BtnSubmit
                  display={"inline-block"}
                  text="Save Draft"
                  bgColor="transparent"
                  border="1px solid var(--themeBackgroundColor)"
                  color="var(--themeBackgroundColor)"
                  padding="11px 19px"
                  onClick={saveDraft}
                />
              </>
              }
              <>
              
                {label === sort ? (
                  <ButtonModal
                    setmodal={deliveryType === 'p2p' ? MODAL_ACTION.update_status_sort_point : MODAL_ACTION.update_status_sort}
                    type="primary"
                    style={{ width: "100%", height: "45px", borderRadius:"6px" }}
                    onClick={progressBTNAction}
                    extra={{ ordid }}
                  >
                    {"Sort Order"}
                  </ButtonModal>
                ) : 
                (
                  deliveryType === 'p2p' ?
                    (<ButtonModal 
                      setmodal={
                        MODAL_ACTION.update_status_self_lodge_point}
                      type="primary"
                      style={{ width: "100%", height: "45px", borderRadius:"6px" }}
                      onClick={progressBTNAction}
                      extra={{ ordid }}
                    >
                      {"Proceed Order"}
                    </ButtonModal>)
                    :
                    (<ButtonModal
                      setmodal={doorPick ? MODAL_ACTION.update_status_pickup : MODAL_ACTION.update_status_self_lodge}
                      type="primary"
                      style={{ width: "100%", height: "45px", borderRadius:"6px" }}
                      onClick={progressBTNAction}
                      extra={{ ordid }}
                    >
                      {"Proceed Order"}
                    </ButtonModal>) 
                )}
              </>
            </Space>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default OrderHeader;
