import * as CONFIG from '../../../config'

export const adp_v1_fe_path = CONFIG.APP_CONFIG.ADP_V1_FE_API_GATEWAY
export const headers = {
    'Accept': '*/*',
    'Content-Type': 'application/json; charset=utf-8'
}

export const adp_v1_fe_vehicle_path = CONFIG.APP_CONFIG.ADP_VEHICLE_URL;
export const headerMap = {
  Accept: "*/*",
  "Content-Type": "application/json; charset=utf-8",
  "Access-Control-Allow-Origin": "*",
  apiKey: CONFIG.APP_CONFIG.ADP_VEHICLE_API_KEY, 
};