// React Thirty Party Library
import { useState } from "react";

// Constants Library
import { TEMPLATE } from "../../../../constants";

// Components Library
import { TableList, BtnSubmit } from "../../../../components";
import DrawerAddVehicle from "../DrawerAddVehicle"

// Handler Librarys
import { exportHDLR } from "../../../../handlers";

// Services Library 
import { TIME } from "../../../../services/util";

// Redux Actions 

// Styles Library
import classes from "../../../../components/index.module.css";

const { TABLE_VEHICLE_INDEX, TABLE_VEHICLE_TITLE } =
  TEMPLATE.VEHICLE_LIST;
const { BTN_IMG } = TEMPLATE.BTN;
const { VEHICLE_SUMMARY_COL } = TEMPLATE.REPORT.VEHICLE;


const { parseExportTime } = TIME;

const SearchVehicle = (props) => {
  const {
    dataSource,
    filteredData,
    setfilteredData,
    selectedRow
  } = props;


  const [isOpenAdd, setIsOpenAdd] = useState(false);

  const template = VEHICLE_SUMMARY_COL({
    exportState: true,
  });

  const options = [
    {
      value: TABLE_VEHICLE_INDEX.Plate,
      label: TABLE_VEHICLE_TITLE.Plate,
      type: "text",
    },
    {
      value: TABLE_VEHICLE_INDEX.CreatedAt,
      label: "Custom Date",
      type: "timeframe",
    },

  ];

  const exportData = selectedRow.length > 0 ? selectedRow : filteredData;

  return (
    <>
    <DrawerAddVehicle isOpen={isOpenAdd} setIsOpen={setIsOpenAdd} />
    <div className={classes.headerPage}>
      <TableList.SearchTableQuery
        dataSource={dataSource}
        setFilteredData={setfilteredData}
        options={options}
        defaultValue={TABLE_VEHICLE_INDEX.Plate}
      />
      <div>
        <BtnSubmit
          display="inline-block"
          text="Export"
          bgColor="transparent"
          color="#4CAF50"
          padding="9px 18px"
          onClick={() =>
            exportHDLR.exportWithTemplate({
              data: exportData,
              filename: `VehicleSummary_${parseExportTime()}`,
              template: template,
            })
          }
          showIcon={true}
          icon={BTN_IMG.EXPORT}
          icon_alt={"export"}
        />
        <BtnSubmit
          display={"inline-block"}
          text={"Add Vehicle"}
          bgColor="#F08800"
          color="white"
          padding="9px 18px"
          border="1px solid var(--themeBackgroundColor)"
          onClick={() => {
            setIsOpenAdd(true);
          }}
          showIcon={false}
        />
      </div>
    </div>
    </>
  );
};

export default SearchVehicle;
