import * as API_CONSTANT from "../constants";

export const getParkedOrderTimeFrameAll = ({ startTime, endTime }) =>
	fetch(
		`${API_CONSTANT.adp_v1_fe_path}/order/park/ticket/get/timeframe/all`,
		{
			method: "POST",
			headers: API_CONSTANT.headers,
			body: JSON.stringify({
				startTime,
				endTime,
			}),
		}
	).then((res) => res.json());

export const setParkedOrderComment = ({
	oparkid,
	action,
	oparkcmt,
	oparkcmtid,
	items,
}) =>
	fetch(`${API_CONSTANT.adp_v1_fe_path}/order/park/comment/cu `, {
		method: "POST",
		headers: API_CONSTANT.headers,
		body: JSON.stringify({
			oparkid,
			action,
			oparkcmt,
			oparkcmtid,
			items,
		}),
	}).then((res) => res.json());

export const getParkedOrderComment = ({ oparkid }) =>
	fetch(`${API_CONSTANT.adp_v1_fe_path}/order/park/comment/get/oparkid`, {
		method: "POST",
		headers: API_CONSTANT.headers,
		body: JSON.stringify({
			oparkid,
		}),
	}).then((res) => res.json());

export const updateParkedOrder = ({ oparkid, updatedBy, items }) =>
	fetch(`${API_CONSTANT.adp_v1_fe_path}/order/park/ticket/update`, {
		method: "POST",
		headers: API_CONSTANT.headers,
		body: JSON.stringify({
			oparkid,
			updatedBy,
			items,
		}),
	}).then((res) => res.json());
