import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

// Constants Library
import { TEMPLATE, ROUTES, COMMON_FEATURES } from "../../../../../../../../constants";

// Components Library
import { TableList, BtnSubmit } from "../../../../../../../../components";

// Services Library
import { REDUX as REDUX_UTIL, TIME } from "../../../../../../../../services/util";

//handlers
import { exportHDLR } from "../../../../../../../../handlers";

import classes from "../../../../../../../../components/index.module.css";

const {
  ROUTE_URL
} = ROUTES 

const { TABLE_HUB_TITLE, TABLE_HUB_INDEX } = TEMPLATE.HUB;
const { BTN_IMG } = TEMPLATE.BTN;
const { HUB_SUMMARY_COL } = TEMPLATE.REPORT.HUB;
const { FEATURE_NAME } = COMMON_FEATURES
const { reduxStoreWrapper } = REDUX_UTIL
const { parseExportTime } = TIME;

const SearchHub = ({
  dataSource,
  filteredData,
  setFilteredData,
  selectedRow
}) =>{
    const navigate = useNavigate()
    const hubs = useSelector(reduxStoreWrapper("hub.byID"));
    const options = [
        {
          value: TABLE_HUB_INDEX.hub_code,
          label: TABLE_HUB_TITLE.hub_code,
          type: "text",
        },
        {
          value: TABLE_HUB_INDEX.hub_name,
          label: TABLE_HUB_TITLE.hub_name,
          type: "text",
        },
        {
          value: TABLE_HUB_INDEX.created_at,
          label: "Custom Date",
          type: "timeframe",
        },
      ];

      const exportData = selectedRow.length > 0 ? selectedRow : filteredData;

    return(
        <div className={classes.headerPage} style={{backgroundColor:'white', marginTop:'4rem'}}>
        <TableList.SearchTableQuery
           dataSource={dataSource}
           setFilteredData={setFilteredData}
           options={options}
          defaultValue={TABLE_HUB_INDEX.hub_code}
          style={classes.searcHub}
        />
        <div>
          <BtnSubmit
            display="inline-block"
            text="Export"
            bgColor="transparent"
            color="#4CAF50"
            padding="9px 18px"
            onClick={() =>
              exportHDLR.exportWithTemplate({
                data: exportData,
                filename: `HubList_${parseExportTime()}`,
                template: HUB_SUMMARY_COL(
                  {   
                    redux:{
                      hubs
                    },
                    exportState : true
                  },
                ),
              })
            }
            showIcon={true}
            icon={BTN_IMG.EXPORT}
            icon_alt={"export"}
          />
          <BtnSubmit
            display="inline-block"
            text="Create new hub"
            bgColor="var(--themeBackgroundColor)"
            color="var(--creamColor)"
            padding="9px 18px"
            onClick={() => {
              navigate(ROUTE_URL[FEATURE_NAME.HUB_CREATE])
            }}
            showIcon={false}
            />
        </div>
      </div>
    )
}

export default SearchHub;
