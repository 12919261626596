import * as ActionTypes from '../action-types'

const point_defaultState = {
    byID: {},
    byCode: {},
    byStatus: {},
    allIDs: []
}

export const point = (state = point_defaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.POINT_GET_ALL: {

            const points = action.points

            points
            &&
            points.length
            &&
            points.map(point => {

                const { hubid, code } = point

                newState.byID[hubid] = point
                newState.byCode[code] = point

                if(!newState.allIDs.includes(hubid)) newState.allIDs.push(hubid)

                return null
            })

            return newState;
        }
        case ActionTypes.CREATE_POINT: {
          const items = action.items
          const { hubid, code } = items

          newState.byID[hubid] = items
          newState.byCode[code] = items

          return newState
        }
        case ActionTypes.GET_ACTIVE_POINTS: {

            const points = action.points

            points
            &&
            points.length
            &&
            points.map(point => {

                const { hubid } = point

                newState.byStatus[hubid] = point

                if(!newState.allIDs.includes(hubid)) newState.allIDs.push(hubid)

                return null
            })
            return newState
        }
        case ActionTypes.UPDATE_POINT: {
            const items = action.point
            const hubid  = items.hubid
            
            newState.byID[hubid] = items

            return newState
        }
        default: {
            return state;
        }
    }
}
