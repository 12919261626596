// React Thirty Party Library
import { useState } from "react";


// Constants Library
import { TEMPLATE } from "../../../../../../constants";

// Components Library
import { TableList, BtnSubmit } from "../../../../../../components";
import DrawerAddPoint from "../DrawerAddPoint/"

//handlers
import { exportHDLR } from "../../../../../../handlers";
 
// Services Library
import { TIME, REDUX as REDUX_UTIL } from "../../../../../../services/util";

import classes from "../../../../../../components/index.module.css";

const { TABLE_POINT_OPERATOR_TITLE, TABLE_POINT_OPERATOR_INDEX } = TEMPLATE.POINT;
const { BTN_IMG } = TEMPLATE.BTN;
const { HUB_OPERATOR_SUMMARY_COL } = TEMPLATE.REPORT.HUBOPERATOR;

const { parseExportTime } = TIME;

const SearchPointOperator = (props) => {
 const {filteredData, setFilteredData, dataSource, selectedRow} = props
    const options = [
        {
          value: TABLE_POINT_OPERATOR_INDEX.point_opid,
          label: TABLE_POINT_OPERATOR_TITLE.point_opid,
          type: "text",
        },
        {
          value:TABLE_POINT_OPERATOR_INDEX.created_at,
          label: "Custom Date",
          type: "timeframe",
        },
      ];

    const [isOpenAdd, setIsOpenAdd] = useState(false)

    const exportData = selectedRow.length > 0 ? selectedRow : filteredData;

    return (
      <>
        <DrawerAddPoint isOpen={isOpenAdd} setIsOpen={setIsOpenAdd} />
        <div className={classes.headerPage}>
        <TableList.SearchTableQuery
          dataSource={dataSource}
          setFilteredData={setFilteredData}
          options={options}
          defaultValue={TABLE_POINT_OPERATOR_INDEX.point_opid}
        />
        <div>
          <BtnSubmit
            display="inline-block"
            text="Export"
            bgColor="transparent"
            color="#4CAF50"
            padding="9px 18px"
            onClick={() =>
              exportHDLR.exportWithTemplate({
                data: exportData,
                filename: `HubOperatorList_${parseExportTime()}`,
                template: HUB_OPERATOR_SUMMARY_COL({exportState : true}),
              })
            }
            showIcon={true}
            icon={BTN_IMG.EXPORT}
            icon_alt={"export"}
          />
          <BtnSubmit
            display="inline-block"
            text="Add point operator"
            bgColor="var(--themeBackgroundColor)"
            color="var(--creamColor)"
            padding="9px 18px"
            onClick={() => setIsOpenAdd(true)}
            showIcon={false}
            />
        </div>
      </div>
      </>
    )
}

export default SearchPointOperator;
