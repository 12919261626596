import * as API from "../../api"
import * as ActionTypes from "../action-types";

import { message } from 'antd'
import { batch } from "react-redux";

// Constants and Config

// Services

// Redux Action

// Neccessary Actions
import {
    setLoadingZone,
    unsetLoadingZone,
    setLoadingZonePriceList,
    unsetLoadingZonePriceList
} from './style'

 const dummyData = [{
    zonid : "ZN001",
    name: "Kuala Lumpur",
    total: "3",
    active: "2",
    inactive: "1",
    createdAt: 1698896161000,
    updatedAt: 1701574561000,
    remark: "-"
}]

export const get_zone_all_request = () => dispatch => {

    dispatch(setLoadingZone())

    API.getAllZones()
        .then(info => {

            const {
                status,
                zones,
                msg
            } = info

            if (status === 200) {
                dispatch(get_zone_all_success(zones))
            } else {
                message.error(msg)
            }
        })
        .finally(() => {
            dispatch(unsetLoadingZone())
        })
}

const get_zone_all_success = (zones) => {
    return {
        type: ActionTypes.GET_ZONE_LIST,
        zones
    };
}

export const get_price_list_thru_zonid_request = (zoneid) => dispatch => {

    dispatch(setLoadingZonePriceList())

    API.getPricelistThruZoneid(zoneid)
        .then(info => {

            const {
                status,
                zonePrices,
                msg
            } = info

            if (status === 200) {
                dispatch(get_price_list_thru_zonid_success(zoneid, zonePrices))
            } else {
                message.error(msg)
            }
        })
        .finally(() => {
            dispatch(unsetLoadingZonePriceList())
        })
}

const get_price_list_thru_zonid_success = (zoneid, priceList) => {
    return {
        type: ActionTypes.GET_ZONE_PRICE_LIST,
        zoneid,
        priceList
    };
}

export const create_zone_request = (items, cb) => dispatch => {

    dispatch(setLoadingZone())

    API.createZone(items)
        .then(info => {

            const {
                status,
                zone,
                msg
            } = info

            if (status === 200) {
                dispatch(create_zone_success(zone))
            } else {
                message.error(msg)
            }
        })
        .finally(() => {
            dispatch(unsetLoadingZone())
            cb()
        })
}

const create_zone_success = (zone) => {
    return {
        type: ActionTypes.CREATE_ZONE_LIST,
        zone
    };
}

export const update_zone_price_list_request = (items, cb) => dispatch => {

    dispatch(setLoadingZone())

    API.updateZonePriceList(items)
        .then(info => {

            const {
                status,
                zone,
                zonePrices,
                msg
            } = info

            if (status === 200) {
                dispatch(update_zone_price_list_success(zone))
                dispatch(update_zone_price_list_by_zoneid_success(zone.zoneid, zonePrices))
            } else {
                message.error(msg)
            }
        })
        .finally(() => {
            dispatch(unsetLoadingZone())
            
            cb()
        })
}

const update_zone_price_list_success = (zone) => {
    return {
        type: ActionTypes.UPDATE_ZONE_PRICE_LIST,
        zone
    };
}

const update_zone_price_list_by_zoneid_success = (zoneid, zonePrices) => {
    return {
        type: ActionTypes.UPDATE_ZONE_PRICE_LIST_BY_ZONEID,
        zoneid, 
        zonePrices
    };
}

export const update_status_zone_price_list_request = (items, cb) => dispatch => {

    dispatch(setLoadingZone())

    API.updateStatusZonePriceList(items)
        .then(info => {

            const {
                status,
                zonePrice,
                msg
            } = info

            if (status === 200) {
                dispatch(update_status_zone_price_list_success(zonePrice))
            } else {
                message.error(msg)
            }
        })
        .finally(() => {
            dispatch(unsetLoadingZone())
            cb()
        })
}

const update_status_zone_price_list_success = (zonePrice) => {
    return {
        type: ActionTypes.UPDATE_ZONE_PRICE,
        zonePrice
    };
}
