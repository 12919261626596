import { Drawer, Form, Input, Radio, Card } from "antd";
import {
  LockOutlined,
} from '@ant-design/icons';
import { useState, useEffect } from 'react';

import { BtnSubmit } from "../../../../../../components";


import classStyles from "./index.module.css";
const { TextArea } = Input;

const DrawerFormVehicle = (props) => {
  const { isOpen, setIsOpen, type, isTrackingValue, form, createVehicle, updateVehicle} = props
  
  useEffect(() => {
    setTrackingValue(isTrackingValue);
  }, [isTrackingValue]);

  
  const [trackingValue, setTrackingValue] = useState(isTrackingValue);

  useEffect(() => {
    form.setFieldsValue({ ...props.initialValue });
  }, [isOpen]);

  const handleTrackingChange = (e) => {
    setTrackingValue(e.target.value);
  };

  const ActionButton = (
    <Form.Item style={{ paddingTop: "20px" }}>
      <div>
        <BtnSubmit
          display="inline-block"
          text={type === "add" ? "Add" : "Edit"}
          bgColor="var(--themeBackgroundColor)"
          color="var(--creamColor)"
          padding="9px 18px"
          onClick={() => {
            if (type === "add") {
              createVehicle();
              setIsOpen(false);
            } else if (type === "edit") {
              updateVehicle();
              setIsOpen(false);
            }
          }}
        />
        {type === "add" &&
        <BtnSubmit
          display="inline-block"
          text="Add & create another"
          bgColor="transparent"
          color="var(--themeBackgroundColor)"
          padding="9px 18px"
          border="1px solid var(--themeBackgroundColor)"
          onClick={() => {
            form.resetFields();
            createVehicle();
          }}
        />
        }
        <BtnSubmit
          display="inline-block"
          text="Cancel"
          bgColor="transparent"
          color="var(--bs-gray-600)"
          padding="9px 18px"
          onClick={() => {
            form.resetFields();
            setIsOpen(false);
          }}
        />
      </div>
    </Form.Item>
  );

  return (
    <>
    <Drawer
      title={type === "add" ? "Add Vehicle" : "Edit Vehicle"}
      placement="right"
      closable={false}
      width={410}
      onClose={() => setIsOpen(false)}
      footer={ActionButton}
      open={isOpen}
      style={{
        textAlign: "center",
        fontSize: "20px",
      }}
    >
    <Form
      form={form}
      layout="vertical"
    >
        <label htmlFor="plate"  className={classStyles.labelInput}>
            Vehicle Plate
        </label>
        <Form.Item
          name="plate"
          rules={[
            {
              required: true,
              message: "Vehicle plate is required",
            },
          ]}
          className={classStyles.materialTextField} 
        >
          <Input className={classStyles.inputForm} disabled={type === 'edit'} style={{ backgroundColor: 'transparent' }} suffix={type === 'edit' && <LockOutlined style={{ color: '#D9D9D9' }} />} />
        </Form.Item>

        
        <label htmlFor="driver"  className={classStyles.labelInput}>
            Bus Operator
        </label>
        <Form.Item
          name="driver"
          rules={[
            {
              required: true,
              message: "Bus operator is required",
            },
          ]}
          className={classStyles.materialTextField} 
        >
          <Input className={classStyles.inputForm} />
        </Form.Item> 

        <label htmlFor="isTracking"  className={classStyles.labelInput}>
            Live Location
        </label>
        <Form.Item
          name="isTracking"
          rules={[
            {
              required: true,
              message: "Live location is required",
            },
          ]}
          className={classStyles.materialTextField} 
        >
          <Card className={`${classStyles.inputForm} ${classStyles.Card}`}>
            <Radio.Group onChange={handleTrackingChange} value={trackingValue} className={classStyles.Radio}>
              <Radio value={1}>Yes</Radio>
              <Radio value={0}>No</Radio>
            </Radio.Group>
          </Card>
        </Form.Item> 

        
        <label htmlFor="remark"  className={classStyles.labelInput}>
            Remarks
        </label>
        <Form.Item
          name="remark"
          rules={[
            {
              required: true,
              message: "Remarks is required",
            },
          ]}
          className={classStyles.materialTextField} 
        >
            <TextArea className={classStyles.inputForm} />
        </Form.Item>
    </Form>
    </Drawer>
    </>
  );
};

export default DrawerFormVehicle;
