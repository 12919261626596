import * as API_CONSTANT from '../constants'

export const getAllPoints = () => (
    fetch(`${API_CONSTANT.adp_v1_fe_path}/point/get/all`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({})
    })
    .then(res => res.json())
)

export const createPoint = (items) => 
    fetch(`${API_CONSTANT.adp_v1_fe_path}/point/create`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify(items)
    })
    .then(res => res.json())
    

  export const updatePoint = (data) =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/point/update`, {
      method:'POST',
      headers: API_CONSTANT.headers,
      body:JSON.stringify(data)
  })
  .then(res => res.json())

export const getAllPointOperatorRelation = () => (
    fetch(`${API_CONSTANT.adp_v1_fe_path}/point/operator/relation/get/all`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({})
    })
    .then(res => res.json())
)

export const pointAssignedOperator = (items) => (
    fetch(`${API_CONSTANT.adp_v1_fe_path}/point/assign/operator`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify(items)
    })
    .then(res => res.json())
)

export const createPointOperator = (items) => 
fetch(`${API_CONSTANT.adp_v1_fe_path}/point/operator/relation/create`, {
    method: 'POST',
    headers: API_CONSTANT.headers,
    body: JSON.stringify(items)
})
.then(res => res.json())

// export const updatePointOperator = (items) => 
// fetch(`${API_CONSTANT.adp_v1_fe_path}/operator/update`, {
//     method: 'POST',
//     headers: API_CONSTANT.headers,
//     body: JSON.stringify(items)
// })
// .then(res => res.json())

// export const getOperatorThruOPUID = (pointid) => 
// fetch(`${API_CONSTANT.adp_v1_fe_path}/operator/get/thru/op/uid`, {
//     method: 'POST',
//     headers: API_CONSTANT.headers,
//     body: JSON.stringify({pointid})
// })
// .then(res => res.json())

export const getActivePoints = () => (
    fetch(`${API_CONSTANT.adp_v1_fe_path}/point/get/active`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({})
    })
    .then(res => res.json())
)
