import * as ActionTypes from '../action-types'

const hubOperator_defaultState = {
    byID: {},
    allIDs: [],
    byHubid: {}, 
    byRole: {},
}

export const hubOperator = (state = hubOperator_defaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))
    

    if(!action) return newState
    

    switch (action.type) {
        case ActionTypes.GET_HUB_OPERATOR_ONLY: {

            // const hubs = action.hubs
            const operators = action.operators
      
            // newState.byRole[role] = operators;

            // newState.byRole = {};

            // Update byRole based on the page
            // newState.byID = operators;

            // newState.byID = {}

            operators
            &&
            operators.length
            &&
            operators.map(operator => {

                const { memid } = operator

                newState.byID[memid] = operator

            //     if(!newState.allIDs.includes(memid)) newState.allIDs.push(memid)

            //     return null
            })

            return newState;
        }
        case ActionTypes.GET_HUB_OPERATOR_ALL: {

            // const hubs = action.hubs
            const operators = action.operators
            const role = action.role
      
            // newState.byRole[role] = operators;

            newState.byRole = {};

            // Update byRole based on the page
            newState.byRole[role] = operators;

            // newState.byID = {}

            // hubs
            // &&
            // hubs.length
            // &&
            // hubs.map(operators => {

            //     const { memid } = operators

            //     newState.byID[memid] = operators

            //     if(!newState.allIDs.includes(memid)) newState.allIDs.push(memid)

            //     return null
            // })

            return newState;
        }
        case ActionTypes.CREATE_HUB_OPERATOR: {
            const operator = action.hubs;
            const { memid, uroleid } = operator;

            const role = uroleid === "POP" ? 1 : 0

            newState.byRole[role].push(operator)
      
            if (!newState.allIDs.includes(memid)) newState.allIDs.push(memid);
      
            return newState;
          }
          case ActionTypes.UPDATE_HUB_OPERATOR: {
            const operator = action.hubs
      
            const {memid} = operator
            
            const array = newState.byRole[0] ? 0 : 1;
            const matchedIndex = newState.byRole["0"]? newState.byRole["0"].findIndex(item => item.memid === memid) : newState.byRole["1"].findIndex(item => item.memid === memid);
            // const matchedIndexPoint = newState.byRole["1"].findIndex(item => item.memid === memid);
            // const index = array.findIndex(item => item.memid === memid)
            newState.byRole[array][matchedIndex] = operator
      
            return newState
          }
          case ActionTypes.GET_HUB_OPERATOR_THRU_HUBID: {

            const operators = action.operators
            // const memid = action.memid
            const hubid = action.hubid
      
            newState.byHubid[hubid] = operators;
      
            return newState;
        }

        case ActionTypes.DELETE_HUB_OPERATOR_LIST_FROM_HUBID: {
            const operator = action.operator
            const hubid = action.hubid
            
            newState.byHubid[hubid] = operator

            return newState
        }
        
        case ActionTypes.ASSIGNED_OPERATOR: {
            const hubid = action.hubid
            const operators = action.operators

            newState.byHubid[hubid] = operators
      
            return newState
          }
        default: {
            return state;
        }
    }
}
