import * as API from "../../api"
import * as ActionTypes from "../action-types";

import { message } from 'antd'
import { batch } from "react-redux";

// Constants and Config

// Services

// Redux Action

// Neccessary Actions
import {
    setLoadingHub,
    unsetLoadingHub
} from './style'

export const get_all_hubs_request = () => dispatch => {

    dispatch(setLoadingHub())

    API
        .getAllHubs()
        .then(info => {

            const {
                status,
                hubs,
                msg
            } = info

            if (status === 200) {
                dispatch(get_all_hubs_success(hubs))
            } else {
                message.error(msg)
            }
        })
        .finally(() => {
            dispatch(unsetLoadingHub())
        })
}

const get_all_hubs_success = (hubs) => {
    return {
        type: ActionTypes.HUB_GET_ALL,
        hubs
    };
}



export const create_new_hub_request = (items, operator, cb = () => {}, back = () => {}) => dispatch => {

    Promise.all([
        API.createHub(items),
        API.hubAssignedOperator(operator)
    ])
    .then(([infoNewHub, infoAssignedOperator]) => {
            const { hub, msg, status } = infoNewHub
            const { relations } = infoAssignedOperator
            const { operators, hubid } = relations


            if(status === 200) {
                dispatch(create_new_hub(hub))
                dispatch(hub_assigned_operator_success(hubid, operators))
                message.success(msg)
                back()
            } else {
                message.warning(msg)
            }
    })
    .finally(() => {
        cb()
    })
}

export const create_new_hub = (items) => {
    return {
        type: ActionTypes.CREATE_HUB,
        items
    }
}

export const hub_assigned_operator_request = (items, operators, cb) => dispatch => {

    API.hubAssignedOperator(
        items
    ).then(info => {
        const { status, msg, relations } = info
        const { hubid } = relations

        if(status === 200) {
            dispatch(hub_assigned_operator_success(hubid, operators))
            message.success(msg)
        } else {
            message.warning(msg)
        }
    })
    .finally(() => {
        cb()
    })
}

const hub_assigned_operator_success = (hubid, operators) => {
    return {
        type: ActionTypes.ASSIGNED_OPERATOR,
        hubid,
        operators
    }
}

export const get_active_hubs_request = (cb) => dispatch => {
  API
  .getActiveHubs() 
  .then(info => {

      const {
          status,
          hubs,
          msg
      } = info

      if(status === 200) {
          dispatch(get_active_hubs_success(hubs))
      } else {
          message.error(msg)
      }
  }).finally(() => cb())
}

const get_active_hubs_success = (hubs) => {
  return {
      type: ActionTypes.GET_ACTIVE_HUBS,
      hubs
  };
}


export const hub_update_request = (items, operator,  cb = () => {}, back = () => {}) => dispatch => {
    
    Promise.all([
        API.updateHub({hubid: items.hubid, items}),
        API.hubAssignedOperator(operator)
    ])
    .then(([infoPoint, infoAssignedOperator]) => {

        const { hub, msg, status } = infoPoint
        const { relations } = infoAssignedOperator
        const { operators, hubid } = relations

        if(status === 200) {
            dispatch(hub_update_success(hub))
            dispatch(hub_assigned_operator_success(hubid, operators))
            message.success(msg)
            back()
        } else {
            message.error(msg)
        }
    })
    .finally(() => {
        cb()
    })
}

export const hub_update_status_request = (items, cb) => dispatch => {

    API.updateHub(items)
    // .then(res => res.json())
    .then(info => {

        const { status, hub, msg } = info

        if(status === 200) {
            dispatch(hub_update_success(items.hubid, hub))
            message.success(msg)
        } else {
            message.error(msg)
        }
    })
    .finally(() => {
        cb()
    })
}

const hub_update_success = (hubid, hubs) => {
    return {
        type: ActionTypes.UPDATE_HUB,
        hubid,
        hubs
    };
}
