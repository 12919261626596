import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Constants Library

// Components Library
import { ManageCreditIndex } from "../../components"

// Services Library
import { REDUX as REDUX_UTIL } from "../../../../services/util";

import * as REDUX_ACTION from "../../../../services/redux/actions";

const { reduxStoreWrapper } = REDUX_UTIL;

const ManageCredit = (props) => {
  const { memid = '' } = props

  const dispatch = useDispatch(); 

  const billInv = useSelector(reduxStoreWrapper("billing.inv"));
  const customer = useSelector(reduxStoreWrapper(`customer.byID.${memid}`));
  const wallet = Object.values(useSelector(reduxStoreWrapper("wallet.byID")));
  const journals = Object.values(useSelector(reduxStoreWrapper("journalNote.byID")));
  const billings = Object.values(useSelector(reduxStoreWrapper("billing.byID")));

  useEffect(() => {
    dispatch(REDUX_ACTION.v1_wallet.get_customer_all_request_90days());
    dispatch(
      REDUX_ACTION.v1_billing.get_billing_timeframe_thru_90days_request()
    );
  }, [dispatch]);

  const matchedWallet = wallet.find(w => w && customer && w.uid === customer.uid);
  const walletCust = matchedWallet && matchedWallet.balance
 
  return (
    <>
      <ManageCreditIndex billInv={billInv} customer={customer} type="TPN" balanceInv={walletCust} journal={journals} billing={billings}/>
    </>
  );
};

export default ManageCredit;
