// React Thirty Party Library
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Steps, Skeleton } from "antd";

// Constants Library
import { TEMPLATE, ROOT_CONSTANT } from "../../../../../../constants";

// Components Library
import { getTitleCancelPark, getTitleCompleteClose, getTitleDoorstep, getDescDoorstep, getTitleArrive, getDescArrive } from "./title"

// Handler Library

// Services Library
import { REDUX as REDUX_UTIL, TIME } from "../../../../../../services/util";

// Redux Actions
import { v1_orderLog } from "../../../../../../services/redux/actions";

// Styles Library
import classes from "../../../../../../components/index.module.css";
import "./index.css";
import { ButtonLink } from "../../../../../../components";

const { ORDER_STATUS } = TEMPLATE.ORDER;
const { reduxStoreWrapper } = REDUX_UTIL;
const { parseTimeMinDayFirst } = TIME;

const ProgressTable = (props) => {
  const {record, hubs, points, NavigateOrderTimeline} = props;
  const {ordid, status: os, } = record


  const dispatch = useDispatch();
  const orderStatuses = useSelector(reduxStoreWrapper("orderStatus.byID"));
  const orderLogs = useSelector(reduxStoreWrapper(`orderLog.byID.${ordid}`));
  const loading = useSelector(reduxStoreWrapper("style.loading.orderLog"));
  const user = useSelector(reduxStoreWrapper("user"));
  
  
  const OrderStatusDisplay = (status) =>
  (orderStatuses[status] && orderStatuses[status].display) || status;

  const OrderStatusDesc = (status) => {
    const olog =
      status && orderLogs && orderLogs.find((o) => o.status === status);

    return (
      <>
        {(olog && (
          <div className="progressContent">
            <div className="progressDesc">
              <p>{olog.updatedBy}</p>
              <p>{parseTimeMinDayFirst(olog.updatedAt)}</p><br/>
            </div>
            
          </div>
        )) || <></>}
      </>
    );
  };

  const progresses = [
    {
      title: 
      <ButtonLink onClick={() => NavigateOrderTimeline(ordid, os, record)}>
        {OrderStatusDisplay(ORDER_STATUS.SUBMITTED)}
      </ButtonLink>,
      description: OrderStatusDesc(ORDER_STATUS.SUBMITTED),
    },
    {
      title: 
      <ButtonLink onClick={() => NavigateOrderTimeline(ordid, os, record)}>
        {getTitleDoorstep(os, OrderStatusDisplay, record)}
      </ButtonLink>,
      description: getDescDoorstep(os, OrderStatusDesc, record),
    },
    {
      title: 
      <ButtonLink onClick={() => NavigateOrderTimeline(ordid, os, record)}>
      In Transit
      </ButtonLink>,
      description: OrderStatusDesc(record.deliveryType === "p2p" ? ORDER_STATUS.PENDING_OUTBOUND_TO_POINT : ORDER_STATUS.DEPARTED_FROM_ORIGIN_HUB ),
    },
    {
      title: 
      <ButtonLink onClick={() => NavigateOrderTimeline(ordid, os, record)}>
        {getTitleArrive(os, OrderStatusDisplay, record)} 
      </ButtonLink>,
      description: getDescArrive(os, OrderStatusDesc, record),
    },
    {
      title: 
      <ButtonLink onClick={() => NavigateOrderTimeline(ordid, os, record)}>
        {OrderStatusDisplay(ORDER_STATUS.FULFILLED)}
      </ButtonLink>,
      description: OrderStatusDesc(ORDER_STATUS.FULFILLED),
    },
    {
      title: 
      <ButtonLink onClick={() => NavigateOrderTimeline(ordid, os, record)}>
        {getTitleCancelPark(os, OrderStatusDisplay)}
      </ButtonLink>,
      description:  os === ORDER_STATUS.CANCELLED ? 
                      OrderStatusDesc(ORDER_STATUS.CANCELLED) 
                      :
                      os === ORDER_STATUS.PARKED ? 
                        OrderStatusDesc(ORDER_STATUS.PARKED) 
                        :
                        OrderStatusDesc(ORDER_STATUS.CLOSED),
    },
    {
      title: 
      <ButtonLink onClick={() => NavigateOrderTimeline(ordid, os, record)}>
        {getTitleCompleteClose(os, OrderStatusDisplay)}
      </ButtonLink>,
      description: os === ORDER_STATUS.COMPLETED && OrderStatusDesc(ORDER_STATUS.COMPLETED) || os === ORDER_STATUS.CLOSED &&OrderStatusDesc(ORDER_STATUS.CLOSED),
    },
  ];
  
  const getProgress = (os) => {
    let step;

    switch (os) {
      case ORDER_STATUS.SUBMITTED: {
        step = 0;
        break;
      }
      case ORDER_STATUS.PENDING_SELF_LODGE:
      case ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER:
      case ORDER_STATUS.PENDING_SELF_LODGE_POINT: {
        step = 1;
        break;
      }
      case ORDER_STATUS.ARRIVED_AT_ORIGIN_POINT:
      case ORDER_STATUS.CHECK_OUT_FROM_POINT:
      case ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB:
      case ORDER_STATUS.DEPARTED_FROM_ORIGIN_HUB:
      case ORDER_STATUS.ARRIVED_AT_DEST_HUB: 
      case ORDER_STATUS.PENDING_OUTBOUND_TO_POINT:{
        step = 2;
        break;
      } 
      case ORDER_STATUS.PENDING_SELF_COLLECTION:
      case ORDER_STATUS.PENDING_SELF_COLLECTION_POINT: 
      case ORDER_STATUS.PENDING_DOORSTEP_DELIVERY: {
        step = 3;
        break;
      }
      case ORDER_STATUS.FULFILLED: {
        step = 4;
        break;
      }
      case ORDER_STATUS.CANCELLED:
      case ORDER_STATUS.PARKED:  {
        step = 5;
        break;
      }
      case ORDER_STATUS.CLOSED: 
      case ORDER_STATUS.COMPLETED:{
        step = 6;
        break;
      }
      default: {
        step = 0;
        break;
      }
    }

    return step;
  };

  const customDot = (dot) => (
    <>
      <>{dot}</>
    </>
  );

  useEffect(() => {
    dispatch(v1_orderLog.get_order_logs_thru_ordid_request(ordid));

  }, [dispatch, os]);

  return (
    <div className={classes.StepProgression}>
      <Skeleton loading={loading} active>
        <Steps
          items={progresses}
          size="small"
          className={getProgress(os) === 6 && "custome-step"}
          current={getProgress(os)}
          progressDot={customDot}
        />
      </Skeleton>
    </div>
  );
};

export default ProgressTable;
