import { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Select } from "antd";

import { TEMPLATE } from "../../../../constants";

import * as REDUX_ACTION from "../../../../services/redux/actions";

import styles from "../common.module.css";

const { MODALS, MODAL_ACTION } = TEMPLATE.MODAL;

const ModalStandard = (props) => {
	const { setmodal, isModalOpen, setIsModalOpen, onClick = () => {}, extra } = props;

  const dispatch = useDispatch()
  const [ accManagerIdVal, setAccManagerIdVal ] = useState('')
  let selectedAm = extra.am && extra.am.find(p => p.memid === accManagerIdVal)
  
	const handleOkButton = () => {
    setmodal === MODAL_ACTION.link_account_manager_id ? 
	dispatch(REDUX_ACTION.v1_customerList.add_account_manager_id({
		uid: extra.uid,
		hierCode: selectedAm.hierCode,
		hierLevel: selectedAm.hierLevel,
		cm: selectedAm,
	  })).then(data => {
        const updatedData = {
          ...extra,
          hierCode: data.user.hierCode,
          hierLevel: data.user.hierLevel,
        };

        delete updatedData.am, updatedData.amid

        dispatch(REDUX_ACTION.v1_customerList.edit_customer_info_request(updatedData));
		setIsModalOpen();
      }).catch(error => {
        console.error("Error during dispatch:", error);
      })
	  
    :
		onClick()
		setIsModalOpen();
	};

	const content =
		(extra &&
			Object.keys(extra).length &&
			MODALS[setmodal]?.content(extra)) ||
		MODALS[setmodal]?.content;

	return (
		<Modal
			open={isModalOpen}
			footer={null}
			onCancel={setIsModalOpen}
			width={400}
		>
			<div className={styles["content-container"]}>
				<img src={MODALS[setmodal]?.img} alt="the logo" />

				<div className={styles["contents"]}>
					<h2>{MODALS[setmodal]?.title}</h2>
					<div>
						<p className={styles.textCard}>{content}</p>
					</div>

					{setmodal === MODAL_ACTION.link_account_manager_id && (
						<Select
							size="large"
							style={{ textAlign: "left", fontSize: "10px" }}
							onChange={e => setAccManagerIdVal(e)}
							placeholder="Account manager ID"
							options={extra.am.map(am => ({
                label: `${am.memid} / ${am.name}`,
                value: am.memid
              }))}
						/>
					)}

					<div className={styles["buttons"]}>
						<button
							onClick={handleOkButton}
							className={`${styles["button"]} ${styles["active"]}`}
						>
							Yes, Proceed
						</button>
						<button
							onClick={setIsModalOpen}
							className={styles["button"]}
						>
							No, Back to {setmodal.split('_')[0] === 'confirmation' ? 'list' : 'Portal' }
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ModalStandard;
