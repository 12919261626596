// React Thirty Party Library
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "antd"; 

// Constants Library
import { TEMPLATE } from "../../../../../../constants";

// Components Library
import { TableList, ButtonLink, DrawerChangePass, DrawerRemark } from "../../../../../../components";
import { ActionItems } from "./action.js"
import DrawerEditHub from "./DrawerEditHub"

//handlers
import { userListHDLR, tableHDLR } from "../../../../../../handlers";
 
// Services Library
import { REDUX as REDUX_UTIL } from "../../../../../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../../../../../services/redux/actions";


const { TABLE_HUB_TITLE, TABLE_HUB_OPERATOR_INDEX } = TEMPLATE.HUB;
const { SELECT_OPTION_DAYS } = TEMPLATE.BILLING
const { HUB_OPERATOR_SUMMARY_COL } = TEMPLATE.REPORT.HUBOPERATOR;
const { reduxStoreWrapper } = REDUX_UTIL;

const TableHub = (props) => {
    const {
        dataSource,
        filteredData,
        setFilteredData,
        loading,
        selectedRow,
        setSelectedRow
      } = props;

    const dispatch = useDispatch()

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isOpenEdit, setIsOpenEdit] = useState(false)
    const [isOpenChange, setIsOpenChange] = useState(false)
    const [isOpenRemark, setIsOpenRemark] = useState(false)
    const [data, setData] = useState({})

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
 
    const columns = [
        ...HUB_OPERATOR_SUMMARY_COL({
          func:{
            handleSearch
          }
        }),
        {
          title: TABLE_HUB_TITLE.actions,
          width: 100,
          fixed: "right",
          render: (_, record) => {

            return (
            <Dropdown
                menu={{
                    items: ActionItems(setIsOpenEdit, setData, record, setIsOpenChange, setIsOpenRemark, dispatch),
                }}
            >
                <ButtonLink>Action</ButtonLink>
            </Dropdown>
            );
        }},
      ]

      const hubOperator = useSelector(reduxStoreWrapper("hubOperator.byRole"))
      const submitRemarkHDLR = (items, cb) => dispatch(REDUX_ACTION.v1_hubOperator.create_hub_operators_remark_request(items, cb()))
      const editRemarkHDLR = (items, cb) => dispatch(REDUX_ACTION.v1_hubOperator.update_hub_operators_remark_request(items, cb()))
      const deleteRemarkHDLR = (items, cb) => dispatch(REDUX_ACTION.v1_hubOperator.delete_hub_operators_remark_request(items, cb()))

      const sortedData = filteredData.sort((a, b) => b.updatedAt - a.updatedAt);

      const [filteredInfo, setFilteredInfo] = useState({});
      const [sortedInfo, setSortedInfo] = useState({});
    
      const handleTableChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
      };
    
      const sortFunctions = {
        createdAt: (a, b) => a.createdAt - b.createdAt,
        updatedAt: (a, b) => a.updatedAt - b.updatedAt,
      };
      
      const filtered = sortedData.filter((record) => {
        let include = true;
      
        Object.keys(filteredInfo).forEach((key) => {
          if (filteredInfo[key] && filteredInfo[key].length > 0) {
            include = include && filteredInfo[key].includes(record[key]);
          }
        });

        if (searchText) {
          if(searchedColumn === "email") {
            if ((typeof record.email === 'string' && record.email.includes(searchText))) {
              include = true;
            }
          }
          if(searchedColumn === "memid") {
            if ((typeof record.memid === 'string' && record.memid.includes(searchText)) || 
                (typeof record.name === 'string' && record.name.includes(searchText))) {
              include = true;
            }
          }
          if(searchedColumn === "contact") {
            if ((typeof record.contact === 'string' && record.contact.includes(searchText))) {
              include = true;
            }
          }
        }      
    
        return include;
    
      }).sort((a, b) => {
        const { columnKey } = sortedInfo;
        return sortFunctions[columnKey] ? sortFunctions[columnKey](a, b) : 0;
      });

      const onSelectRow = (record, selected) => {
        tableHDLR.selectRow(setSelectedRowKeys, setSelectedRow, selectedRow, selectedRowKeys, record, selected)
      };
    
      const rowSelection = {
        selectedRowKeys,
        onSelect: onSelectRow
      };

      return (
        <>
          <DrawerEditHub isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} data={data}/>
          <DrawerChangePass isOpen={isOpenChange} setIsOpen={setIsOpenChange} data={data} page="operator"/>
          <DrawerRemark  
              isOpen={isOpenRemark} setIsOpen={setIsOpenRemark} data={data}
              reduxData={hubOperator[0]}
              submitRemarkHDLR={submitRemarkHDLR}
              editRemarkHDLR={editRemarkHDLR}
              deleteRemarkHDLR={deleteRemarkHDLR}
              loading={loading}
            />
          <TableList.DataList
            dataSource={dataSource}
            filteredData={filtered}
            setFilteredData={setFilteredData}
            optionDay={SELECT_OPTION_DAYS}
            optionFirst={userListHDLR.userListStatus()}
            dataDate={TABLE_HUB_OPERATOR_INDEX.created_at}
            defaultValue="Created At"
            text1="Hub Operator Status"
            columnData={columns} 
            loading={loading}
            rowSelection={rowSelection}
            showFirstFilter={true}
            onChange={handleTableChange}
          /> 
        </>
      );
}

export default TableHub
