// React Thirty Party Library
import { Tag, Typography } from "antd";

// Constant
import { TEMPLATE } from "../../../constants";

// Components
import { ButtonLink } from "../../../components";

//handlers
import { filter } from "../../../handlers"

// Service
import { TIME } from "../../../services/util";

const {
  TABLE_BILLING_TITLE,
  TABLE_BILLING_INDEX,
  OPTION_STATUS_TITLE,
  BILLING_TYPE,
  OPTION_TYPE_TITLE,
} = TEMPLATE.BILLING;

const { parseTimeMin } = TIME;

const { searchHDLR, filterHDLR } = filter

const { Text } = Typography;

export const BILLING_SUMMARY_COL = ({ func = {}, exportState = false }) => {

  const { NavigateOrderSummary, handleSearch } = func;

  return [
    {
      title: TABLE_BILLING_TITLE.Billing_ID,
      key: TABLE_BILLING_INDEX.Billing_ID,
      render: (record) => {
        const { billid } = record;
        return exportState ? billid : <p>{billid} </p>;
      },
      ...searchHDLR.getColumnSearchProps("billid", handleSearch)
    },
    {
      title: TABLE_BILLING_TITLE.Billing_Type,
      key: TABLE_BILLING_INDEX.Billing_Type,
      width: 150,
      render: (record) => {
        const { type } = record;
        const typeBill = OPTION_TYPE_TITLE[type];
        return exportState ? typeBill : <span>{typeBill} </span>;
      },
      filters : filterHDLR.getColumnsFilterType(BILLING_TYPE, OPTION_TYPE_TITLE),
      onFilter: (value, record) => record.type === value,
    },
    {
      title: TABLE_BILLING_TITLE.Billing_Status,
      key: TABLE_BILLING_INDEX.Billing_Status,
      width: 150,
      render: (record) => {
        const { status } = record;
        const { name, colorTag } = OPTION_STATUS_TITLE[status && status];
        return exportState ? (
          name
        ) : ( 
          <>
            <Tag color={colorTag} key={status}>
              {name}
            </Tag>
          </>
        );
      },
      filters : filterHDLR.getColumnsFilterStatus(OPTION_STATUS_TITLE),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: TABLE_BILLING_TITLE.Billing_Date,
      key: TABLE_BILLING_INDEX.Billing_Date,
      render: (record) => {
        const { updatedAt } = record;
        return exportState ? (
          parseTimeMin(updatedAt)
        ) : (
          <span>{parseTimeMin(updatedAt)} </span>
        );
      },
      sorter: (a, b) => a.updatedAt - b.updatedAt,
    },
    {
      title: TABLE_BILLING_TITLE.customer_id,
      key: TABLE_BILLING_INDEX.customer_id,
      width: 190,
      render: (record) => {

        const { memid, name } = record;

        return exportState ? (
          `${memid} / ${name}`
        ) : (
          <span>
            {memid} / {name}
          </span>
        );
      },
      ...searchHDLR.getColumnSearchProps("memid", handleSearch) 
    },
    {
      title: TABLE_BILLING_TITLE.Price,
      key: TABLE_BILLING_INDEX.Price,
      render: (record) => {
        const { currency, amount } = record;
        return exportState ? `${currency} ${amount}` : <span>RM{amount} </span>;
      },
    },
    {
      title: TABLE_BILLING_TITLE.attachment,
      key: TABLE_BILLING_INDEX.attachment,
      render: (record) => {
        const { url = {}, refid } = record;

        const handleButtonClick = () => {
          window.open(url.jn, '_blank'); 
        };

        const journal = () => {
          if (!url.jn || url.jn === undefined || url.jn === "" || url.jn === "-") {
            return (
              <Text disabled>{refid}</Text>
            );
          } else {
            return (
              <ButtonLink onClick={handleButtonClick}>
                {refid}
              </ButtonLink>
            );
        };
      }

        const journalid = journal()

        return exportState ? (
          url.jn
        ) : (
          journalid
        );
      }, 
    },
    {
      title: TABLE_BILLING_TITLE.Order_ID,
      key: TABLE_BILLING_INDEX.Order_ID,
      render: (record) => {
        const { ordid } = record;

        const order = () => {
          if (ordid === undefined || ordid === "" || ordid === "-") {
            return (
              <Text disabled>{"-"}</Text>
            );
          } else {
            return (
              <ButtonLink onClick={()=>NavigateOrderSummary(ordid)}>{ordid}</ButtonLink>
            );
        };
      }

      const orderID = order()

        return exportState ? (
          ordid
        ) : (
          orderID
        ); 
      },
      ...searchHDLR.getColumnSearchProps("ordid", handleSearch) 
    },
    {
      title: TABLE_BILLING_TITLE.Remarks,
      key: TABLE_BILLING_INDEX.Remarks,
      width: 150,
      render: (record) => {
        const { remark } = record;
        return exportState ? remark : <span>{remark} </span>;
      },
    }
  ];
};
