import * as API_CONSTANT from "../constants";
import { APP_INFO } from "../../../config";

export const getOrderTimeFrameThruPORGID = ({ porgids, startTime, endTime }) =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/order/get/timeframe/porgid`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      porgids,
      startTime,
      endTime,
    }),
  }).then((res) => res.json());

export const getOrderLogsByORDID = ({ ordid }) =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/order/log/get/ordid`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      ordid,
    }),
  }).then((res) => res.json());

export const updateOrderLogsByORDID = ({ordid, items}) =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/order/log/update`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      ordid,
      items
    }),
  }).then((res) => res.json());

export const getAllOrderStatues = ({ platid = APP_INFO.APP_PLATFORM } = {}) =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/order/status/get/all`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      platid,
    }),
  }).then((res) => res.json());

  
export const updateOrder = async ({ order, statusChange, orderState, update, customer, attachment, sorting}) => {

  const requestBody = {
    order, statusChange, orderState, update
  };

  if (customer && Object.keys(customer).length > 0) {
    requestBody.customer = customer;
  }

  if (attachment && attachment.length > 0) {
    requestBody.attachment = attachment;
  } 

  if (sorting) {
    requestBody.sorting = sorting;
  }

  // console.log(requestBody)

  const res = await fetch(
    `${API_CONSTANT.adp_v1_fe_path}/order/update`,
    {
      method: "POST",
      headers: API_CONSTANT.headers,
      body: JSON.stringify(requestBody),
    }
  );
  return await res.json();
};

export const submitBulkOrder = async (requestData) => {

const res = await fetch(
  `${API_CONSTANT.adp_v1_fe_path}/order/submit/bulk`, 
  {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify(requestData),
  } 
  );
  return await res.json();
}

  export const setOrderComment = ({ordid, action, orderComment}) => 
    fetch(`${API_CONSTANT.adp_v1_fe_path}/order/comment/cu`, {
      method: "POST",
      headers: API_CONSTANT.headers,
      body: JSON.stringify({
        ordid,
        action,
        orderComment
      })
    })
    .then(res => res.json())

  export const updateOrderComment = ({ordid, action, items}) => 
    fetch(`${API_CONSTANT.adp_v1_fe_path}/order/comment/cu`, {
      method: "POST",
      headers: API_CONSTANT.headers,
      body: JSON.stringify({
        ordid,
        action,
        items
      })
    })
    .then(res => res.json())

  export const getOrderCommentThruOrdid = ({ordid, startTime, endTime}) => 
    fetch(`${API_CONSTANT.adp_v1_fe_path}/order/comment/get/timeframe/ordid`, {
      method: "POST",
      headers: API_CONSTANT.headers,
      body: JSON.stringify({
        ordid,
        startTime,
        endTime
      })
    })
    .then(res => res.json())

    export const getOrderAssignVehicle = ({orders, v_plate, adpMemid}) => 
      fetch(`${API_CONSTANT.adp_v1_fe_path}/order/assign/vehicle`, {
        method: "POST",
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
          orders,
          v_plate,
          adpMemid
        })
      })
      .then(res => res.json())


