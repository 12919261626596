// Third-party Library 
import { 
    useNavigate 
} from "react-router";
import {
    useSelector
} from 'react-redux'
import { 
    Row, 
    Col,
    Button
} from "antd";
import { 
    LeftOutlined
} from "@ant-design/icons";

// Components 
import { 
    BtnSubmit 
} from "../../../../../../components"

// Services 
import { REDUX as REDUX_UTIL } from "../../../../../../services/util";

const { reduxStoreWrapper } = REDUX_UTIL

const PointCreateHeader = (props) => {
    const { title, btnText, hubid, onSubmit } = props;
    const navigate = useNavigate()

    const hubOperatorByHubid = useSelector(reduxStoreWrapper(`hubOperator.byHubid.${hubid}`))
    const disabled = hubOperatorByHubid || !hubid ? false : true
    
    const goBack = () => {
        navigate(-1)
    }

    
    return (
        <Row justify={'space-between'} style={{position:'fixed', zIndex:1, width: '75%', top: '100px', background:'white', padding: '40px 10px 10px'}}>
            <Col span={10}>
                <Row justify={'start'} className="" onClick={goBack} style={{ cursor: 'pointer'}}>
                    <LeftOutlined onClick={() => {}} style={{fontSize: '20px'}}/>
                    <p style={{fontSize: '20px', fontWeight: 600, marginLeft: '20px'}} >{title}</p>
                </Row>
            </Col>
            <Col span={6}>
                <Row justify={'end'}>
                    <Button
                        style={{
                          display: 'inline-block',
                          color: '#878787',
                          backgroundColor: 'transparent',
                          border: '1px solid #878787',
                          padding: '10px 19px 32px 19px'
                        }}
                        type="button"
                        onClick={goBack}
                    >
                        Cancel
                    </Button>
                    <BtnSubmit
                        display={"inline-block"}
                        text={btnText}
                        bgColor="var(--themeBackgroundColor)"
                        color="var(--creamColor)"
                        padding="11px 19px"
                        disable={disabled}
                        onClick={() => onSubmit()}
                    />
                </Row>
            </Col>
        </Row>
    )
}

export default PointCreateHeader
