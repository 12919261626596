// React Thirty Party Library
import { useSelector } from "react-redux";

// Constants Library
import { TEMPLATE } from "../../../../../../constants";

// Components Library
import { BtnSubmit, TableList } from "../../../../../../components";

// Handler Library
import { exportHDLR } from "../../../../../../handlers";

// Services Library
import { TIME, REDUX as REDUX_UTIL } from "../../../../../../services/util";

// Redux Actions

// Styles Library
import classes from "../../../../../../components/index.module.css";

const { TABLE_USER_INDEX, TABLE_USER_TITLE } = TEMPLATE.USERLIST;
const { BTN_IMG } = TEMPLATE.BTN;
const { CUSTOMERLIST_SUMMARY_COL } = TEMPLATE.REPORT.CUSTOMERLIST;

const { parseExportTime } = TIME;
const { reduxStoreWrapper } = REDUX_UTIL

const SearchCustomerList = (props) => {
  const { setFilteredData, dataSource, filteredData, selectedRow } = props;

  const wallets = Object.values(useSelector(reduxStoreWrapper("wallet.byID")));

  const template = CUSTOMERLIST_SUMMARY_COL({redux: {wallets}, exportState: true});

  const options = [
    {
      value: TABLE_USER_INDEX.User_ID,
      label: TABLE_USER_TITLE.User_ID,
      type: "text",
    },    
    {
      value: TABLE_USER_INDEX.Created_At,
      label: "Custom Date",
      type: "timeframe",
    },
  
  ];

  const exportData = selectedRow.length > 0 ? selectedRow : filteredData;

  return (
    <div className={classes.headerPage}>
      <TableList.SearchTableQuery
        dataSource={dataSource}
        setFilteredData={setFilteredData}
        options={options}
        defaultValue={TABLE_USER_INDEX.User_ID}
      />
      <BtnSubmit
        display="inline-block"
        text="Export"
        bgColor="transparent"
        color="#4CAF50"
        padding="9px 18px"
        onClick={() =>
          exportHDLR.exportWithTemplate({
            data: exportData,
            filename: `CustomerList_${parseExportTime()}`,
            template: template,
          })
        }
        showIcon={true}
        icon={BTN_IMG.EXPORT}
        icon_alt={"export"}
      />
    </div>
  );
};

export default SearchCustomerList;
