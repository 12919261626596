import { Tag } from "antd";
// Contanst
import { TEMPLATE } from "../../constants";

const {
  OPTION_PARK_ORDER_STATUS,
  OPTION_PARK_ORDER_STATUS_ALL,
  OPTION_PARK_ORDER_STATUS_LABEL
} = TEMPLATE.PARKORDER

export const parkOrderStatus = () => {
  let tree = []


  if(OPTION_PARK_ORDER_STATUS) {
    const allChildren = Object.values(OPTION_PARK_ORDER_STATUS).map(status => {

      const { tag, color } = OPTION_PARK_ORDER_STATUS_LABEL[status]

      const title = (
        <Tag color={color} key={status}>
          {tag}
        </Tag>
      )
      const key = `status#${status}`
      const value =  `status#${status}`

      return {
        title: title, 
        key: key, 
        value: value
      }
    })

    const allOption = {
      title: "ALL",
      key: "ALL",
      value: "ALL"
    }

    tree.push({ ...allOption, children: [...allChildren] })
  }

  const filterSelectedChildren = node => {
    if(node.value === "ALL"){
      return tree.flatMap(n => n.children.map(child => child.value))
    }

    return [node.value]
  }

  tree.filterSelectedChildren = filterSelectedChildren

  return tree
}
