
import { useNavigate } from "react-router";

// Constants Library
import { ROUTES, COMMON_FEATURES } from "../../constants";

// Components
import {
  SearchReport,
  TableReport
} from './components'

// Services Library

// Redux Actions

// Styles
import coms from "../common.module.css"

const { ROUTE_URL } = ROUTES;
const { FEATURE_NAME } = COMMON_FEATURES;

const Report = () => {
  const navigate = useNavigate()
  
  const NavigateOrderSummary = (ordid) => {
    navigate(ROUTE_URL[FEATURE_NAME.ORDER_SUMMARY], {
      state: { ordid },
    });
  };

  return (
      <div className={coms["container"]}>
          <SearchReport  />
          <TableReport NavigateOrderSummary={NavigateOrderSummary}/>
      </div>
  )
}

export default Report