// React Thirty Party Library
import { useState } from "react";
import { Tag, Badge, Switch, message, Alert } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { useDispatch } from "react-redux";
// Constant
import { TEMPLATE } from "../../../constants";

// Components 
import { ButtonModal } from "../../../components";

const { MODAL_ACTION } = TEMPLATE.MODAL;

//handlers
import { filter } from "../../../handlers"

// Service
import { TIME } from "../../../services/util";
import * as REDUX_ACTION from "../../../services/redux/actions"
import '../../../layout/index.css'

const {
  TABLE_USER_TITLE,
  TABLE_USER_INDEX,
  OPTION_USER_STATUS_TITLE,
  OPTION_USER_STATUS,
  OPTION_VERIFICATION_STATUS,
  OPTION_VERIFICATION_STATUS_TITLE
} = TEMPLATE.USERLIST;

const { parseTimeMin } = TIME;

const { searchHDLR, filterHDLR } = filter

 
export const CUSTOMERLIST_SUMMARY_COL = ({ redux = {}, func = {}, exportState = false }, setIsOpenBusiness, setData) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [activateBizAcc, setActivateBizAcc] = useState(false)
  const dispatch = useDispatch()
  const [messageApi, contextHolder] = message.useMessage();


  const toggleDrawer = () => {
    setModalVisible(!modalVisible);
  };
  const { wallets, customers, accManager } = redux
  const { handleSearch } = func

  const warningSwitch = (click) => {
    if (click) {
      message.warning('You are using personal account. Please activate the business account for using this feature');
    }
  }

  return [
    {
      title: TABLE_USER_TITLE.User_ID + "/" + TABLE_USER_TITLE.User_Name,
      key: TABLE_USER_INDEX.User_ID,
      width: 200,
      fixed: "left",
      render: (record) => {
        const { memid, name } = record;
        return exportState ? (
          `${memid} ${name}`
        ) : (
          <span>
            {memid} / {name}
          </span>
        );
      },
      ...searchHDLR.getColumnSearchProps("memid", handleSearch)
    },
    {
      title: TABLE_USER_TITLE.User_Status,
      key: TABLE_USER_INDEX.User_Status,
      width: 150,
      fixed: "left",
      render: (record) => {
        const { status } = record;

        const statusKey = status ? OPTION_USER_STATUS.Active : OPTION_USER_STATUS.Suspend;
        const { name, colorTag } = OPTION_USER_STATUS_TITLE[statusKey];

        return exportState ? (
          name
        ) : (
          <>
            <Tag color={colorTag} key={statusKey}>{name}</Tag>
          </>
        );
      },
      filters: filterHDLR.getColumnsFilterStatus(OPTION_USER_STATUS_TITLE),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: TABLE_USER_TITLE.Role,
      width: 150,
      // fixed: "left",
      render: (record) => {
        const { uroleid } = record;

        return exportState ? (
          uroleid
        ) : (
          <>
            {uroleid === 'C' ? 'IPICK' : 'MBE Kiosk'}
          </>
        );
      },
    },
    {
      title: TABLE_USER_TITLE.Verification_Status,
      key: TABLE_USER_INDEX.Verification_Status,
      width: 150,
      render: (record) => {
        const { cm_verified } = record;

        const cmVerifiedKey = cm_verified
          ? OPTION_VERIFICATION_STATUS.Verified
          : OPTION_VERIFICATION_STATUS.Unverified;
        const { name, colorTag } = OPTION_VERIFICATION_STATUS_TITLE[cmVerifiedKey];

        return exportState ? name :
          <Badge color={colorTag} text={<span style={{ color: colorTag }}>{name}</span>} />
      },
      filters: filterHDLR.getColumnsFilterStatus(OPTION_VERIFICATION_STATUS_TITLE),
      onFilter: (value, record) => record.cm_verified === value,
    },
    {
      title: TABLE_USER_TITLE.Wallet_Balance,
      key: TABLE_USER_INDEX.Wallet_Balance,
      width: 150,
      render: (record) => {
        const w = wallets.find(w => w.uid === record.uid)
        return exportState ? (
          `${w && w.currency} ${w && w.balance}` || ""
        ) : (
          <span>
            {w ? w.currency : 0}
            {w && w.balance}
          </span>
        );
      },
    },
    {
      title: TABLE_USER_TITLE.Account_Manager_ID,
      key: TABLE_USER_INDEX.Account_Manager_ID,
      width: 200,
      render: (record) => {
        const { hierCode, hierLevel } = record
        let amid
        let name
        if (hierLevel > 2) {
          const hierCodes = hierCode.split("#")
          hierCodes.pop()
          const am = accManager && accManager.find((r) => r.hierCode.startsWith(hierCodes.join("#")))
          amid = am && am.memid
          name = am && am.name
        } else {
          amid = ""
        }
        return exportState ? amid || "" : <span> {amid ? amid + " / " + name : "-"} </span>;
      },
    },
    {
      title: TABLE_USER_TITLE.Contact,
      key: TABLE_USER_INDEX.Contact,
      width: 200,
      render: (record) => {
        const { contact } = record;
        return exportState ? contact : <span> {contact} </span>;
      },
    },
    {
      title: TABLE_USER_TITLE.Email,
      key: TABLE_USER_INDEX.Email,
      width: 200,
      render: (record) => {

        const { email, email_verified } = record;
        return exportState ? (
          email
        ) : (
          <span>
            {email} &nbsp;
            {email_verified === 1 ? (
              <CheckCircleFilled
                style={{
                  color: "var(--bs-success)",
                }}
              />
            ) : (
              ""
            )}
          </span>
        );
      },
      ...searchHDLR.getColumnSearchProps("email", handleSearch)
    },
    {
      title: TABLE_USER_TITLE.Address,
      key: TABLE_USER_INDEX.Address,
      width: 200,
      render: (record) => {
        const { address } = record;
        return exportState ? (
          address
        ) : (
          address ?
            <span>
              {address}
            </span> : '-'
        );
      },
    },
    {
      title: TABLE_USER_TITLE.State,
      key: TABLE_USER_INDEX.State,
      width: 150,
      render: (record) => {
        const { state } = record;
        return exportState ? (
          state
        ) : (
          state ?
            <span>
              {state}
            </span> : '-'
        );
      },
    },
    {
      title: TABLE_USER_TITLE.Postcode_City,
      key: TABLE_USER_INDEX.Postcode,
      width: 200,
      render: (record) => {

        const { postcode, city } = record;
        return exportState ? (
          postcode
        ) : (
          postcode ?
            <span>
              {postcode} / {city}
            </span> : '-'
        );
      },
    },
    {
      title: TABLE_USER_TITLE.Comp_Name,
      // key: TABLE_USER_INDEX.Postcode,
      width: 200,
      render: (record) => {
        const { company } = record
        return (
          company === undefined ? '-' : company.name
        );
      },
    },
    {
      title: TABLE_USER_TITLE.State,
      // key: TABLE_USER_INDEX.Postcode,
      width: 200,
      render: (record) => {
        const { company } = record
        return (
          company === undefined ? '-' : company.state
        );
      },
    },
    {
      title: TABLE_USER_TITLE.Postcode_City,
      // key: TABLE_USER_INDEX.Postcode,
      width: 200,
      render: (record) => {
        const { company } = record
        return (
          company === undefined ? '-' : `${company.postcode} / ${company.city}`
        );
      },
    },
    {
      title: TABLE_USER_TITLE.Address,
      // key: TABLE_USER_INDEX.Postcode,
      width: 200,
      render: (record) => {
        const { company } = record
        return (
          company === undefined ? '-' : company.address
        );
      },
    },
    {
      title: TABLE_USER_TITLE.Biz_Acc,
      // key: TABLE_USER_INDEX.Postcode,
      width: 200,
      render: (record) => {
        const { type, company } = record
        return (
          type === 'CT01' ?
            <ButtonModal
              model="link"
              extra={record}
              setmodal={MODAL_ACTION.personal_account}
              onClick={() => {
                const updatePersonal = {
                  ...record,
                  type: 'CT00',
                  paymentModel: 'PM00'
                }
                message.loading({
                  content: "Updating...",
                });
                dispatch(REDUX_ACTION.v1_customerList.edit_customer_info_request(updatePersonal, () => message.destroy()));
                // setIsOpenBusiness(false);
              }}
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No" checked={company === undefined ? false : (company.name ? true : false)} />
            </ButtonModal> :
            <ButtonModal
              model="link"
              extra={record}
              setmodal={MODAL_ACTION.business_account}
              onClick={() => {
                setIsOpenBusiness(true)
                setData(record)
              }}
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No"
                onChange={toggleDrawer} checked={
                  false
                }
              />
            </ButtonModal>

        );
      },
    },
    {
      title: TABLE_USER_TITLE.Postpaid,
      // key: TABLE_USER_INDEX.Postcode,
      width: 200,
      render: (record) => {
        const { type, paymentModel, uroleid } = record
        return (
            (paymentModel === 'PM01'  ? <ButtonModal
              model="link"
              extra={record}
              setmodal={MODAL_ACTION.prepaid}
              onClick={() => {
                const updateToPrepaid = {
                  ...record,
                  paymentModel: 'PM00'
                }
                message.loading({
                  content: "Updating...",
                  // duration: 0,
                });
                dispatch(REDUX_ACTION.v1_customerList.edit_customer_info_request(updateToPrepaid, () => message.destroy()));
              }}
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No"
                onChange={toggleDrawer} checked={true} />
            </ButtonModal> :
              <ButtonModal
                model="link"
                extra={record}
                setmodal={MODAL_ACTION.postpaid}
                onClick={() => {
                  const updateToPostpaid = {
                    ...record,
                    paymentModel: 'PM01',
                  }
                  message.loading({
                    content: "Updating...",
                    // duration: 0,
                  });
                  dispatch(REDUX_ACTION.v1_customerList.edit_customer_info_request(updateToPostpaid, () => message.destroy()));

                }}
              >
              <Switch checkedChildren="Yes" unCheckedChildren="No" checked={false} />
              </ButtonModal>) 
        );
      },
    },
    {
      title: TABLE_USER_TITLE.Created_At,
      key: TABLE_USER_INDEX.Created_At,
      width: 200,
      render: (record) => {
        const { createdAt } = record;
        return exportState ? (
          parseTimeMin(createdAt)
        ) : (
          <span> {parseTimeMin(createdAt)} </span>
        );
      },
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      title: TABLE_USER_TITLE.Remark,
      key: TABLE_USER_INDEX.Remark,
      width: 200,
      render: (record) => {
        const { remark } = record;
        return exportState ? Array.isArray(remark) && remark[0] && remark[0].msg : <p> {Array.isArray(remark) && remark[0] && remark[0].msg || '-'} </p>
      },
    },
  ];

};
