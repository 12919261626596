import * as ActionTypes from "../action-types";

const journalNote_defaultState = {
  byID: {},
  allIDs: [],
};

export const journalNote = (state = journalNote_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.GET_JOURNAL_NOTE: {
      const journalNotes = action.journalNote;
      
      journalNotes &&
      journalNotes.length &&
      journalNotes.map((journalNote) => {
        const { jnid } = journalNote;
        
        newState.byID[jnid] = journalNote;
        
        if (!newState.allIDs.includes(jnid)) newState.allIDs.push(jnid);
        
        return null;
      });
      
      return newState;
    }
    case ActionTypes.SET_JOURNAL_NOTE: {
      const journalNotes = action.journalNote;
      const { jnid } = journalNotes;

      if (jnid) {
        newState.byID[jnid] = journalNotes;
        if (!newState.allIDs.includes(jnid)) newState.allIDs.push(jnid);
      }


      return newState;
    }
    default: {
      return state;
    }
  }
};
