// Third-party Library
import { useEffect, useState, createContext } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Form, message } from 'antd'
import moment from 'moment';

// Constants
import { POINT } from '../../../../constants/templates';

// Components 
import {
    HubCreateHeader,
    HubInformation,
    HubLocation,
    HubOperators,
    PICInformation
} from "./components";

// Services 
import { REDUX as REDUX_UTIL } from "../../../../services/util";

import * as REDUX_ACTION from "../../../../services/redux/actions";

// Styles 
import classes from "../../common.module.css"

const { reduxStoreWrapper } = REDUX_UTIL

const { GET_BIZ_HRS_OBJ, GET_BIZ_HRS_ARR } = POINT

const HubCreate = ({
    hubData
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const user = useSelector(reduxStoreWrapper("user"))
    
    const [ form ] = Form.useForm();
    const [ messageApi, contextHolder ] = message.useMessage();

        const onSubmit = () => {

            const { name, code, coordinate, pic, url, address, postcode, city, state, contactPrefix, biz_hrs, type, operator: ops } = form.getFieldsValue()

            // Some modified value for data passing to API
            const hubid = hubData ? hubData.hubid : `hub#${code.toLowerCase()}` 
            const status = hubData ? hubData.status : 1

            const operators = {
                hubid,
                // op is Operator object array, consists of list of uid 
                operators: ops.map(op => ({
                    uid: op
                }))
            }

            // value passed into API, with adjusted format
            const items = {
                hubid, status,
                type, name, code, url, postcode, city, state, address,
                coordinate: {
                    lat: parseFloat(coordinate.lat),
                    lon: parseFloat(coordinate.lon)
                },
                pic: pic.map(pic => ({ 
                  name: pic.name, 
                  contact: `${pic.contactPrefix}-${pic.contact}` 
                })),
                biz_hrs: GET_BIZ_HRS_ARR(biz_hrs),
            }

            delete items.operator

            if(items.biz_hrs.length !== 0) {
                  // to show the loading popup 
                  messageApi.open({
                    type: "loading",
                    content: `${hubData ? "Updating" : "Adding new"} hub`,
                    duration: 0,
                });

                if (!hubData) {
                    dispatch(REDUX_ACTION.v1_hub.create_new_hub_request(
                        items,
                        operators,
                        () => messageApi.destroy(),
                        () => navigate(-1)
                    ))
                } else {
                    dispatch(REDUX_ACTION.v1_hub.hub_update_request(
                        { ...items, updatedBy: user.memid },
                        operators,
                        () => messageApi.destroy(),
                        () => navigate(-1)
                    ))
                }
            } else {
              message.error("Operation Hours cannot be empty. Please enter the corresponding information.")
            }
    }

    /*
        validate form function
        if the required form still blank, it won't be submitted
    */
    const onSubmitValidateHDLR = async (e) => {
        e
        &&
        await form.validateFields()
        .then(() => {
            onSubmit()
        })
        .catch(() => {
            message.error("Fill the blank!")
        })
    }
    
    const initialValues = 
        hubData 
        && 
        {
            ...hubData,
            biz_hrs: GET_BIZ_HRS_OBJ(hubData),
            pic: hubData.pic.map(pic => ({
                name: pic.name,
                contact: pic.contact.split('-')[1],
                contactPrefix: pic.contact.split('-')[0]
            }))
        } 

    const title = hubData ? "Edit Hub" : "Create new hub"
    const btnText = hubData ? "Save" : "Create"

    return (
        <div className={classes["container"]}>
            { contextHolder }
            <Form
                form={form}
                name="form_createHub"
                layout="vertical"
                onSubmitCapture={onSubmitValidateHDLR}
                initialValues={initialValues}
            >
                <HubCreateHeader
                    title={title}
                    btnText={btnText}
                    hubid={hubData?.hubid}
                />
                <div
                    style={{
                        width: '40vw',
                        minWidth: '50em',
                        margin: '7em auto'
                    }}
                >
                    
                    <HubInformation form={form}/>
                    <HubLocation />
                    <HubOperators hubid={hubData?.hubid} />
                    <PICInformation />
                </div>
            </Form>

        </div>
    )
}

export default HubCreate 
