// React Thirty Party Library
import { Tag } from "antd";

// Components 
import { ButtonLink } from "../../../components";

// Constant
import { TEMPLATE } from "../../../constants";
import { TABLE_POINT_OPERATOR_TITLE, TABLE_POINT_OPERATOR_INDEX } from "../point";

// Services Library
import * as REDUX_ACTION from "../../../services/redux/actions";

//handlers
import { filter } from "../../../handlers"

import { TIME } from "../../../services/util";

const { filterHDLR, searchHDLR } = filter
const { parseTimeMin } = TIME;


const {
    TABLE_POINT_TITLE,
    TABLE_POINT_INDEX,
    OPTION_POINT_STATUS,
    OPTION_POINT_STATUS_TITLE,
    POINT_TYPE_CODE,
    POINT_TYPE_TITLE,
    OPTION_POINT_OP_STATUS_TITLE, 
    OPTION_POINT_OP_STATUS
} = TEMPLATE.POINT

const {
    STATE_TITLE
} = TEMPLATE.ORDER 

export const POINT_SUMMARY_COL = ({redux = {}, func = {},  exportState = false}) => {

    const {hubs, zones} = redux
    const {handleSearch} = func

    return[
        {
            title: TABLE_POINT_TITLE.point_code_name,
            key: TABLE_POINT_INDEX.point_code_name,
            width: 200,
            fixed: "left",
            render:(record)=>{
                const { code, name } = record;
                return exportState ? code : <p>{code} / {name}</p>
            },
            ...searchHDLR.getColumnSearchProps("name", handleSearch)
        }, 
        {
            title: TABLE_POINT_TITLE.status,
            key:TABLE_POINT_INDEX.status,
            width:120,
            fixed: "left",
            render:(record)=>{
                const { status }= record;

                const statusKey = status 
                ? OPTION_POINT_STATUS.Active
                : OPTION_POINT_STATUS.Inactive;

                const { name, colorTag } = OPTION_POINT_STATUS_TITLE[statusKey];
                return exportState ? name :
                <>
                    <Tag color={colorTag}>{name}</Tag>
                </>
            },
            filters: filterHDLR.getColumnsFilterStatus(OPTION_POINT_STATUS_TITLE),
            onFilter: (value, record) => record.status === value

        },
        {
            title: TABLE_POINT_TITLE.zone,
            key:TABLE_POINT_INDEX.zone,
            width:180,
            render:(record)=>{
                const { zone } = record;
7
                return exportState ? zones[zone]?.name : <p>{ zones[zone]?.name|| '-'}</p>
            },
            filters : filterHDLR.getColumnsFilterType(POINT_TYPE_CODE, POINT_TYPE_TITLE),
            onFilter: (value, record) => record.zonid === value,
        },
        {
            title: TABLE_POINT_TITLE.hub_name,
            key: TABLE_POINT_INDEX.hub_name,
            width:180,
            render: (record)=>{
              const { parentHub } = record

                return exportState ? hubs[parentHub]?.name : <p>{ hubs[parentHub]?.name || '-'}</p>
            }
        },
        {
            title: TABLE_POINT_TITLE.state,
            key: TABLE_POINT_INDEX.state,
            width:180,
            render: (record)=>{
              const { state } = record

                return exportState ? state : <p>{state || '-'}</p>
            },
            filters : filterHDLR.getColumnsFilterState(STATE_TITLE),
            onFilter: (value, record) => record.state === value,
        },
        {
            title: TABLE_POINT_TITLE.pc,
            key: TABLE_POINT_INDEX.pc,
            width:180,
            render: (record)=>{

              const { postcode, city } = record

                return exportState ?  `${postcode}, ${city}` : <>
                    <p>{postcode || '-'}</p>
                    <p>{city}</p>
                </>
            }
        },
        {
            title: TABLE_POINT_TITLE.address,
            key: TABLE_POINT_INDEX.address,
            width:200,
            render:(record) =>{
                const {address} = record;
                return exportState ? address : <p>{address}</p>
            }
        },
        {
            title: TABLE_POINT_TITLE.point_operators,
            key: TABLE_POINT_INDEX.point_operators,
            width:180,
            render: (record) =>{
                const {operatorCount} = record;
                return exportState ? operatorCount : <p>{operatorCount}</p>
            },
        },
        {
            title: TABLE_POINT_TITLE.pic,
            key:TABLE_POINT_INDEX.pic,
            width:200,
            render:(record)=>{
                const { pic= [] } = record
                return pic.map((data, i)=>{
                    const name = data.name
                    const contact = data.contact

 
                    return (exportState ? `name ${name}` :
                    
                        <p key={i}>{name} ({contact})</p>
                    )
                })
            }
        },
        {
            title: TABLE_POINT_TITLE.map_coor,
            key:TABLE_POINT_INDEX.map_coor,
            width:180,
            render:(record)=>{
                const { coordinate } = record;
                return exportState ? coordinate :
                <>
                    <p>{ coordinate && coordinate.lat}</p>
                    <p>{coordinate && coordinate.lon}</p>
                </> 
            }
        },
        {
            title: TABLE_POINT_TITLE.url,
            key: TABLE_POINT_INDEX.url,
            width:200,
            render:(record)=>{
                const { url } = record
                return exportState ? url : <a href={url}>{url}</a>
            }
        },
        {
            title: TABLE_POINT_TITLE.created_at,
            key: TABLE_POINT_INDEX.created_at,
            width:190,
            render:(record)=>{
                const { createdAt } = record
                return exportState ? parseTimeMin(createdAt) : <p>{TIME.parseTimeMin(createdAt)}</p>
            },
            sorter: (a, b) => a.createdAt - b.createdAt,
        },
        {
            title: TABLE_POINT_TITLE.last_update,
            width:180,
            render:(record)=>{
              const { updatedAt, updatedBy } = record
              
              return exportState ? `${parseTimeMin(updatedAt)}, ${updatedBy}` : 
              <>
                <p>{updatedBy || '-'}</p>
                <p>{TIME.parseTimeMin(updatedAt)}</p>
              </>
          },
          sorter: (a, b) => a.updatedAt - b.updatedAt,
        }
    ]
};

export const EXPANDED_POINT_OPERATORS_LIST = [
    {
        title:TABLE_POINT_OPERATOR_TITLE.name + "/" + TABLE_POINT_OPERATOR_TITLE.point_opid,
        key: TABLE_POINT_OPERATOR_INDEX.point_opid,
        width:200,
        render: (record) => {
            const { memid, name } = record;
            return  <span>{memid} / {name} </span>;
          },
    },
    {
        title:TABLE_POINT_OPERATOR_TITLE.status,
        key: TABLE_POINT_OPERATOR_INDEX.status,
        width:200,
        render: (record) => {
            const { status } = record;

            const statusKey = status
          ? OPTION_POINT_OP_STATUS.Active
          : OPTION_POINT_OP_STATUS.Suspend;
          
        const { name, colorTag } = OPTION_POINT_OP_STATUS_TITLE[statusKey];

        return (
          <>
            <Tag color={colorTag}>{name}</Tag>
          </>
        )},
    },
    {
        title:TABLE_POINT_OPERATOR_TITLE.contact,
        key: TABLE_POINT_OPERATOR_INDEX.contact,
        width:200,
        render: (record) => {
            const { contact } = record;
            return  <span> {contact} </span>;
          },
    },
    {
        title:TABLE_POINT_OPERATOR_TITLE.created_at,
        key: TABLE_POINT_OPERATOR_INDEX.created_at,
        width:200,
        render: (record) => {
            const { createdAt } = record;
            return  <span>{TIME.parseTimeMin(createdAt)} </span>;
          },
    },
]
