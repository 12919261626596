import { Drawer, Form, Input, Select, message } from "antd";
import { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import DrawerFormVehicle from "../DrawerFormVehicle";
import { REDUX as REDUX_UTIL, TIME } from "../../../../../v1/services/util";

import * as REDUX_ACTION from "../../../../../v1/services/redux/actions";

const { reduxStoreWrapper } = REDUX_UTIL;

const DrawerAddVehicle = (props) => {
  const { isOpen, setIsOpen } = props
  
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const user = useSelector(reduxStoreWrapper("user"));
  const admid = user.memid;


  const handleSave = () => {
    form
      .validateFields()
      .then(() => {
        const formData = form.getFieldsValue(); 

        messageApi.open({
          type: "loading",
          content: "Creating...",
          duration: 0,
        });
      
        form.resetFields();
        setIsOpen(false);
      }).catch(() => {
        message.error("Please fill the required fields");
      });
  };


  const createVehicle = () => {
    const formData = form.getFieldValue()
    const driverVec = formData.driver
    const plateVec = formData.plate
    const isTrackingVec = parseInt(formData.isTracking)
    const remarkVec = formData.remark

    messageApi.open({
      type: "loading",
      content: "Create Vehicle...",
      duration: 0,
    });

    dispatch(
      REDUX_ACTION.v1_vehicle.create_vehicle({ driver: driverVec, plate: plateVec, isTracking: isTrackingVec, remark: remarkVec, adpMemid: admid}, () => {
        messageApi.destroy();
      })
    );
  };

  return (
    <>
    {contextHolder}
    <DrawerFormVehicle
        form={form}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        type="add"
        handleSave={handleSave}
        createVehicle={createVehicle}
    />
    </>
  );
};

export default DrawerAddVehicle;
