import { Dropdown, Table, Spin, message} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router"; 

// Constants Library
import { TEMPLATE, COMMON_FEATURES, ROUTES  } from "../../../../constants";

// Components Library
import { TableList, ButtonLink, ButtonModal } from "../../../../components";
import { ActionItems } from "./action"
import DrawerEditZone from "./DrawerEditZone"

import classes from "../../../../components/index.module.css";

// Handler Library
import { tableHDLR } from "../../../../handlers";

// Services Library
import { REDUX as REDUX_UTIL } from "../../../../services/util";

import * as REDUX_ACTION from "../../../../services/redux/actions";

const { TABLE_ZONE_TITLE, TABLE_EXPAND_ZONE_PRICELIST_TITLE, SELECT_OPTION_DAYS, TABLE_ZONE_INDEX} = TEMPLATE.ZONEPRICELIST;
const { ZONE_SUMMARY_COL, ZONE_PRICELIST_SUMMARY_COL } = TEMPLATE.REPORT.ZONE;
const {MODAL_ACTION} = TEMPLATE.MODAL
const { reduxStoreWrapper } = REDUX_UTIL


const TableZone = (props) => {

    const {
        dataSource,
        filteredData,
        setFilteredData,
        loading,
        selectedRow,
        setSelectedRow
      } = props;

    const dispatch = useDispatch()
    const [messageApi, contextHolder] = message.useMessage();

    const [isOpenEdit, setIsOpenEdit] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState(null)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const priceList = useSelector(reduxStoreWrapper("zone.byZoneid"))

    const handleEditZone = (record) =>{
        setIsOpenEdit(true)
        setSelectedRecord(record)
    }

    const expandedRowRender = (record) => {
        const {zoneid} = record
        let loadTable = false

        if (Object.values(priceList).length === 0 || !priceList[zoneid]) {
            dispatch(REDUX_ACTION.v1_zone.get_price_list_thru_zonid_request(zoneid));
            loadTable = true
        }
        

    const zone = dataSource

        const columns = [
            ...ZONE_PRICELIST_SUMMARY_COL({redux: {zone}}),
            {
                title:TABLE_EXPAND_ZONE_PRICELIST_TITLE.action,
                width:200,
                render: (record) => {
                    const { origin , dest } = record

                    const originZone = zone.find(z => z.zoneid === origin)
                    const origName = originZone && originZone.name
                    const destinationZone = zone.find(z => z.zoneid === dest)
                    const destName = destinationZone && destinationZone.name

                    record.origName = origName;
                    record.destName = destName;
 
                    return record.status === 0 || !record.status ? 
                        <ButtonModal
                            model="link"
                            extra={record}
                            setmodal={MODAL_ACTION.activate_zone_price}
                            onClick={() => {
                                const { origName, destName, ...rest } = record;
                                dispatch(
                                    REDUX_ACTION.v1_zone.update_status_zone_price_list_request({
                                        origin: origin, 
                                        dest: dest, 
                                        items: {...rest, status: 1}
                                    }, () => messageApi.destroy())
                                );
                            }}
                        >
                            Activate
                        </ButtonModal>
                  :
                  <ButtonModal
                    model="link"
                    extra={record}
                    setmodal={MODAL_ACTION.deactivate_zone_price}
                    onClick={() => {
                                const { origName, destName, ...rest } = record;
                                dispatch(
                                    REDUX_ACTION.v1_zone.update_status_zone_price_list_request({
                                        origin: origin, 
                                        dest: dest, 
                                        items: {...rest, status: 0}
                                    }, () => messageApi.destroy())
                                );
                    }}
                  >
                    Deactivate
                  </ButtonModal>
                  
                }
            }
        ]

        return (
            <>
                <Table
                    columns={columns}
                    dataSource={priceList[zoneid]}
                    pagination={false}
                    loading={loadTable}
                />
            </>
        )
    }

    const [searchText, setSearchText] = useState('');

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const columns = [
    ...ZONE_SUMMARY_COL({
        // redux:{
        // },
        func:{
            handleSearch
        }
    }),
    {
        title: TABLE_ZONE_TITLE.action,
        width: 100,
        fixed: "right",
        render: (_, record) => {
        return(
            <Dropdown
            menu={{
                items: ActionItems(
                record,
                handleEditZone
                )
            }}>
            <ButtonLink>Actions</ButtonLink>
            </Dropdown>
        )
        }
    },
    ]

    const sortedData = filteredData.sort((a, b) => b.updatedAt - a.updatedAt)

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const handleTableChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const sortFunctions = {
        createdAt: (a, b) => a.createdAt - b.createdAt,
        updatedAt: (a, b) => a.updatedAt - b.updatedAt,
    };
    
    const filtered = sortedData.filter((record) => {
        let include = true;
    
        Object.keys(filteredInfo).forEach((key) => {
        if (filteredInfo[key] && filteredInfo[key].length > 0) {
            include = include && filteredInfo[key].includes(record[key]);
        }
        });
    
        if (searchText) {
        if ((typeof record.code === 'string' && record.code.includes(searchText))) {
            include = true;
        }
        }    

        return include;

    }).sort((a, b) => {
        const { columnKey } = sortedInfo;
        return sortFunctions[columnKey] ? sortFunctions[columnKey](a, b) : 0;
    });

  
    const onSelectRow = (record, selected) => {
        tableHDLR.selectRow(setSelectedRowKeys, setSelectedRow, selectedRow, selectedRowKeys, record, selected)
      };
 
      const rowSelection = {
        selectedRowKeys,
        onSelect: onSelectRow
      };

    return (
        <>
        <DrawerEditZone isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} record={selectedRecord} />
          <TableList.DataList
            dataSource={dataSource}
            filteredData={filtered}
            setFilteredData={setFilteredData}
            optionDay={SELECT_OPTION_DAYS} 
            defaultValue="CREATED AT"
            dataDate={TABLE_ZONE_INDEX.createdAt}
            columnData={columns}
            expandedRowRender={expandedRowRender}
            loading={loading}
            rowSelection={rowSelection}
            onChange={handleTableChange}
          />
        </>
    );
}

export default TableZone
