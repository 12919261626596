// React Third Party Lib
import { Popconfirm, Typography, Tag, Upload } from "antd";

// Constant
import { TEMPLATE } from "../../../../../../../constants";

// Components
import { ButtonLink } from "../../../../../../../components";

// Service
import { TIME } from "../../../../../../../services/util";

const { ORDER_DATA_TITLE, DATA_FIELD, DATA_FIELD_VALUES, STATE_BYID } =
  TEMPLATE.ORDER;

const { dayJS, parseDate } = TIME;

export const BulkColumns = (
  { hubs, points, optionHubPoint, optionsDestDeliveryType, optionsOrigDeliveryType, optionHubPointDest,},
  { handleColDelete, handleEditModal, handleModalUpload }
) => {

return [
  {
    title: ORDER_DATA_TITLE[DATA_FIELD.memid],
    dataIndex: DATA_FIELD.memid,
    key: DATA_FIELD.memid,
    inputType: "input",
  },
  {
    title: ORDER_DATA_TITLE.ORIGIN_INFORMATION,
    children: [
      {
        title: ORDER_DATA_TITLE[DATA_FIELD.origDeliveryType],
        dataIndex: DATA_FIELD.origDeliveryType,
        key: DATA_FIELD.origDeliveryType,
        inputType: "select",
        options: optionsOrigDeliveryType,
        render: (record) =>
        (record &&
          Array.isArray(record) &&
          record.map((r) => (
            <Tag>{DATA_FIELD_VALUES[DATA_FIELD.origDeliveryType][r]}</Tag>
          ))) || <Tag>{DATA_FIELD_VALUES[DATA_FIELD.origDeliveryType][record]}</Tag>,
      },
      {
        title: ORDER_DATA_TITLE[DATA_FIELD.origid],
        dataIndex: DATA_FIELD.origid,
        key: DATA_FIELD.origid,
        inputType: "select",
        options: optionHubPoint,
        render: (hubid) => <>{hubs[hubid] && hubs[hubid].code ? 
          hubs[hubid] && hubs[hubid].code : 
          points[hubid] && points[hubid].code}</>,
      },
      {
        title: ORDER_DATA_TITLE[DATA_FIELD.etd],
        dataIndex: DATA_FIELD.etd,
        key: DATA_FIELD.etd,
        inputType: "date",
        render: (record) => <span>{parseDate(dayJS(record).valueOf())}</span>,
      },
  ]},
  {
      title: ORDER_DATA_TITLE.DESTINATION_INFORMATION,
      children: [
        {
          title: ORDER_DATA_TITLE[DATA_FIELD.destDeliveryType],
          dataIndex: DATA_FIELD.destDeliveryType,
          key: DATA_FIELD.destDeliveryType,
          inputType: "select",
          options: optionsDestDeliveryType,
          render: (record) =>
          (record &&
            Array.isArray(record) &&
            record.map((r) => (
              <Tag>{DATA_FIELD_VALUES[DATA_FIELD.destDeliveryType][r]}</Tag>
            ))) || <Tag>{DATA_FIELD_VALUES[DATA_FIELD.destDeliveryType][record]}</Tag>,
        },
        {
          title: ORDER_DATA_TITLE[DATA_FIELD.dstid],
          dataIndex: DATA_FIELD.dstid,
          key: DATA_FIELD.dstid,
          inputType: "select",
          options: optionHubPointDest,
          render: (hubid) => <>{hubs[hubid] ? hubs[hubid] && hubs[hubid].code : points[hubid] && points[hubid].code}</>,
        },
        {
          title: ORDER_DATA_TITLE[DATA_FIELD.eta],
          dataIndex: DATA_FIELD.eta,
          key: DATA_FIELD.eta,
          inputType: "date",
          render: (record) => <span>{parseDate(dayJS(record).valueOf())}</span>,
        },
  ]},
  {
    title: ORDER_DATA_TITLE[DATA_FIELD.parcel_remark],
    dataIndex: "remark",
    key: "remark",
    inputType: "input"
  },
  {
    title: ORDER_DATA_TITLE[DATA_FIELD.price],
    dataIndex: DATA_FIELD.price,
    key: DATA_FIELD.price,
    inputType: "input",
  },
  {
    title: ORDER_DATA_TITLE[DATA_FIELD.attachment],
    key: DATA_FIELD.attachment,
    render: (record) => {
      const { parcel = {} } = record

      const title = parcel.img && parcel.img.length > 0 ? "View" : "Upload"

      return (
        <span>
          <Typography.Link onClick={() => handleModalUpload(record)}>
            {title}
          </Typography.Link>
        </span>
      );
    },
  },
  {
    title: "Actions",
    key: "Actions",
    fixed: "right",
    render: (record) => {
      return (
        <span>
          <Typography.Link onClick={() => handleEditModal(record)}>
            Edit
          </Typography.Link>
          <Popconfirm
            title="Sure to remove?"
            onConfirm={() => handleColDelete(record.key)}
          >
            <ButtonLink>Remove</ButtonLink>
          </Popconfirm>
        </span>
      );
    },
  },
]}

export const ParcelColumns = ({ handleEditParcelModal, handleAddParcelModal, handleParcelDelete }) => [

  {
    title: "Parcel Quantity",
    dataIndex: "qty",
    key: "qty",
    inputType: "number",
    render: (dimension) => <>{dimension || 0 }</>,
  },
  {
    title: ORDER_DATA_TITLE[DATA_FIELD.parcel_length],
    dataIndex: "length",
    key: "length",
    inputType: "number",
    render: (dimension) => <>{dimension || 0 }</>,
  },
  {
    title: ORDER_DATA_TITLE[DATA_FIELD.parcel_width],
    dataIndex: "width",
    key: "width",
    inputType: "number",
    render: (dimension) => <>{dimension || 0 }</>,
  },
  {
    title: ORDER_DATA_TITLE[DATA_FIELD.parcel_height],
    dataIndex: "height",
    key: "height",
    inputType: "number",
    render: (dimension) => <>{dimension || 0 }</>,
  },
  {
    title: ORDER_DATA_TITLE[DATA_FIELD.parcel_weight],
    dataIndex: "weight",
    key: "weight",
    inputType: "number",
    render: (dimension) => <>{dimension || 0 }</>,
  },
  {
    title: "Actions",
    key: "Actions",
    width: 150,
    render: (record) => {
      return (
        <>
        <span>
          <Typography.Link onClick={() => handleEditParcelModal(record)} style={{ marginRight: "10px"}}>
            Edit
          </Typography.Link>
          <Typography.Link onClick={() => handleParcelDelete(record)}>
           Remove
          </Typography.Link>
        </span>
      </>
      );
    },
  },
    {
      title: '',
      key: "add",
      render: (record) => {
        return (
          <ButtonLink style={{marginTop: "35px", position:'relative', top:'-110px'}} onClick={() => handleAddParcelModal(record)}>Add</ButtonLink>
        );
      },
    },
];

export const SRColumns = ({ optionsState, handleEditSRModal }) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    inputType: "input",
  },
  {
    title: "Contact",
    dataIndex: "contact",
    key: "contact",
    inputType: "input",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    inputType: "input",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    inputType: "input",
  },
  {
    title: "Postcode",
    dataIndex: "postcode",
    key: "postcode",
    inputType: "input",
  },
  {
    title: "City",
    dataIndex: "city",
    key: "city",
    inputType: "input",
  },
  {
    title: "State",
    dataIndex: "state",
    key: "state",
    inputType: "select",
    options: optionsState,
    render: (code) => <>{STATE_BYID[code] && STATE_BYID[code].name}</>,
  },
  {
    title: "Remark",
    dataIndex: "remark",
    key: "remark",
    inputType: "input",
  },
  {
    title: "Actions",
    key: "Actions",
    render: (record) => {
      return (
        <span>
          <Typography.Link onClick={() => handleEditSRModal(record)}>
            Edit
          </Typography.Link>
        </span>
      );
    },
  },
];
