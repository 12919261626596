import * as API from "../../api";
import * as ActionTypes from "../action-types";

import { message } from "antd";

// Constants and Config

// Services

// Redux Action
import { get_order_by_ordid_success } from "./order";

// Neccessary Actions

// Get Shipmennt Order Thru SOID

export const get_shipment_order_thru_soid_request =
  (soid, cb = () => {}) =>
  (dispatch) => {
    API.getShipmentOrderBySOID({
      soid,
    }).then((info) => {
      const { status, shipmentOrder, msg } = info;

      if (status === 200) {
        dispatch(get_shipment_order_thru_soid_success(shipmentOrder));

        const { url } = shipmentOrder;

        cb({
          status,
          url,
        });
      } else {
        message.warning(msg);
        cb({
          status,
        });
      }
    });
  };

export const get_shipment_order_thru_ordid_request =
  (ordid, cb = () => {}) =>
  (dispatch) => {
    API.getShipmentOrderByORDID({
      ordid,
    }).then((info) => {
      const { status, shipmentOrder, msg } = info;

      if (status === 200) {
        const { ordid, url, updatedAt, soid } = shipmentOrder;

        dispatch(get_shipment_order_thru_soid_success(shipmentOrder));

        dispatch(
          get_order_by_ordid_success({ ordid, soid, printedAt: updatedAt })
        );

        cb({
          status,
          url,
        });
      } else {
        message.warning(msg);
        cb({
          status,
        });
      }
    });
  };

const get_shipment_order_thru_soid_success = (shipmentOrder) => {
  return {
    type: ActionTypes.SHIPMENT_ORDER_GET_TRU_SOID,
    shipmentOrder,
  };
};
