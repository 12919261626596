export const TABLE_BILLING_INDEX = {
  Billing_ID: "billid",
  Billing_Type: "type",
  Billing_Status: "status",
  Billing_Date: "createdAt",
  customer_id: "memid",
  name: "name",
  ordid: 'ordid',
  Price: "amount",
  Transaction_Id: "txnid",
  attachment: "url",
  jnid:  "refid",
  Order_ID: "ordid",
  Remarks: "remark",
  action:"action"
};

export const TABLE_BILLING_TITLE = {
  key: "key",
  Billing_ID: "Billing ID",
  Billing_Type: "Billing Type",
  Billing_Status: "Billing Status",
  Billing_Date: "Billing Date",
  customer_id: "Customer ID / Name",
  name: "Name",
  ordid: "Order ID",
  Price: "Price",
  Transaction_Id: "Transaction ID",
  attachment: "Attachment",
  Order_ID: "Order ID",
  Remarks: "Remarks",
  action: "Action"
};

export const OPTION_DAYS = {
  LAST_30_DAYS: "Last 30 Days",
  LAST_60_DAYS: "Last 60 Days",
  LAST_90_DAYS: "Last 90 Days",
};

export const OPTION_DAYS_VALUE = {
  LAST_30_DAYS: 30,
  LAST_60_DAYS: 60,
  LAST_90_DAYS: 90,
};

export const BILLING_STATUS = {
  Rejected: 404,
  Success: 200,
  Pending: 100,
  Cancelled: 300,
};

export const OPTION_STATUS_TITLE = {
  [BILLING_STATUS.Rejected]: { name: "Rejected", colorTag: "red",  status:404},
  [BILLING_STATUS.Success]: { name: "Successful", colorTag: "green", status:200 },
  [BILLING_STATUS.Pending]: { name: "Pending", colorTag: "blue", status:100 },
  [BILLING_STATUS.Cancelled]: { name: "Cancelled", colorTag: "red", status:300 },
};

export const BILLING_TYPE = {
  Topup: "BILL001",
  Refund: "BILL002",
  Invoice: "BILL003",
};

export const OPTION_TYPE_TITLE = {
  [BILLING_TYPE.Topup]: "Top Up",
  [BILLING_TYPE.Refund]: "Refund",
  [BILLING_TYPE.Invoice]: "Invoice",
};

export const BILLING_STATUS_ALL = "ALL";
export const BILLING_TYPE_ALL = "ALL";

export const SELECT_OPTION_DAYS = Object.keys(OPTION_DAYS).map((option) => ({
  label: OPTION_DAYS[option],
  value: OPTION_DAYS_VALUE[option],
}));
