import { useState, useEffect } from 'react';
import {
    Card,
    Form,
    Radio,
    Input, 
    Row,
    Col,
    TimePicker,
    Switch
} from 'antd'

// Constant
import { TEMPLATE } from '../../../../../../constants'

// Services 
import { TIME } from "../../../../../../services/util";

// Style
import classes from "../../../../common.module.css"

const {
    HUB_TYPE_CODE,
    HUB_TYPE_TITLE
} = TEMPLATE.HUB

const { RangePicker } = TimePicker

const HubInformation = (props) => {

    const {form} = props


      /* 
          state for Operation Hours
      */
      const [schedule, setSchedule] = useState([
          { day: 'Monday', active: false },
          { day: 'Tuesday', active: false },
          { day: 'Wednesday', active: false },
          { day: 'Thursday', active: false },
          { day: 'Friday', active: false },
          { day: 'Saturday', active: false },
          { day: 'Sunday', active: false },
      ]);

      useEffect(() => {
          const biz_hrs = form.getFieldsValue().biz_hrs

          const updatedSchedule = schedule.map(schd => {
              const bzHrsDay = biz_hrs.day[schd.day]

              return bzHrsDay ? { ...schd, active: true } : schd;
          });

          setSchedule(updatedSchedule);
          
      }, [])


        /*
            handleChange for Operation Hours
        */
        const handleChange = (checked, day, i) => {

          const updatedSchedule = [...schedule];
          updatedSchedule[i] = { ...schedule[i], active: checked };

          setSchedule(updatedSchedule);

          if(checked) {
              const epochTime = TIME.getEpochTimeForDay(day);
              form.setFieldValue(['biz_hrs', 'day', day], epochTime);
          }
      }


    return (
        <Card className={classes['card-container']}>
            <h1 className={classes['card-title']}>HUB INFORMATION</h1>

            <Form.Item label="Hub Type" 
                name={'type'}
                rules={[{required: true}]}
                hasFeedback
            >
                <Radio.Group>
                    {
                        Object.values(HUB_TYPE_CODE).map(value => <Radio key={value} value={value}>{HUB_TYPE_TITLE[value]}</Radio>)
                    }
                </Radio.Group>
            </Form.Item>

            <Row gutter={15}>
                <Col span={10}>
                    <Form.Item label="Hub Name" name='name'
                        rules={[{required: true}]}
                    >
                        <Input placeholder='Eg. Taman Sari Putri' />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item label="Hub Code" name="code"
                         rules={[{required: true}]}
                    >
                        <Input placeholder='Eg. SPT'/>
                    </Form.Item>
                </Col>
            </Row>

            <p style={{ paddingBottom: '20px' }} >Operation Hours</p>
            { schedule.map((item, i) => {

                const { day, active } = item

                return (
                    <Row key={i}>
                      <Col span={12} style={{ display: 'grid', gridTemplateColumns: '40% 30% 30%' }}>
                          <label htmlFor='day' style={{ paddingTop: '.7em' }}>{day}</label>

                          <Form.Item name={['biz_hrs', 'day', day]} valuePropName='checked'>
                              <Switch onChange={e => handleChange(e, day, i)} />
                          </Form.Item>

                          <label htmlFor='day' style={{ paddingTop: '.7em' }}>{active ? 'Open' : 'Closed'}</label>
                      </Col>
                      <Col span={8} >
                          <Form.Item name={['biz_hrs', 'hour', day]}>
                              <RangePicker format='HH:mm' disabled={!active}/>
                          </Form.Item>
                      </Col>
                  </Row>
                )}
            )}
        </Card>
    )
}

export default HubInformation
