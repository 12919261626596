import React from "react";
import { Card, Row, Col, Table, message } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { ButtonLink } from "../../../../components";

// Constant
import { ROOT_CONSTANT, TEMPLATE } from "../../../../constants";

// Service
import { TIME, REDUX as REDUX_UTIL } from "../../../../services/util";

// Styles
import cs from "./index.module.css";
import styles from "../../../../pages/common.module.css";

const { icon_pin, icon_arrow } = ROOT_CONSTANT.IMG.ICON;

const { HUBS, HUBS_HUBID, DATA_FIELD_VALUES, GET_OPERATION_HOURS_ARR , PARCELS_TOTAL_CALCULATION} = TEMPLATE.ORDER;

const { parseDate, currentTimeMilliSecond } = TIME;

const { reduxStoreWrapper } = REDUX_UTIL;

const OrderSummary = (props) => {
    const { ordid = "" } = props;
    const navigate = useNavigate();

    const orders = useSelector(reduxStoreWrapper(`order.byID.${ordid}`));
    const hubs = useSelector(reduxStoreWrapper("hub.byID"))
    const point = useSelector(reduxStoreWrapper("point.byID"))
    const uroleid = useSelector(reduxStoreWrapper("user.uroleid"))

    function handleButtonBack() {
        navigate(-1);
    }

  const {
    ordid: o_ordid,
    deliveryType,
    origid = "hub#btw",
    dstid = "hub#amj",
    origpid = '',
    dstpid = '',
    etd = currentTimeMilliSecond(),
    eta = currentTimeMilliSecond(),
    add_on = [],
    price,
    priceSort,
    version,
    priceInfo: { sort: s_priceInfo, unsort: us_priceInfo} = {},
    parcel: {
        desc: p_desc,
        sort: s_parcel = [],
        sort: s_parcel_old = {},
        unsort: us_parcel = [],
      } = {},
    sender = {},
    receiver = {},
    orig_detail,
    dst_detail,
    sorted,
    remark,
  } = orders;

  const HUBS_DATA = deliveryType === 'p2p' ? point : hubs

  const orderDataUnsort = us_parcel?.map((p, i) => ({ key: i, ...p }))
  const orderDataSort = s_parcel || [].map((p, i) => ({ key: i, ...p }));
  const sortOld = version === undefined && Object.values(s_parcel_old).length > 0 ? [Object.assign({}, s_parcel_old)] : [];
  const p_sort = sortOld.length > 0 ? sortOld : orderDataSort
  const orderData2 = p_sort.length > 0 ? p_sort : orderDataUnsort

    const HUB_CODE = (hubid) => hubid && HUBS_DATA[hubid] && HUBS_DATA[hubid].code;

    const HUB_ADDRESS_SELECTOR = (hubid, type) => {
      if (hubid === HUBS.OTHERS.hubid) {
        if (type === "origin") {
          return orig_detail || "-";
        } else {
          return dst_detail || "-";
        }
      }
      return (HUBS_DATA[hubid] && HUBS_DATA[hubid].address) || "-";
    };
  
    const ADD_ON_SERVICE_DETECTOR = (add_on) => {
      let str = "";
  
      add_on &&
        add_on.length &&
        add_on.map((ao) => {
          str =
            (ao &&
              DATA_FIELD_VALUES.add_on[ao] &&
              str + `${DATA_FIELD_VALUES.add_on[ao]} ,`) ||
            str;
          return 1;
        });
  
      return (str && str.slice(0, -2)) || `No Additional Services`;
    };
  
    const onHubCopy = (type, hubid) => {
      const str = `
          ${type.toUpperCase()} - ${HUB_CODE(hubid)}
  
          ${(HUBS_DATA[hubid] && HUBS_DATA[hubid].name) || "-"}
  
          ${(HUBS_DATA[hubid] && HUBS_DATA[hubid].address) || "-"}
  
          ${(HUBS_DATA[hubid] && HUBS_DATA[hubid].url) || "-"}
  
          ${
            (HUBS_DATA[hubid] &&
              HUBS_DATA[hubid].pic &&
              HUBS_DATA[hubid].pic.map((p) => `${p.name} ${p.contact}`)) ||
            "-"
          }
  
          ${
          Array.isArray(HUBS_DATA[hubid].biz_hrs) 
          &&
          GET_OPERATION_HOURS_ARR(HUBS_DATA[hubid].biz_hrs).map(hr => `${hr.day}   ${hr.hour ? TIME.getHoursNameFromEpoch(hr.hour) : 'Closed' } \n`).join('\t')
          ||
          `${HUBS_DATA[hubid].biz_hrs.day} ${HUBS_DATA[hubid].biz_hrs.time} `
  
          }
          `;
  
  
      navigator.clipboard.writeText(str);
      message.info("Copy");
    };
  
    const orderColumns = [
      {
        title: <p className={cs.orderData}>Order ID</p>,
        key: "ordid",
        onCell: (rowData, rowIndex) => ({
          rowSpan: rowIndex === 0 ? orderData2.length : 0,
        }),
        render: (ordid) =>
          (ordid && <p className={cs.orderData}>{o_ordid || '-'}</p>) || <p>-</p>,
      },
      {
        title: <p className={cs.orderData}>Delivery Type</p>,
        key: "deliveryType",
        onCell: (rowData, rowIndex) => ({
          rowSpan: rowIndex === 0 ? orderData2.length : 0,
        }),
        render: () =>
          <p className={cs.orderData}>{DATA_FIELD_VALUES.deliveryType[deliveryType]}</p>
      },
      {
          title: <p className={cs.orderData}>Parcel Remarks</p>,
          key: "parcel_desc",
          onCell: (rowData, rowIndex) => ({
              rowSpan: rowIndex === 0 ? orderData2.length : 0,
          }),
          render: (text) => (
              <p className={cs.orderData}>{remark || '-'}</p>
          ),
      },
      {
        title: <p className={cs.orderData}>Quantity</p>,
        key: "qty",
        render: (rowData) => {
  
          const { qty } = rowData
  
          return (
              <p className={cs.orderData}>{qty || 1} Pieces</p>
          )
        }
      },
      {
        title: <p className={cs.orderData}>Parcel Info</p>,
        key: "parcel_info",
        render: (rowData) => {
  
          const { length, width, height, weight, qty } = rowData
  
          return (
              <p className={cs.orderData}>
                  {(((parseFloat(width) * parseFloat(length) * parseFloat(height)) / 1000000) || 0)}m³/ {(((parseFloat(qty || 1)) * parseFloat(weight)) || 0)}kg
              </p>
          )
        }
      },
    ];
  
    const SRCardInfo = ({ data = {}, title }) => (
      <>
        <Card className={cs.SRCard}>
          <Row>
            <Col span={4}>
              <img src={icon_pin} className={cs.icon} alt={`pin#${title}`} />
            </Col>
            <Col span={20}>
              <div>
                <div className={cs["sender-receiver-content"]}>
                  <h2 className={cs.SRCardTitle}>
                    <b>{title.toUpperCase()}</b>
                  </h2>
                </div>
                <p className={cs["sender-receiver-content"]}>{data.name}</p>
                <p className={cs["sender-receiver-content-1"]}>
                  {data.contactPrefix}
                  {data.contact}
                </p>
                <p className={cs["sender-receiver-content"]}>{data.email}</p>
                <p className={cs["sender-receiver-content"]}>
                  {data.address}, {data.city}, {data.postcode}, {data.state}
                </p>
                <p className={cs["sender-receiver-content"]}>{title} Remarks</p>
                <p className={cs["sender-receiver-content"]}>{data.remark}</p>
              </div>
            </Col>
          </Row>
        </Card>
      </>
    );

      const OPERATION_HOURS_SELECTOR = (HUBS_DATA) => { 

          if(typeof HUBS_DATA.biz_hrs.day && HUBS_DATA.biz_hrs.day === 'string') {
              return (
                  <p className={cs["sender-receiver-content"]}>
                      {`${HUBS_DATA.biz_hrs.day} ${HUBS_DATA.biz_hrs.time}`}
                  </p>
              )
          } else {
              return (
                  <>
                      {
                          GET_OPERATION_HOURS_ARR(hubs.biz_hrs).map((bz, i) => (
                              <div key={i} style={{ display: 'grid', gridTemplateColumns: '40% 60%', width: '10em'}}>
                                  <p>{bz.day}</p>
                                  <p> {bz.hour ? TIME.getHoursNameFromEpoch(bz.hour) : 'Closed'}</p>
                              </div>
                          ))
                          ||
                          <></>
                      }
                  </>
              )
          }
  
      }
  
      const HUB_CARD_LABEL = (hubid, type, deliveryType) => {
          let label = ''
  
          if(uroleid === 'POP') {
              if(type === 'origin') {
                  label = `Self Lodge At Point ${point[hubid]?.name}`
              } else {
                  label =  `Self Collect At Point ${point[hubid]?.name}`
              }
          } else {
              if(type === 'destination') {
                  if(deliveryType === 'h2h') {
                      label = `Pick Up At Hub ${hubs[hubid].name}`
                  } else {
                      label = `Doorstep Drop Off`
                  }
              } else {
                  if(add_on) {
                      label = `Doorstep Pick Up`
                  } else {
                      label = `Drop Off At Hub ${hubs[hubid].name}`
                  }
              }
          }
  
          return label
      }
  
    const HubCardInfo = ({ type, hubid, deliveryType }) => (
      <Card className={cs.hubCard}>
        <Row className={cs.hubTitle}>
          <Col span={20}>
              <p style={{fontSize: '12px', marginBottom: '10px'}}>{type?.toUpperCase()}</p>
            <h2>
              {
                  HUB_CARD_LABEL(hubid, type, deliveryType)
              }
            </h2>
          </Col>
          <Col span={4}>
            <button className={cs.copyBTN} onClick={() => onHubCopy(type, hubid)}>
              Copy
            </button>
          </Col>
        </Row>
        <div>
          <p className={cs["sender-receiver-content"]}>
            {HUB_CODE(hubid) || "-"}
          </p>
          <p className={cs["sender-receiver-content"]}>
            {(HUBS_DATA[hubid] && HUBS_DATA[hubid].name) || "-"}
          </p>
          <p className={cs["sender-receiver-content"]}>
            {HUB_ADDRESS_SELECTOR(hubid, type)}
          </p>
          <p className={cs["sender-receiver-content"]}>
            {(HUBS_DATA[hubid] && HUBS_DATA[hubid].url) || "-"}
          </p>
          <div className={cs["sender-receiver-content"]}>
            {(HUBS_DATA[hubid] &&
              HUBS_DATA[hubid].pic &&
              HUBS_DATA[hubid].pic.map((p, i) => (
                <span key={`${p.name}#${i}`}>
                  <p>
                    {p.name} {p.contact}
                  </p>
                </span>
              ))) ||
              "-"}
          </div>
          <div className={cs["sender-receiver-content"]}>
              {OPERATION_HOURS_SELECTOR(HUBS_DATA[hubid])}
          </div>
        </div>
      </Card>
    );
  
      // Parcel Calculation
      const totalUnsort = us_parcel && PARCELS_TOTAL_CALCULATION(us_parcel) || {}
      const totalSort = p_sort && PARCELS_TOTAL_CALCULATION(p_sort) || {}
      const totalParcel = s_parcel && p_sort.length > 0 ? totalSort : totalUnsort

      const addOnObj = (s_priceInfo && s_priceInfo.length > 0) ?
        s_priceInfo.reduce((acc, currentObj) => {
            return { ...acc, ...currentObj };
        }, {}) :
        (us_priceInfo && us_priceInfo.length > 0) ?
        us_priceInfo.reduce((acc, currentObj) => {
            return { ...acc, ...currentObj };
        }, {}) :
        {};

      const totalPrice = p_sort.length > 0 ? priceSort : price
  
      const { total_pieces, total_volumes, total_weights } = totalParcel
  
      return (
        <>
            <div className={styles.container}>
                <div className={styles.headerPage}>
                    <ButtonLink
                        onClick={handleButtonBack}
                        style={{ display: "inline-block" }}
                    >
                        <img
                            src={icon_arrow}
                            className={styles.filterIcon}
                            alt=""
                            style={{ marginBottom: "-2px" }}
                        />
                    </ButtonLink>
                    <h4
                        style={{
                            display: "inline-block",
                            fontSize: 18,
                            marginLeft: 7,
                        }}
                    >
                        {ordid}
                    </h4>
                </div>
                <div style={{ marginTop: 30 }}>
                    <Row>
                        <Col span={16}>
                            <Card >
                                <Row className={cs.orderCardInnerRow} justify={'space-between'}>
                                    <Col span={12}>
                                        <h2 style={{ fontSize: '20px' }}>
                                            {HUB_CODE(origpid ? origpid : origid)}-{HUB_CODE(dstpid ? dstpid : dstid)}
                                        </h2>
                                        <p>
                                            ETD {parseDate(etd)} | ETA {parseDate(eta)}
                                        </p>
                                    </Col>
                                    <Col span={12} style={{ textAlign: 'right' }}>
                                        <h2 style={{ fontSize: '16px' }}>RM {(totalPrice + (addOnObj.addon || 0)) || '-'}</h2>
                                        <p>
                                            {`${total_pieces || 0} Pieces/ ${total_volumes || 0} m³/ ${total_weights || 0}kg`}
                                        </p>
                                    </Col>

                                </Row>
                                <Row className={cs.orderCardInnerRow}>
                                    <Table
                                        className={cs.orderTable}
                                        columns={orderColumns}
                                        dataSource={orderData2}
                                        size="small"
                                        scroll={{ x: "max-content" }}
                                        pagination={false}
                                    />
                                </Row>
                                <Row className={cs.orderCardInnerRow}>
                                    <Col span={12}>
                                        <SRCardInfo
                                            data={sender}
                                            title={"Sender"}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <SRCardInfo
                                            data={receiver}
                                            title={"Receiver"}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col className={cs.hubContainer} span={8}>
                        {
                        <>
                            <Row>
                                <HubCardInfo type={"origin"} hubid={origpid ? origpid : origid} deliveryType={deliveryType} />
                            </Row>
                            <Row>
                                <HubCardInfo type={"destination"} hubid={dstpid ? dstpid : dstid} deliveryType={deliveryType} />
                            </Row>
                        </>
                        }
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
  };

export default OrderSummary;
