// Third-party Library 
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react"
import { Select, Card, Row, Col, Avatar, Tooltip, Skeleton } from "antd";
import { GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';

// Constants 
import { APP_CONFIG } from '../../../../../../config';
import { ROOT_CONSTANT, TEMPLATE } from '../../../../../../constants';

// Services Library
import { REDUX as REDUX_UTIL, TIME } from "../../../../../../services/util";
import * as REDUX_ACTION from "../../../../../../services/redux/actions";

// Styles 
import classes from '../../../../common.module.css'

const {
    icon_map_color
} = ROOT_CONSTANT.IMG.ICON.MODAL

const {
    reduxStoreWrapper
} = REDUX_UTIL

const {
    getDayNamefromEpoch,
    getHoursNameFromEpoch
} = TIME

const {
    GET_OPERATION_HOURS_LIST
} = TEMPLATE.POINT

const MapView = () => {
    const dispatch = useDispatch()

    const [point, setHub] = useState('point#abc')

    const points = useSelector(reduxStoreWrapper('point.byID'))
    const pointsIDs = useSelector(reduxStoreWrapper('point.allIDs'))
    const pointOperatorByID = useSelector(reduxStoreWrapper(`hubOperator.byHubid.${point}`))

    
    const [infoWindowOpen, setInfoWindowOpen] = useState(false);
    
    
    const stateName = points[point]?.address.split(', ').pop()

    // OptionGroup -- set options value for its child 
    const pointMap = {}

    pointsIDs.forEach(pointid => {
        const pointState = points[pointid].state

        if(pointState !== undefined){
            if (!pointMap[pointState]) {
                pointMap[pointState] = []
            }
            pointMap[pointState].push({
                label: points[pointid].name,
                value: pointid
            })
        }
    })

    // OptionGroup -- Main OptionGroup value
    const pointList = Object.keys(pointMap).map(pointState => ({
        label: pointState,
        options: pointMap[pointState]
    }))


    const coordinates = {
        lat: parseFloat(points[point]?.coordinate.lat),
        lng: parseFloat(points[point]?.coordinate.lon)
    };

    const DetailsInfoWindow = () => {

        !pointOperatorByID && dispatch(REDUX_ACTION.v1_hubOperator.get_operator_thru_hubid_request(point));

        return (
            <div className={classes["infoWindow-container"]}>
                <Row style={{fontSize: '20px', marginBottom: '20px'}} justify='space-between'>
                    <Col>
                        <h3>{points[point].code} ({points[point].name})</h3>
                    </Col>
                    <Col>
                        <h3>{points[point].state ? points[point].state.toUpperCase() : stateName.toUpperCase()}</h3>
                    </Col>
                </Row>
                <Card style={{fontSize: '13px'}}>
                    <p style={{ lineHeight: '18px', color: '#868686'}}>{points[point].address}</p>
                    <span style={{display: 'flex', margin: '15px 0', gap: '10px'}}>
                        <img style={{height: '15px'}} src={icon_map_color} alt="" />
                        <a href={points[point].url} target="_blank">{points[point].url}</a>
                    </span>
                </Card>
                <div className={classes["infoWindow-detail-container"]}>
                    <h6>HUB OPERATOR</h6>

                    <Avatar.Group
                        maxCount={2}
                        maxStyle={{
                            color: '#f56a00',
                            backgroundColor: '#fde3cf',
                        }}
                    >
                        {pointOperatorByID && pointOperatorByID.map((pointOp, i) => (
                            <Tooltip title={pointOp.name} key={i}>
                                <Avatar
                                    style={{
                                        backgroundColor: (i % 2 && '#f56a00') || (i % 3 && '#87d068') || ''
                                    }}
                                >
                                    {pointOp.name.charAt(0)}
                                </Avatar>
                            </Tooltip>
                        ))}
                    </Avatar.Group>
                </div>
                <div className={classes["infoWindow-detail-container"]}>
                    <h6>PIC INFORMATION</h6>
                    <ul style={{ listStyle: 'square', paddingLeft: '18px'}}>
                        {points[point].pic.map((pic, i) => (
                            <li key={i} style={{margin: '5px'}}>{pic.name} {pic.contact}</li>
                        ))}
                    </ul>
                </div>
                <div className={classes["infoWindow-detail-container"]}>
                    <h6>OPERATING HOURS</h6>
                    {GET_OPERATION_HOURS_LIST(points[point].biz_hrs)}
                </div>  
            </div>
        )
    }

    const toggleInfoWindow = () => {
        setInfoWindowOpen(!infoWindowOpen)
    }

    return (
        <div className={classes["container-map"]}>
            <Select
                size="large"
                style={{
                    width: 280,
                    marginBottom: "15px",
                    top: '.5em',
                    left: '10px'
                }}
                defaultValue={point}
                onChange={(val) => setHub(val)}
                options={pointList}
            />
              {
                APP_CONFIG.API_KEY.GOOGLE_MAP
                &&
                <LoadScript googleMapsApiKey={APP_CONFIG.API_KEY.GOOGLE_MAP}>
                    <GoogleMap
                        mapContainerStyle={{
                            width: '100%',
                            height: '80vh'
                        }}
                        center={coordinates}
                        zoom={8}
                    >
                        <Marker
                            position={coordinates}
                            onClick={toggleInfoWindow}
                        >
                            {infoWindowOpen && (
                            <InfoWindow onCloseClick={toggleInfoWindow}>
                               {points && <DetailsInfoWindow />}
                            </InfoWindow>
                            )}
                        </Marker>
                    </GoogleMap>
                </LoadScript>
              }
        </div>
    )
}

export default MapView
