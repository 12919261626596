// Constant
import { TEMPLATE } from "../../../../../../constants";

// Componets
import { ButtonLink, ButtonModal } from "../../../../../../components";

import * as REDUX_ACTION from "../../../../../../services/redux/actions";

const { MODAL_ACTION } = TEMPLATE.MODAL;

export const ActionItems = (record, dispatch, am, setIsOpenRemark, setIsOpenEdit, setIsOpenChangePass, setData) => {

	return [
	{
		key: 0,
		label: (
			<ButtonModal
				model="link"
				extra={record}
				setmodal={MODAL_ACTION.verify_customer}
                onClick={() => {

                    dispatch(REDUX_ACTION.v1_customerList.verify_customer_request(record))
                }}
			>
				Verify
			</ButtonModal>
		),
	},
	{
		key: 1,
		label: (
		  <ButtonLink
		  >
			Edit Customers
		  </ButtonLink>
		),
		onClick: () => {
		  setIsOpenEdit(true);
		  setData(record);
		},
	},
	{
		key: 2,
		label: (
			<ButtonLink  onClick={() => {
		setIsOpenRemark(true)
        setData(record)
      }}
            
                >
				Edit Remarks
			</ButtonLink>
		),
	},
	{
		key: 3,
		label: (
			<ButtonModal
				model="link"
				extra={{
          ...record,
          am,
        }}
				setmodal={MODAL_ACTION.link_account_manager_id}
			>
				Link Account Manager ID
			</ButtonModal>
		),
	},
  {
		key: 4,
		label: (
		  <ButtonLink>
			Change password
		  </ButtonLink>
		),
		onClick: () => {
		  setIsOpenChangePass(true);
		  setData(record);
		},
	},
	{
		key: 5,
		label: (
      <>
      {record.status === 0 || !record.status ? 
			<ButtonModal
				model="link"
				extra={record}
				setmodal={MODAL_ACTION.reactivate_user}
				onClick={() => {
						dispatch(
							REDUX_ACTION.v1_customerList.update_user_list_status({...record, status: 1
							})
						);
				}}
			>
				Activate
			</ButtonModal>
      :
      <ButtonModal
        model="link"
        extra={record}
        setmodal={MODAL_ACTION.suspend_user}
                onClick={() => {
                    dispatch(
                        REDUX_ACTION.v1_customerList.update_user_list_status({...record, status: 0
						})
                    );
        }}
      >
        Suspend
      </ButtonModal>
      }
      </>
		),
	},
]
}
