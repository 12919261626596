import { Drawer, Form, Input, Select, message } from "antd";
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { NumericInput, BtnSubmit } from "../../../../../../../../components";

import { REDUX as REDUX_UTIL, TIME } from "../../../../../../../../services/util";

import * as REDUX_ACTION from "../../../../../../../../services/redux/actions";

import classStyles from "./index.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;

const DrawerAssignedOperator = (props) => {
  const { isOpen, setIsOpen, data: hubid} = props
  const [messageApi, contextHolder] = message.useMessage();

  const [selectedHub, setSelectedHub] = useState(null);

  const hubOperatorBYID = useSelector(reduxStoreWrapper(`hubOperator.byHubid.${hubid}`));
  const hubOperators = useSelector(reduxStoreWrapper("hubOperator.byRole." + 1))

  const dispatch = useDispatch();

  const [form] = Form.useForm();
  
  let operators = hubOperatorBYID && hubOperatorBYID
  .map((d) => ({
      label: `${d.memid} / ${d.name}`,
      value: d.uid,
    }))

  useEffect(() => {
    operators && form.setFieldsValue({ operators: operators.map(o => o.value)});
  }, [operators]);

  const handleHub = (values) => {
    const selectedHubArray = values && values.map((uid) => ({
      hubid, 
      uid: uid,
    }));
  
    setSelectedHub(selectedHubArray);
  };

  useEffect(() => {
    dispatch(REDUX_ACTION.v1_hubOperator.get_all_hub_operators_request(1));


  }, [dispatch]);
  
  

  useEffect(() => {
    form.setFieldsValue({ operators: selectedHub && selectedHub.map(o => o.uid)});
  }, [selectedHub]);

  const handleSave = () => {

    // Temporary solution 
    const hubArray = {
      hubid: selectedHub[0].hubid,
      operators: selectedHub.map(op => ({
          uid: op.uid
      }))
  }

  const operatorsData = selectedHub.flatMap(o => hubOperators.filter(op =>  op.uid === o.uid))

    messageApi.open({
      type: "loading",
      content: "Updating...",
      duration: 0,
    });


    dispatch(REDUX_ACTION.v1_hub.hub_assigned_operator_request(hubArray, operatorsData, () => messageApi.destroy()));
   
    setIsOpen(false);
  }

  const ActionButton = (
    <Form.Item style={{ paddingTop: "20px" }}>
      <div>
        <BtnSubmit
          display="inline-block"
          text="Save"
          bgColor="var(--themeBackgroundColor)"
          color="var(--creamColor)"
          padding="9px 18px"
          onClick={!selectedHub ? () => message.warning("No change exists") : handleSave}
        />
        <BtnSubmit
          display="inline-block"
          text="Cancel"
          bgColor="transparent"
          color="var(--bs-gray-600)"
          padding="9px 18px"
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </div>
    </Form.Item>
  );

  return (
    <>
    {contextHolder}
    <Drawer
      title="Assign Point Operator"
      placement="right"
      closable={false}
      width={410}
      onClose={() => setIsOpen(false)}
      footer={ActionButton}
      open={isOpen}
      style={{
        textAlign: "center",
        fontSize: "20px",
      }}
    >
    <Form
      form={form}
      layout="vertical"
      initialValues={{ 
        operators: operators && operators.map(o => o.value)}}
    >
        <Form.Item
          name="operators"
          label={<span>Assign Hub Operator</span>}
          rules={[
            {
              required: true,
              message: "select at least one option",
            },
          ]}
        >
        <Select
          showSearch
          mode="multiple"
          maxTagCount={10}
          onChange={handleHub}
        >
        {
          hubOperators
          &&
          hubOperators.map((o) => (
            <Select.Option key={o.uid} value={o.uid}>
              {`${o.memid} / ${o.name}`}
            </Select.Option>
          ))
          }
        </Select>
        </Form.Item>
      </Form>
    </Drawer>
    </>
  );
};

export default DrawerAssignedOperator;
