// Third-party library 
import { message } from "antd";

// Constant
import { TEMPLATE } from "../../../../../../constants";

// Componets
import { ButtonLink, ButtonModal } from "../../../../../../components"; 

import * as REDUX_ACTION from "../../../../../../services/redux/actions";

const { MODAL_ACTION } = TEMPLATE.MODAL;


export const ActionItems = (setIsOpenEdit, setData, record, setIsOpenChange, setIsOpenRemark, dispatch) => {

	return [
	{
		key: 0,
		label: (<ButtonLink>Edit point operator</ButtonLink>),
		onClick: () => {
			setIsOpenEdit(true);
			setData(record)
		  },
	},
	{
		key: 1,
		label: (<ButtonLink>Edit remarks</ButtonLink>),
		onClick: () => {
		  setIsOpenRemark(true);
		  setData(record);
		},
	},
	{
		key: 2,
		label: (<ButtonLink>Change password</ButtonLink>),
		onClick: () =>{
			setIsOpenChange(true);
      		setData(record)
		}
	},
	{
		key: 3,
		label: (
      <>
      {record.status === 0 || !record.status ? 
			<ButtonModal
				model="link"
				extra={record}
				setmodal={MODAL_ACTION.reactivate_user}
				onClick={() => {
          message.loading('Updating the status...', 0) 
                    dispatch(
                        REDUX_ACTION.v1_hubOperator.update_hub_operators_request({
							uid: record.uid,
							items: {...record, status: 1}
                        },
                        () => message.destroy()
                        )
                    );
				}}
			>
				Activate
			</ButtonModal>
      :
      <ButtonModal
        model="link"
        extra={record}
        setmodal={MODAL_ACTION.suspend_user}
                onClick={() => {
                  message.loading('Updating the status...', 0)

					  dispatch(
                        REDUX_ACTION.v1_hubOperator.update_hub_operators_request({
						uid: record.uid,
                        items: {...record, status: 0}
                        }, 
                        () => message.destroy())
                    );
        }}
      >
        Suspend
      </ButtonModal>
      }
      </>
		),
	},
]
}
