import * as ActionTypes from "../action-types";

// Constants and Config

// Services

// Redux Action

// Neccessary Actions

export const set_journalnote_success = (journalNote) => {
    return {
      type: ActionTypes.SET_JOURNAL_NOTE,
      journalNote
    };
  }; 