import * as API from "../../api";
import * as ActionTypes from "../action-types";

import { message } from "antd";

// Constants and Config

// Services

// Redux Action
import { setLoadingCustomerWallet, unsetLoadingCustomerWallet } from "./style";


export const get_customer_all_request_90days = () => (dispatch) => {
  dispatch(setLoadingCustomerWallet());

  API.getUserWalletAll()
    .then((info) => {
      const { status, wallet, msg } = info;

      if (status === 200) {
        dispatch(get_customer_all_success(wallet));
        message.success("Get Customer Wallet List Data Successfully");
      } else {
        message.warning(msg);
      }
    })
    .finally(() => {
      dispatch(unsetLoadingCustomerWallet());
    });
};

const get_customer_all_success = (wallets) => {
  return {
    type: ActionTypes.GET_CUSTOMER_WALLET,
    wallets,
  };
};
