import { Form, Row, Col, Input, Select } from "antd";

// Constants
import { TEMPLATE } from '../../../../../../../../constants'

import { NumericInput } from "../../../../../../../../components";

// Service

const { Option } = Select

const { STATE } = TEMPLATE.ORDER



const ProfileForm = ({
    formDataSrc,
    setFormDataSrc,
    userRole,
    handleChange
}) => {
    const prefixContactSelector = (
        <Form.Item name="contactPrefix" noStyle>
            <Select style={{ width: 70 }}>
                <Option value="+60">+60</Option>
            </Select>
        </Form.Item>
    );

    const optionsState = Object.keys(STATE)
    .map(state => ({
        label: STATE[state].name,
        value: STATE[state].code
    }))

    return (
        <>
            <Row>
                <Col span={8}>

                    <Form.Item label="Name" name="name" rules={[{ required: true }]} value={formDataSrc.name}>
                        <Input name="name" onChange={handleChange} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={30}>
                <Col span={8}>
                    <Form.Item name="email" label="Email" rules={[{ required: true, type:"email", pattern: new RegExp ("^[A-Za-z][A-Za-z0-9_]{7,29}$") }]}
                    >
                        <Input
                            name="email"
                            type="email"
                            value={formDataSrc.email}
                            placeholder="example@example.com"
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="contact" label="Contact" 
                        rules={[{ required: true, max: 12, min: 9 }]}
                    >
                        <NumericInput
                            onChange={(e) => handleChange({target:{
                                name:'contact',
                                value: e
                            }})}
                            addonBefore={prefixContactSelector}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="userRole" label="User Role">
                        <label htmlFor="Customer">{userRole}</label>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={16}>
                    <Form.Item 
                      name="address"
                      label="Address"
                      rules={[{ required: true }]}
                    >
                        <Input
                            name="address"
                            value={formDataSrc.address}
                            onChange={handleChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={30}>
                <Col span={8}>
                    <Form.Item name='postcode' label="Postcode" rules={[{ required: true, max: 9 }]}>
                    <Input
                        name="postcode"
                        type="number"
                        placeholder="Eg: 50100"
                        value={formDataSrc.postcode}
                        onChange={handleChange}
                    />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="city" label="City" rules={[{ required: true }]}>
                        <Input
                            name="city"
                            placeholder="Eg: WP Kuala Lumpur"
                            value={formDataSrc.city}
                            onChange={handleChange}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <Form.Item name="state" label="State" rules={[{ required: true }]}>
                        <Select
                            name="state"
                            onChange={(value) => {
                            const name = "state";
                            setFormDataSrc((prev) => ({ ...prev, [name]: value }));
                            }}
                            value={formDataSrc.state}
                            placeholder="-- SELECT --"
                            options={optionsState}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};

export default ProfileForm;
