import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import {
    Card,
    Form,
    Select,
    Input,
    Row,
    Col,
    Switch, 
    TimePicker,
} from 'antd'

// Constants
import { HUB } from '../../../../../../constants/templates';

// Services 
import { REDUX as REDUX_UTIL, TIME } from "../../../../../../services/util";

import classes from "../../../../common.module.css"

const { RangePicker } = TimePicker
const { reduxStoreWrapper } = REDUX_UTIL
const { GET_HUB_LIST } = HUB

const PointInformation = ({
  form
}) => {

  const hubs = useSelector(reduxStoreWrapper('hub.byID'))
  const hubsIDs = useSelector(reduxStoreWrapper('hub.allIDs'))
  const zones = useSelector(reduxStoreWrapper('zone.byID'))

  const hubsList = GET_HUB_LIST({ hubs, hubsIDs })


  /* 
      ZonesList
  */ 
  const zonesList = Object.values(zones).map(zone => ({
      label: zone.name,
      value: zone.zoneid,
  }))

  /* 
      state for Operation Hours
  */
  const [schedule, setSchedule] = useState([
      { day: 'Monday', active: false },
      { day: 'Tuesday', active: false },
      { day: 'Wednesday', active: false },
      { day: 'Thursday', active: false },
      { day: 'Friday', active: false },
      { day: 'Saturday', active: false },
      { day: 'Sunday', active: false },
  ]);

  useEffect(() => {
      const biz_hrs = form.getFieldsValue().biz_hrs

      const updatedSchedule = schedule.map(schd => {
          const bzHrsDay = biz_hrs.day[schd.day]

          return bzHrsDay ? { ...schd, active: true } : schd;
      });

      setSchedule(updatedSchedule);
      
  }, [])

  /*
      handleChange for Operation Hours
  */
  const handleChange = (checked, day, i) => {

      const updatedSchedule = [...schedule];
      updatedSchedule[i] = { ...schedule[i], active: checked };

      setSchedule(updatedSchedule);

      if(checked) {
          const epochTime = TIME.getEpochTimeForDay(day);
          form.setFieldValue(['biz_hrs', 'day', day], epochTime);
      }
  }

    return (
        <Card className={classes['card-container']}>
            <h1 className={classes['card-title']}>POINT INFORMATION</h1>

            <Row gutter={15}>
                <Col span={10}>
                    <Form.Item label="Point Name" name='name'
                        rules={[{required: true}]}
                    >
                        <Input placeholder='Eg. Taman Sari Putri' />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item label="Point Code" name="code"
                         rules={[{required: true}]}
                    >
                        <Input placeholder='Eg. SPT'/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={15}>
                <Col span={10}>
                    <Form.Item label="Assign Zone" name='zone'
                        rules={[{required: true}]}
                    >
                        <Select
                            placeholder='-- SELECT ZONE --'
                            options={zonesList}
                        />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item label="Assign Hub" name="parentHub" 
                         rules={[{required: true}]}
                    >
                        <Select
                            placeholder='-- SELECT HUB --'
                            options={hubsList}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <p style={{ paddingBottom: '20px' }} >Operation Hours</p>
            { schedule.map((item, i) => {

                const { day, active } = item

                return (
                    <Row key={i}>
                      <Col span={12} style={{ display: 'grid', gridTemplateColumns: '40% 30% 30%' }}>
                          <label htmlFor='day' style={{ paddingTop: '.7em' }}>{day}</label>

                          <Form.Item name={['biz_hrs', 'day', day]} valuePropName='checked'>
                              <Switch onChange={e => handleChange(e, day, i)} />
                          </Form.Item>

                          <label htmlFor='day' style={{ paddingTop: '.7em' }}>{active ? 'Open' : 'Closed'}</label>
                      </Col>
                      <Col span={8} >
                          <Form.Item name={['biz_hrs', 'hour', day]}>
                              <RangePicker format='HH:mm' disabled={!active}/>
                          </Form.Item>
                      </Col>
                  </Row>
                )}
            )}

        </Card>
    )
}

export default PointInformation
