// React Thirty Party Library
import React, { useState } from "react";
import { useNavigate } from "react-router"; 

// Constants Library
import { COMMON_FEATURES, TEMPLATE , ROUTES} from "../../../../constants";

// Components Library
import { TableList } from "../../../../components";
import ModalImage from "../ModalImage";

// Handler Library
import { transactionHDLR, tableHDLR } from "../../../../handlers";

// Services Library

// Redux Actions
 
// Styles Library

const { TABLE_TRANSACTION_INDEX, SELECT_OPTION_DAYS } =
  TEMPLATE.TRANSACTIONLIST;
const { TRANSACTION_SUMMARY_COL } = TEMPLATE.REPORT.TXN;

const { ROUTE_URL } = ROUTES ;

const TableTransaction = (props) => {
  const {
    dataSourceTransaction, 
    filteredDataTransaction,
    setFilteredDataTransaction,
    loading, 
    selectedRow,
    setSelectedRow
  } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleModal = (record) => {
    setSelectedRecord(record);
    setIsModalOpen(true);
  };

  const navigate = useNavigate();

  const NavigateOrderSummary = (ordid) =>{
    navigate(ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.ORDER_SUMMARY],{
      state : {ordid},
    })
  }

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const columns = TRANSACTION_SUMMARY_COL({
    func: {
      handleModal,
      NavigateOrderSummary,
      handleSearch
    }
  });

  const onSelectRow = (record, selected) => {
    tableHDLR.selectRow(setSelectedRowKeys, setSelectedRow, selectedRow, selectedRowKeys, record, selected)
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelectRow
  };

  const sortedData = filteredDataTransaction.sort(
    (a, b) => b.createdAt - a.createdAt
  );

  
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const sortFunctions = {
    createdAt: (a, b) => a.createdAt - b.createdAt,
  };
  
  const filtered = sortedData.filter((record) => {
    let include = true;
  
    Object.keys(filteredInfo).forEach((key) => {
      if (filteredInfo[key] && filteredInfo[key].length > 0) {
        include = include && filteredInfo[key].includes(record[key]);
      }
    }); 
  
    if (searchText) {
      if(searchedColumn === "txnid") {
        if ((typeof record.txnid === 'string' && record.txnid.includes(searchText))) {
          include = true;
        }
      }
      if(searchedColumn === "memid") {
        if ((typeof record.memid === 'string' && record.memid.includes(searchText)) || 
            (typeof record.name === 'string' && record.name.includes(searchText))) {
          include = true;
        }
      }
      if(searchedColumn === "ordid") {
        if ((typeof record.ordid === 'string' && record.ordid.includes(searchText))) {
          include = true;
        }
      }
      if(searchedColumn === "jnid") {
        if ((typeof record.jnid === 'string' && record.jnid.includes(searchText))) {
          include = true;
        }
      }
    }   

    return include;

  }).sort((a, b) => {
    const { columnKey } = sortedInfo;
    return sortFunctions[columnKey] ? sortFunctions[columnKey](a, b) : 0;
  });

  return (
    <>
      <TableList.DataList
        dataSource={dataSourceTransaction}
        filteredData={filtered}
        setFilteredData={setFilteredDataTransaction}
        optionDay={SELECT_OPTION_DAYS}
        optionFirst={transactionHDLR.transactionStatus()}
        optionSecond={transactionHDLR.transactionType()}
        dataDate={TABLE_TRANSACTION_INDEX.Transacted_At}
        defaultValue="Date"
        text1="Transaction Status"
        text2="Transaction Type"
        showFirstFilter={true}
        showSecondFilter={true}
        columnData={columns}
        loading={loading}
        rowSelection={rowSelection}
        onChange={handleTableChange}
      />
      <ModalImage
        isModalOpen={isModalOpen && selectedRecord}
        setIsModalOpen={() => setIsModalOpen(false)}
        dataOrder={selectedRecord}
      />
    </>
  );
};

export default TableTransaction;
