import * as API from "../../api";
import * as ActionTypes from "../action-types";

import { message } from "antd";

// Constants and Config

// Services
import { TIME } from "../../util";

// Redux Action
import {
  setLoadingTransactionList,
  unsetLoadingTransactionList,
  setLoadingBillingList,
  unsetLoadingBillingList
} from "./style";

// Neccessary Actions

const { lastNDay, latestTime } = TIME;

import { v1_billing, v1_journalNote } from "../actions";
 

export const get_transaction_timeframe_90days_request = () => (dispatch) => {
  dispatch(get_transaction_timeframe_request(lastNDay(90), latestTime));
};

export const get_transaction_timeframe_request =
  (startTime, endTime) => (dispatch) => {
    dispatch(setLoadingTransactionList());

    API.getTransactionTimeFrameAll({
      startTime,
      endTime,
    }) 
      .then((info) => {
        const { status, transaction, msg } = info;

        if (status === 200) {
          dispatch(get_transaction_timeframe_all_success(transaction));
          message.success("Get Transaction List Data Successfully");
        } else {
          message.warning(msg);
        }
      })
      .finally(() => {
        dispatch(unsetLoadingTransactionList());
      });
  };

export const create_transaction_request =
  (transactionData, cb = () => {}) =>
  (dispatch) => {
    dispatch(setLoadingTransactionList());
    dispatch(setLoadingBillingList());
    
    API.createTransaction(transactionData)
      .then((info) => {
        const { status, transaction, msg } = info; 

        if (status === 200) {
          if(transactionData.type === "INV") {
            dispatch(v1_billing.update_billing_success(info.billing));
            dispatch(create_transaction_success(transaction));
            dispatch(v1_journalNote.set_journalnote_success(info.journalNote))
            message.success("Succesfully Pay the Invoice");
          } else {
            dispatch(v1_billing.create_billing_success(info.billing));
            dispatch(create_transaction_success(transaction));
            dispatch(v1_journalNote.set_journalnote_success(info.journalNote))
            message.success("Succesfully Create The Transaction");
          }
        } else {
          message.warning(msg);
        }
      })
      .finally(() => {
        dispatch(unsetLoadingTransactionList());
        dispatch(unsetLoadingBillingList());
        cb();
      });
  };


const get_transaction_timeframe_all_success = (transaction) => {
  return {
    type: ActionTypes.GET_TRANSACTION_LIST,
    transaction,
  };
};

const create_transaction_success = (transaction) => {
  return {
    type: ActionTypes.CREATE_TRANSACTION,
    transaction,
  };
};

