import * as ActionTypes from "../action-types";

const customer_defaultState = {
  byID: {},
  allIDs: [],
  byCM: {}
};

export const customer = (state = customer_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState; 

  switch (action.type) {
    case ActionTypes.GET_CUSTOMER_LIST_THRU_CM: {
      const customer = action.customers;
      const amid = action.amid

          newState.byCM[amid] = customer;

      return newState;
    }
    
    case ActionTypes.GET_CUSTOMER_ALL: {
      const customer = action.customers;
      
      customer &&
        customer.length &&
        customer.map((user) => {
          const {memid} = user;

          newState.byID[memid] = user;

          if (!newState.allIDs.includes(memid)) {
            newState.allIDs.push(memid);
          }
          return null;
        });

      return newState;
    }
    case ActionTypes.UPDATE_CUSTOMER_INFO: {

            const user = action.customer
            const { memid } = user
      
            newState.byID[memid] = user
      
            return newState
    }
    default: {
      return state;
    }
  }
};
