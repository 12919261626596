import * as API_CONSTANT from "../constants";

export const getCustomerInfoAll = () =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/customer/getInfo/all`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({}),
  }).then((res) => res.json());


export const updateCustomerInfo = ({uid, items}) => 
fetch(`${API_CONSTANT.adp_v1_fe_path}/customer/info/update`, {
  method: "POST",
  headers: API_CONSTANT.headers,
  body: JSON.stringify({
    uid,
    items
  })
}).then(res => res.json())


export const customerChangePassword = async ({uid, password}) => {
  
const res = await fetch(
`${API_CONSTANT.adp_v1_fe_path}/customer/update/password`, {
  method: "POST",
  headers: API_CONSTANT.headers,
  body: JSON.stringify({
    uid,
    password
  })
})
return await res.json();
}

export const updateCustomerLinkToAccountManager = ({uid, hierCode, hierLevel, cm}) => 
fetch(`${API_CONSTANT.adp_v1_fe_path}/customer/link/cm`, {
  method: "POST",
  headers: API_CONSTANT.headers,
  body: JSON.stringify({
    uid,
    hierCode,
    hierLevel,
    cm
  }),
}).then((res) => res.json());


export const getUserWalletAll = () =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/customer/getWallet/all`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({}),
  }).then((res) => res.json());

export const updatePassword = ({uid, password}) =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/operator/update/password`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      uid,
      password
    }),
  }).then((res) => res.json());

