// Constants Library
import { FEATURE_NAME } from "../common/features";

export const ROUTE_URL = {
	// Order
	[FEATURE_NAME.ORDER_MANAGEMENT]: "/order",
	[FEATURE_NAME.ORDER_LIST]: "/order/list",
	[FEATURE_NAME.ORDER_SORT]: "/order/sort",
	[FEATURE_NAME.ORDER_SUMMARY]: "/order/summary", 
	[FEATURE_NAME.BULK_UPLOAD]: "/order/bulk/upload",
	[FEATURE_NAME.ORDER_PARKED_LIST]: "/order/parked/list",
	[FEATURE_NAME.ORDER_TIMELINE]: "/order/timeline",
	// Finance
	[FEATURE_NAME.CUSTOMER_WALLET_MANAGEMENT]: "/customer/wallet",
	[FEATURE_NAME.MANAGE_CREDIT]: "/manage/credit",
	[FEATURE_NAME.MANAGE_CREDIT_INVOICE]: "/manage/credit/invoices",
	[FEATURE_NAME.TRANSACTION_LIST]: "/transaction/list",
	[FEATURE_NAME.BILLING_LIST]: "/billing/list",
	// User
	[FEATURE_NAME.USER_MANAGEMENT]: "/user",
	[FEATURE_NAME.CUSTOMER_LIST]: "/customer/list",
	[FEATURE_NAME.ACCOUNT_MANAGER]: "/account/manager",
	// App Info
	[FEATURE_NAME.DASHBOARD]: "/",
	[FEATURE_NAME.ACCOUNT_PROFILE]: "/account",
	// Vehicle
	[FEATURE_NAME.VEHICLE_MANAGEMENT]: "/vehicle",
	[FEATURE_NAME.VEHICLE_LIST]: "/vehicle/list",
	[FEATURE_NAME.VEHICLE_MAP]: "/vehicle/map",


	// signup acc manager
	[FEATURE_NAME.ACCOUNT_MANAGER_SIGN_UP]: "/account/manager/signup",
	[FEATURE_NAME.ACCOUNT_MANAGER_CREATED]: "/account/manager/created",

	// Hub
	[FEATURE_NAME.HUB_MANAGEMENT]:"/hub",
	[FEATURE_NAME.HUB_OPERATOR_LIST]:"/hub/operator",
	[FEATURE_NAME.HUB_LIST]:"/hub/list",
	[FEATURE_NAME.HUB_CREATE]:"/hub/create",
	[FEATURE_NAME.HUB_EDIT]:"/hub/edit",
	
  // Point
	[FEATURE_NAME.POINT_MANAGEMENT]:"/point",
	[FEATURE_NAME.POINT_OPERATOR_LIST]:"/point/operator",
	[FEATURE_NAME.POINT_LIST]:"/point/list",
	[FEATURE_NAME.POINT_FORM]:"/point/form",
	[FEATURE_NAME.POINT_CREATE]:"/point/create",
	[FEATURE_NAME.POINT_EDIT]:"/point/edit",
  
	// price
	[FEATURE_NAME.PRICE_MANAGEMENT]:"/price",
	[FEATURE_NAME.ZONE_PRICE_LIST]:"/zone",
	
	//report
	[FEATURE_NAME.REPORT] : "/report",
};

export const ROUTE_TITLE = {
	// Order
	[FEATURE_NAME.ORDER_MANAGEMENT]: "Order",
	[FEATURE_NAME.ORDER_LIST]: "Order List",
	[FEATURE_NAME.ORDER_SORT]: "Order List",
	[FEATURE_NAME.ORDER_SUMMARY]: "Order List",
	[FEATURE_NAME.BULK_UPLOAD]: "Order List",
	[FEATURE_NAME.ORDER_PARKED_LIST]: "Parked List",
	[FEATURE_NAME.ORDER_TIMELINE]: "Order Timeline",
	// Finance
	[FEATURE_NAME.CUSTOMER_WALLET_MANAGEMENT]: "Customer Wallet",
	[FEATURE_NAME.MANAGE_CREDIT]: "Customer Wallet",
	[FEATURE_NAME.MANAGE_CREDIT_INVOICE]: "Customer Wallet",
	[FEATURE_NAME.TRANSACTION_LIST]: "Transaction List",
	[FEATURE_NAME.BILLING_LIST]: "Billing List",
	// User
	[FEATURE_NAME.USER_MANAGEMENT]: "User List",
	[FEATURE_NAME.CUSTOMER_LIST]: "Customer List",
	[FEATURE_NAME.ACCOUNT_MANAGER]: "Account Manager List",
	// Vehicle
	[FEATURE_NAME.VEHICLE_MANAGEMENT]: "Vehicle",
	[FEATURE_NAME.VEHICLE_LIST]: "Vehicle List",
	[FEATURE_NAME.VEHICLE_MAP]: "Vehicle List",
	// App Info
	[FEATURE_NAME.ACCOUNT_PROFILE]: "My Account",
	[FEATURE_NAME.LOGOUT]: "Logout",
	//Hub
	[FEATURE_NAME.HUB_MANAGEMENT]: "Hub",
	[FEATURE_NAME.HUB_OPERATOR_LIST]: "Hub Operator List",
	[FEATURE_NAME.HUB_LIST]: "Hub List",
	[FEATURE_NAME.HUB_CREATE]: "Hub List",
	[FEATURE_NAME.HUB_EDIT]: "Hub List",
	//Point
	[FEATURE_NAME.POINT_MANAGEMENT]: "Point Management",
	[FEATURE_NAME.POINT_OPERATOR_LIST]: "Point Operator List",
	[FEATURE_NAME.POINT_LIST]: "Point List",
	[FEATURE_NAME.POINT_FORM]: "Point List",
	[FEATURE_NAME.POINT_CREATE]: "Point List",
	[FEATURE_NAME.POINT_EDIT]: "Point List",
  //Price
  [FEATURE_NAME.PRICE_MANAGEMENT]: "Price Management",
  [FEATURE_NAME.ZONE_PRICE_LIST]: "Zone Price List",
	//Report
	[FEATURE_NAME.REPORT]: 'Report'
};

export const ROUTE_TITLE_PATH = Object.fromEntries(
	Object.entries(ROUTE_URL).map(([key, value]) => [value, ROUTE_TITLE[key]])
);
