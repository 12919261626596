import React, { useState, useEffect } from 'react'
import { Form, Space, Row, Col, Input, Select, DatePicker, Button} from 'antd'

// Constants
import { TEMPLATE } from '../../../../../../../../constants'

// Components
import { NumericInput } from '../../../../../../../../components'

// Styles
import coms from '../common.module.css'

const { 
    DATA_FIELD,
    HUBS,
    STATE,
} = TEMPLATE.ORDER

const dateFormat = "DD/MM/YYYY";

const SenderInformation = ({formData, onClick, label, sort, hubs, hubsAll}) => {

    const origid = formData.origid
    const showHubDetailDefault = (origid && origid === HUBS.OTHERS.hubid && true) || false;

    const [ showHubDetail, setShowHubDetail ] = useState(showHubDetailDefault)

    const [hubMap, setHubMap] = useState({});
    const [hubList, setHubList] = useState([]);
    
    useEffect(() => {
      if (hubs && hubsAll) {
        const newHubMap = {};
    
        hubsAll.forEach((hubid) => {
          const hubState = hubs[hubid]?.state;
    
          if (hubState !== undefined) {
            if (!newHubMap[hubState]) {
              newHubMap[hubState] = [];
            }
    
            newHubMap[hubState].push({
              label: hubs[hubid]?.name,
              value: hubid,
            });
          }
        });
    
        setHubMap(newHubMap);
      }
    }, [hubs, hubsAll]);
    
    useEffect(() => {
      const newHubList = Object.keys(hubMap).map((hubState) => ({
        label: hubState,
        options: hubMap[hubState],
      }));
    
      setHubList(newHubList);
    }, [hubMap]);
    
    const optionsState = Object.keys(STATE)
        .map(state => ({
            label: STATE[state].name,
            value: STATE[state].code
        }))

    const prefixContactSelector = (
        <Form.Item name="sender_contactPrefix" noStyle>
            <Select style={{ width: 70 }}>
                <Select.Option value="+60">+60</Select.Option>
            </Select>
        </Form.Item>
    );

    const HubSelector = (hubid) => {
        if(hubid === HUBS.OTHERS.hubid) 
            setShowHubDetail(true)
        else
            if(showHubDetail)
                setShowHubDetail(false)
    }

    return (
        <>
            <Space direction={'vertical'} style={{width:"100%"}}>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            label={'Origin'}
                            name={DATA_FIELD.origid}
                            rules={[{ required: true }]}
                        >
                            <Select
                                options={hubList}
                                style={{width:'14vw'}}
                                onSelect={(s) => HubSelector(s)}
                                disabled={label ===  sort}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={'Expected Departure Date'}
                            name={DATA_FIELD.etd}
                            rules={[{ required: true }]}
                        >
                            <DatePicker style={{width:'14vw'}} format={dateFormat} disabled={label ===  sort}/>
                         </Form.Item>
                    </Col>
                </Row>
                {
                    showHubDetail
                    &&
                    (
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label={'Please specify the hub, city and state'}
                                    name={DATA_FIELD.orig_detail}
                                    rules={[{ required: true }]}
                                >
                                    <Input placeholder='Eg: Kuantan Sentral/ Kuantan/ Pahang' disabled={label ===  sort}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                }
                <Row>
                    <Col span={8}>
                        <Form.Item
                            label={'Sender Name'}
                            rules={[{ required: true }]}
                            name={DATA_FIELD.sender_name}
                        >
                            <Input className={coms.inputText} disabled={label ===  sort}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={'Sender Contact'}
                            name={DATA_FIELD.sender_contact}
                            rules={[{ required: true }]}
                        >
                            <NumericInput addonBefore={prefixContactSelector} style={{width:'15vw'}} disabled={label ===  sort}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={'Sender Email'}
                            name={DATA_FIELD.sender_email}
                            rules={[{ type: "email" }]}
                        >
                            <Input placeholder="example@exmplace.com" className={coms.inputText} disabled={label ===  sort}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={'Sender Address'}
                            name={DATA_FIELD.sender_address}
                            rules={[{ required: true }]}
                        >
                            <Input disabled={label ===  sort}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            label={'Postcode'}
                            name={DATA_FIELD.sender_postcode}
                            rules={[{ required: true }]}
                        >
                            <Input className={coms.inputText} disabled={label ===  sort} maxLength={5}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={'City'}
                            name={DATA_FIELD.sender_city}
                            rules={[{ required: true }]}
                        >
                            <Input className={coms.inputText} disabled={label ===  sort}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={'State'}
                            name={DATA_FIELD.sender_state}
                            rules={[{ required: true }]}
                        >
                            <Select
                                options={optionsState}
                                style={{width:'14vw'}}
                                rules={[{ required: true }]}
                                disabled={label ===  sort}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={'Sender Remark'}
                            name={DATA_FIELD.sender_remark}
                        >
                            <Input 
                            disabled={label ===  sort}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <div className={coms.nextBTN}>
                    <Button type="primary" onClick={() => onClick()}>Next</Button>
                </div>
            </Space>
        </>
    )
}

export default SenderInformation
