export const TABLE_ZONE_TITLE = {
    zone_id : "Zone ID",
    zone_name: "Zone Name",
    total: "Total Zone",
    active: "Active Zone Price",
    inactive: "Inactive Zone Price",
    createdAt: "Created At",
    updated: "Last Updated",
    remark: "Remark",
    action: "Action"
}

export const TABLE_ZONE_INDEX = {
    zone_id : "zoneid",
    zone_name: "name",
    total: "total",
    active: "active",
    inactive: "inactive",
    createdAt: "createdAt",
    updated: "updatedAt",
    remark: "remark"
}

export const TABLE_EXPAND_ZONE_PRICELIST_TITLE = {
    zone_from : "From Zone",
    zone_to: "To Zone",
    status: "Status",
    price: "Price(RM)",
    updated: "Last Updated",
    action: "Action" 
}

export const TABLE_EXPAND_ZONE_PRICELIST_INDEX = {
    zone_from : "originZone",
    zone_to: "destinationZone",
    status: "status",
    price: "price",
    updated: "updatedAt", 
}

export const OPTION_PRICE_STATUS = {
  active: 1,
  inactive: 0,
};

export const OPTION_PRICE_STATUS_TITLE = {
  [OPTION_PRICE_STATUS.active]: { name: "ACTIVE", colorTag: "green", status: 1 },
  [OPTION_PRICE_STATUS.inactive]: { name: "INACTIVE", colorTag: "red", status: 0 },
};

export const OPTION_DAYS = {
    LAST_30_DAYS: "Last 30 Days",
    LAST_60_DAYS: "Last 60 Days",
    LAST_90_DAYS: "Last 90 Days",
  };
  
  export const OPTION_DAYS_VALUE = {
    LAST_30_DAYS: 30,
    LAST_60_DAYS: 60,
    LAST_90_DAYS: 90,
  };

  export const SELECT_OPTION_DAYS = Object.keys(OPTION_DAYS).map((option) => ({
    label: OPTION_DAYS[option],
    value: OPTION_DAYS_VALUE[option],
  }));