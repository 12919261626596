// Third Party Lib
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import {
  Button,
  Form,
  Modal,
  Input,
  InputNumber,
  Select,
  DatePicker,
  message,
} from "antd";

import * as REDUX_ACTION from "../../../../../../services/redux/actions";

import { uploadHDLR } from "../../../../../../handlers";

import classes from "../../index.module.css";

 
const OrderModal = ({
  open,
  title,
  form,
  loading,
  handleModalEdit,
  handleModalCancel,
  items,
  btnText,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
      
  useEffect(() => {
    messageApi.open({
      type: "loading",
      content: "get active hubs...",
      duration: 0,
    });
    dispatch(
      REDUX_ACTION.v1_hub.get_active_hubs_request(() => 
        messageApi.destroy())
    );
    return () => {};
  }, [dispatch]);  


  const FormItemRender = (item, n) => {
    const { inputType, children, options, key, title } = item;
    let CustomInput

    if (children && children.length) {
      return children.map((c, i) => FormItemRender(c, `${n}#${i}`));
    } 
    else {
      switch (inputType) {
        case "date": {
          CustomInput = 
          <DatePicker className={classes.inputColumn} 
          format={"DD/MM/YYYY"} 
          /> 
          break;
        }
        case "select": {
          CustomInput = (
            <Select
              options={options}
              style={{ width: "270px", textAlign: "start" }}
            />
          );
          break;
        }
        case "number": {
          CustomInput = <InputNumber className={classes.inputColumn} />;
          break;
        }
        case "input":
        default: {
          CustomInput = <Input className={classes.inputColumn} />;
          break;
        }
      }
    }

    return (
      <Form.Item
        label={title}
        name={key}
        key={`${n}#${key}`}
        className={classes.labelColumn}
      >
        {CustomInput}
      </Form.Item>
    );
  };

  return (
    <>
    {contextHolder}
    <Modal
      centered
      width={550}
      title={title}
      open={open}
      onOk={handleModalEdit}
      onCancel={handleModalCancel}
      footer={[
        <Button key="back" onClick={handleModalCancel}>
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleModalEdit}
        >
          {btnText}
        </Button>,
      ]}
      className={classes.formEdit}
    >
      <Form 
      form={form}
      >
        <div className={classes.formColumn}>
          {items.length &&
            items
              .filter(({ key }) => key !== "Actions" && key !== "add" && key !== 'img')
              .map((item, i) => FormItemRender(item, i))}
        </div>
      </Form>
    </Modal>
    </>
  );
};

export default OrderModal;
