// import { API } from 'aws-amplify'
import * as API_CONSTANT from '../constants'

export const getInfoByUser = (uid, DLT = 0) => (
    fetch(`${API_CONSTANT.adp_v1_fe_path}/user/getInfo`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            uid,
            DLT
        })
    })
    .then(res => res.json())
)

export const getRoleByUser = (uroleid) => (
    fetch(`${API_CONSTANT.adp_v1_fe_path}/user/getRole`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            uroleid
        })
    })
    .then(res => res.json())
)

export const getUserHierarchy = (hierCode) => (
    fetch(`${API_CONSTANT.adp_v1_fe_path}/user/getHierarchy`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({
            hierCode
        })
    })
    .then(res => res.json())
)

export const updateUserInfo = (uid, items)=> (
  fetch(`${API_CONSTANT.adp_v1_fe_path}/user/info/update`, {
    method: 'POST',
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
        uid,
        items
    })
})
.then(res => res.json())
)
