import * as API_CONSTANT from "../constants";

export const getAccountManagerInfoAll = () =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/cm/getInfo/all`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({}),
  }).then((res) => res.json());
  
  export const getCustomerInfoThruCM = ({hierLevel, hierCode}) =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/customer/getThru/cm`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({hierLevel, hierCode}),
  }).then((res) => res.json());


  export const generateQRCM = (uid) =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/cm/qr/generate`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({uid}),
  }).then((res) => res.json());

  export const createCM = ({name, email, contact}) =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/cm/submit`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({name, email, contact}),
  }).then((res) => res.json());


