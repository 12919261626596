import { Drawer, Form, Input, Select, message, Radio } from "antd";
import { useEffect, useState } from 'react';
import { useDispatch} from "react-redux";

import { NumericInput, BtnSubmit } from "../../../../../../components";

import * as REDUX_ACTION from "../../../../../../services/redux/actions";

import classStyles from "./index.module.css";

const DrawerEditCustomer = (props) => {
  const { isOpen, setIsOpen, data, dataCM} = props

  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();
  const [selectedAmid, setSelectedAmid] = useState(null);
  const [selectedCode, setSelectedCode] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);
 
  const prefixContactSelector = (
    <Form.Item name="contactPrefix" noStyle>
      <Select style={{ width: 70 }}>
        <Select.Option value="+60">+60</Select.Option>
      </Select>
    </Form.Item>
  );

  const optionsCM = dataCM.map(
    (d) => ({
      label: `${d.memid} / ${d.name}`,
      value: `${d.memid},${d.hierCode},${d.hierLevel}`,
    })
  );

  const optionsCMEmpty = [
    {
      label: "None",
      value: "",
    },
  ];
  
  const optionsAllCM = optionsCMEmpty.concat(optionsCM);

  const handleCM = (value) => {
    const [memid, hierCode, hierLevel] = value.split(",");
    setSelectedAmid(memid || "")
    setSelectedCode(hierCode || "~");
    setSelectedLevel(hierLevel || 1);
  };

  const user = dataCM.find(c => c.memid === selectedAmid);
  
  const handleSave = () => {
    const formData = { ...data, ...form.getFieldsValue() };
    if (selectedAmid !== null) {
      
      dispatch(REDUX_ACTION.v1_customerList.add_account_manager_id({
        uid: data.uid,
        hierCode: selectedCode,
        hierLevel: Number(selectedLevel),
        cm: user
      })).then(data => {
        const updatedData = {
          ...formData,
          amid: selectedAmid,
          hierCode: data.user.hierCode,
          hierLevel: data.user.hierLevel,
        };

        messageApi.open({
          type: "loading",
          content: "Updating...",
          duration: 0,
        });


        dispatch(REDUX_ACTION.v1_customerList.edit_customer_info_request(updatedData,
          () => messageApi.destroy()));
      }).catch(error => {
        console.error("Error during dispatch:", error);
      });

    } else {
      messageApi.open({
        type: "loading",
        content: "Updating...",
        duration: 0,
      });
      dispatch(REDUX_ACTION.v1_customerList.edit_customer_info_request(formData,
        () => messageApi.destroy() ));
    }
   
    setIsOpen(false);
  };

  const ActionButton = (
    <Form.Item style={{ paddingTop: "20px" }}>
      <div>
        <BtnSubmit
          display="inline-block"
          text="Save & back to list"
          bgColor="var(--themeBackgroundColor)"
          color="var(--creamColor)"
          padding="9px 18px"
          onClick={handleSave}
        />
        <BtnSubmit
          display="inline-block"
          text="Cancel"
          bgColor="transparent"
          color="var(--bs-gray-600)"
          padding="9px 18px"
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </div>
    </Form.Item>
  );

  return (
    <>
    {contextHolder}
    <Drawer
      title="Edit Customer Account"
      placement="right"
      closable={false}
      width={410}
      onClose={() => setIsOpen(false)}
      footer={ActionButton}
      open={isOpen}
      style={{
        textAlign: "center",
        fontSize: "20px",
      }}
    >
    <Form
      form={form}
      layout="vertical"
      initialValues={{ ...data, contactPrefix: "+60" }}
    >
      <Form.Item
          name="memid"
          label={<span>Customer ID</span>}
          rules={[
            {
              required: true,
              message: "Customer ID is required",
            },
          ]}
        >
          <Input className={classStyles.inputField} disabled/>
        </Form.Item>
        <Form.Item
          name="name"
          label={<span>Name</span>}
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
        >
          <Input className={classStyles.inputField} />
        </Form.Item>
        <Form.Item
          name="uroleid"
          label={<span>Role</span>}
          rules={[
            {
              required: true,
              message: "Role is required",
            },
          ]}
        >
          <Radio.Group>
            <Radio value={'C'}>IPICK</Radio>
            <Radio value={'POP'}>MBE Kiosk</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="email"
          label={<span>Email</span>}
          rules={[
            {
              required: true,
              message: "Email is required",
              type: "email",
            },
          ]}
          
        >
          <Input className={classStyles.inputField} disabled={data.email_verified === 1}/>
        </Form.Item>
        <Form.Item
          name="contact"
          label={<span>Contact</span>}
          rules={[
            {
              required: true,
              message: "Contact is required",
            },
          ]}
        >
          <NumericInput
            addonBefore={prefixContactSelector}
          />
        </Form.Item>
        <Form.Item
          name="amid"
          label={<span>Link Account Manager</span>}
        >
        <Select
          showSearch
          onChange={handleCM}
        >
        {optionsAllCM.map((o) => (
          <Select.Option key={o.value} value={o.value}>
            {o.label}
          </Select.Option>
        ))}
        </Select>
        </Form.Item>
      </Form>
    </Drawer>
    </>
  );
};

export default DrawerEditCustomer;
