import * as ActionTypes from '../action-types'

const zone_defaultState = {
    byID: {},
    allIDs: [],
    byZoneid: {}
}

export const zone = (state = zone_defaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.GET_ZONE_LIST: {

            const zones = action.zones

            zones
            &&
            zones.length
            &&
            zones.map(zone => {

                const {zoneid} = zone

                newState.byID[zoneid] = zone

                if(!newState.allIDs.includes(zoneid)) newState.allIDs.push(zoneid)

                return null
            })

            return newState;
        }
        case ActionTypes.GET_ZONE_PRICE_LIST: {

            const priceList = action.priceList
            const zoneid = action.zoneid

            newState.byZoneid[zoneid] = priceList

            return newState; 
        }
        case ActionTypes.UPDATE_ZONE_PRICE_LIST:{

            const zone = action.zone
            const {zoneid} = zone

            if (zoneid) {
                newState.byID[zoneid] = zone
            }
            return newState;
        }
        case ActionTypes.UPDATE_ZONE_PRICE_LIST_BY_ZONEID:{

            const zonePrices = action.zonePrices
            const zoneid = action.zoneid

            if (zoneid) {
                newState.byZoneid[zoneid] = zonePrices
            }
            return newState;
        }
        case ActionTypes.UPDATE_ZONE_PRICE:{

            const zonePrice = action.zonePrice

            newState.byZoneid[zonePrice.origin] = newState.byZoneid[zonePrice.origin].map(p => {
                if (p.dest === zonePrice.dest) {
                    return zonePrice;
                } else {
                    return p;
                }
            });

            return newState;
        }
        case ActionTypes.CREATE_ZONE_LIST:{

            const zone = action.zone
            const {zoneid} = zone

            newState.byID[zoneid] = zone

            return newState;
        }
        default: {
            return state;
        }
    }
}
