import { Modal } from "antd";

// eslint-disable-next-line import/namespace
import { ROOT_CONSTANT } from "../../../../constants";

import styles from "../common.module.css";

const { icon_logout_modal } = ROOT_CONSTANT.IMG.ICON.MODAL;

const ConfirmLogout = (props) => {
  const { isModalOpen, setIsModalOpen, onClick } = props;

  return (
    <Modal
      open={isModalOpen}
      footer={null}
      onCancel={setIsModalOpen}
      width={400}
    >
      <div className={styles["content-container"]}>
        <img src={icon_logout_modal} alt="" />

        <div className={styles["contents"]}>
          <h2>Confirm Logout</h2>
          <div>
            <p>Are you sure you want to logout?</p>
          </div>

          <div className={styles["buttons"]}>
          <button
              onClick={onClick}
              className={`${styles["button"]} ${styles["active"]}`}
            >
              Yes, Logout
            </button>
            <button onClick={setIsModalOpen} className={styles["button"]}>
              No, Back to Portal
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmLogout;
