// React Thirty Party Library
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

// Constants Library
import { ROUTES, COMMON_FEATURES } from "../../../../constants";

// Components Library
import { SearchCustomerWallet, TableCustomerWallet } from "./components";

// Handler Library

// Services Library
import { REDUX as REDUX_UTIL } from "../../../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../../../services/redux/actions";

// Styles Library
import classes from "../../../common.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;
const { ROUTE_URL } = ROUTES;
const { FEATURE_NAME } = COMMON_FEATURES;

const CustomerWalletTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector(
    reduxStoreWrapper("style.loading.customer_wallet")
  );

  const wallet = useSelector(reduxStoreWrapper("wallet.byID"));
  const customers = useSelector(reduxStoreWrapper("customer.byID"));
  const transaction = useSelector(reduxStoreWrapper("transaction.byID"));
  const Users = Object.values(customers).map((t) => ({
    key: t.uid,
    ...t,
  }));

  const data = Users.filter((t) => {
    return t.uroleid === "C" || t.uroleid === "POP";
  });

  // const uid = data.map((u) => u.uid);

  const dataSourceUser = data.map((u) => {
    const matchingTransactions = Object.values(transaction).filter(
      (t) => t.memid === u.memid
    );
    matchingTransactions.sort((a, b) => b.createdAt - a.createdAt);
    const lastTransaction = matchingTransactions[0];

    const matchingWallet = Object.values(wallet).find((w) => w.uid === u.uid);

    return {
      ...u,
      lastTransaction: lastTransaction?.createdAt,
      currency: matchingWallet && matchingWallet.currency,
      balance: matchingWallet && matchingWallet.balance !== undefined ? matchingWallet && matchingWallet.balance : 0, 
      status: u.status !== undefined ? u.status : 1,
    };
  });

  const [filteredDataWallet, setFilteredDataWallet] = useState(dataSourceUser);

  useEffect(() => {
    dispatch(REDUX_ACTION.v1_wallet.get_customer_all_request_90days());
    dispatch(
      REDUX_ACTION.v1_transaction.get_transaction_timeframe_90days_request()
    );
    dispatch(
      REDUX_ACTION.v1_billing.get_billing_timeframe_thru_90days_request()
    )
    // dispatch(
    //   REDUX_ACTION.v1_customerList.get_customer_all_request()
    // );
    return () => {};
  }, [dispatch]);

  useEffect(() => {
    if (filteredDataWallet !== dataSourceUser) {
      setFilteredDataWallet(dataSourceUser);
    }
  }, [dataSourceUser]);

  useEffect(() => {
    setFilteredDataWallet(filteredDataWallet);
}, [filteredDataWallet]);

  const [selectedRow, setSelectedRow] = useState([]);

  return (
    <div className={classes.container}>
      <SearchCustomerWallet
        filteredDataWallet={filteredDataWallet}
        setFilteredDataWallet={setFilteredDataWallet}
        dataSourceWallet={dataSourceUser}
        selectedRow={selectedRow}
      />
      <TableCustomerWallet
        dataSourceWallet={dataSourceUser}
        filteredDataWallet={filteredDataWallet}
        setFilteredDataWallet={setFilteredDataWallet}
        // handleChangePage={handleManageCredit}
        loading={loading}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </div>
  );
};

export default CustomerWalletTable;
