export const selectRow = (setSelectedRowKeys, setSelectedRow, selectedRow, selectedRowKeys, record, selected) => {
    const updatedAllSelectedRow = [...selectedRow];
    const updatedSelectedRowKeys = [...selectedRowKeys];

      if (selected === true) {
        updatedAllSelectedRow.push(record);
        updatedSelectedRowKeys.push(record.key);
      } else {
        const index = updatedAllSelectedRow.findIndex((item) => item.key === record.key);
          if (index > -1) {
            updatedAllSelectedRow.splice(index, 1);
            const keyIndex = updatedSelectedRowKeys.findIndex((key) => key === record.key);
              if (keyIndex > -1) {
                updatedSelectedRowKeys.splice(keyIndex, 1);
              }
          } 
      }
    setSelectedRowKeys(updatedSelectedRowKeys);
    setSelectedRow(updatedAllSelectedRow);
  };