import * as API_CONSTANT from "../constants";

export const getAllVehicle = async() =>
  await fetch(`${API_CONSTANT.adp_v1_fe_path}/vehicle/get/all`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({}),
  }).then((res) => res.json());

export const createVehicle = ({ driver, plate, isTracking, remark, adpMemid }) =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/vehicle/create`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
        driver,
        plate,
        isTracking,
        remark,
        adpMemid
    }),
  }).then((res) => res.json());

  export const getVehicleThruPlate = (plate) =>
    fetch(`${API_CONSTANT.adp_v1_fe_path}/vehicle/state/get/plate`, {
      method: "POST",
      headers: API_CONSTANT.headerMap,
      body: JSON.stringify(plate),
    }).then((res) => res.json());

  export const updateVehicle = ({ vid, items, updatedBy }) =>
    fetch(`${API_CONSTANT.adp_v1_fe_path}/vehicle/update`, {
      method: "POST",
      headers: API_CONSTANT.headers,
      body: JSON.stringify({
          vid,
          items,
          updatedBy
      }),
    }).then((res) => res.json());
