import * as API_CONSTANT from "../constants";

export const getBillingTimeFrameAll = ({ startTime, endTime }) =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/billing/get/timeframe/all`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      startTime,
      endTime,
    }),
  }).then((res) => res.json());

export const updateBilling = ({ billid, items }) =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/billing/update`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      billid,
      items,
    }),
  }).then((res) => res.json());

// export const getInvoiceBilling = ({ uid, startTime, endTime }) =>
//   fetch(`${API_CONSTANT.adp_v1_fe_path}/billing/get/timeframe/uid`, {
//     method: "POST",
//     headers: API_CONSTANT.headers,
//     body: JSON.stringify({
//       uid,
//       startTime,
//       endTime,
//     }),
//   }).then((res) => res.json());

export const getInvoiceBilling = async (billing) => {
  const { mode, ...restData } = billing;

  const requestBody = {
    ...restData,
  };

  if (mode) {
    requestBody.mode = mode;
  }

  const res = await fetch(
    `${API_CONSTANT.adp_v1_fe_path}/billing/get/timeframe/uid`,
    {
      method: "POST",
      headers: API_CONSTANT.headers,
      body: JSON.stringify(requestBody),
    }
  );
  return await res.json();
};
