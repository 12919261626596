export const MODULE_ROUTE = {
  // App Info
  'Root_Page' : '*',
  'Landing_Page' : '*',
  'Dashboard' : '/',
  'Logout': '/logout',
  'Login': '/login',
  'Signup': '/signup',
  'Signup_Pending': '/signup_pending',
  'Signup_Success': '/signup_success',
  'ResetCredential_Forgot_Password': '/reset/forgot_pass',
  'ResetCredential_Forgot_Password_Pending': '/reset/forgot_pass/pending',
  'ResetCredential_Reset_Password': '/reset/reset_pass',
  'ResetCredential_Reset_Password_Success': '/reset/reset_pass/success',
  'Link_Verify': '/verify/link/:link',
  'Link_Unvalidated': '/link/unvalidated'
}