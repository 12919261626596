export const TABLE_CUSTOMER_WALLET_INDEX = {
  key: "key",
  Customer_Id: "memid",
  Name: "name",
  User_Role: "uroleid",
  User_Status: "User_Status",
  Contact: "contact",
  Wallet_Balance: "Wallet_Balance",
  Last_Transaction_Date: "lastTransaction",
  Action: "Action",
};

export const TABLE_CUSTOMER_WALLET_TITLE = {
  key: "key",
  Customer_Id: "Customer ID / Name",
  Name: "Name",
  User_Role: "User Role",
  User_Status: "User Status",
  Contact: "Contact",
  Wallet_Balance: "Wallet Balance",
  Last_Transaction_Date: "Last Transaction Date",
  Action: "Action",
};

export const OPTION_DAYS = {
  LAST_30_DAYS: "Last 30 Days",
  LAST_60_DAYS: "Last 60 Days",
  LAST_90_DAYS: "Last 90 Days",
};

export const OPTION_DAYS_VALUE = {
  LAST_30_DAYS: 30,
  LAST_60_DAYS: 60,
  LAST_90_DAYS: 90,
};

export const OPTION_WALLET_BALANCE = {
  None: "None",
  Ascending: "Ascending",
  Descending: "Descending",
};

// export const OPTION_USER_STATUS = {
//   ACTIVE: "ACTIVE",
//   SUSPENDED: "SUSPENDED",
// };

export const USER_ROLE = { 
  CUSTOMER: "C",
  OPERATOR: "POP"
};

export const USER_ROLE_TITLE = {
  [USER_ROLE.CUSTOMER]: "IPICK",
  [USER_ROLE.OPERATOR]: "MBE Kiosk"
};

export const OPTION_USER_STATUS = {
  Active: 1,
  Suspend: 0,
}

export const OPTION_USER_STATUS_TITLE = {
  [OPTION_USER_STATUS.Active]: { name: "ACTIVE", colorTag: "green", user: 1 },
  [OPTION_USER_STATUS.Suspend]: { name: "SUSPEND", colorTag: "red", user: 2 },
};

export const OPTION_USER_STATUS_ALL = "ALL";
export const OPTION_WALLET_NONE = "NONE";

export const SELECT_OPTION_DAYS = Object.keys(OPTION_DAYS).map((option) => ({
  label: OPTION_DAYS[option],
  value: OPTION_DAYS_VALUE[option],
}));

export const SELECT_OPTION_WALLET = Object.keys(OPTION_WALLET_BALANCE).map(
  (option) => ({
    label: OPTION_WALLET_BALANCE[option],
    value: OPTION_WALLET_BALANCE[option],
  })
);



