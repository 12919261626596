// Third-party Library 
import {
  Card,
  Form,
  Select,
  Input,
  Row,
  Col,
  List
} from 'antd'
import { 
  PlusOutlined, 
  DeleteOutlined,
  DragOutlined 
} from '@ant-design/icons';

import classes from "../../../../common.module.css"

const PICInformation = () => {
  
  const prefixContactSelector =  name => (
      <Form.Item name={[name, 'contactPrefix']} initialValue={'+60'} noStyle>
          <Select style={{ width: 90 }} >
              <Select.Option value="+60">+60</Select.Option>
              <Select.Option value="+62">+62</Select.Option>
          </Select>
      </Form.Item>
  );

  return (
      <Card className={classes['card-container']}>
          <h1 className={classes['card-title']}>PIC INFORMATION</h1>
      
          <Form.List name={'pic'} initialValue={[{}]}>
              {(fields, { add, remove }) => {

                  if(fields.length === 0) {
                      add()
                  }

                  return (
                      <>
                          {
                              fields.map((field, i) => (
                                  <Row gutter={15} key={i}>
                                      <Col span={8}>
                                          <Form.Item label="PIC Name" name={[field.name, "name"]} rules={[{ required: true }]}>
                                              <Input />
                                          </Form.Item>
                                      </Col>
                                      <Col span={10}>
                                          <Form.Item label="PIC Contact" name={[field.name, "contact"]} rules={[{ required: true }]}>
                                              <Input 
                                                  addonBefore={prefixContactSelector(field.name)}
                                              />
                                          </Form.Item>
                                      </Col>
                                      <Col span={2} style={{ display: 'flex', gap: '15px', fontSize: '1.5em' }}>
                                          <PlusOutlined onClick={() => add()} />
                                          {"        "}
                                          {i > 0 && <DeleteOutlined onClick={() => remove(field.name)} />}
                                      </Col>
                                  </Row>
                              ))
                          }
                      </>
                  )
              }}
          </Form.List>
      </Card>
  )

}

export default PICInformation
