import { Drawer, Form, Input, Select, message } from "antd";
import { useEffect, useState } from 'react';
import { useDispatch} from "react-redux";

import { NumericInput, BtnSubmit } from "../../../../../../components";

import * as REDUX_ACTION from "../../../../../../services/redux/actions";

const { TextArea } = Input
const DrawerBusinessAcc = (props) => {
  const { isOpen, setIsOpen, data, dataCM, setIsBizAcc} = props
  
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();
  const [selectedCode, setSelectedCode] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const options=[
    { value: "Johor", label: "Johor" },
    { value: "Kedah", label: "Kedah" },
    { value: "Melaka", label: "Melaka" },
    { value: "Negeri Sembilan", label: "Negeri Sembilan" },
    { value: "Pahang", label: "Pahang" },
    { value: "Penang", label: "Penang" },
    { value: "Perak", label: "Perak" },
    { value: "Perlis", label: "Perlis" },
    { value: "Sabah", label: "Sabah" },
    { value: "Sarawak", label: "Sarawak" },
    { value: "Selangor", label: "Selangor" },
    { value: "Trengganu", label: "Trengganu" },
    { value: "WP Labuan", label: "WP Labuan" },
    { value: "WP Putrajaya", label: "WP Putrajaya" },
    { value: "WP Kuala Lumpur", label: "WP Kuala Lumpur" },
  ]
  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  const handleSave = () => {
    const formData = { ...data, ...form.getFieldsValue() };
      const updateBusiness={
        ...formData,
        type : 'CT01',
        ...form.resetFields()
      }
      
      messageApi.open({
        type: "loading",
        content: "Updating...",
        duration: 0,
      });
      dispatch(REDUX_ACTION.v1_customerList.edit_customer_info_request(updateBusiness,
        () => messageApi.destroy() ));
        setIsOpen(false);
    }

  const ActionButton = (
    <Form.Item style={{ paddingTop: "20px" }}>
      <div>
        <BtnSubmit
          display="inline-block"
          text="Save"
          bgColor="var(--themeBackgroundColor)"
          color="var(--creamColor)"
          padding="9px 18px"
          onClick={handleSave}
        />
        <BtnSubmit
          display="inline-block"
          text="Cancel"
          bgColor="transparent"
          color="var(--bs-gray-600)"
          padding="9px 18px"
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </div>
    </Form.Item>
  );

  return (
    <>
    {contextHolder}
    <Drawer
      title="Enabled Business Account"
      placement="right"
      closable={false}
      width={410}
      onClose={() => setIsOpen(false)}
      footer={ActionButton}
      open={isOpen}
      style={{
        textAlign: "center",
        fontSize: "20px",
      }}
    >
    <Form
      form={form}
      layout="vertical"
      initialValues={{ contactPrefix: "+60" }}
    >
      <Form.Item
          name="memid"
          label={<span>Customer ID</span>}
          rules={[
            {
              required: true,
              message: "Customer ID is required",
            },
          ]}
        >
          <Input 
        //   className={classStyles.inputField} 
          disabled/>
        </Form.Item>
        <Form.Item
          name={['company', 'name']}
          label={<span>Company Name</span>}
          rules={[
            {
              required: true,
              message: "Company Name is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name= {['company', 'address']}
          label={<span>Company Address</span>}
          
        >
            <TextArea style={{height:'30px'}}/>
        </Form.Item>
        <Form.Item
          name={['company', 'postcode']}
          label={<span>Postcode</span>}
        >
          <Input 
          placeholder="Eg: 43300" />
        </Form.Item>
        <Form.Item
          name={['company', 'city']}
          label={<span>City</span>}
        >
          <Input 
            placeholder="Eg: Kuala Lumpur" />
        </Form.Item>
        
        <Form.Item
          name={['company', 'state']}
          label={<span>State</span>}
        >
        <Select
          showSearch
          placeholder='-Select-'
        >
        {options.map((o) => (
          <Select.Option key={o.value} value={o.value}>
            {o.label}
          </Select.Option>
        ))}
        </Select>
        </Form.Item>
      </Form>
    </Drawer>
    </>
  );
};

export default DrawerBusinessAcc;
