// React Thirty Party Library
import { useSelector } from "react-redux";
import { useState } from "react";

// Constants Library
import { TEMPLATE } from "../../../../../../constants";

// Components Library
import { BtnSubmit, TableList } from "../../../../../../components";
import DrawerBulkAssign from "../DrawerBulkAssign"

// Handler Librarys
import { exportHDLR } from "../../../../../../handlers";

// Services Library
import { REDUX as REDUX_UTIL, TIME } from "../../../../../../services/util";

// Redux Actions

// Styles Library
import classes from "../../../../../../components/index.module.css";

const { TABLE_ORDERLIST_INDEX, TABLE_ORDERLIST_TITLE } = TEMPLATE.ORDERLIST;
const { BTN_IMG } = TEMPLATE.BTN;
const { ORDER_SUMMARY_COL } = TEMPLATE.REPORT.ORDER;

const { SearchTableQuery } = TableList;

const { parseExportTime } = TIME;
const { reduxStoreWrapper } = REDUX_UTIL;

const SearchOrder = ({
  filteredData,
  setFilteredData,
  dataSource,
  NavigateBulkUpload,
  selectedRow,
  setIsOpenAddBulkAssign,
}) => {
  
  const orderSorted = useSelector(reduxStoreWrapper("orderState.bySorted"));
  const orderStates = useSelector(reduxStoreWrapper("orderState.byID"));
  const orderStatuses = useSelector(reduxStoreWrapper("orderStatus.byID"));
  const hubs = useSelector(reduxStoreWrapper("hub.byID"));
  const points = useSelector(reduxStoreWrapper("point.byID"));
  const comments = useSelector(reduxStoreWrapper("order.byComment"))
  const operators = useSelector(reduxStoreWrapper("hubOperator.byID"));
  const admins = useSelector(reduxStoreWrapper("user"));
  const orderLog = useSelector(reduxStoreWrapper('orderLog.byID'))
  const vehicle = useSelector(reduxStoreWrapper('vehicle.byPlate'))

  const exportData = selectedRow.length > 0 ? selectedRow : filteredData;

  const options = [
    {
      value: TABLE_ORDERLIST_INDEX.Order_ID,
      label: TABLE_ORDERLIST_TITLE.Order_ID,
      type: "text",
    },
    {
      value: TABLE_ORDERLIST_INDEX.customId,
      label: TABLE_ORDERLIST_TITLE.customerId,
      type: "text",
    },
    {
      value: TABLE_ORDERLIST_INDEX.Invoice,
      label: TABLE_ORDERLIST_TITLE.Invoice,
      type: "text",
    }, 
    {
      value: TABLE_ORDERLIST_INDEX.SO_Printed,
      label: TABLE_ORDERLIST_TITLE.SO_Printed,
      type: "timeframe",
    },
    {
      value: TABLE_ORDERLIST_INDEX.Created_At,
      label: "Custom Date",
      type: "timeframe",
    },
  ];

  return (
    <>
    <div className={classes.headerPage}>
      <SearchTableQuery
        dataSource={dataSource}
        setFilteredData={setFilteredData}
        options={options}
        defaultValue={TABLE_ORDERLIST_INDEX.Order_ID}
        type="ord"
      />
      <div>
        <BtnSubmit
          display={"inline-block"}
          text={"Export"}
          bgColor="transparent"
          color={"#4CAF50"}
          padding="9px 18px"
          onClick={() =>
            exportHDLR.exportWithTemplate({
              data: exportData,
              filename: `OrderSummary_${parseExportTime()}`,
              template: ORDER_SUMMARY_COL({
                redux: {
                  orderSorted,
                  orderStates,
                  orderStatuses,
                  hubs,
                  points,
                  comments,
                  orderLog,
                  operators,
                  admins,
                  vehicle
                },
                exportState: true,
              }),
            })
          }
          showIcon={true}
          icon={BTN_IMG.EXPORT}
          icon_alt={"export"}
        />
        <BtnSubmit
          display={"inline-block"}
          text={"Bulk Assign Vehicle"}
          bgColor="transparent"
          color="var(--themeBackgroundColor)"
          padding="9px 18px"
          border="1px solid var(--themeBackgroundColor)"
          onClick={() => setIsOpenAddBulkAssign(true)}
          showIcon={false}
        />
        <BtnSubmit
          display={"inline-block"}
          text={"Bulk Upload"}
          bgColor="transparent"
          color="var(--themeBackgroundColor)"
          padding="9px 18px"
          border="1px solid var(--themeBackgroundColor)"
          onClick={NavigateBulkUpload}
        />
      </div>
    </div>
    </>
  );
};

export default SearchOrder;
