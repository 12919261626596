import { TEMPLATE } from "../../../../../../constants";


// Componets
import { ButtonLink, ButtonModal } from "../../../../../../components";

// Services Library 


const { MODAL_ACTION } = TEMPLATE.MODAL;
const { ACTIONS_LABEL_TITLE, ACTIONS_LABEL } = TEMPLATE.FORMORDER;
const {ORDER_STATUS} =  TEMPLATE.ORDER

const sort = ACTIONS_LABEL_TITLE[ACTIONS_LABEL.sort_receiver_order];
const proceed = ACTIONS_LABEL_TITLE[ACTIONS_LABEL.process_order];

export const ActionItemsFunc = ({
	record,
	PrintSO,
	UpdateOrder,
	NavigateOrderSort,
	deliveryType,
	setIsOpen,
	setData,
	customer,
	orderlog,
	commentDataHDLR,
	admins,
	setIsOpenAdd,
	setSelectedRecord,
}) => {

	const { ordid, status, sorted } = record
	

	return [{
		key: 0,
		label:  (orderlog.includes("O0201") || orderlog.includes("O0202") || orderlog.includes("O4300") || orderlog.includes("O2000") || orderlog.includes("O4100") || orderlog.includes("O1200")) && 
		<>Proceed order</> || <ButtonLink>Proceed order</ButtonLink>,
		disabled: (orderlog.includes("O0201") || orderlog.includes("O0202") || orderlog.includes("O4300") || orderlog.includes("O2000") || orderlog.includes("O4100") || orderlog.includes("O1200")),
		onClick: () => NavigateOrderSort(ordid, proceed),
	},
	{ 
		key: 1,
		label: (status === "O0100" || status === "O2000" || status === "O0600" || status ==="O4100" || status === "O4300" || ((orderlog.includes("O1300") || orderlog.includes("O0300")) && sorted === 1))
		 &&
			<>Received / Sort order</> || 
			<ButtonLink>Received / Sort order</ButtonLink>,
		disabled: (status === "O0100" || status === "O2000" || status === "O0600" || status ==="O4100" || status === "O4300" || ((orderlog.includes("O1300") || orderlog.includes("O0300")) && sorted === 1)),
		onClick: () => NavigateOrderSort(ordid, sort),
	},
	{
		key: 2,
		label: (status === "O0100" && orderlog.includes("O0100") || ((orderlog.includes("O0501") || orderlog.includes("O0502") || orderlog.includes("O4300") || orderlog.includes("O2000") || orderlog.includes("O4100") || orderlog.includes("O0203") || deliveryType !== 'p2p')))  && 
		<>Check-out from origin point</> || (
		 <ButtonModal
			model={"link"}
			setmodal={MODAL_ACTION.update_status_check_out_from_point}
			type="primary"
			style={{ width: "100%", height: "100%" }}
			onClick={(value) => {
				UpdateOrder({
					order: {
						...record
					},
					statusChange: true,
					orderState: {
						ordid,
						status: ORDER_STATUS.CHECK_OUT_FROM_POINT,
						remark: value.remark,
					},
          update: {
            updatedAt: value && value.initiatedAt,
            updatedBy: admins.memid
          },
					customer: customer,
					attachment: value.attachment
				});
			}}
			extra={{ ordid }} >
				Check-out from origin point
		  </ButtonModal> 
		),
		disabled: (status === "O0100" && orderlog.includes("O0100") || ((orderlog.includes("O0501") || orderlog.includes("O0502") || orderlog.includes("O4300") || orderlog.includes("O0203") || orderlog.includes("O2000") || orderlog.includes("O4100") || deliveryType !== 'p2p')))
	},
	{
		key: 3,
		label: (status === "O0100" && orderlog.includes("O0100") || (orderlog.includes("O0400") || orderlog.includes("O4300") || orderlog.includes("O2000") || orderlog.includes("O4100") || orderlog.includes("O0300") || deliveryType !== 'p2p')) && 
			<>Check-in to origin hub</> || (
			<ButtonModal
			model={"link"}
			setmodal={MODAL_ACTION.update_status_check_in_to_orighub}
			type="primary"
			style={{ width: "100%", height: "100%" }}
			onClick={(value) => {
				UpdateOrder({
					order: {
						...record
					},
					statusChange: true,
					orderState: {
						ordid,
						status: ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB,
						remark: value.remark,
					},
          update: {
            updatedAt: value && value.initiatedAt,
            updatedBy: admins.memid
          },
					customer: customer,
					attachment: value.attachment
				});
			}}
			extra={{ ordid }} >
			Check-in to origin hub
		  </ButtonModal> 
		),
		disabled:  (status === "O0100" && orderlog.includes("O0100") || (orderlog.includes("O0400") || orderlog.includes("O4300") || orderlog.includes("O2000") || orderlog.includes("O4100") || orderlog.includes("O0300") || deliveryType !== 'p2p'))
	},
	{
		key: 4,
		label: (status === "O0100" && orderlog.includes("O0100") || (orderlog.includes("O0400") || orderlog.includes("O4300") || orderlog.includes("O2000") || orderlog.includes("O4100"))) && 
			<>Check-out from origin hub</> || (
			<ButtonModal
			model={"link"}
			setmodal={MODAL_ACTION.update_status_check_out_from_orighub}
			type="primary"
			style={{ width: "100%", height: "100%" }}
			onClick={(value) => {
				UpdateOrder({
					order: {
						...record
					},
					statusChange: true,
					orderState: {
						ordid,
						status: ORDER_STATUS.DEPARTED_FROM_ORIGIN_HUB,
						remark: value.remark,
					},
          update: {
            updatedAt: value && value.initiatedAt,
            updatedBy: admins.memid
          },
					customer: customer,
					attachment: value.attachment
				});
			}}
			extra={{ ordid }} >
			Check-out from origin hub
		  </ButtonModal> 
		),
		disabled:  (status === "O0100" && orderlog.includes("O0100") || (orderlog.includes("O0400") || orderlog.includes("O4300") || orderlog.includes("O2000") || orderlog.includes("O4100")))
	},
	{
		key: 5,
		label: (status === "O0100" && orderlog.includes("O0100") || ((orderlog.includes("O0501") || orderlog.includes("O0502") || orderlog.includes("O0503") || orderlog.includes("O4300") || orderlog.includes("O2000") || orderlog.includes("O4100"))))  && 
		<>Check-in to destination hub</> || (
		 <ButtonModal
			model={"link"}
			setmodal={deliveryType === 'p2p' ? MODAL_ACTION.update_status_check_in_to_dsthub : (deliveryType === 'h2h' ?  MODAL_ACTION.update_status_self_collect : MODAL_ACTION.update_status_pending_delivery)}
			type="primary"
			style={{ width: "100%", height: "100%" }}
			onClick={(value) => {
				UpdateOrder({
					order: {
						...record
					},
					statusChange: true,
					orderState: {
						ordid,
						status: deliveryType === 'p2p' ? ORDER_STATUS.ARRIVED_AT_DEST_HUB : (deliveryType === 'h2h' ? ORDER_STATUS.PENDING_SELF_COLLECTION : ORDER_STATUS.PENDING_DOORSTEP_DELIVERY),
						remark: value.remark,
					},
          update: {
            updatedAt: value && value.initiatedAt,
            updatedBy: admins.memid
          },
					customer: customer,
					attachment: value.attachment
				});
			}}
			extra={{ ordid }} >
				Check-in to destination hub
		  </ButtonModal> 
		),
		disabled: (status === "O0100" && orderlog.includes("O0100") || ((orderlog.includes("O0501") || orderlog.includes("O0502") || orderlog.includes("O0503") || orderlog.includes("O4300") || orderlog.includes("O2000") || orderlog.includes("O4100"))))
	},
	{
		key: 6,
		label: (status === "O0100" && orderlog.includes("O0100") || ((orderlog.includes("O0501") || orderlog.includes("O0502") || orderlog.includes("O4300") || orderlog.includes("O2000") || orderlog.includes("O4100") || orderlog.includes("O1400") || deliveryType !== 'p2p')))  && 
		<>Check-out from destination hub</> || (
		 <ButtonModal
			model={"link"}
			setmodal={MODAL_ACTION.update_status_check_out_from_dsthub}
			type="primary"
			style={{ width: "100%", height: "100%" }}
			onClick={(value) => {
				UpdateOrder({
					order: {
						...record
					},
					statusChange: true,
					orderState: {
						ordid,
						status: ORDER_STATUS.PENDING_OUTBOUND_TO_POINT,
						remark: value.remark,
					},
          update: {
            updatedAt: value && value.initiatedAt,
            updatedBy: admins.memid
          },
					customer: customer,
					attachment: value.attachment
				});
			}}
			extra={{ ordid }} >
				Check-out from destination hub
		  </ButtonModal> 
		),
		disabled: (status === "O0100" && orderlog.includes("O0100") || ((orderlog.includes("O0501") || orderlog.includes("O0502") || orderlog.includes("O4300") || orderlog.includes("O2000") || orderlog.includes("O4100") || orderlog.includes("O1400") || deliveryType !== 'p2p')))
	},
	{
		key: 7,
		label: (status === "O0100" && orderlog.includes("O0100") || ((orderlog.includes("O0501") || orderlog.includes("O0502") || orderlog.includes("O1500") || orderlog.includes("O4300") || orderlog.includes("O2000") || orderlog.includes("O4100") || deliveryType !== 'p2p')))  && 
		<>Check-in to destination point</> || (
		 <ButtonModal
			model={"link"}
			setmodal={deliveryType === 'h2h' ?  MODAL_ACTION.update_status_self_collect : MODAL_ACTION.update_status_pending_delivery}
			type="primary"
			style={{ width: "100%", height: "100%" }}
			onClick={(value) => {
				UpdateOrder({
					order: {
						...record
					},
					statusChange: true,
					orderState: {
						ordid,
						status: ORDER_STATUS.PENDING_SELF_COLLECTION_POINT,
						remark: value.remark,
					},
          update: {
            updatedAt: value && value.initiatedAt,
            updatedBy: admins.memid
          },
					customer: customer,
					attachment: value.attachment
				});
			}}
			extra={{ ordid }} >
				Check-in to destination point
		  </ButtonModal> 
		),
		disabled: (status === "O0100" && orderlog.includes("O0100") || ((orderlog.includes("O0501") || orderlog.includes("O0502") || orderlog.includes("O1500") || orderlog.includes("O4300") || orderlog.includes("O2000") || orderlog.includes("O4100") || deliveryType !== 'p2p')))
	},
	{
		key: ORDER_STATUS.FULFILLED,
		label:  (status === "O0100" && orderlog.includes("O0100") || (orderlog.includes("O0600") || orderlog.includes("O4300") || orderlog.includes("O2000") || orderlog.includes("O4100"))) && 
		<>Received by receiver</> || (
			<ButtonModal
			model={"link"}
			setmodal={MODAL_ACTION.update_status_received_by_receiver}
			type="primary"
			style={{ width: "100%", height: "100%" }}
			onClick={(value) => {
				UpdateOrder({
					order: {
						...record
					},
					statusChange: true,
					orderState: {
						ordid,
						status: ORDER_STATUS.FULFILLED,
						remark: value.remark,
					},
          update: {
            updatedAt: value && value.initiatedAt,
            updatedBy: admins.memid
          },
					customer: customer,
					attachment: value.attachment
				});
			}}
			extra={{ ordid }} >
			Received by receiver
		  </ButtonModal>),
		disabled: (status === "O0100" && orderlog.includes("O0100") || (orderlog.includes("O0600") || orderlog.includes("O4300") || orderlog.includes("O2000") || orderlog.includes("O4100")))
	},
	{
		key: ORDER_STATUS.PARKED,
		label: (status === "O0100" && orderlog.includes("O0100") || (orderlog.includes("O4200") || orderlog.includes("O4100") || orderlog.includes("O4300") || orderlog.includes("O2000"))) && 
			<>Parked order</> ||
			<ButtonModal
			model={"link"}
			setmodal={MODAL_ACTION.update_status_park_order}
			type="primary"
			style={{ width: "100%", height: "100%" }}
			onClick={(value) => {
				UpdateOrder({
					order: {
						...record
					},
					statusChange: true,
					orderState: {
						ordid,
						status: ORDER_STATUS.PARKED,
						remark: value.remark,
					},
          update: {
            updatedAt: value && value.initiatedAt,
            updatedBy: admins.memid
          },
					attachment: value.attachment
				});
			}}
			extra={{ ordid }} >
			Parked order
		  </ButtonModal>,
		disabled: (status === "O0100" && orderlog.includes("O0100") || (orderlog.includes("O4200") || orderlog.includes("O4100") || orderlog.includes("O4300") || orderlog.includes("O2000"))),
	},
	{
		key: ORDER_STATUS.CANCELLED,
		label: (status < ORDER_STATUS.PENDING_SELF_LODGE && (
			<ButtonModal
				model={"link"}
				setmodal={MODAL_ACTION.update_status_cancel_order}
				type="primary"
				style={{ width: "100%", height: "100%" }}
				extra={{ ordid }}
				onClick={(value) => {
					UpdateOrder({
						order: {
							...record
						},
						statusChange: true,
						orderState: {
							ordid,
							status: ORDER_STATUS.CANCELLED,
							remark: value.remark,
						},
            update: {
              updatedAt: value && value.initiatedAt,
              updatedBy: admins.memid
            },
						attachment: value.attachment
					});
				}}
			>
				Cancel order
			</ButtonModal>
		)) || <>Cancel order</>,
		disabled: !(status < ORDER_STATUS.PENDING_SELF_LODGE),
	},
	{
		key: 8,
		label: (orderlog.includes('O2000') || orderlog.includes("O4100") || orderlog.includes('O4300')) && 
		<>Print shipment order</> ||
		<ButtonLink>Print shipment order</ButtonLink>,
		disabled: (orderlog.includes('O2000') || orderlog.includes("O4100") || orderlog.includes('O4300')),
		onClick: () => {
			PrintSO(ordid);
		}
	},
	{
		key: 9,
		label: !(status === "O0203" || status === "O0300" || status === "O0400" || status ==="O0503" || status === "O1300" || status === "O1400")
		 &&
			<>Assign Vehicle</> || 
			<ButtonLink>Assign Vehicle</ButtonLink>,
		disabled: !(status === "O0203" || status === "O0300" || status === "O0400" || status ==="O0503" || status === "O1300" || status === "O1400"),
		onClick: () => {
			setIsOpenAdd(true)
			setSelectedRecord(record)
		}
	},
	{
		key: 10,
		label: <ButtonLink>Add comment</ButtonLink>,
		onClick: () => {
      commentDataHDLR()
      setIsOpen(true)
      setData({ordid, status})
    },
	}
]}
