// React Thirty Party Library
import { Menu } from "antd";

// Constants Library

// Components Library

// Handler Library

// Services Library

// Redux Actions

// Styles Library
import "../../../index.css";

const SidebarMenu = (props) => {
  const { onClick, sidebarItems } = props;

  const MenuComponent = (m) => ({
    key: m.key,
    disabled: m.disabled,
    type: m.type,
    icon: m.icon,
    onClick: onClick,
    label: m.label,
  });

  return (
    <Menu
      className="sidebar-menu"
      mode="inline"
      onClick={onClick}
      items={sidebarItems.map((item) => {
        const { children = [] } = item;

        return (
          (children &&
            children.length && {
              ...MenuComponent(item),
              children: item.children.map((m) => MenuComponent(m)),
            }) ||
          MenuComponent(item)
        );
      })}
    />
  );
};

export default SidebarMenu;
