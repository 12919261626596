// React Thirty Party Library
import { Layout as LayoutAntd } from "antd";

// Constants Library

// Components Library
import { Sidebar, Header, Content } from "./components/index";

// Handler Library

// Services Library

// Redux Actions

// Styles Library

const LayoutContainer = (props) => {
  return (
    <LayoutAntd>
      <Sidebar />
      <LayoutAntd>
        <Header />
        <Content>{props.children}</Content>
      </LayoutAntd>
    </LayoutAntd>
  );
};

export default LayoutContainer;
