// Third Party Lib
import React, { useState } from "react";
import { Upload, Button, message } from "antd";

// Handler
import { uploadHDLR } from "../../../../handlers";

const { getBase64, put2AssetS3CustomeRequest } = uploadHDLR;

const JNAttachmentUpload = ({ setUploadTransaction }) => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const validateFileType = (file) => {
    const fileTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
    ];
    return fileTypes.includes(file.type);
  };

  const uploadProps = {
    beforeUpload: (file) => {
      if (!validateFileType(file)) {
        message.error(`File type ${file.type} is not supported.`);
        return false;
      } 
      return true;
    },
  };

  const handleChange = (info) => {
    const { status, originFileObj, response = {}} = info.file;

    const validFiles = info.fileList.filter(item => validateFileType(item));
    setFileList(validFiles);

    if (status === "uploading") {
      setLoading(true);
      return;
    }
    if (status === "done") {
      getBase64(originFileObj, () => {
        const { Location: url } = response;
        setLoading(false);
        setUploadTransaction(p => [...p, {attachment: url}]);
      });
    } else {
      setLoading(false);
    }
  };

  return (
    <> 
      <Upload
        {...uploadProps}
        onChange={handleChange}
        customRequest={put2AssetS3CustomeRequest}
        listType="text"
        fileList={fileList}
      >
        <Button
          loading={loading}
          type="primary"
          style={{
            height: "45px",
          }}
        >
          Click to Upload
        </Button>
      </Upload>
    </>
  );
};

export default JNAttachmentUpload;
