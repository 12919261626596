import * as ActionTypes from "../action-types";

// Constants & Config

// Services

const STYLES_INITIAL_STATE = {
  loading: {
    general: false,
    order_submit: false,
    order_list: false,
    order_update: false,
    orderLog: false,
    billing: false,
    transaction_list: false,
    customer_wallet: false,
    park_order: false,
    accountManager: false,
    customer: false,
    hubOperator: false,
    hub: false,
    order_comment: false,
    zone: false,
    priceList: false,
    vehicle: false
  },
};

export const style = (state = STYLES_INITIAL_STATE, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.SET_LOADING_VEHICLE: {
      newState.loading.vehicle = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_VEHICLE: {
      newState.loading.vehicle = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_GENERAL: {
      newState.loading.general = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_GENERAL: {
      newState.loading.general = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_ORDER_SUBMIT: {
      newState.loading.order_submit = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_ORDER_SUBMIT: {
      newState.loading.order_submit = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_ORDER_LIST: {
      newState.loading.order_list = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_ORDER_LIST: {
      newState.loading.order_list = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_ORDER_UPDATE: {
      newState.loading.order_update = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_ORDER_UPDATE: {
      newState.loading.order_update = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_ORDER_COMMENT: {
      newState.loading.order_comment = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_ORDER_COMMENT: {
      newState.loading.order_comment = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_ORDER_LOG: {
      newState.loading.orderLog = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_ORDER_LOG: {
      newState.loading.orderLog = false;
      return newState;
    }
    case ActionTypes.SET_LOADING_TRANSACTION_LIST: {
      newState.loading.transaction_list = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_TRANSACTION_LIST: {
      newState.loading.transaction_list = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_BILLING_LIST: {
      newState.loading.billing = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_BILLING_LIST: {
      newState.loading.billing = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_CUSTOMER_WALLET: {
      newState.loading.customer_wallet = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_CUSTOMER_WALLET: {
      newState.loading.customer_wallet = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_PARK_ORDER: {
      newState.loading.park_order = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_PARK_ORDER: {
      newState.loading.park_order = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_ACCOUNT_MANAGER: {
      newState.loading.accountManager = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_ACCOUNT_MANAGER: {
      newState.loading.accountManager = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_CUSTOMER_LIST: {
      newState.loading.customer = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_CUSTOMER_LIST: {
      newState.loading.customer = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_HUB_OPERATOR: {
      newState.loading.hubOperator = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_HUB_OPERATOR: {
      newState.loading.hubOperator = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_HUB: {
      newState.loading.hub = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_HUB: {
      newState.loading.hub = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_ZONE: {
      newState.loading.zone = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_ZONE: {
      newState.loading.zone = false;
      return newState;
    }
    case ActionTypes.SET_LOADING_ZONE_PRICELIST: {
      newState.loading.priceList = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_ZONE_PRICELIST: {
      newState.loading.priceList = false;
      return newState;
    }


    default: {
      return state;
    }
  }
};
