import * as API from "../../api";
import * as ActionTypes from "../action-types";

import { message } from "antd";

// Constants and Config
import { TIME } from "../../util";

// Services

// Redux Action
import { setLoadingBillingList, unsetLoadingBillingList } from "./style";

// Neccessary Actions

const { lastNDay, latestTime } = TIME;

export const get_billing_invoice_uid_request = (billing, cb) => (dispatch) => {
  API.getInvoiceBilling(billing).then((info) => {
    const { status, billings, msg } = info;

    if (status === 200) {
      dispatch(get_invoice_billing_success(billings));
    } else {
      message.warning(msg);
    }
  }).finally(() => {
    cb()
  });
};

export const get_billing_timeframe_thru_90days_request = () => (dispatch) => {
  dispatch(get_billing_timeframe_all_request(lastNDay(90), latestTime));
};

export const get_billing_timeframe_all_request =
  (startTime, endTime) => (dispatch) => {
    dispatch(setLoadingBillingList());

    API.getBillingTimeFrameAll({
      startTime,
      endTime,
    })
      .then((info) => {
        const { status, msg, billings } = info;

        if (status === 200) {
          dispatch(get_billing_timeframe_all_success(billings));
          message.success("Get Billing List Data Successfully");
        } else {
          message.warning(msg);
        }
      })
      .finally(() => {
        dispatch(unsetLoadingBillingList());
      });
  };

const get_billing_timeframe_all_success = (billing) => {
  return {
    type: ActionTypes.GET_BILLING_LIST,
    billing,
  };
}; 

export const create_billing_success = (billing) => {
  return {
    type: ActionTypes.CREATE_BILLING,
    billing
  };
};

export const update_billing_success = (billing) => {
  return {
    type: ActionTypes.UPDATE_BILLING,
    billing
  };
};

const get_invoice_billing_success = (billing) => {
  return {
    type: ActionTypes.GET_INVOICE_BILLING,
    billing,
  };
};
 