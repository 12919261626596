// React Thirty Party Library

// Constants Library
import { ROOT_CONSTANT } from "../../../../constants";

// Components Library

// Handler Library

// Services Library

// Redux Actions

// Styles Library
import "../../../index.css";

const Logo = () => {
  return (
    <>
      <div className="sidebar-logo-container">
        <img
          src={ROOT_CONSTANT.IMG.ICON.icon_logo}
          alt="IPick Logo"
          className="sidebar-logo"
        />
      </div>
    </>
  );
};
export default Logo;
