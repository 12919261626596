import { Drawer, Form, Input, Select, message } from "antd";
import { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { NumericInput, BtnSubmit } from "../../../../../../components";

import { REDUX as REDUX_UTIL, TIME } from "../../../../../../services/util";
import { APP_CONFIG } from "../../../../../../config";

import { encryptFE } from "../../../../../../services/util";
import * as REDUX_ACTION from "../../../../../../services/redux/actions";

import classStyles from "./index.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;

const DrawerAddHub = (props) => {
  const { isOpen, setIsOpen } = props
  
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [selectedHub, setSelectedHub] = useState(null);

  const hub = Object.values(useSelector(reduxStoreWrapper("hub.byID")));

 
  const prefixContactSelector = (
    <Form.Item name="contactPrefix" noStyle>
      <Select style={{ width: 70 }}>
        <Select.Option value="+60">+60</Select.Option>
      </Select>
    </Form.Item>
  );

  const handleHub = (values) => {
    const selectedValues = values.map((value) => {
      const [code, hubid] = value.split(", ");
      return { code: code, hubid: hubid };
    });

    setSelectedHub(selectedValues.map((item) => item));
  };


  const handleSave = () => {
    form
      .validateFields()
      .then(() => {
        const formData = form.getFieldsValue(); 

        const hubArray = selectedHub.map((s) => ({
          code: s.code,
          hubid: s.hubid,
          createdAt: TIME.latestTime,
        }));

        const { contact, contactPrefix } = formData

        const opData = {
          ...formData,
          hubs: hubArray,
          contact: `${contactPrefix}-${contact}`,
        };

        delete opData.contactPrefix
        delete opData.confirmPassword
        delete opData.hub

        messageApi.open({
          type: "loading",
          content: "Creating...",
          duration: 0,
        });

        dispatch(REDUX_ACTION.v1_hubOperator.create_hub_operators_request(opData, () => messageApi.destroy()));
      
        form.resetFields();
        setIsOpen(false);
      }).catch(() => {
        message.error("Please fill the required fields");
      });
  };

  const ActionButton = (
    <Form.Item style={{ paddingTop: "20px" }}>
      <div>
        <BtnSubmit
          display="inline-block"
          text="Add"
          bgColor="var(--themeBackgroundColor)"
          color="var(--creamColor)"
          padding="9px 18px"
          onClick={handleSave}
        />
        <BtnSubmit
          display="inline-block"
          text="Add & create another"
          bgColor="transparent"
          color="var(--themeBackgroundColor)"
          padding="9px 18px"
          border="1px solid var(--themeBackgroundColor)"
        />
        <BtnSubmit
          display="inline-block"
          text="Cancel"
          bgColor="transparent"
          color="var(--bs-gray-600)"
          padding="9px 18px"
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </div>
    </Form.Item>
  );

  return (
    <>
    {contextHolder}
    <Drawer
      title="Add Hub Operator"
      placement="right"
      closable={false}
      width={410}
      onClose={() => setIsOpen(false)}
      footer={ActionButton}
      open={isOpen}
      style={{
        textAlign: "center",
        fontSize: "20px",
      }}
    >
    <Form
      form={form}
      layout="vertical"
      initialValues={{ contactPrefix: "+60" }}
    >
        <Form.Item
          name="name"
          label={<span>Name</span>}
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
        >
          <Input className={classStyles.inputField} />
        </Form.Item>
        <Form.Item
          name="email"
          label={<span>Email</span>}
          rules={[
            {
              required: true,
              message: "Email is required",
              type: "email",
            },
          ]}
          
        >
          <Input className={classStyles.inputField} />
        </Form.Item>
        <Form.Item
          name="contact"
          label={<span>Contact</span>}
          rules={[
            {
              required: true,
              message: "Contact is required",
            },
          ]}
        >
          <NumericInput
            addonBefore={prefixContactSelector}
          />
        </Form.Item>
        <Form.Item
          name="hub"
          label={<span>Assigned Hub</span>}
          rules={[
            {
              required: true,
              message: "select at least one option",
            },
          ]}
        >
        <Select
          showSearch
          mode="multiple"
          maxTagCount="responsive"
          onChange={handleHub}
        >
          {Array.isArray(hub) && hub.map((o) => (
            <Select.Option key={o.hubid} value={`${o && o.code}, ${o && o.hubid}`}>
              {o.code}
            </Select.Option>
          ))} 
        </Select>
        </Form.Item>
        <Form.Item
          name="password"
          label={<span>Password</span>}
          rules={[
            {
              required: true,
              message: "Password is required",
              type: "password",
            },
          ]}
          
        >
          <Input.Password className={classStyles.inputField}/>
        </Form.Item>
        <Form.Item
        name="confirmPassword"
        label={<span>Confirm Password</span>}
        rules={[
          {
            required: true,
            message: "Password is required",
            type: "password",
          },
        ]}
        
      >
        <Input.Password className={classStyles.inputField}/>
      </Form.Item>
      </Form>
    </Drawer>
    </>
  );
};

export default DrawerAddHub;
