import { Drawer, Form, Select, message } from "antd";
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { BtnSubmit } from "../../../../../../components";

import { REDUX as REDUX_UTIL } from "../../../../../../services/util";
import * as REDUX_ACTION from "../../../../../../services/redux/actions";

import classStyles from "./index.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;

const DrawerAssign = (props) => {
  const { isOpen, setIsOpen,  handleAssign, data} = props
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const order = useSelector(reduxStoreWrapper("order.allIDs"));
  const plate = useSelector(reduxStoreWrapper("vehicle.byID"));
  const user = useSelector(reduxStoreWrapper("user"));
  const admid = user.memid;

  const optionOrderId = order.map(o => {
    return {
      label: o,
      value: o,
    }
  })

  const optionVehiclePlate = Object.values(plate)
    .map(vehicle => vehicle.plate)
    .filter((value, index, self) => self.indexOf(value) === index)
    .map(value => ({
      label: value,
      value: value,
  }));
  
  const orderAssignVehicle = () => {
    const formData = form.getFieldsValue()
    const { ordid, plate } = formData;

    messageApi.open({
      type: "loading",
      content: "Assign Vehicle...",
      duration: 0,
    });
    
    const orders = Array.isArray(ordid) ? ordid : [ordid];

    dispatch(
      REDUX_ACTION.v1_order.order_assign_vehicle({ orders , v_plate: plate, adpMemid: admid}, () => {
        messageApi.destroy();
      })
    );
  };

  useEffect(() => {
    data && form.setFieldValue('ordid', data.ordid);
  }, [data]);
  
  const ActionButton = (
    <Form.Item style={{ paddingTop: "20px" }}>
      <div>
        <BtnSubmit
          display="inline-block"
          text="Assign"
          bgColor="var(--themeBackgroundColor)"
          color="var(--creamColor)"
          padding="9px 18px"
          onClick={() => {
            orderAssignVehicle()
            setIsOpen(false);
          }}
        />
        <BtnSubmit
          display="inline-block"
          text="Cancel"
          bgColor="transparent"
          color="var(--bs-gray-600)"
          padding="9px 18px"
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </div>
    </Form.Item>
  );

  return (
    <>
    {contextHolder}
    <Drawer
      title="Assign Vehicle"
      placement="right"
      closable={false}
      width={410}
      onClose={() => setIsOpen(false)}
      footer={ActionButton}
      open={isOpen}
      style={{
        textAlign: "center",
        fontSize: "20px",
      }}
    >
    <Form
      form={form}
      layout="vertical"
      initialValues={data}
    >
        <label htmlFor="ordid" className={classStyles.labelInput}>
            Order ID
        </label>
        <Form.Item
          name="ordid"
          placeholder="Search to Select"
          className={classStyles.materialTextField} 
        >
          <Select
            showSearch
            options={optionOrderId}  className={classStyles.optionDrawer}
          />
        </Form.Item>
        
        <label htmlFor="plate"  className={classStyles.labelInput}>
            Vehicle Plate
        </label>
        <Form.Item
          name="plate"
          rules={[
            {
              required: true,
              message: "Vehicle plate is required",
            },
          ]}
          className={classStyles.materialTextField} 
        >
          
          <Select
            options={optionVehiclePlate}  className={classStyles.optionDrawer}
          />
        </Form.Item> 
    </Form>
    </Drawer>
    </>
  );
};

export default DrawerAssign;
