// React Thirty Party Library
import { useSelector } from "react-redux";

// Constants Library
import { TEMPLATE, COMMON_FEATURES , ROUTES } from "../../../../constants";

// Components Library
import { TableList, BtnSubmit } from "../../../../components";

// Handler Librarys
import { exportHDLR } from "../../../../handlers";

// Services Library 
import { TIME, REDUX as REDUX_UTIL } from "../../../../services/util";

// Redux Actions 

// Styles Library
import classes from "../../../../components/index.module.css";

const { TABLE_TRANSACTION_INDEX, TABLE_TRANSACTION_TITLE } =
  TEMPLATE.TRANSACTIONLIST;
const { BTN_IMG } = TEMPLATE.BTN;
const { TRANSACTION_SUMMARY_COL } = TEMPLATE.REPORT.TXN;


const { parseExportTime } = TIME;
const { reduxStoreWrapper } = REDUX_UTIL;

const SearchTransaction = (props) => {
  const {
    dataSourceTransaction,
    filteredDataTransaction,
    setFilteredDataTransaction,
    selectedRow
  } = props;

  const billing = Object.values(useSelector(reduxStoreWrapper("billing.byID")))

  const template = TRANSACTION_SUMMARY_COL({
    redux: {
      billing
    },
    exportState: true,
  });

  const options = [
    {
      value: TABLE_TRANSACTION_INDEX.Transaction_Id,
      label: TABLE_TRANSACTION_TITLE.Transaction_Id,
      type: "text",
    },
    {
      value: TABLE_TRANSACTION_INDEX.Billing_ID,
      label: TABLE_TRANSACTION_TITLE.Billing_ID,
      type: "text",
    },
    {
      value: TABLE_TRANSACTION_INDEX.Transacted_At,
      label: "Custom Date",
      type: "timeframe",
    },
  ];

  const exportData = selectedRow.length > 0 ? selectedRow : filteredDataTransaction;

  return (
    <div className={classes.headerPage}>
      <TableList.SearchTableQuery
        dataSource={dataSourceTransaction}
        setFilteredData={setFilteredDataTransaction}
        options={options}
        defaultValue={TABLE_TRANSACTION_INDEX.Transaction_Id}
        type="tnx"
      />
      <div>
        <BtnSubmit
          display="inline-block"
          text="Export"
          bgColor="transparent"
          color="#4CAF50"
          padding="9px 18px"
          onClick={() =>
            exportHDLR.exportWithTemplate({
              data: exportData,
              filename: `TransactionSummary_${parseExportTime()}`,
              template: template,
            })
          }
          showIcon={true}
          icon={BTN_IMG.EXPORT}
          icon_alt={"export"}
        />
      </div>
    </div>
  );
};

export default SearchTransaction;
