import { useDispatch } from "react-redux";
import { message } from "antd";
  
import { ButtonLink } from "../../../../components";
import { TEMPLATE, COMMON_FEATURES } from "../../../../constants";
import { REDUX } from "../../../../services/util";
import * as REDUX_ACTION from "../../../../services/redux/actions"


  export const ActionItems = (record, handleEditZone) => {

    return [
        {
            key: 0,
            label: (
                <ButtonLink >
                    Edit
                </ButtonLink>
            ),
            onClick: () => handleEditZone(record)
        },
    ]
}
