// Third Party Lib

// Config
import { APP_CONFIG } from '../../config'

// Services
import { AWSSDK } from '../../services/cloud'

const {
    AWS_URI
} = APP_CONFIG

const {
    S3GetObject
} = AWSSDK

export const getOrderTemplateXLSXFromS3 = () => 
    S3GetObject(
        AWS_URI.s3.asset.bucket,
        AWS_URI.s3.asset.key.orderbulk
    )
    .then(data =>  new Response(data.Body).blob())
    .then(blob => {
        const blobUrl = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = "Order_Bulk_Upload_Template.xlsx";

        link.click();

        window.URL.revokeObjectURL(blobUrl);
    })