import { MODAL } from "..";
import { ROOT_CONSTANT } from "../../../constants";

const {
	icon_logout_modal,
	icon_user_yes,
	icon_user_no,
	icon_arrow_bottom,
	icon_arrow_right,
	icon_cancel_order,
	icon_cancel_trip_manifest,
	icon_park_order,
	icon_park_resolved,
	icon_checklist,
	icon_comment,
	icon_link,
	icon_update_status,
  icon_confirmation,
  icon_hide_hub,
  icon_show_hub,
  icon_deactivate_zone
} = ROOT_CONSTANT.IMG.ICON.MODAL;

const { IMG_MY_ACCOUNT_DEFAULT } = ROOT_CONSTANT.IMG.ICON;

const ordid = "ORD0001"; 

export const MODAL_ACTION = {
	delete_person: "delete_person",
	logout: "logout",
	bus_info: "bus_info",
	load_to_bus: "load_to_bus",
	unload_from_bus: "unload_from_bus",
	bulk_load_to_bus: "bulk_load_to_bus",
	bulk_unload_from_bus: "bulk_unload_from_bus",
	park_order: "park_order",
	park_resolved: "park_resolved",
	suspend_user: "suspend_user",
	reactivate_user: "reactivate_user",
	hide_hub : "hide_hub",
	show_hub : "show_hub",
	hide_point : "hide_point",
	show_point : "show_point",
	add_comment: "add_comment",
	cancel_order: "cancel_order",
	confirm_sorting: "confirm_sorting",
	verify_customer: "verify_customer",
	customer_remark: "customer_remark",
	business_account:'business_account',
	personal_account:'personal_account',
	postpaid:'postpaid',
	prepaid :'prepaid',
	link_account_manager_id: "link_account_manager_id",
	update_status_self_lodge: "update_status_self_lodge",
	update_status_pickup: "update_status_pickup",
	update_status_self_lodge_point: "update_status_self_lodge_point",
	update_status_sort: "update_status_sort",
	update_status_check_out_from_point: "update_status_check_out_from_point",
	update_status_check_in_to_orighub: "update_status_check_in_to_orighub",
	update_status_check_out_from_orighub: "update_status_check_out_from_orighub",
	update_status_check_in_to_dsthub: "update_status_check_in_to_dsthub",
	update_status_check_out_from_dsthub: "update_status_check_out_from_dsthub",
	update_status_sort_point: "update_status_sort_point",
	update_status_load_to_bus: "update_status_load_to_bus",
	update_status_self_collect: "update_status_self_collect",
	update_status_pending_delivery: "update_status_pending_delivery",
	update_status_received_by_receiver: "update_status_received_by_receiver",
	update_status_mark_as_resolved: "update_status_mark_as_resolved",
	update_status_park_order: "update_status_park_order",
	update_status_cancel_order: "update_status_cancel_order",
	confirmation_update_status_self_lodge: "confirmation_update_status_self_lodge",
	confirmation_update_status_pickup: "confirmation_update_status_pickup",
	confirmation_update_status_sort: "confirmation_update_status_sort",
	confirmation_update_status_load_to_bus: "confirmation_update_status_load_to_bus",
	confirmation_update_status_self_collect: "confirmation_update_status_self_collect",
	confirmation_update_status_pending_delivery: "confirmation_update_status_pending_delivery",
	confirmation_update_status_received_by_receiver: "confirmation_update_status_received_by_receiver",
	confirmation_update_status_mark_as_resolved: "confirmation_update_status_mark_as_resolved",
	confirmation_update_status_park_order: "confirmation_update_status_park_order",
	confirmation_update_status_cancel_order: "confirmation_update_status_cancel_order",
	activate_zone_price: "activate_zone_price",
	deactivate_zone_price: "deactivate_zone_price",
	confirmation_mark_as_active_vehicle: "confirmation_mark_as_active_vehicle",
	confirmation_mark_as_inactive_vehicle: "confirmation_mark_as_inactive_vehicle",
};

export const MODALS = {
	[MODAL_ACTION.logout]: {
		img: icon_logout_modal,
		title: "Confirm Logout",
		content: "Are you sure you want to logout?",
	},
	[MODAL_ACTION.load_to_bus]: {
		img: icon_arrow_right,
		title: "Load To Bus",
		content: ({ ordid }) =>
			`You are about to load order ${ordid} into bus. Are you sure you want to proceed?`,
	},
	[MODAL_ACTION.unload_from_bus]: {
		img: icon_arrow_bottom,
		title: "Unload From Bus",
		content: ({ ordid }) =>
			`You are about to unload order ${ordid} from bus. Are you sure you want to proceed?`,
	},
	[MODAL_ACTION.bulk_load_to_bus]: {
		img: icon_arrow_right,
		title: "Bulk Load To Bus",
		content: ({ ordsum }) =>
			`You are about to load ${ordsum} orders from bus. Are you sure you want to proceed?`,
	},
	[MODAL_ACTION.bulk_unload_from_bus]: {
		img: icon_arrow_bottom,
		title: "Bulk Unload From Bus", 
		content: ({ ordsum }) =>
			`You are about to unload ${ordsum} orders from bus. Are you sure you want to proceed?`,
	},
	[MODAL_ACTION.park_order]: {
		img: icon_park_order,
		title: "Park Order",
		content: ({ ordid }) =>
			`You are about to park order ${ordid}. Please add a remark to proceed further`,
	},
	[MODAL_ACTION.park_resolved]: {
		img: icon_park_resolved,
		title: "Park Resolved",
		content: ({ ordid }) =>
			`You are about to resolve park order ${ordid}. Please add a remark to proceed further`,
	},
	[MODAL_ACTION.suspend_user]: {
		img: icon_user_no,
		title: "Suspend User",
		content: ({ memid, name }) =>
			`You are about to suspend user ${memid}/${name}. Are you sure you want to proceed?`,
	},
	[MODAL_ACTION.reactivate_user]: {
		img: icon_user_yes,
		title: "Reactivate User",
		content: ({ memid, name }) =>
			`You are about to reactivate user ${memid}/${name}. Are you sure you want to proceed?`,
	},
	[MODAL_ACTION.hide_hub]:{
		img: icon_hide_hub,
		title: "Hide Hub",
		content: ({ name, code }) =>
		     `You are about to hide ${code}/${name} hub. Are you sure you want to proceed?`,
	},
	[MODAL_ACTION.show_hub]:{
		img: icon_show_hub,
		title: "Show Hub",
		content: ({ name, code }) =>
		     `You are about to show ${code}/${name} hub. Are you sure you want to proceed?`,
	},
	[MODAL_ACTION.hide_point]:{
		img: icon_hide_hub,
		title: "Hide Point",
		content: ({ name, code }) =>
		     `You are about to hide ${code}/${name} point. Are you sure you want to proceed?`,
	},
	[MODAL_ACTION.show_point]:{
		img: icon_show_hub,
		title: "Show Point",
		content: ({ name, code }) =>
		     `You are about to show ${code}/${name} point. Are you sure you want to proceed?`,
	},
	[MODAL_ACTION.add_comment]: {
		img: IMG_MY_ACCOUNT_DEFAULT,
		title: "Add Comment",
		content: [],
	},
	[MODAL_ACTION.cancel_order]: {
		img: icon_cancel_order,
		title: "Cancel Order",
		content: ({ ordid }) =>
			`You are about to cancel order ${ordid}. Please add a remark to proceed further`,
	},
	[MODAL_ACTION.confirm_sorting]: {
		img: icon_checklist,
		title: "Confirm Sorting",
		content: ({ ordid }) =>
			`You are about to confirm sorting ${ordid}. Are you sure you want to proceed?`,
	},
	[MODAL_ACTION.verify_customer]: {
		img: icon_user_yes,
		title: "Verify Customer",
		content: ({ memid, name }) =>
			`You are about to verify customer ${memid}/${name}. Are you sure you want to proceed?`,
	},
	[MODAL_ACTION.customer_remark]: {
		img: icon_comment,
		title: "Add Remarks",
		content: () => "Add remarks to this customer.",
	},
	[MODAL_ACTION.business_account]: {
		img: icon_confirmation,
		title: "Business Account",
		content:  ({ memid, name }) =>
				`You are about to switch customer ${memid}/${name} to have a business account. Are you sure you want to proceed?`,
	},
	[MODAL_ACTION.personal_account]: {
		img: icon_confirmation,
		title: "Personal Account",
		content:  ({ memid, name }) =>
				`You are about to switch customer ${memid}/${name} to a personal account. Are you sure you want to proceed?`,
	},
	[MODAL_ACTION.postpaid]: {
		img: icon_confirmation,
		title: "Postpaid Account",
		content:  ({ memid, name, uroleid }) =>
				`You are about to switch customer ${memid}/${name} to a postpaid account${ uroleid === 'POP' ? ` and IPICK Account` : ''}. Are you sure you want to proceed?`,
	},
	[MODAL_ACTION.prepaid]: {
		img: icon_confirmation,
		title: "Prepaid Account",
		content:  ({ memid, name }) =>
				`You are about to switch customer ${memid}/${name} to a prepaid account. Are you sure you want to proceed?`,
	},
	[MODAL_ACTION.link_account_manager_id]: {
		img: icon_link,
		title: "Link Account Manager ID",
		content: ({ memid, name }) =>
			`Select an account manager to link with customer ${memid}/${name}`,
	},
	[MODAL_ACTION.update_status_self_lodge]: {
		img: icon_update_status,
		title: "Status Update",
		content:  ({ ordid }) => `Confirm the pending self lodge/ awaiting sender self-lodge to origin hub time for ${ordid} at:` 
	},
	[MODAL_ACTION.update_status_self_lodge_point]: {
		img: icon_update_status,
		title: "Status Update",
		content:  ({ ordid }) => `Confirm the pending self lodge/ awaiting sender self-lodge to origin point time for ${ordid} at:` 
	},
	[MODAL_ACTION.update_status_pickup]: {
		img: icon_update_status,
		title: "Status Update",
		content:  ({ ordid }) =>
				`Confirm the awaiting pick up/ pending pick-up to origin hub time for ${ordid} at: `
	},
	[MODAL_ACTION.update_status_sort]: {
		img: icon_update_status,
		title: "Status Update",
		content:  ({ ordid }) =>
				`Confirm the pending outbound/received at origin hub time for ${ordid} at:`
	},
	[MODAL_ACTION.update_status_sort_point]: {
		img: icon_update_status,
		title: "Status Update",
		content:  ({ ordid }) =>
				`Confirm the received at origin point/ sort time for ${ordid} at: `
	},
	[MODAL_ACTION.update_status_check_out_from_point]: {
		img: icon_update_status,
		title: "Status Update",
		content:  ({ ordid }) =>
				`Confirm the departing from origin point time for ${ordid} at: `
	},
	[MODAL_ACTION.update_status_check_in_to_orighub]: {
		img: icon_update_status,
		title: "Status Update",
		content:  ({ ordid }) =>
				`Confirm the received at origin hub time for ${ordid} at: `
	},
	[MODAL_ACTION.update_status_check_out_from_orighub]: {
		img: icon_update_status,
		title: "Status Update",
		content:  ({ ordid }) =>
				`Confirm the departing from origin hub time for ${ordid} at:  `
	},
	[MODAL_ACTION.update_status_check_in_to_dsthub]: {
		img: icon_update_status,
		title: "Status Update",
		content:  ({ ordid }) =>
				`Confirm the received at destination hub time for ${ordid} at:  `
	},
	[MODAL_ACTION.update_status_check_out_from_dsthub]: {
		img: icon_update_status,
		title: "Status Update",
		content:  ({ ordid }) =>
				`Confirm the departing from destination hub time for ${ordid} at:  `
	},
	[MODAL_ACTION.update_status_load_to_bus]: {
		img: icon_update_status,
		title: "Status Update",
		content:  ({ ordid }) =>
				`Confirm the pending inbound/departing to destination hub time for ${ordid} at:`
	},
	[MODAL_ACTION.update_status_self_collect]: {
		img: icon_update_status,
		title: "Status Update",
		content:  ({ ordid }) =>
				`Confirm the pending self collect/ awaiting receiver self collect at destination hub time for ${ordid} at: `
	},
	[MODAL_ACTION.update_status_pending_delivery]: {
		img: icon_update_status,
		title: "Status Update",
		content:  ({ ordid }) =>
				`Confirm the awaiting delivery to receiver/ pending delivery to receiver from destination hub time for ${ordid} at:`
	},
	[MODAL_ACTION.update_status_received_by_receiver]: {
		img: icon_update_status,
		title: "Status Update",
		content:  ({ ordid }) =>
				`Confirm the received by receiver / receiver collected time for ${ordid} at:`
	},
	[MODAL_ACTION.update_status_mark_as_resolved]: {
		img: icon_update_status,
		title: "Status Update",
		content:  ({ ordid, oparkid }) =>
				`Confirm the resolved time for the parking ticket (${oparkid}) and the park order closed time (${ordid}) at:`
	},
	[MODAL_ACTION.update_status_park_order]: {
		img: icon_update_status,
		title: "Status Update",
		content:  ({ ordid }) =>
				`Confirm the parking time for ${ordid} at:`
	},
	[MODAL_ACTION.update_status_cancel_order]: {
		img: icon_update_status,
		title: "Status Update",
		content:  ({ ordid }) =>
				`Confirm the cancellation time for ${ordid} at:`
	},
	[MODAL_ACTION.confirmation_update_status_self_lodge]: {
		img: icon_confirmation,
		title: "Confirmation Required",
		content:  ({ ordid }) => `You are about to edit the awaiting customer self-lodge time for for ${ordid}. Are you sure you want to proceed?` 
	},
	[MODAL_ACTION.confirmation_update_status_pickup]: {
		img: icon_confirmation,
		title: "Confirmation Required",
		content:  ({ ordid }) =>
				`You are about to edit the pending pick up time for ${ordid}. Are you sure you want to proceed?`
	},
	[MODAL_ACTION.confirmation_update_status_sort]: {
		img: icon_confirmation,
		title: "Confirmation Required",
		content:  ({ ordid }) =>
				`You are about to edit the pending outbound/received at origin hub time for ${ordid}. Are you sure you want to proceed?`
	},
	[MODAL_ACTION.confirmation_update_status_load_to_bus]: {
		img: icon_confirmation,
		title: "Confirmation Required",
		content:  ({ ordid }) =>
				`You are about to edit the pending inbound/departing to destination hub time for ${ordid}. Are you sure you want to proceed?`
	},
	[MODAL_ACTION.confirmation_update_status_self_collect]: {
		img: icon_confirmation,
		title: "Confirmation Required",
		content:  ({ ordid }) =>
				`You are about to edit the arrived and ready for self-collection time for ${ordid}. Are you sure you want to proceed?`
	},
	[MODAL_ACTION.confirmation_update_status_pending_delivery]: {
		img: icon_confirmation,
		title: "Confirmation Required",
		content:  ({ ordid }) =>
				`You are about to edit the arrived and ready for delivery time for ${ordid}. Are you sure you want to proceed?`
	},
	[MODAL_ACTION.confirmation_update_status_received_by_receiver]: {
		img: icon_confirmation,
		title: "Confirmation Required",
		content:  ({ ordid }) =>
				`You are about to edit the received by receiver/ receiver collected time for ${ordid}. Are you sure you want to proceed?`
	},
	[MODAL_ACTION.confirmation_update_status_mark_as_resolved]: {
		img: icon_confirmation,
		title: "Confirmation Required",
		content:  ({ ordid, oparkid }) =>
				`You are about to edit resolved time for the parking ticket (${oparkid}) and the park order closed time (${ordid}). Are you sure you want to proceed?`
	},
	[MODAL_ACTION.confirmation_update_status_park_order]: {
		img: icon_confirmation,
		title: "Confirmation Required",
		content:  ({ ordid }) =>
				`You are about to edit the parking time for ${ordid}. Are you sure you want to proceed?`
	},
	[MODAL_ACTION.confirmation_update_status_cancel_order]: {
		img: icon_confirmation,
		title: "Confirmation Required",
		content:  ({ ordid }) =>
    `You are about to edit the cancellation time for ${ordid}. Are you sure you want to proceed?`
	},
	[MODAL_ACTION.deactivate_zone_price]:{
		img: icon_deactivate_zone, 
		title: "Deactivate Zone Price",
		content: ({ origName, destName, price }) =>
		     `You are about to deactivate zone price for ${origName} - ${destName}(RM${price}). Are you sure you want to proceed?`,
	},
	[MODAL_ACTION.activate_zone_price]:{
		img: icon_show_hub,
		title: "Reactivate Zone Price",
		content: ({ origName, destName, price}) =>
		     `You are about to reactivate zone price for ${origName} - ${destName}(RM${price}). Are you sure you want to proceed?`,
	},
	[MODAL_ACTION.confirmation_mark_as_active_vehicle]: {
		img: icon_update_status,
		title: "Status Update",
		content:  ({ vid }) =>
				`You want to activate status for id = ${vid}. Are you sure want to activate it?`
	},
	[MODAL_ACTION.confirmation_mark_as_inactive_vehicle]: {
		img: icon_update_status,
		title: "Status Update",
		content:  ({ vid }) =>
				`You want to inactivate status for id = ${vid}. Are you sure want to inactivate it?`
	},
	
};

export const MODALS_STANDARD = [
	MODAL_ACTION.delete_person,
	MODAL_ACTION.logout,
	MODAL_ACTION.bus_info,
	MODAL_ACTION.load_to_bus,
	MODAL_ACTION.unload_from_bus, 
	MODAL_ACTION.bulk_load_to_bus,
	MODAL_ACTION.bulk_unload_from_bus,
	MODAL_ACTION.suspend_user,
	MODAL_ACTION.hide_hub,
	MODAL_ACTION.show_hub,
	MODAL_ACTION.hide_point,
	MODAL_ACTION.show_point,
	MODAL_ACTION.reactivate_user,
	MODAL_ACTION.verify_customer,
	MODAL_ACTION.business_account,
	MODAL_ACTION.personal_account,
	MODAL_ACTION.postpaid,
	MODAL_ACTION.prepaid,
	MODAL_ACTION.link_account_manager_id,
	MODAL_ACTION.deactivate_zone_price,
	MODAL_ACTION.activate_zone_price,
	MODAL_ACTION.confirmation_mark_as_active_vehicle,
	MODAL_ACTION.confirmation_mark_as_inactive_vehicle,
];

export const MODALS_REMARK = [
	MODAL_ACTION.park_order,
	MODAL_ACTION.park_resolved,
	MODAL_ACTION.cancel_order,
	MODAL_ACTION.cancel_trip_manifest,
	MODAL_ACTION.confirm_sorting,
	MODAL_ACTION.customer_remark,
];

export const MODALS_COMMENT = MODAL_ACTION.add_comment;

export const MODALS_UPDATE_STATUS = 
[
 MODAL_ACTION.update_status_self_lodge,
 MODAL_ACTION.update_status_self_lodge_point,
 MODAL_ACTION.update_status_pickup,
 MODAL_ACTION.update_status_sort,
 MODAL_ACTION.update_status_sort_point,
 MODAL_ACTION.update_status_check_out_from_point,
 MODAL_ACTION.update_status_check_in_to_orighub,
 MODAL_ACTION.update_status_check_out_from_orighub,
 MODAL_ACTION.update_status_check_in_to_dsthub,
 MODAL_ACTION.update_status_check_out_from_dsthub,
 MODAL_ACTION.update_status_load_to_bus,
 MODAL_ACTION.update_status_self_collect,
 MODAL_ACTION.update_status_pending_delivery,
 MODAL_ACTION.update_status_received_by_receiver,
 MODAL_ACTION.update_status_mark_as_resolved,
 MODAL_ACTION.update_status_park_order,
 MODAL_ACTION.update_status_cancel_order,
]
