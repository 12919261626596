import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Form, Input, Button, message } from "antd";

// Constant
import { ROUTE as ROUTE_CONSTANT } from "../../constants";

// Components
import { 
  // ButtonLink, 
  CopyrightFooter 
} from "../../components";

/**
 * Redux Actions
 */
import * as ACTIONS from "../../services/redux/actions";

// Style
import classStyles from "./index.module.css";
import "./index.css";

const SSOLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [loadingLogin, setLoadingLogin] = useState(false);

  const onFinish = (e) => {
    const { email, password } = e;

    dispatch(
      ACTIONS.main_user.auth_user_sign_in(
        email.trim(),
        password,
        ({ status, err }) => {
          if (status === 200) {
            setLoadingLogin(false);
          } else if (status === 404) {
            message.error(err);
            setLoadingLogin(false);
          }
          navigate(ROUTE_CONSTANT.MODULE_ROUTE.Dashboard);
        }
      )
    );

    setLoadingLogin(true);
  };

  const validateMessages = {
    required: "${name} is required!",
  };

  const PasswordLabel = () => {
    return (
      <>
        <span className={classStyles.inputLabel}>Password</span>
        {/* <ButtonLink
          class_name={`col text-end ${classStyles.forgotPassword} ${classStyles.inputLabel}`}
          onClick={() =>
            navigate(
              ROUTE_CONSTANT.MODULE_ROUTE.ResetCredential_Forgot_Password
            )
          }
        >
          Forgot your password?
        </ButtonLink> */}
      </>
    );
  };

  return (
    <div className={classStyles.inputForm}>
      <div className={classStyles.inputContainer}>
        <h4>
          <strong>Log In</strong>
        </h4>
        <p className="mt-1">For Everyone Anywhere Anytime</p>

        <div className={classStyles.inputWrap}>
          <div className={classStyles.input}>
            <Form
              form={form}
              name="form_login"
              layout="vertical"
              validateMessages={validateMessages}
              onFinish={onFinish}
            >
              <Form.Item
                name="email"
                label={<span className={classStyles.inputLabel}>Email</span>}
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                    type: "email",
                  },
                ]}
                hasFeedback
              >
                <Input className={classStyles.inputField} />
              </Form.Item>
              <Form.Item
                name="password"
                label={<PasswordLabel />}
                rules={[
                  {
                    required: true,
                    message: "Password is required",
                  },
                ]}
                hasFeedback
              >
                <Input.Password className={classStyles.inputPassword} />
              </Form.Item>
              <Form.Item>
                <Button
                  className={classStyles.inputButton}
                  type="primary"
                  htmlType="submit"
                  loading={loadingLogin}
                >
                  Log In
                </Button>
              </Form.Item>
            </Form>
          </div>

          <CopyrightFooter />
        </div>
      </div>
    </div>
  );
};

export default SSOLogin;