// React Thirty Party Library
import { Row, Col, Form, Select, Radio, Input , InputNumber, Alert} from "antd";
import { useState } from "react";

// Constants Library
import { TEMPLATE } from "../../../../constants";

// Components Library
import JNAttachmentUpload from "../JNAttachmentUpload";

// Handler Library

// Services Library

// Redux Actions

// Styles Library
import classes from "../../../../components/index.module.css";

const { OPTION_TYPE_TITLE, OPTION_TYPE } = TEMPLATE.TRANSACTIONLIST;
const { BILLING_TYPE } = TEMPLATE.BILLING;

const ManageCreditBody = (props) => {
  const {
    filteredData,
    dataInv,
    transactionType,
    handleTransactionTypeChange,
    handleAmountChange,
    onChangeRemarks,
    setUploadTransaction,
    handleBillingId,
    billAmount,
    currentBalance,
    balanceAfter,
    updatedAmount
  } = props;

  const { TextArea } = Input;

  return (
    <>
      <div>
        <Row className={classes.creditBody}>
          <Col span={7}></Col>
          <Col span={15}>
            <div className={classes.formManageCredit}>
              <div className={classes.inputManageCredit}>
                <Form.Item
                  label="Customer ID / Name / Payment Model"
                  name="memid"
                  rules={[{ required: true }]}
                >
                  <Select showSearch>
                    <Select.Option
                      key={filteredData?.uid}
                      value={filteredData?.memid}
                    >
                      {filteredData?.memid} / {filteredData?.name} / <span style={{
                        backgroundColor: filteredData?.paymentModel === "PM00" ? "#f08800" : "#1f1f1f",
                        color: "#fff",
                        borderRadius: "20px",
                        padding: "3px 7px",
                        }}
                      >
                        {filteredData?.paymentModel === "PM00" ? "Prepaid" : "Postpaid"}
                      </span>
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>

              <div className={classes.inputManageCredit}>
                <Form.Item
                  label="Transaction Type (Addition)"
                  name="type"
                  rules={[{ required: true }]}
                >
                  <Radio.Group
                    onChange={handleTransactionTypeChange}
                    value={transactionType}
                  >
                    <Radio value={OPTION_TYPE.Topup}>Top up</Radio>
                    <Radio value={OPTION_TYPE.Refund}>Refund</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className={classes.inputManageCredit}>
                <Form.Item
                  label="Transaction Type (Deduction)"
                  name="type"
                  rules={[{ required: true }]}
                >
                  <Radio.Group
                    onChange={handleTransactionTypeChange}
                    value={transactionType}
                  >
                    <Radio value={OPTION_TYPE.Payment}>Payment</Radio>
                    <Radio value={OPTION_TYPE.Deduction}>Wallet Deduction</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>

              {(transactionType === OPTION_TYPE.Topup ||
                transactionType === OPTION_TYPE.Refund || 
                transactionType === OPTION_TYPE.Deduction
              ) && (
                <>
                  <div className={classes.inputManageCredit}>
                    <Form.Item
                      label={`${OPTION_TYPE_TITLE[transactionType]} Amount(RM)`}
                      name="amount"
                      rules={[{ required: true }]}
                    >
                      <InputNumber addonBefore="RM" onChange={handleAmountChange} />
                    </Form.Item>

                    {transactionType === OPTION_TYPE.Deduction && 
                      updatedAmount > currentBalance &&
                      filteredData?.paymentModel === "PM00" && (
                        <Alert
                          message="Not enough balance"
                          type="error"
                          showIcon
                        />
                    )}
                  </div>
                  <div className={classes.simulateBalance}>
                      <p>Current Balance</p>
                      <p>RM {currentBalance}</p>
                      <p>Balance After</p>
                      <p>RM {balanceAfter}</p>
                  </div>
                  <div className={classes.inputManageCredit}>
                    <Form.Item label="Attachment">
                      <JNAttachmentUpload
                        setUploadTransaction={setUploadTransaction}
                      />
                    </Form.Item>
                  </div>
                </>
              )}

              {transactionType === OPTION_TYPE.Payment && (
                <div className={classes.inputManageCredit}>
                  <Row>
                    <Col span={10}>
                      <Form.Item name="billid" label="Invoices ID / Amount">
                        <Select showSearch onChange={handleBillingId}>
                          {Array.isArray(dataInv) &&
                            dataInv.map((bill) => (
                              <Select.Option
                                key={bill.billid}
                                value={`${bill.billid},${bill.amount},${bill.refid}`}
                              >
                                {bill.refid} / RM {bill.amount}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={10} offset={2}>
                      <Form.Item name="payment" label="Invoices Amount">
                        <InputNumber value={billAmount} />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              )}

              {/* Remarks Section */}
              <div className={classes.inputManageCredit}>
                <Form.Item label="Add Remarks">
                  <TextArea
                    rows={4}
                    placeholder="add a note to your transaction.."
                    onChange={onChangeRemarks}
                  />
                </Form.Item>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ManageCreditBody;
