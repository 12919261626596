import { ButtonLink, ButtonModal } from "../../../../../v1/components";
import { TEMPLATE } from "../../../../constants";

const { MODAL_ACTION } = TEMPLATE.MODAL
  export const ActionItems = (record, setIsOpenEdit, setData, handleMark, navigateMapView) => {
    const { vid, status } = record

    return [
        {
            key: 0,
            label: (<ButtonLink>Edit</ButtonLink>),
            onClick: () => {
                setIsOpenEdit(true);
                setData(record)
              },
        },
        {
            key: 1,
            label: (
                <>
            {status === 0 || !status ? 
            <ButtonModal
			    model={"link"}
                setmodal={MODAL_ACTION.confirmation_mark_as_active_vehicle}
                type="primary"
                style={{ width: "100%", height: "100%" }}
                onClick={() => handleMark(record, 'active')}
                extra={{ vid }}
            >
                Mark as active
            </ButtonModal> :
            <ButtonModal 
                model='link'
                setmodal={MODAL_ACTION.confirmation_mark_as_inactive_vehicle}
                onClick={() => handleMark(record, 'inactive')}
                extra={{ vid }}
            >
                Mark as inactive
            </ButtonModal>}</>
		)
        },
        {
            key: 3,
            label: (
                <ButtonLink>
                    View on map
                </ButtonLink>
            ),
            onClick: () => navigateMapView(record.plate, record.vid)
        }
    ]
}
