import { Tag } from "antd";
// Contanst
import { TEMPLATE } from "../../constants";

const { OPTION_USER_ROLE, OPTION_USER_STATUS, OPTION_USER_STATUS_TITLE } =
  TEMPLATE.USERLIST;

export const userListRole = () => {
  let tree = [];

  if (OPTION_USER_ROLE) {
    const allChildren = Object.keys(OPTION_USER_ROLE).map((status) => {
      const title = OPTION_USER_ROLE[status];
      const key = `User_Role#${status}`;
      const value = `User_Role#${status}`;

      return {
        title: title,
        key: key,
        value: value,
      };
    });

    const allOption = {
      title: "ALL",
      key: "ALL",
      value: "ALL",
    };

    tree.push({ ...allOption, children: [...allChildren] });
  }
  const filterSelectedChildren = (node) => {
    if (node.value === "ALL") {
      return tree.flatMap((n) => n.children.map((child) => child.value));
    }

    return [node.value];
  };

  tree.filterSelectedChildren = filterSelectedChildren;

  return tree;
};

export const userListStatus = () => {
  let tree = [];

  if (OPTION_USER_STATUS) {
    const allChildren = Object.values(OPTION_USER_STATUS).map((userstat) => {
      const { name, colorTag } = OPTION_USER_STATUS_TITLE[userstat];

      const title = (
        <Tag color={colorTag} key={userstat}>
          {name}
        </Tag>
      );
      const key = `status#${userstat}`;
      const value = `status#${userstat}`;

      return {
        title: title,
        key: key,
        value: value,
      };
    });

    const allOption = {
      title: "ALL",
      key: "ALL",
      value: "ALL",
    };

    tree.push({ ...allOption, children: [...allChildren] });
  }
  const filterSelectedChildren = (node) => {
    if (node.value === "ALL") {
      return tree.flatMap((n) => n.children.map((child) => child.value));
    }

    return [node.value];
  };

  tree.filterSelectedChildren = filterSelectedChildren;

  return tree;
};

export const userListStatusWallet = () => {
  
  let resultArray;
  if (OPTION_USER_STATUS) {
  resultArray = Object.values(OPTION_USER_STATUS).map((userstat) => {
    const { name, colorTag } = OPTION_USER_STATUS_TITLE[userstat];

    const title = (
      <Tag color={colorTag} key={userstat}>
        {name}
      </Tag>
    );
    const key = `status#${userstat}`;
    const value = `status#${userstat}`;

    return {
      title: title,
      key: key,
      value: value,
    };
  });
}

  return resultArray;
  
};
