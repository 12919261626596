import { useSelector } from "react-redux";

// Constants Library
import { TEMPLATE } from "../../../../constants";

// Components Library
import { TableList, BtnSubmit } from "../../../../components";

// Services Library
import { REDUX as REDUX_UTIL, TIME } from "../../../../services/util";

//handlers
import { exportHDLR } from "../../../../handlers";

import classes from "../../../../components/index.module.css";


const { TABLE_ZONE_TITLE, TABLE_ZONE_INDEX } = TEMPLATE.ZONEPRICELIST;
const { BTN_IMG } = TEMPLATE.BTN;
const { ZONE_SUMMARY_COL } = TEMPLATE.REPORT.ZONE;
const { reduxStoreWrapper } = REDUX_UTIL
const { parseExportTime } = TIME;

const SearchZone = ({
  dataSource,
  filteredData,
  setFilteredData,
  setIsOpenAdd,
  selectedRow
}) =>{
    const zone = useSelector(reduxStoreWrapper("zone.byID"));
    const options = [
        {
          value: TABLE_ZONE_INDEX.zone_id,
          label: TABLE_ZONE_TITLE.zone_id,
          type: "text",
        },
        {
          value: TABLE_ZONE_INDEX.createdAt,
          label: "Custom Date",
          type: "timeframe",
        },
      ];

    const exportData = selectedRow.length > 0 ? selectedRow : filteredData;

    return(
        <div className={classes.headerPage}>
        <TableList.SearchTableQuery
           dataSource={dataSource}
           setFilteredData={setFilteredData}
           options={options}
          defaultValue={TABLE_ZONE_INDEX.zone_id}
        />
        <div className="">
          <BtnSubmit
            display="inline-block"
            text="Export"
            bgColor="transparent"
            color="#4CAF50"
            padding="9px 18px"
            onClick={() =>
              exportHDLR.exportWithTemplate({
                data: exportData,
                filename: `ZonePrice_${parseExportTime()}`,
                template: ZONE_SUMMARY_COL(
                  {   
                    redux:{
                      zone
                    },
                    exportState : true
                  },
                ),
              })
            }
            showIcon={true}
            icon={BTN_IMG.EXPORT}
            icon_alt={"export"}
          />
          <BtnSubmit
            display="inline-block"
            text="Add zone"
            bgColor="var(--themeBackgroundColor)"
            color="var(--creamColor)"
            padding="9px 18px"
            onClick={() => setIsOpenAdd(true)}
            showIcon={false}
            />
        </div>
      </div>
    )
}

export default SearchZone;
