// React Thirty Party Library
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Components 
import { SearchPointOperator, TablePointOperator } from "./components"

// Services Library
import { REDUX as REDUX_UTIL } from "../../../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../../../services/redux/actions";

// Styles 
import classes from "../../common.module.css"; 

const { reduxStoreWrapper } = REDUX_UTIL;


const PointOperator = () => {
  const dispatch = useDispatch();
  
  const point = 1
      const hubOperator = useSelector(reduxStoreWrapper("hubOperator.byID"));
      const operatorRole = Object.values(useSelector(reduxStoreWrapper(`hubOperator.byRole`)))
      const flattenedData = operatorRole.flatMap(sublist => sublist);
      const dataSource = flattenedData.map((op) => {
        return {
            key: op.memid,
            ...op
        }});

      const loading = useSelector(reduxStoreWrapper("style.loading.hubOperator"));
    
      const [filteredData, setFilteredData] = useState(dataSource);
      useEffect(() => {
        dispatch(
          REDUX_ACTION.v1_hubOperator.get_all_hub_operators_request(point)
        );
    
        return () => {};
      }, [dispatch]);
    
    
    
      useEffect(() => {
        if (filteredData !== dataSource) {
          setFilteredData(dataSource);
        }
      }, [dataSource]);
    
      useEffect(() => {
        setFilteredData(filteredData);
      }, [filteredData]);

      const [selectedRow, setSelectedRow] = useState([]);
    
    return (
        <div className={classes.container}>
            <SearchPointOperator dataSource={dataSource} filteredData={filteredData} setFilteredData={setFilteredData} selectedRow={selectedRow}/>
            <TablePointOperator dataSource={dataSource} filteredData={filteredData} setFilteredData={setFilteredData} loading={loading} selectedRow={selectedRow} setSelectedRow={setSelectedRow}/>
        </div>
    )
}

export default PointOperator;
