import { Form, Row, Col, Input, Button, InputNumber, Select, Upload } from "antd";
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

// Constants
import { uploadHDLR } from "../../../../../../../../handlers"

//components

// Styles
import coms from "../common.module.css";

const ParcelInformation = (props) => {
const { formData, onClick, order, handleSummary, onRemove, setImgAttach, label, sort} = props

const { 
  getBase64, 
  put2AssetS3OPCommentAttachmentRequest 
} = uploadHDLR;

const parcelQuantityType = (
    <Select defaultValue="pieces" style={{padding:0}}>
        <Select.Option style={{padding:0}} value="pieces">Pcs</Select.Option>
        <Select.Option value="kg">Kg</Select.Option>
    </Select>
)

const handleChangeUploadImg = (info) => {
  const { status, originFileObj, response = {} } = info.file;

  if (status === "uploading") {
      return;
  }
  if (status === "done") {
      getBase64(originFileObj, () => {
          const { Location: url } = response;
          setImgAttach(p => [...p, url]);
      });
  }
}

const imgs = order.parcel?.img

const img = imgs && imgs
  .map((url, index) => ({
      uid: index,
      name: `attachment-${index}`,
      url,
      status: "done",
}));

  return (
    <>
      <Form.List name={'parcel_unsort'}>
            {(fields, {add, remove}) => {

                if (fields.length === 0) {
                    add();
                }

                return (
                    <>
                        { fields.map((field, i) => {
                            
                            const { name } = field

                            return (
                              <Row gutter={20} key={i}>

                                    <Col span={5}>
                                        <Form.Item label="Parcel Quantity" name={[name, "qty"]} rules={[{ required: true }]} >
                                            <InputNumber min={1} disabled={order.status === 'O1200' || order.status === 'O0202' || order.status === 'O0201'} addonAfter={parcelQuantityType} 
                                            onChange={ (e) => handleSummary(e, name, 'qty')} 
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item label="Parcel Length (cm)" name={[name, "length"]} rules={[{ required: true }]}>
                                            <InputNumber min={0} disabled={order.status === 'O1200' || order.status === 'O0202' || order.status === 'O0201'} 
                                            onChange={(e) => handleSummary(e, name, 'length')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item label="Parcel Width (cm)" name={[name, "width"]} rules={[{ required: true }]}>
                                            <InputNumber min={0} disabled={order.status === 'O1200' || order.status === 'O0202' || order.status === 'O0201'} 
                                            onChange={(e) => handleSummary(e, name, 'width')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item label="Parcel Height (cm)" name={[name, "height"]} rules={[{ required: true }]}>
                                            <InputNumber min={0} disabled={order.status === 'O1200' || order.status === 'O0202' || order.status === 'O0201'} type='number' 
                                            onChange={(e) => handleSummary(e, name, 'height')} 
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item label="Parcel Weight (kg)" name={[name, "weight"]} rules={[{ required: true }]}>
                                            <InputNumber min={0} disabled={order.status === 'O1200' || order.status === 'O0202' || order.status === 'O0201'} type='number' 
                                            onChange={(e) => handleSummary(e, name, 'weight')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    {label !== sort &&
                                    <Col span={2} style={{ display: 'flex', gap: '15px', fontSize: '1.5em' }}>
                                        <PlusOutlined onClick={() => {
                                            add()
                                        }} />
                                        {i > 0 && (
                                          <DeleteOutlined onClick={() => {
                                            onRemove(name);
                                            remove(name);
                                          }}
                                            />
                                        )}   
                                    </Col>
                                    }
                                </Row>
                        )} ) }
                    </>
                )
            }}
        </Form.List>
        <Row gutter={20}>
              <Col span={16}>
                  <Form.Item
                      name={formData.parcel_remark ? 'parcel_remark' : 'parcel_desc'}
                      label="Parcel Remarks"
                  >
                      <Input disabled={order.status === 'O1200' || order.status === 'O0202' || order.status === 'O0201'} />
                  </Form.Item>
              </Col>
              <Col span={5}>
                  <Form.Item name={'dv'} label="Declared Value (RM)" >
                      <InputNumber disabled={order.status === 'O1200' || order.status === 'O0202' || order.status === 'O0201'} type='number' addonBefore="RM" 
                      />
                  </Form.Item>
              </Col>
          </Row>
          <Form.Item label="Parcel Images" name='parcel_img'>
              <Upload
                  disabled={order.status === 'O1200' || order.status === 'O0202' || order.status === 'O0201'}
                  name="avatar"
                  listType="picture-card"
                  onChange={handleChangeUploadImg}
                  customRequest={put2AssetS3OPCommentAttachmentRequest}
                  accept=".jpg,.jpeg,.png"
                  defaultFileList={imgs && img}
              >
                  {!imgs && '+ Upload' || imgs && img.length < 5 && '+ Upload'}
              </Upload>
          </Form.Item>
    <div className={coms.nextBTN}>
      <Button type="primary" onClick={() => onClick()}>
        Next
      </Button>
    </div>
    </>
  );
};

export default ParcelInformation;