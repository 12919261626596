export const STATUS_LOG_TABLE_LIST_INDEX = {
    hubid: 'hubid',
    ordid: 'ordid',
    status: 'status',
    intiatedAt: 'initiatedAt',
    completedAt: 'completedAt',
    updatedAt: 'updatedAt',
    createdAt: 'createdAt',
    createdBy: 'createdBy'
}

export const STATUS_LOG_TABLE_LIST_TITLE = {
    hubid: 'Hub/ Point',
    ordid: 'Order ID',
    status: 'Status',
    intiatedAt: 'Initiation Time',
    completedAt: 'Completion Time',
    updatedAt: 'Updated Time',
    createdAt: 'Created Time',
    createdBy: 'Created By'
}

export const SCAN_LOG_TABLE_LIST_INDEX = {
    hubid: 'hubid',
    ordid: 'ordid',
    type: 'type',
    scanStatus: 'scanStatus',
    scannedAt: 'scannedAt',
    updatedBy: 'updatedBy',
    remark: 'remark'
}

export const SCAN_LOG_TABLE_LIST_TITLE = {
    hubid: 'Hub/ Point',
    ordid: 'Order ID',
    type: 'Type',
    scanStatus: 'Status',
    scannedAt: 'Scanned At',
    updatedBy: 'Scanned By',
    remark: 'Remark'
}

export const SCAN_TYPE = [
    { text: 'Check in', value: 'Check in' }, 
    { text: 'Check out', value: 'Check out' } 
]

export const SCAN_STATUS = [
    { text: 'SUCCESS', value: 1, color: 'success' }, 
    { text: 'FAIL', value: 0, color: 'error' } 
]

export const REPORT_TYPE_MODE = [
    { label: 'Status Log Report', value: 'status' },
    { label: 'Scan Log Report', value: 'scan' },
]

export const INPUT_FIELD_INDEX = (datas, title, value) => {
    return [
        {
            label: 'All',
            value: 'all'
        },
        ...Object.values(datas).map(data => ({
                label: title ? data[title] : data,
                value: value ? data[value] : data
        }))
    ]
}
