import * as API_CONSTANT from "../constants";

export const getJournalNoteTimeFrameAll = ({ startTime, endTime }) =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/jn/get/timeframe/all`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      startTime,
      endTime,
    }),
  }).then((res) => res.json());
