// React Thirty Party Library
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Constants Library

// Components Library
import { DrawerChangePass, DrawerRemark } from "../../../../components";
import { SearchCustomerList, TableCustomerList, DrawerEditCustomer, DrawerBusinessAcc } from "./components";

// Handler Library

// Services Library
import { REDUX as REDUX_UTIL } from "../../../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../../../services/redux/actions";

// Styles Library
import classes from "../../../common.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;

const CustomerList = () => {
    const dispatch = useDispatch();
    const accountManager = Object.values(useSelector(reduxStoreWrapper("accountManager.byID")));
    const customers = useSelector(reduxStoreWrapper("customer.byID"));

    const loading = useSelector(
        reduxStoreWrapper("style.loading.customer")
      );
    

    const dataSource = Object.values(customers).map((t) => {
    return {
        key: t.uid,
        status: t.status !== undefined ? t.status : 1,
        ...t,
    }});

    const [filteredData, setFilteredData] = useState(dataSource);

    useEffect(() => {
        if (filteredData !== dataSource) {
            setFilteredData(dataSource);
        }
    }, [dataSource]);

    useEffect(() => {
      setFilteredData(filteredData);
  
    }, [filteredData]);


  useEffect(() => {
    dispatch(REDUX_ACTION.v1_wallet.get_customer_all_request_90days());
    // dispatch(REDUX_ACTION.v1_customerList.get_customer_all_request());
    dispatch(REDUX_ACTION.v1_accountManager.get_account_manager_list_request())

    return () => {};
  }, [dispatch]);

    // since the 'edit remark' did not access different route, i created useState data for passing data once the 'edit remark' clicked
    const [data, setData] = useState({})

    const [isOpenRemark, setIsOpenRemark] = useState(false)
    const [isOpenEdit, setIsOpenEdit] = useState(false)
    const [isOpenBusiness, setIsOpenBusiness] = useState(false)
    const [isOpenChangePass, setIsOpenChangePass] = useState(false)
    const [isBizAcc, setIsBizAcc] = useState(false)
    const [selectedRow, setSelectedRow] = useState([]);

    const customer = useSelector(reduxStoreWrapper("customer.byID"))
    const submitRemarkHDLR = (items, cb) => dispatch(REDUX_ACTION.v1_customerList.create_user_list_remark_request(items, setData, cb()))
    const editRemarkHDLR = (items, cb) => dispatch(REDUX_ACTION.v1_customerList.update_user_list_remark_request(items, setData, cb()))
    const deleteRemarkHDLR = (items, cb) => dispatch(REDUX_ACTION.v1_customerList.delete_user_list_remark_request(items, setData, cb()))

    return (
        <div className={classes.container}>
            <DrawerRemark 
                isOpen={isOpenRemark} 
                setIsOpen={setIsOpenRemark} 
                data={data}
                reduxData2={customer}
                submitRemarkHDLR={submitRemarkHDLR}
                editRemarkHDLR={editRemarkHDLR}
                deleteRemarkHDLR={deleteRemarkHDLR}
            />

            <DrawerChangePass isOpen={isOpenChangePass} setIsOpen={setIsOpenChangePass} data={data} page="customer" />
            <DrawerEditCustomer isOpen={isOpenEdit} setIsOpen={setIsOpenEdit} data={data} dataCM={accountManager} />
            <DrawerBusinessAcc isOpen={isOpenBusiness} setIsOpen={setIsOpenBusiness} data={data} dataCM={accountManager} setIsBizAcc={setIsBizAcc} />
            <SearchCustomerList
                dataSource={dataSource}
                setFilteredData={setFilteredData}
                filteredData={filteredData}
                selectedRow={selectedRow}
            />
            <TableCustomerList
                dataSource={dataSource}
                setIsOpenRemark={setIsOpenRemark}
                setIsOpenEdit={setIsOpenEdit}
                setIsOpenBusiness={setIsOpenBusiness}
                isOpenBusiness={isOpenBusiness}
                setIsOpenChangePass={setIsOpenChangePass}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                setData={setData}
                loading={loading}
                isBizAcc={isBizAcc}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
            />
        </div>
    );
};

export default CustomerList;
