// React Thirty Party Library
import { Tag, Typography, Tooltip } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

// Constant
import { TEMPLATE } from "../../../constants";
import { IMG } from "../../../../../constants"
import { DATA_FIELD_VALUES, inTransit } from "../order";

// Components
import { ButtonLink } from "../../../components";

//handlers
import { filter } from "../../../handlers"

// Service
import { TIME } from "../../../services/util";

const { parseTimeMin, parseTime, parseTimeMinDayFirst } = TIME;

const { searchHDLR, filterHDLR  } = filter

const { Text } = Typography;

const { icon_comment_parcel, icon_active_map, icon_inactive_map } = IMG.ICON
const { TABLE_ORDERLIST_INDEX, TABLE_ORDERLIST_TITLE} = TEMPLATE.ORDERLIST
const { ORDER_STATUS } = TEMPLATE.ORDER
const { OPTION_STATUS_TITLE } = TEMPLATE.BILLING

export const ORDER_SUMMARY_COL = ({
  redux = {},
  func = {},
  exportState = false,
}) => {
  const { orderSorted, orderStates, orderStatuses, hubs, points, comments, orderLog, operators, admins, vehicle } = redux;
  const { NavigateOrderSummary, navigateMap, SORenavigate, setIsOpen, setData, handleSearch } = func;

  const renderDeliveryInfo = (id, text) => (
    <p key={id}>{`${text} ${hubs[id] && hubs[id].code}`}</p>
  );
  return [
    {
      title: TABLE_ORDERLIST_TITLE.Order_ID,
      key: TABLE_ORDERLIST_INDEX.Order_ID,
      width: 180,
      fixed: "left",
      render: (record) => {
        const { ordid, sorted, sortedAt = '-' } = record;

        const sortedTime = orderLog && orderLog[ordid]?.find(olog => olog.status === ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB)?.createdAt
  
        return exportState ? (
          ordid
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <ButtonLink onClick={() => NavigateOrderSummary(ordid)}>
              {ordid}
            </ButtonLink>

            {sorted === 1 ? (
              <Tooltip placement="BottomLeft" title={`Sorted at: ${TIME.parseTimeMin(sortedAt)}`} >
              <CheckCircleFilled
                style={{
                  marginLeft: 8,
                  color: "var(--bs-success)",
                }}
              />
              </Tooltip>
            ) : (
                <CheckCircleFilled
                  style={{
                    marginLeft: 8,
                    color: "var(--bs-gray-500)",
                  }}
                /> 
              )}
          </div>
        );
      },
      sorter: (a, b) => {
        a = parseInt(a.ordid.replace('P', ''));
        b = parseInt(b.ordid.replace('P', ''));

        return a - b;
      },
    },
    {
      title: TABLE_ORDERLIST_TITLE.Order_Status,
      key: TABLE_ORDERLIST_INDEX.Order_Status,
      fixed: "left",
      width: 180,
      render: (rowData) => {
        const { ordid, v_plate } = rowData;
        const status =
          (orderStates[ordid] && orderStates[ordid].status) || rowData.status;
        const statusName =
          orderStatuses[status] && orderStatuses[status].display;
        const colorTag =
          (orderStatuses[status] && orderStatuses[status].colorTag) ||
          "default";
          

        return exportState ? (
          statusName
        ) : (
          <div style={{ display: "flex", alignItems: "center"}}>
          {v_plate && inTransit.includes(status) ? 
            <ButtonLink onClick={() => navigateMap(ordid)}>
              <img src={icon_active_map} alt="" style={{ width: "18px", marginRight: "5px", marginTop: "6px"}}/>
            </ButtonLink> : 
              <img src={icon_inactive_map} alt=""style={{ width: "18px", marginRight: "5px"}} /> 
          }
            <Tag color={colorTag} key={status}>
              {statusName}
            </Tag>
          </div>
        );
      },
      filters: filterHDLR.getColumnsFilterStatus(orderStatuses),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: TABLE_ORDERLIST_TITLE.Delivery_Instruction,
      key: TABLE_ORDERLIST_INDEX.Delivery_Instruction,
      width: 180,
      render: (rowData) => {
        const { deliveryType, origid, dstid, origpid, dstpid, uroleid } =
          rowData;
        const orig = "H" + (hubs[origid] && hubs[origid].code);
        const dest = "H" + (hubs[dstid] && hubs[dstid].code);
        const origPoint = "P" + (points[origpid] && points[origpid].code);
        const destPoint = "P" + (points[dstpid] && points[dstpid].code);

        const route =
        deliveryType !== 'p2p'
            ? `${orig}-${dest}`
            : `${origPoint}-${orig}-${dest}-${destPoint}`;
        const type = deliveryType !== 'p2p' ? 'Hub to Hub' : 'Point to Point'
        return exportState ? (
          (DATA_FIELD_VALUES["deliveryType"][deliveryType], route)
        ) : (
          <>
            <p>{DATA_FIELD_VALUES["deliveryType"][deliveryType]}</p>
            <p>{route}</p>
          </>
        );
        
      },
    },
    {
      title: TABLE_ORDERLIST_TITLE.Customer_Instruction,
      width: 180,
      render: (rowData) => {
        const { add_on, origid, deliveryType, dstid, origpid, dstpid } = rowData;
      
        return exportState ? (
          null
        ) : (
          <>
          {
            deliveryType === 'p2p' ? 
            <>
              <p>{`Self Lodge At Point ${points[origpid] && points[origpid].code}`}</p>
              <p>{`Self Collect At Point ${points[dstpid] && points[dstpid].code}`}</p>
            </> 
            : 
            (Array.isArray(add_on) && add_on.length > 0 && add_on.every(value => value !== null)? 
               <>
               <p>Doorstep Pickup</p>
               <p>{deliveryType === 'h2h' ? `Self Collect At Hub ${hubs[dstid] && hubs[dstid].code}`:'Doorstep Drop Off'}</p>
               </>
               : 
               <>
               <p>{`Self Lodge At Hub ${hubs[origid] && hubs[origid].code}`}</p>
               <p>{deliveryType === 'h2h' ? `Self Collect At Hub ${hubs[dstid] && hubs[dstid].code}`:'Doorstep Drop Off'}</p>
               </>)
          }
            
          </>
        );
      },
    },
    {
      title: TABLE_ORDERLIST_TITLE.plate,
      width: 180,
      render: (rowData) => {
        const { v_plate } = rowData;

        const driver = vehicle[v_plate] && vehicle[v_plate].driver || rowData.driver;
        const vehicleData = v_plate ? `${v_plate} / ${driver}` : "-"

        return exportState ? (
          vehicleData
        ) : (
          <>
            <>{v_plate ? `${v_plate} / ${driver}`: "-"}</>
          </>
        );
      },
    },
    {
      title: TABLE_ORDERLIST_TITLE.Origin_Point,
      width: 180,
      render: (rowData) => {
        const { origpid, etd, deliveryType } = rowData;

        
        const origin = deliveryType === 'p2p' ? 'P' + (points[origpid] && points[origpid].code) : "-"
        const date = deliveryType === 'p2p' ? parseTimeMinDayFirst(etd) : '-'

        return exportState ? (
          origin
        ) : (
          
          <>
            <p>{origin}</p>
            <p>{date}</p>
          </>
        );
      },
    },
    {
      title: TABLE_ORDERLIST_TITLE.Origin_Hub,
      key: TABLE_ORDERLIST_INDEX.Origin,
      width: 180,
      render: (rowData) => {
        const { origid, etd, deliveryType } = rowData;

        const date = deliveryType === 'p2p' ? '-' : (parseTimeMinDayFirst(etd) || '-')

        return exportState ? (
          (hubs[origid] && hubs[origid].code) || "-"
        ) : (
          
          <>
            <p>H{(hubs[origid] && hubs[origid].code) || "-"}</p>
            <p>{date}</p>
          </>
        );
      },
      filters: filterHDLR.getColumnsFilterHub(hubs),
      onFilter: (value, record) => record.origid === value,
    },
    {
      title: TABLE_ORDERLIST_TITLE.Destination_Hub,
      key: TABLE_ORDERLIST_INDEX.Destination,
      width: 180,
      render: (rowData) => {
        const { dstid, eta, deliveryType } = rowData;

        const date = deliveryType === 'p2p' ? '-' : (parseTimeMinDayFirst(eta) || '-')

        return exportState ? (
          (hubs[dstid] && hubs[dstid].code) || "-"
        ) : (
          <>
            <p>H{(hubs[dstid] && hubs[dstid].code) || "-"}</p>
            <p>{date}</p>
          </>
        );
      },
      filters: filterHDLR.getColumnsFilterHub(hubs),
      onFilter: (value, record) => record.dstid === value,
    },
    {
      title: TABLE_ORDERLIST_TITLE.Destination_Point,
      width: 180,
      render: (rowData) => {
        const { destpid, eta, deliveryType, dstpid } = rowData;

        const dest = deliveryType === 'p2p' ? 'P' + (points[dstpid] && points[dstpid].code || points[destpid] && points[destpid].code) : "-"
        const date = deliveryType === 'p2p' ? parseTimeMinDayFirst(eta) : '-'

        return exportState ? (
          dest
        ) : (
          
          <>
            <p>{dest}</p>
            <p>{date}</p> 
          </>
        );
      },
    },
    
    {
      title: TABLE_ORDERLIST_TITLE.Price,
      key: TABLE_ORDERLIST_INDEX.Price,
      width: 180,
      render: (record) => {
        const { price, priceSort, priceInfo = {} } = record;

        const addOnArr = priceInfo.unsort && priceInfo.unsort.length > 0 ? priceInfo.unsort.reduce((acc, currentObj) => {
          return { ...acc, ...currentObj };
        }, {}) : {};
  
          const addOnSortArr = priceInfo.sort && priceInfo.sort.length > 0 ? priceInfo.sort.reduce((acc, currentObj) => {
            return { ...acc, ...currentObj };
        }, {}) : {};

          const totalPrice = (price || 0) + (addOnArr.addon || 0)
          const totalSortPrice = priceSort && (priceSort || 0) + (addOnSortArr.addon || 0)

        return exportState ? 
        (`est: ${totalPrice} / sort: ${totalSortPrice}`) :
        (
          <>
            <p>Est: RM {totalPrice}</p>
            <p>Sorted: {priceSort ? `RM ${totalSortPrice}` : null}</p>
          </>
        ) 
      }
    },
    {
      title: TABLE_ORDERLIST_TITLE.Parameter,
      key: TABLE_ORDERLIST_INDEX.parameter,
      width: 250,
      render: (rowData) => {
        const { parcel = {}, sorted, version} = rowData;
        const { sort: newSort = [], sort: oldSort = {}, unsort = [] } = parcel

        const oldSortData = version === undefined && Object.values(oldSort).length 

        const volume = par => {
          return Object.values(par).reduce((sum, p) => {
              const qty = parseFloat(p && p.qty) || 0;
              const length = parseFloat(p && p.length) || 0;
              const width = parseFloat(p && p.width) || 0;
              const height = parseFloat(p && p.height) || 0;
      
              return sum + (qty * ((length * width * height) / 1000000));
          }, 0).toFixed(6); 
        };     
   
        const unsortVolumeNew = volume(unsort)
        const unsortVolumeOld = unsort.length === 0 && volume(parcel)

        const estVolume = unsort.length > 0 ? unsortVolumeNew : unsortVolumeOld
        const volumeEstTotal = estVolume ? `${estVolume}m3` : `${0}m3`

        const sortVolumeNew = volume(newSort)
        const sortVolumeOld = version === undefined && volume(oldSort)

        const sortVolume = newSort.length > 0 ?  sortVolumeNew : sortVolumeOld  
        const volumeSortTotal = sortVolume ? `${sortVolume}m3` : `${0}m3`

        const totalWeight = par => {
          return Object.values(par).reduce((sum, p) => {
              const qty = parseFloat(p && p.qty) || 1;
              const weight = parseFloat(p && p.weight) || 0;
      
              return sum + (qty * weight);
          }, 0)
        };       

        const unsortWeightNew = totalWeight(unsort)
        const unsortWeightOld = unsort.length === 0 && totalWeight(parcel)

        const estWeight = unsort.length > 0 ? unsortWeightNew : unsortWeightOld
        const weightEstTotal = estWeight ? `${estWeight}kg` : `${0}kg`
       
        const sortOld = version === undefined && [Object.assign({}, oldSort)];
        const sortWeightNew = totalWeight(newSort)
        const sortWeightOld = totalWeight(sortOld) 

        const sortWeight = sortOld.length > 0 ? sortWeightOld : sortWeightNew 
        const weightSortTotal = sortWeight ? `${sortWeight}kg` : `${0}kg`

        const totalQty = par => {
          return Object.values(par).reduce((sum, p) => {
              const qty = parseFloat(p && p.qty) || 0;
      
              return sum + qty;
          }, 0)
        }; 

        const sortQty = totalQty(newSort)
        const unsortQuantity = parcel.total_pieces || 1
        const sortQuantity = parcel.sorted_total_pieces || sortQty
        
        const est = `${unsortQuantity}/ ${volumeEstTotal}/ ${weightEstTotal} `
        const sortData = `${sortQuantity || unsortQuantity}/ ${volumeSortTotal}/ ${weightSortTotal}`


        return exportState ? (
          `est: ${est} / sort: ${sortData}` 
        ) : (
          <>
            <p>
              Est: {est}
            </p>
            {newSort.length > 0 || oldSortData > 0 ? 
            <p>Sorted: {sortData}</p> :
            <p>Sorted: </p>
            }
          </>
        );
      },
    },
    {
      title: TABLE_ORDERLIST_TITLE.Invoice_Status,
      key: TABLE_ORDERLIST_INDEX.Invoice,
      width: 180,
      render: (record) => {
        const { url, jnid, statusBill } = record;

        const { name, colorTag } = OPTION_STATUS_TITLE[statusBill] || "";

        const handleButtonClick = () => {
          window.open(url && url.jn, '_blank');
        };

        const journal = () => { 
          if(jnid){
            if (url && url.jn === undefined || url && url.jn === "" || url && url.jn === "-") {
              return exportState ? `${jnid}` : (
                <Text disabled>{jnid}</Text>
              );
            } else {
              return exportState ? `${jnid}/ ${name}` :(
              <span> <ButtonLink onClick={handleButtonClick}>
                  {jnid}
                </ButtonLink>
                <Tag color={colorTag} key={jnid}>
                {name}
              </Tag>
              </span>
              )
            }
        } else {
          return exportState && "-"
        }
        };

        const journalid = journal()

        return journalid
      }
    },
    {
      title: TABLE_ORDERLIST_TITLE.customId,
      key: TABLE_ORDERLIST_INDEX.customId,
      width: 250,
      render: (record) => {
        const { memid, name, contact } = record;

        return exportState ? `${memid}/ ${name} / ${contact}` : <> {memid}/ {name} / {contact}</>
      },
      ...searchHDLR.getColumnSearchProps("memid", handleSearch) 
    },
    {
      title: TABLE_ORDERLIST_TITLE.Orders_Remarks,
      key: TABLE_ORDERLIST_INDEX.Orders_Remarks,
      width: 180,
      render: (render) => {
 
        const {ordid, status, parcel = {} } = render

        return exportState ? parcel?.remark : (
          <div style={{ display: "flex", alignItems: "center", gap: "5px"}}>
            <>{comments?.[ordid]?.length || 0}</>
              <ButtonLink onClick={ () => {
                setIsOpen(true)
                setData({ordid, status})
              }} >
                <img src={icon_comment_parcel} alt="" />
              </ButtonLink>
            <>{parcel.remark || "-"}</>
          </div>
        )
      },
    },
    {
      title: TABLE_ORDERLIST_TITLE.Last_Updated,
      key: TABLE_ORDERLIST_INDEX.Last_Updated,
      width: 300,
      render: (record) => {
        const { updatedAt, updatedBy, status, origid, dstid } = record

        const opData = operators[updatedBy];
        const opName = opData ? opData.name : "-";

        const hubData = () => {
          if (status <= 'O0300') {
            return hubs[origid] && hubs[origid].code; 
          } else if (status >= 'O0400') {
            return hubs[dstid] && hubs[dstid].code; 
          }
          return null; 
        };

        const hub = hubData()

        const name = opData ? opName : admins.name;
        const role = opData ? `Hub Operator ${hub}` : "Admin" ;

        return exportState ? 
        `${parseTimeMinDayFirst(updatedAt)} ${updatedBy}/ ${name}/ ${role}` : 
        (
          <>
            <p>{parseTimeMinDayFirst(updatedAt)}</p>
            <p>{updatedBy}/ {name}/ {role}</p>
          </>
        )
      },
      sorter: (a, b) => a.updatedAt - b.updatedAt,
    },
    {
      title: TABLE_ORDERLIST_TITLE.Created_At,
      key: TABLE_ORDERLIST_INDEX.Created_At,
      width: 180,
      render: (record) => {
        const {createdAt} = record
        return exportState ? parseTimeMinDayFirst(createdAt) : <>{parseTimeMinDayFirst(createdAt)}</>
      },
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      title: TABLE_ORDERLIST_TITLE.SO_Printed,
      key: TABLE_ORDERLIST_INDEX.SO_Printed,
      width: 150,
      render: (rowData) => {
        const { soid, printedAt } = rowData;

        return exportState ? (
          parseTimeMin(printedAt)
        ) : (
          <>
            <ButtonLink onClick={() => SORenavigate(soid)}>{soid}</ButtonLink>
            <p>{parseTimeMin(printedAt)}</p>
          </>
        );
      },
      sorter: (a, b) => a.printedAt - b.printedAt,
    },
  ];
};
