import { message } from "antd";
import * as API from "../../api";
import * as ActionTypes from "../action-types";
import { TIME } from "../../util";

// Redux Action
import {
  setLoadingOrderUpdate,
  setLoadingOrderList,
  setLoadingOrderComment,
  unsetLoadingOrderList,
  unsetLoadingOrderUpdate,
  unsetLoadingOrderComment
} from "./style";


// Neccessary Actions
import { v1_billing, v1_journalNote } from '../actions'

const { lastNDay, latestTime, currentTimeMilliSecond } = TIME;

// Get Order TimeFrame Data

export const get_order_timeframe_thru_porgid_last_90_days_request =
  (porgids = ["ipick"]) =>
  (dispatch) => {
    dispatch(
      get_order_timeframe_thru_porgid_request(porgids, lastNDay(90), currentTimeMilliSecond())
    );
  };

export const get_order_timeframe_thru_porgid_request =
  (porgids, startTime, endTime) => (dispatch) => {
    dispatch(setLoadingOrderList());

    API.getOrderTimeFrameThruPORGID({
      porgids,
      startTime,
      endTime,
    })
      .then((info) => {
        const { status, orders, msg } = info;

        if (status === 200) {
          dispatch(get_order_timeframe_thru_porgid_success(orders));
          message.success("Get Order List Data Successfully");
        } else {
          message.warning(msg);
        }
      })
      .finally(() => {
        dispatch(unsetLoadingOrderList());
      });
  };

const get_order_timeframe_thru_porgid_success = (orders) => {
  return {
    type: ActionTypes.ORDER_TIMEFRAME_GET_TRU_PORGID,
    orders,
  };
};

export const get_order_by_ordid_success = (order) => {
  return {
    type: ActionTypes.ORDER_GET_BY_ORDID,
    order,
  };
};

// Order Update

export const update_order_request =
  ({ order = {}, statusChange = 0, orderState = {}, update = {}, customer = {}, attachment = [], sorting }, cb = () => {}) =>
  (dispatch) => {
    dispatch(setLoadingOrderUpdate())
    API.updateOrder({
      order, 
      statusChange,
      orderState,
      update,
      customer, 
      attachment,
      sorting
    })
      .then((info) => {
        const { status, msg, orderState, billing, journalNote } = info;

        const od = {
          ...order, 
          updatedAt: statusChange === false ? update.updatedAt : orderState && info.orderState.updatedAt,
          status: statusChange === false ? order.status : orderState && info.orderState.status}

        if (status === 200) {
          dispatch(update_order_success(od));
          journalNote &&
          dispatch(v1_journalNote.set_journalnote_success(info.journalNote))
          //if billing return exist, do create_billing_success
          billing && 
          dispatch(v1_billing.create_billing_success(info.billing))
          message.success("Update Order Successfully");
        } else {
          message.warning(msg);
        }
      })
      .finally(() => {
        dispatch(unsetLoadingOrderUpdate());
        cb()
      });
  };

export const update_order_success = (order) => {
  return {
    type: ActionTypes.ORDER_UPDATE,
    order,
  };
};

export const submit_bulk_order_request =
  (requestData, cb = () => {}) =>
  (dispatch) => {
    dispatch(setLoadingOrderList());

    API.submitBulkOrder(requestData)
      .then((info) => {
        const { status, orders, msg } = info;

        if (status === 200) {
          dispatch(submit_bulk_order_success(orders));
          message.success("Uploaded Bulk Order Successfully");
        } else {
          message.error(msg);
        }
        dispatch(unsetLoadingOrderList());
      })
      .finally(() => {
        dispatch(unsetLoadingOrderList());
        cb();
      });
  }; 

const submit_bulk_order_success = (orders) => {
  return {
    type: ActionTypes.ORDER_BULK_SUBMIT,
    orders,
  };
};


export const order_assign_vehicle =
  ({orders, v_plate, adpMemid}, cb) => (dispatch) => {
    dispatch(setLoadingOrderList());

    API.getOrderAssignVehicle({
        orders,
        v_plate,
        adpMemid,
    })
      .then((info) => {
        const { status, orders, msg } = info;

        if (status === 200) {
          dispatch(order_assign_vehicle_success(orders));
          message.success("Order Assign Vehicle Successfully");
        } else {
          message.warning(msg);
        }
      })
      .finally(() => {
        cb()
        dispatch(unsetLoadingOrderList());
      });
  };

export const order_assign_vehicle_success = (orders) => {
  return {
    type: ActionTypes.ORDER_ASSIGN_VEHICLE,
    orders,
  };
};


// ORDER COMMENT REDUX

    export const get_order_comment_request = (ordid) => dispatch => {

    dispatch(setLoadingOrderComment())

    API.getOrderCommentThruOrdid({
        ordid,
        startTime: lastNDay(90),
        endTime: latestTime
    }).then(info => {
        const { status, msg, orderComment } = info

        const newOrderComment = orderComment.filter(oc => oc.active === 1 || oc.active === undefined)

        if(status === 200) {
            dispatch(get_order_comment_success(newOrderComment, ordid))
        } else {
            message.warning(msg)
        }
    })
    .finally(() => {
        dispatch(unsetLoadingOrderComment())
    })
}

export const create_order_comment_data = (items) => (dispatch) => {

    dispatch(setLoadingOrderComment())

    API.setOrderComment({
        ordid: items.ordid,
        action: "C",
        orderComment: items
    }).then((info) => {
        const { status, orderComment, msg } = info;

        if (status === 200) {
            dispatch(add_order_comment_success(orderComment));
            message.success(msg)
        } else {
            message.warning(msg);
        }
    })
    .finally(() => {
        dispatch(unsetLoadingOrderComment())
    })
};

export const edit_order_comment_data = (items) => (dispatch) => {

    dispatch(setLoadingOrderComment())

    API.updateOrderComment({
        ordid: items.ordid,
        action: "U",
        items,
    }).then((info) => {
        const { status, orderComment, msg } = info;
        
        if (status === 200) {
            message.success(msg)
            dispatch(update_order_comment_success(orderComment))
        } else {
            message.warning(msg);
        }
    })
    .finally(() => {
        dispatch(unsetLoadingOrderComment())
    })
};

export const archive_order_comment_data = (items) => (dispatch) => {

    dispatch(setLoadingOrderComment())
    
    const newItems = {
        ...items,
        active: 0
    }

    API.updateOrderComment({
        ordid: items.ordid,
        action: "U",
        items: newItems
    }).then((info) => {
        const { status, orderComment, msg } = info;

        if (status === 200) {
            dispatch(update_order_comment_success(orderComment));
            message.success("Order comment deleted successfully")
        } else {
            message.warning(msg);
        }
    }).finally(() => {
        dispatch(unsetLoadingOrderComment())
    });
}

const get_order_comment_success = (orderComment, ordid) => {
    return {
        type: ActionTypes.GET_ORDER_COMMENT_THRU_ORDID,
        orderComment, ordid
    };
};

const add_order_comment_success = (orderComment) => {
    return {
        type: ActionTypes.ADD_ORDER_COMMENT,
        orderComment,
    };
};

const update_order_comment_success = (orderComment) => {
    return {
        type: ActionTypes.UPDATE_ORDER_COMMENT,
        orderComment,
    };
};

