import * as API_CONSTANT from "../constants";

export const getAllZones = () =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/zone/get/all`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({}),
  }).then((res) => res.json());

export const getPricelistThruZoneid = (zoneid) =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/zone/price/get/zoneid`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      zoneid
    }),
  }).then((res) => res.json());

  export const updateZonePriceList = (items) =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/zone/update`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify(items),
  }).then((res) => res.json());

  export const updateStatusZonePriceList = (items) =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/zone/price/update`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify(items),
  }).then((res) => res.json());

  export const createZone = (items) =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/zone/create`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify(items),
  }).then((res) => res.json());
