import React from "react";
import { HomeOutlined } from "@ant-design/icons";

// Constants
import { FEATURE_NAME, FEATURE_TYPE } from "../common/features";
import { ROOT_CONSTANT } from "../index";

// Services

const { ICON } = ROOT_CONSTANT.IMG;

const {
  icon_order_management,
  icon_trip_manifest,
  icon_customer_wallet,
  icon_transaction_list,
  icon_billing_list,
  icon_user_list,
  icon_hub_list,
  icon_point,
  icon_report,
  icon_my_account,
  icon_logout,
  icon_vehicle_list
} = ICON.SIDEBAR;

export const FEATURES = FEATURE_NAME;

export const FEATURE_LABEL = {
  // Order
  [FEATURE_NAME.ORDER_MANAGEMENT]: "Order Management",
  [FEATURE_NAME.ORDER_LIST]: "Order List",
  [FEATURE_NAME.ORDER_PARKED_LIST]: "Parked Order List",
  // Finance
  [FEATURE_NAME.CUSTOMER_WALLET_MANAGEMENT]: "Customer Wallet",
  [FEATURE_NAME.TRANSACTION_LIST]: "Transaction List",
  [FEATURE_NAME.BILLING_LIST]: "Billing List",
  // User
  [FEATURE_NAME.USER_MANAGEMENT]: "Customer Management",
  [FEATURE_NAME.CUSTOMER_LIST]: "Customer List",
  [FEATURE_NAME.ACCOUNT_MANAGER]: "Account Manager List",
  // App Info
  [FEATURE_NAME.DASHBOARD]: "Dashboard",
  [FEATURE_NAME.ACCOUNT_PROFILE]: "My Account",
  [FEATURE_NAME.LOGOUT]: "Log out",
  // Vehicle
  [FEATURE_NAME.VEHICLE_MANAGEMENT]: "Vehicle Management",
  [FEATURE_NAME.VEHICLE_LIST]: "Vehicle List",
  // Hub
  [FEATURE_NAME.HUB_MANAGEMENT]:"Hub Management",
  [FEATURE_NAME.HUB_OPERATOR_LIST]:"Hub Operator List",
  [FEATURE_NAME.HUB_LIST]: "Hub List",
	//Point
	[FEATURE_NAME.POINT_MANAGEMENT]: "Point Management",
	[FEATURE_NAME.POINT_OPERATOR_LIST]: "Point Operator List",
	[FEATURE_NAME.POINT_LIST]: "Point List",
  //price
  [FEATURE_NAME.PRICE_MANAGEMENT] : "Price Management",
  [FEATURE_NAME.ZONE_PRICE_LIST] : "Zone Price List",
  //Report 
  [FEATURE_NAME.REPORT] : 'Report'
};

const MENU_ICON_SIZE = 24;

const MENU_COMPONENT_ITEM = (menu) => ({
  key: menu.feature,
  icon:
    (menu.icon && <menu.icon style={{ fontSize: MENU_ICON_SIZE }} />) || null,
  label: FEATURE_LABEL[menu.feature],
  feature: menu.feature,
  type: menu.type || null,
  children:
    (menu.children && menu.children.map((m) => MENU_COMPONENT_ITEM(m))) || [],
  disabled: menu.disabled,
  function: menu.function,
});

export const FEATURE_MENU_ITEMS_OBJ = {
  [FEATURE_NAME.DASHBOARD]: {
    feature: FEATURE_NAME.DASHBOARD,
    icon: HomeOutlined,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.ORDER_MANAGEMENT]: {
    feature: FEATURE_NAME.ORDER_MANAGEMENT,
    icon: icon_order_management,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.ORDER_LIST]: {
    feature: FEATURE_NAME.ORDER_LIST,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.ORDER_PARKED_LIST]: {
    feature: FEATURE_NAME.ORDER_PARKED_LIST,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  // [FEATURE_NAME.TRIP_MANIFEST]: {
  //   feature: FEATURE_NAME.TRIP_MANIFEST,
  //   icon: icon_trip_manifest,
  //   routeType: FEATURE_TYPE.ROUTE,
  //   disabled: true, 
  // },
  [FEATURE_NAME.CUSTOMER_WALLET_MANAGEMENT]: {
    feature: FEATURE_NAME.CUSTOMER_WALLET_MANAGEMENT,
    icon: icon_customer_wallet,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.TRANSACTION_LIST]: {
    feature: FEATURE_NAME.TRANSACTION_LIST,
    icon: icon_transaction_list,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.BILLING_LIST]: {
    feature: FEATURE_NAME.BILLING_LIST,
    icon: icon_billing_list,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.USER_MANAGEMENT]: {
    feature: FEATURE_NAME.USER_MANAGEMENT,
    icon: icon_user_list,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.CUSTOMER_LIST]: {
    feature: FEATURE_NAME.CUSTOMER_LIST,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.ACCOUNT_MANAGER]: {
    feature: FEATURE_NAME.ACCOUNT_MANAGER,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.HUB_MANAGEMENT]: {
    feature: FEATURE_NAME.HUB_MANAGEMENT,
    icon: icon_hub_list,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.HUB_OPERATOR_LIST]: {
    feature: FEATURE_NAME.HUB_OPERATOR_LIST,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.HUB_LIST]: {
    feature: FEATURE_NAME.HUB_LIST,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.POINT_MANAGEMENT]: {
    feature: FEATURE_NAME.POINT_MANAGEMENT,
    icon: icon_point,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.POINT_OPERATOR_LIST]: {
    feature: FEATURE_NAME.POINT_OPERATOR_LIST,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.POINT_LIST]: {
    feature: FEATURE_NAME.POINT_LIST,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.PRICE_MANAGEMENT]: {
    feature: FEATURE_NAME.PRICE_MANAGEMENT,
    icon: icon_billing_list,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.ZONE_PRICE_LIST]: {
    feature: FEATURE_NAME.ZONE_PRICE_LIST,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.VEHICLE_MANAGEMENT]: {
    feature: FEATURE_NAME.VEHICLE_MANAGEMENT,
    icon: icon_vehicle_list,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.VEHICLE_LIST]: {
    feature: FEATURE_NAME.VEHICLE_LIST,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.REPORT]: {
    feature: FEATURE_NAME.REPORT,
    icon: icon_report,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.ACCOUNT_PROFILE]: {
    feature: FEATURE_NAME.ACCOUNT_PROFILE,
    icon: icon_my_account,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.LOGOUT]: {
    feature: FEATURE_NAME.LOGOUT,
    icon: icon_logout,
    routeType: FEATURE_TYPE.LOGOUT,
    disabled: false,
  },
};

export const FEATURE_MENU_ITEMS = Object.values(FEATURE_MENU_ITEMS_OBJ);
export const FEATURE_MENU_ITEMS_LAYER_FIRST = [
  {
    ...FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.ORDER_MANAGEMENT],
    children: [
      FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.ORDER_LIST],
      FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.ORDER_PARKED_LIST],
    ],
  },
  FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.CUSTOMER_WALLET_MANAGEMENT],
  FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.TRANSACTION_LIST],
  FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.BILLING_LIST],
  {
    ...FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.USER_MANAGEMENT],
    children: [
      FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.CUSTOMER_LIST],
      FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.ACCOUNT_MANAGER],
    ],
  },
  {
    ...FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.HUB_MANAGEMENT],
    children:[
      FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.HUB_OPERATOR_LIST],
      FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.HUB_LIST],
    ]
  },
  {
    ...FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.POINT_MANAGEMENT],
    children:[
      FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.POINT_OPERATOR_LIST],
      FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.POINT_LIST],
    ]
  },
  {
    ...FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.PRICE_MANAGEMENT],
    children:[
      FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.ZONE_PRICE_LIST],
    ]
  },
  {
    ...FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.VEHICLE_MANAGEMENT],
    children: [
      FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.VEHICLE_LIST],
    ],
  },
  FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.REPORT],
];
export const FEATURE_MENU_ITEMS_LAYER_SECOND = [
  FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.ACCOUNT_PROFILE],
  FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.LOGOUT],
];

export const FEATURE_MENU_ITEMS_COMP = FEATURE_MENU_ITEMS.map((menu) =>
  MENU_COMPONENT_ITEM(menu)
);
export const FEATURE_MENU_ITEMS_FIRST_LAYER_GROUP =
  FEATURE_MENU_ITEMS_LAYER_FIRST.map((menu) => MENU_COMPONENT_ITEM(menu));
export const FEATURE_MENU_ITEMS_SECOND_LAYER_GROUP =
  FEATURE_MENU_ITEMS_LAYER_SECOND.map((menu) => MENU_COMPONENT_ITEM(menu));
