import React, { useState, useEffect } from "react";
import { Form, Col, Row, Select, Input, InputNumber } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

// Constants
import { TEMPLATE } from "../../../../../../../../constants";

// Components
 
// Styles

// const { ACTIONS_LABEL_TITLE, ACTIONS_LABEL} = TEMPLATE.ORDER;
const { DATA_FIELD } = TEMPLATE.ORDER;

const SortingInformation = (props) => {
  const { form, formData, handleSummary, setPrice, setAddOnSort, onRemove } = props;

  const parcelQuantityType = (
    <Select defaultValue="pieces"> 
      <Select.Option value="pieces">Pcs</Select.Option>
      <Select.Option value="kg">Kg</Select.Option>
    </Select>
  )

  const handleChange = (e, field) => {
    field === 1 && form.setFieldValue("sort_price", e)
    field === 1 ? setPrice(e): setAddOnSort([{addon: e}])
  }

  return (
    <>
      <Form.List name={'parcel_sort'}>
        {(fields, { add, remove }) => {

          return (
            <>
              {fields.map((field, i) => {

                const { name } = field

                return (
                  <Row gutter={20}>
                    <Col span={5}>
                      <Form.Item label="Parcel Quantity" name={[name, "qty"]} rules={[{ required: true }]}>
                        <InputNumber min={1} addonAfter={parcelQuantityType} onChange={(e) => handleSummary(e, name, 'qty')}/>
                        {/* <Input type='number' addonAfter={parcelQuantityType} /> */}
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label="Parcel Length (cm)" name={[name, "length"]} rules={[{ required: true }]}>
                        <InputNumber min={0} type='number' onChange={(e) => handleSummary(e, name, 'length')} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label="Parcel Width (cm)" name={[name, "width"]} rules={[{ required: true }]}>
                        <InputNumber min={0} type='number' onChange={(e) => handleSummary(e, name, 'width')} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label="Parcel Height (cm)" name={[name, "height"]} rules={[{ required: true }]}>
                        <InputNumber min={0} type='number' onChange={(e) => handleSummary(e, name, 'height')}/>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label="Parcel Weight (kg)" name={[name, "weight"]} rules={[{ required: true }]}>
                        <InputNumber min={0} type='number' onChange={(e) => handleSummary(e, name, 'weight')}/>
                      </Form.Item>
                    </Col>
                    <Col span={2} style={{ display: 'flex', gap: '15px', fontSize: '1.5em' }}>
                          <PlusOutlined onClick={() => {add()}} />
                          {i > 0 && (
                            <DeleteOutlined onClick={() => {
                                onRemove(name);
                                remove(name);
                              }}
                            />
                          )} 
                      </Col>
                  </Row>
                )
              })}
            </>
          )
        }}
      </Form.List>
      <Row>
      <Col span={8}>
          <Form.Item
            label={"Sorted Price"}
            name={DATA_FIELD.price_sort}
            // rules={[{ required: label === sort && true }]}
          >
            <InputNumber 
            addonBefore="RM" style={{ width: "15vw" }} 
            onChange={(e) => handleChange(e, 1)}
            // disabled={  label === sort ? true : false|| deliveryType === 'p2p' && Object.keys(parcelHandle).length > 0 === false}
            // value={price}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
        <Form.List name={"priceInfo_sort"}>
        {(fields, {add, remove}) => {
          return (
            <>
              {fields.map((field, i) => {
                const { name } = field
                return (
                    <Form.Item label="Value Added Services" name={[name, "addon"]} rules={[{ required: true }]} >
                    <InputNumber
                      min={0} 
                      addonBefore="RM" style={{ width: "15vw" }} 
                      onChange={(e) => handleChange(e, 2)}
                      />
                    </Form.Item>
                )
              })}
            </>
          )
        }}
        </Form.List>
        </Col>
        {/* <Col span={8}>
          <Form.Item
            label={"Doorstep Drop Off"}
            name={DATA_FIELD.priceInfo_dropoff}
            rules={[{ required: label === sort && true }]}
          >
            <InputNumber addonBefore="RM" style={{ width: "15vw" }} 
            onChange={handleAmount}
            />
          </Form.Item>
        </Col> */}
      </Row>
    </>
  );
};

export default SortingInformation;
