export const FEATURE_NAME = {
	// Order
	ORDER_MANAGEMENT: "ORDER_MANAGEMENT",
	ORDER_LIST: "ORDER_LIST",
	ORDER_SORT: "ORDER_SORT",
	ORDER_SUBMISSION: "ORDER_SUBMISSION",
	ORDER_SUMMARY: "ORDER_SUMMARY",
	BULK_UPLOAD: "BULK_UPLOAD",
	ORDER_PARKED_LIST: "ORDER__PARKED_LIST",
	ORDER_TIMELINE: "ORDER_TIMELINE",
	// Finance
	CUSTOMER_WALLET_MANAGEMENT: "CUSTOMER_WALLET_MANAGEMENT",
	MANAGE_CREDIT: "MANAGE_CREDIT",
	MANAGE_CREDIT_INVOICE: "MANAGE_CREDIT_INVOICE",
	TRANSACTION_LIST: "TRANSACTION_LIST",
	BILLING_LIST: "BILLING_LIST",
	// User
	USER_MANAGEMENT: "USER_MANAGEMENT",
	CUSTOMER_LIST: "CUSTOMER_LIST",
	ACCOUNT_MANAGER: "ACCCOUNT_MANAGER",
	// App Info
	DASHBOARD: "DASHBOARD",
	LANDING_PAGE: "LANDING_PAGE",
	ACCOUNT_PROFILE: "ACCOUNT_PROFILE",
	ACCOUNT_PROFILE_DATA: "ACCOUNT_PROFILE_DATA",
	ACCOUNT_PROFILE_PWD_CHANGE: "ACCOUNT_PROFILE_PWD_CHANGE",
	ACCOUNT_PROFILE_ADDRESS_BOOK: "ACCOUNT_PROFILE_ADDRESS_BOOK",
	LOGOUT: "LOGOUT",

	ACCOUNT_MANAGER_SIGN_UP: "ACCOUNT_MANAGER_SIGN_UP",
	ACCOUNT_MANAGER_CREATED: "ACCOUNT_MANAGER_CREATED",

	// Vehicle
	VEHICLE_MANAGEMENT: "VEHICLE_MANAGEMENT",
	VEHICLE_LIST: "VEHICLE_LIST",
	VEHICLE_MAP: "VEHICLE_MAP",

	//Hub 
	HUB_MANAGEMENT : "HUB_MANAGEMENT",
	HUB_OPERATOR_LIST : "HUB_OPERATOR_LIST",
	HUB_LIST : "HUB_LIST",
  HUB_CREATE : "HUB_CREATE",
  HUB_EDIT: 'HUB_EDIT',

  // Point
  POINT_MANAGEMENT: "POINT_MANAGEMENT",
  POINT_OPERATOR_LIST: "POINT_OPERATOR_LIST",
  POINT_LIST: "POINT_LIST",
  POINT_FORM: "POINT_FORM",
  POINT_CREATE: "POINT_CREATE",
  POINT_EDIT: "POINT_EDIT",

  //price management
  PRICE_MANAGEMENT : "PRICE_MANAGEMENT",
  ZONE_PRICE_LIST : "ZONE_PRICE_LIST",

  //Report
  REPORT : 'REPORT'
};

export const FEATURE_TYPE = {
	ROUTE: "ROUTE",
	LOGOUT: "LOGOUT",
};

export const PAGE_TITLE = {
	[FEATURE_NAME.ORDER_SUBMISSION]: "Order List",
};
