// React Thirty Party Library
import { useSelector } from "react-redux";
import { Layout as LayoutAntd, Avatar, Space } from "antd";
import { useLocation } from "react-router";

// Constants Library
import { ROOT_CONSTANT, ROUTES } from "../../../constants";

// Components Library

// Handler Library

// Services Library
import { REDUX as REDUX_UTIL } from "../../../services/util";

// Redux Actions

// Styles Library
import "../../index.css";

const { Header } = LayoutAntd;

const { logo_ipick } = ROOT_CONSTANT.IMG.ICON;
const { ROUTE_TITLE_PATH } = ROUTES;

const { reduxStoreWrapper } = REDUX_UTIL;

const LayoutHeader = () => {
  const user = useSelector(reduxStoreWrapper("user"));

  const path = useLocation();
  const title = ROUTE_TITLE_PATH[path.pathname] || "";

  return (
        <Header className="sidebar-header-container">
            <h3  style={{fontSize: "20px", fontWeight:'bold'}}>{title}</h3>
            <Space>
                <Avatar size={40} src={logo_ipick} className="header-avatar" />
                <div>
                <p className="adm-name">{user.name}</p>
                <p className="adm-id">{user.memid}</p>
                </div>
            </Space>
        </Header>
  );
};

export default LayoutHeader;
