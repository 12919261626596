import * as API_CONSTANT from '../constants'

export const getShipmentOrderBySOID = ({soid}) => (
    fetch(`${API_CONSTANT.adp_v1_fe_path}/so/get/soid`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({soid})
    })
    .then(res => res.json())
)

export const getShipmentOrderByORDID = ({ordid}) => (
    fetch(`${API_CONSTANT.adp_v1_fe_path}/so/get/ordid`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({ordid})
    })
    .then(res => res.json())
)