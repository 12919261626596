// Third-party Library 
import { useState } from "react"
import { Upload, message } from "antd";

// Handler
import { uploadHDLR } from "../../../../../handlers";

import '../index.css'

const { 
    getBase64, 
    put2AssetS3CustomeRequest 
} = uploadHDLR;

const StatusUpdateAttachment = ({
    setStatusUpdatedAttachment, 
    statusUpdatedAttachment,
    icon_clip,
    data,
    edit
}) => {
    const [loading, setLoading] = useState(false)

    const validateFileType = (file) => {
        const fileTypes = [
            "image/jpeg",
            "image/jpg",
            "image/png",
            "application/pdf",
        ];
        return fileTypes.includes(file.type);
    };

    const handleBeforeUpload = (file) => {
        if(!validateFileType(file)) {
            message.error(`File type ${file.type} is not supported.`);
            return false;
        } else {
            return true;
        }
    }

    const handleChange = (info) => {
        const { status, originFileObj, response = {} } = info.file;

        if (status === "uploading") {
            setLoading(true);
            return;
        }
        if (status === "done") {
            getBase64(originFileObj, () => {
                const { Location: url } = response;
                setLoading(false);
                setStatusUpdatedAttachment(p => [...p, {url}]);
            });
        }
    }

    const imgUrls = data?.attachment;

    const img = imgUrls && imgUrls.map((u, index) => {
        return {
            uid : index,
            name: `attachment-${index}`,
            url: u.url,
            status: "done"
      }  
    })

    return (
    <>
        <Upload
            beforeUpload={handleBeforeUpload}
            onChange={handleChange}
            customRequest={put2AssetS3CustomeRequest}
            listType="picture-card"
            className="attachment"
            disabled={data && !edit}
            defaultFileList={img}
        >
        {imgUrls && img.length >= 8 ? null : "+ upload"}
        </Upload>
        </>
    ) 
}

export default StatusUpdateAttachment
