
// Config
import { APP_INFO } from '../../config'

// Styles
import classStyles from './index.module.css'

const CopyrightFooter = () => {
    return (
        <>
            <span className={`d-flex justify-content-center ${classStyles.footerClass}`}>
                 © {APP_INFO.PUBLICATION_COMPANY} {APP_INFO.COPYRIGHT_YEAR}. Copyright reserved.
            </span>
        </>
    )
}

export default CopyrightFooter