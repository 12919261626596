import * as ActionTypes from '../action-types'

const hub_defaultState = {
    byID: {},
    byCode: {},
    byStatus: {},
    allIDs: []
}

export const hub = (state = hub_defaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.HUB_GET_ALL: {

            const hubs = action.hubs

            hubs
            &&
            hubs.length
            &&
            hubs.map(hub => {

                const { hubid, code} = hub

                newState.byID[hubid] = hub
                newState.byCode[code] = hub

                if(!newState.allIDs.includes(hubid)) newState.allIDs.push(hubid)

                return null
            })

            return newState;
        }
        case ActionTypes.CREATE_HUB: {
          const items = action.items
          const { hubid, code } = items

          newState.byID[hubid] = items
          newState.byCode[code] = items

          return newState
        }
        case ActionTypes.GET_ACTIVE_HUBS: {

            const hubs = action.hubs

            hubs
            &&
            hubs.length
            &&
            hubs.map(hub => {

                const { hubid } = hub

                newState.byStatus[hubid] = hub

                if(!newState.allIDs.includes(hubid)) newState.allIDs.push(hubid)

                return null
            })
            return newState
        }
        case ActionTypes.UPDATE_HUB:{
            const items = action.hubs
            const hubid  = action.hubid
            
            newState.byID[hubid] = items

            return newState
        }
        default: {
            return state;
        }
    }
}
