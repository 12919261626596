import * as ActionTypes from '../action-types'

const vehicle_defaultState = {
    byID: {},
    byPlate: {},
    allIDs: [],
}

export const vehicle = (state = vehicle_defaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.VEHICLE_GET_ALL: {

            const vehicles = action.vehicle

            vehicles
            &&
            vehicles.length
            &&
            vehicles.map(vehicle => {

                const { vid, plate } = vehicle

                newState.byID[vid] = vehicle
                newState.byPlate[plate] = vehicle

                if(!newState.allIDs.includes(vid)) newState.allIDs.push(vid)

                return null
            })

            return newState;
        }
        case ActionTypes.CREATE_VEHICLE: {
            const vehicles = action.vehicle
            const { vid } = vehicles;
    
          if (vid) {
            newState.byID[vid] = vehicles;
    
            if (!newState.allIDs.includes(vid)) newState.allIDs.push(vid);
          }
    
          return newState;
        }
        case ActionTypes.UPDATE_VEHICLE: {
            const updatedVehicles = action.vehicle;
            const { vid } = updatedVehicles
      
            newState.byID[vid] = updatedVehicles

            return newState;
        }
        case ActionTypes.GET_VEHICLE_THRU_PLATE: {
            const plate = action.plate;
            const vid = action.vid
            const vehicle = action.vehicle;
      
            newState.byPlate[plate] = {...newState.byPlate[plate], ...vehicle, vid: vid };
            newState.byID[vid] = {...newState.byID[vid], ...vehicle , vid: vid};
      
            return newState;
          }
        case ActionTypes.UPDATE_STATUS_VEHICLE: {
            const updatedVehicles = action.vehicle;
            const { vid } = updatedVehicles
      
            newState.byID[vid] = updatedVehicles

            return newState;
        }
        default: {
            return state;
        }
    }
}
