import { useState } from "react";
import { Modal, Input } from "antd";
import { useDispatch } from "react-redux";

import { TEMPLATE } from "../../../../constants";
import * as REDUX_ACTION from "../../../../services/redux/actions";

import classes from "../common.module.css";

const { TextArea } = Input;
const { MODALS, MODAL_ACTION } = TEMPLATE.MODAL;

const ModalRemark = (props) => {
	const dispatch = useDispatch();
	const {
		setmodal,
		isModalOpen,
		setIsModalOpen,
		onClick,
		extra = {},
	} = props;
	const [textField, setTextField] = useState("");

	const handleOkButton = () => {
		onClick(textField);
		setIsModalOpen();

		switch (setmodal) {
			case MODAL_ACTION.park_resolved:
				dispatch(
					REDUX_ACTION.v1_parkOrder.update_parked_order_status(
						extra,
						textField
					)
				);
				break;
			case MODAL_ACTION.customer_remark:
				dispatch(
					REDUX_ACTION.v1_customerList.update_user_list_remark_request(
						{...extra, remark: textField}
					)
				);
				break;
		}
	};

	const content =
		(extra &&
			Object.keys(extra).length &&
			MODALS[setmodal].content(extra)) ||
		MODALS[setmodal].content;

	return (
		<Modal
			open={isModalOpen}
			footer={null}
			onCancel={setIsModalOpen}
			width={400}
		>
			<div className={classes["content-container"]}>
				<img src={MODALS[setmodal].img} alt="" />
				<h3>{MODALS[setmodal].title}</h3>
				<p className={classes.textCard}>{content}</p>
				<TextArea
					onChange={(e) => setTextField(e.target.value)}
					rows={6}
					style={{ resize: "none", margin: "12px 0 30px" }}
				/>

				<div className={classes["buttons"]}>
					<button
						onClick={setIsModalOpen}
						className={classes["button"]}
					>
						Back to list
					</button>
					<button
						onClick={handleOkButton}
						className={`${classes["button"]} ${classes["active"]}`}
					>
						Save and proceed
					</button>
				</div>
			</div>
		</Modal>
	);
};
export default ModalRemark;
