import { useEffect , useState } from "react";
import { useDispatch } from "react-redux";

import { Drawer, Form, Input, Select, message } from "antd";

import { NumericInput, BtnSubmit } from "../../../../../../components";

import * as REDUX_ACTION from "../../../../../../services/redux/actions";

import classStyles from "./index.module.css";

 

const DrawerFormEditAccManager = ({ isOpen, setIsOpen, data }) =>{
  
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);
  
  const handleSaveAndBackToList = () => {
    const formData = { ...data, ...form.getFieldsValue() };

    messageApi.open({
      type: "loading",
      content: "Updating...",
      duration: 0,
    });
    
    dispatch(REDUX_ACTION.v1_accountManager.edit_manager_account_info_request(formData,
      () => messageApi.destroy()));
   
    setIsOpen(false);
  };

    const prefixContactSelector = (
        <Form.Item name="contactPrefix" noStyle>
          <Select style={{ width: 70 }}>
            <Select.Option value="+60">+60</Select.Option>
          </Select>
        </Form.Item>
      );

    const ActionButton = (
        <Form.Item style={{ paddingTop: "20px" }}>
            <div>
            <BtnSubmit
              display="inline-block"
              text="Save & back to list"
              bgColor="var(--themeBackgroundColor)"
              color="var(--creamColor)"
              padding="9px 18px"
              onClick={handleSaveAndBackToList}
            />
            
            <BtnSubmit
                display="inline-block"
                text="Cancel"
                bgColor="transparent"
                color="var(--bs-gray-600)"
                padding="9px 18px"
                onClick={() => {
                setIsOpen(false);
                }}
            />
            </div>
        </Form.Item>
    );

    return (
      <>
      {contextHolder}
        <Drawer
          title="Edit Account Manager"
          placement="right"
          closable={false}
          width={410}
          onClose={() => setIsOpen(false)}
          footer={ActionButton}
          open={isOpen}
          style={{
            textAlign: "center",
            fontSize: "20px",
          }}
        >
          <Form
            form={form}
            name="form_login"
            layout="vertical"
            initialValues={{
              ...data,
              contactPrefix: "+60",
            }}
          >
          <Form.Item
              name="memid"
              label={<span>Customer ID</span>}
              rules={[
                {
                  required: true,
                  message: "Customer ID is required",
                },
              ]}
            >
              <Input className={classStyles.inputField} disabled/>
            </Form.Item>
            <Form.Item
              name="name"
              label={<span>Name</span>}
              rules={[
                {
                  required: true,
                  message: "Name is required",
                },
              ]}
              hasFeedback
            >
              <Input className={classStyles.inputField} />
            </Form.Item>
            <Form.Item
              name="email"
              label={<span>Email</span>}
            >
              <Input className={classStyles.inputField} readOnly/>
            </Form.Item>
            <Form.Item
              name="contact"
              label={<span>Contact</span>}
              rules={[
                {
                  required: true,
                  message: "Contact is required",
                },
              ]}
              hasFeedback
            >
              <NumericInput
                addonBefore={prefixContactSelector}
              />
            </Form.Item>
          </Form>
        </Drawer>
        </>
      );
}

export default DrawerFormEditAccManager;
