import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form , message} from "antd";

import { ROOT_CONSTANT } from "../../../../../../constants";

import {
  ProfileAction,
  ProfileData,
  ProfileForm
} from "./components"

import * as REDUX_ACTION from "../../../../../../services/redux/actions"

const { icon_edit } = ROOT_CONSTANT.IMG.ICON.MY_ACCOUNT;

const ProfileUser = ({
    user = {}, 
    userRoles = {}
}) => {
    const dispatch = useDispatch()
    const [isEdited, setIsEdited] = useState(false);

    const [form] = Form.useForm();

    const { uroleid = "C" } = user;
    const userRole =  "Admin"

    const [formDataSrc, setFormDataSrc] = useState({
        name: "",
        memid: "",
        email: "",
        contactPrefix: "+60",
        contact: "",
        address: "",
        uroleid: uroleid,
        postcode: "",
        city: "",
        state: "",
    });
    
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormDataSrc((prev) => ({
        ...prev,
        [name]: value,
        })
    )}
    
    useEffect(() => {
      if (user) {
        const {contact} = user
        const filteredContact = contact && contact.split('-')[1] || null

            const newFormData = {
                ...user,
                contactPrefix: filteredContact && contact.split('-')[0] || user.contactPrefix,
                contact: filteredContact || contact
            }

            setFormDataSrc(newFormData);
        }
    }, [user]);


    const handleSave = async () => {
        await form.validateFields()
            .then(() => {
                dispatch(REDUX_ACTION.v1_user.user_update_data_request(user.uid, formDataSrc))
                setIsEdited(false)
                message.success('Information updated!')
            })
            .catch(err => {
                message.error("Validation failed. Please ensure all fields are filled correctly.")
            })
        }

    return (
        <>
            <Form
                layout="vertical"
                autoComplete="off"
                style={{ marginTop: "10px" }}
                initialValues={{
                    contactPrefix: "+60",
                }}
                form={form}
            >
                <ProfileAction
                    isEdited={isEdited}
                    icon_edit={icon_edit}
                    setIsEdited={setIsEdited}
                    handleSubmit={handleSave}
                />
                {!isEdited ? (
                    <ProfileData
                        forms={user} 
                        userRole={userRole}
                    />
                ) : (
                    <Form
                        form={form}
                        layout="vertical"
                        autoComplete="off"
                        style={{ marginTop: "10px" }}
                        initialValues={formDataSrc} 
                    >
                        <ProfileForm 
                            formDataSrc={formDataSrc}
                            userRole={userRole}
                            handleChange={handleChange}
                            setFormDataSrc={setFormDataSrc}
                        />
                    </Form>
                )}
            </Form>       
        </>
    );
};

export default ProfileUser;
