import { Drawer, Form, Input, Select, message } from "antd";
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { NumericInput, BtnSubmit } from "../../../../../v1/components";

import { REDUX as REDUX_UTIL, TIME } from "../../../../services/util";

import * as REDUX_ACTION from "../../../../../v1/services/redux/actions";

import DrawerFormVehicle from "../DrawerFormVehicle";

const { reduxStoreWrapper } = REDUX_UTIL;

const DrawerEditVehicle = (props) => {
  const { isOpen, setIsOpen, data } = props

  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const user = useSelector(reduxStoreWrapper("user"))

  useEffect(() => {
    form.setFieldsValue({ ...data });
  }, [isOpen]);
  
  const updateVehicle = () => {
    const formData ={ updatedBy: user.memid, vid: data.vid, status: data.status, porgid: data.porgid, createdAt: data.createdAt, ...form.getFieldsValue()};
    formData.isTracking = parseInt(formData.isTracking);
    
    messageApi.open({
      type: "loading",
      content: "Updating...",
      duration: 0,
    });

    dispatch(REDUX_ACTION.v1_vehicle.update_vehicle_request(formData, () => {
      messageApi.destroy();
      setIsOpen(false);
    }));
  };

  return (
    <>
    {contextHolder}
    <DrawerFormVehicle
        form={form}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        type="edit"
        updateVehicle={updateVehicle}
        initialValue={{...data}}
        isTrackingValue={data.isTracking}
    />
    </>
  );
};

export default DrawerEditVehicle;
