// React Thirty Party Library

// Constants Library
import { TEMPLATE } from "../../../../constants";

// Components Library
import { TableList, BtnSubmit } from "../../../../components";

// Handler Librarys
import { exportHDLR } from "../../../../handlers";

// Services Library
import { TIME } from "../../../../services/util";

// Redux Actions

// Styles Library
import classes from "../../../../components/index.module.css";

const { TABLE_BILLING_INDEX, TABLE_BILLING_TITLE } = TEMPLATE.BILLING;
const { BTN_IMG } = TEMPLATE.BTN;
const { BILLING_SUMMARY_COL } = TEMPLATE.REPORT.BILLING;

const { parseExportTime } = TIME;

const SearchBilling = (props) => {
  const { setFilteredData, dataSource, filteredData, selectedRow} = props;

  const template = BILLING_SUMMARY_COL({exportState: true});

  const options = [
    {
      value: TABLE_BILLING_INDEX.Billing_ID,
      label: TABLE_BILLING_TITLE.Billing_ID,
      type: "text",
    },
    {
      value: TABLE_BILLING_INDEX.Billing_Date,
      label: "Custom Date",
      type: "timeframe",
    },
  ];

  const exportData = selectedRow.length > 0 ? selectedRow : filteredData;

  return (
    <div className={classes.headerPage}>
      <TableList.SearchTableQuery
        dataSource={dataSource}
        setFilteredData={setFilteredData}
        options={options}
        defaultValue={TABLE_BILLING_INDEX.Billing_ID}
        type="bill"
      />
      <div>
        <BtnSubmit
          display="inline-block"
          text="Export"
          bgColor="transparent"
          color="#4CAF50"
          padding="9px 18px"
          onClick={() =>
            exportHDLR.exportWithTemplate({
              data: exportData,
              filename: `BillingSummary_${parseExportTime()}`,
              template: template,
            })
          }
          showIcon={true}
          icon={BTN_IMG.EXPORT}
          icon_alt={"export"}
        />
      </div>
    </div>
  );
};

export default SearchBilling;
