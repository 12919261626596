import * as ActionTypes from "../action-types";

const billing_defaultState = {
  byID: {},
  allIDs: [],
  inv: {},
};

export const billing = (state = billing_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.GET_BILLING_LIST: {
      const billing = action.billing;

      billing &&
        billing.length &&
        billing.map((billing) => {
          const { billid } = billing;

          newState.byID[billid] = billing;

          if (!newState.allIDs.includes(billid)) newState.allIDs.push(billid);

          return null;
        });

      return newState;
    }
    case ActionTypes.GET_INVOICE_BILLING: {
      const bill = action.billing;

      newState.inv = bill;

      return newState;
    }

    case ActionTypes.CREATE_BILLING: {
      const bills = action.billing;
      const { billid } = bills;

      if (billid) {
        newState.byID[billid] = bills;
        if (!newState.allIDs.includes(billid)) newState.allIDs.push(billid);
      }

      return newState;
    }
    case ActionTypes.UPDATE_BILLING: {

      const bill = action.billing;
      const { billid } = bill;

      newState.byID[billid] = bill

      return newState
    }
    default: {
      return state;
    }
  }
};
