// React Thirty Party Library
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { 
  Input, 
  Button, 
  Select, 
  DatePicker 
} from "antd";
import { 
  SearchOutlined 
} from "@ant-design/icons";

// Constants Library

// Components Library

// Handler Library

// Services Library
import { TIME } from '../../../services/util'

// Redux Actions
import * as REDUX_ACTION from "../../../services/redux/actions"

// Styles Library
import classes from "../index.module.css";

const { RangePicker } = DatePicker;

const {todayStart, todayEnd, lastNDay, currentTimeMilliSecond } = TIME

const SearchTableQuery = ({
  dataSource,
  setFilteredData,
  options,
  defaultValue,
  type,
  style,
}) => {
  const dispatch = useDispatch();

  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [searchValue, setSearchValue] = useState("");
  const [searchDate, setSearchDate] = useState([todayStart, todayEnd])
  const [searchType, setSearchType] = useState('text');

  const setToZero = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0, 0); // Set time to 00:00:00
    return normalizedDate.getTime(); // Return epoch time in milliseconds
};

  const nFirst = setToZero(lastNDay(90))
  const nLast = setToZero(currentTimeMilliSecond())

  
  const handleSelectChange = (value) => {
    const option = options.find(o => o.value === value) || {}
    const { type = 'text' } = option

    setSelectedValue(value);
    setSearchValue("")
    setSearchType(type)
  };

  const handleSearch = () => {
    let filteredData = dataSource;  // Start with the default dataSource

    switch (searchType) {
        case 'timeframe': {
            const startDate = setToZero(new Date(searchDate?.[0]));
            const endDate = setToZero(new Date(searchDate?.[1]));
            const isInRange = (startDate >= nFirst) && (endDate <= nLast);
            const porgids = ["ipick"];

            if (!startDate || !endDate) {
              filteredData = dataSource;
              break;
          }

            if (!isInRange) {
              if(type){
                switch (type) {
                    case "ord":
                        dispatch(REDUX_ACTION.v1_order.get_order_timeframe_thru_porgid_request(porgids, startDate, endDate));
                        break; 
                    case "park":
                        dispatch(REDUX_ACTION.v1_parkOrder.get_parked_order_timeframe_request(startDate, endDate));
                        break; 
                    case "tnx":
                        dispatch(REDUX_ACTION.v1_transaction.get_transaction_timeframe_request(startDate, endDate));
                        break;  
                    case "bill":
                        dispatch(REDUX_ACTION.v1_billing.get_billing_timeframe_all_request(startDate, endDate));
                        break; 
                } 
              } else {
                  filteredData = dataSource.filter((item) => {
                      const itemDate = setToZero(new Date(item[selectedValue]).getTime());
                      return itemDate >= startDate && itemDate <= endDate;
                  });
              }
            } else {
                filteredData = dataSource.filter((item) => {
                    const itemDate = setToZero(new Date(item[selectedValue]).getTime());
                    return itemDate >= startDate && itemDate <= endDate;
                });
            }
            break;
        }
        case 'text':
            filteredData = dataSource.filter((item) => {
                const searchValueLowerCase = searchValue.toLowerCase();
                const dataLowerCase = item[selectedValue]?.toLowerCase();
                return dataLowerCase?.includes(searchValueLowerCase);
            });
            break;
        default:
            break;
    }
    setFilteredData(filteredData);
};

  const handleDateChange = (value) => {
    setSearchDate(value);
  };

  const renderInput = () => {
    switch(searchType) {
      case 'timeframe': {
        return (
          <>
            <RangePicker
              onChange={handleDateChange}
              style={{ width: 250, margin: 0 }}
              defaultValue={[todayStart, todayEnd]} 
              format="YYYY-MM-DD"
            />
            <Select
              defaultValue={selectedValue}
              onChange={handleSelectChange}
              style={{ width: 150, margin: 0, marginLeft: -10 }}
            >
              {options.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </>
        );
      }
      case 'text':
      default: {
        return (
          <Input
            addonAfter={
              <Select defaultValue={selectedValue} onChange={handleSelectChange} style={{ width: 150}}>
                {options.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            }
            style={{ width: 350, margin: 0 }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onPressEnter={handleSearch}
          />
        );
      }
    }
  };

  return (
    <div className={`${classes.search} ${style}`}>
      {renderInput()}
      <Button
        icon={<SearchOutlined />}
        style={{           
          display: "flex", 
          justifyContent: "center", 
          alignItems: "center"  
        }}
        onClick={handleSearch}
      />
    </div>
  );
};

export default SearchTableQuery;
