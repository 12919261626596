import 
    React,
    {
        useState,
        useEffect
    }
from 'react'
import { Button, Row, Col } from 'antd'

// Style
import cs from './index.module.css'

const CardBox = React.forwardRef((props, ref) => {

    const {
        title,
        open = false,
        children
    }  = props

    const [ collapse, setCollapse ] = useState(open)

    useEffect(() => {
        setCollapse(open)
    }, [open])

    return (
        <div ref={ref} className={cs.box}>
            <Row>
                <Col span={4}>
                    <h1 className={`card-title ${cs.title}`}>
                        {title}
                    </h1>
                </Col>
                <Col span={19}>
                <span className={collapse ? cs.childShown : cs.childNotShown}>
                    {children}
                </span>
                </Col>
                <Col span={1}>
                    <Button className={cs.arr} type="primary" shape="circle" value="small" onClick={() => setCollapse(!collapse)} >{collapse ? "▲" : "▼"}</Button>
                </Col>
            </Row> 
        </div>
    )
})

export default CardBox