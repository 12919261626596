// React Thirty Party Library
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "antd"; 

// Constants Library
import { TEMPLATE } from "../../../../../../constants";

// Components Library
import {
    TableList, 
    ButtonLink,
} from "../../../../../../components";
import { ActionItems } from "./action";

// Handler Library
import { userListHDLR, tableHDLR } from "../../../../../../handlers";

// Services Library
import { REDUX as REDUX_UTIL } from "../../../../../../services/util";

// Redux Actions
 
// Styles Library

const { 
    TABLE_USER_TITLE, 
    TABLE_USER_INDEX, 
    SELECT_OPTION_DAYS 
} = TEMPLATE.USERLIST;
const { 
    CUSTOMERLIST_SUMMARY_COL 
} = TEMPLATE.REPORT.CUSTOMERLIST;
const {
    reduxStoreWrapper
} = REDUX_UTIL

const TableCustomerList = ({
    dataSource,
    setIsOpenRemark,
    setIsOpenEdit,
    setIsOpenBusiness,
    setIsOpenChangePass,
    setData,
    filteredData,
    setFilteredData,
    loading,
    isBizAcc,
    selectedRow,
    setSelectedRow
}) => {

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const dispatch = useDispatch();
  const userList = useSelector(reduxStoreWrapper("customer.byID"))

  const customers = useSelector(reduxStoreWrapper("customer.byCM"));
  const accManager = Object.values(useSelector(reduxStoreWrapper("accountManager.byID")));
  const wallets = Object.values(useSelector(reduxStoreWrapper("wallet.byID")));


  const am = accManager.map(p => ({
    ...p
  }))

 
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const columns = [
    ...CUSTOMERLIST_SUMMARY_COL({redux:{wallets, customers, accManager}, func: {handleSearch}}, setIsOpenBusiness, setData),
    {
        title: TABLE_USER_TITLE.Action,
        dataIndex: TABLE_USER_INDEX.Action,
        key: TABLE_USER_INDEX.Action,
        width: 150,
        fixed: "right",
        render: (_, record) => {

            return (
            <Dropdown
                menu={{
                    items: ActionItems(record, dispatch, am, setIsOpenRemark, setIsOpenEdit, setIsOpenChangePass, setData),
                }}
            >
                <ButtonLink>Action</ButtonLink>
            </Dropdown>
            );
        },
    },
  ];

  const onSelectRow = (record, selected) => {
    tableHDLR.selectRow(setSelectedRowKeys, setSelectedRow, selectedRow, selectedRowKeys, record, selected)
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelectRow
  };

    const sortedData = filteredData.sort((a, b) => b.createdAt - a.createdAt);

    
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const sortFunctions = {
    createdAt: (a, b) => a.createdAt - b.createdAt,
  };
  
  const filtered = sortedData.filter((record) => {
    let include = true;
  
    Object.keys(filteredInfo).forEach((key) => {
      if (filteredInfo[key] && filteredInfo[key].length > 0) {
        include = include && filteredInfo[key].includes(record[key]);
      }
    });
  
    if (searchText) {
      if(searchedColumn === "memid") {
        if ((typeof record.memid === 'string' && record.memid.includes(searchText)) || 
            (typeof record.name === 'string' && record.name.includes(searchText))) {
          include = true;
        }
      }
      if(searchedColumn === "email") {
        if ((typeof record.email === 'string' && record.email.includes(searchText))) {
          include = true;
        }
      }
    }     

    return include;

  }).sort((a, b) => {
    const { columnKey } = sortedInfo;
    return sortFunctions[columnKey] ? sortFunctions[columnKey](a, b) : 0;
  });

    return (
        <div>
            <TableList.DataList
                dataSource={dataSource}
                filteredData={filtered}
                setFilteredData={setFilteredData}
                optionDay={SELECT_OPTION_DAYS}
                optionFirst={userListHDLR.userListStatus()}
                dataDate={TABLE_USER_INDEX.Created_At}
                defaultValue="CREATED AT"
                text1="USER STATUS"
                columnData={columns}
                showFirstFilter={true}
                rowSelection={rowSelection}
                loading={loading}
                onChange={handleTableChange}
            />
        </div>
    );
};
export default TableCustomerList;
