import React, { useState, useRef, useEffect } from "react";
import { Form, message } from "antd";
import { useSelector} from "react-redux";

import { TEMPLATE } from "../../../../../../constants";

// Components
import CardBox from "../CardBox";
import {
  DeliveryInstruction,
  ParcelInformation,
  ReceiverInformation,
  SenderInformation,
  PriceInformation,
  SortInformation,
} from "./components";

import { REDUX as REDUX_UTIL } from "../../../../../../services/util";

// Styles
import cs from "./index.module.css";

const { reduxStoreWrapper } = REDUX_UTIL

const { ACTIONS_LABEL_TITLE, ACTIONS_LABEL, DATA_FIELD } = TEMPLATE.ORDER;

const OrderSubmissionForm = (props) => {
  const { form, label,  hubs, hubsAll, order, points, setImgAttach } = props;

  const sort = ACTIONS_LABEL_TITLE[ACTIONS_LABEL.sort_receiver_order];
  
  const formName = "order_sorting";
  const formData = form.getFieldsValue()
  const [orderOpen, setOrderOpen] = useState({
    first: true,
    second: false,
    third: false,
    fourth: false,
    fifth: false,
    sixth: false
  });
  
  const box1 = useRef();
  const box2 = useRef();
  const box3 = useRef();
  const box4 = useRef();
  const box5 = useRef();

  
  const origZone = points[order.origpid]?.zonePrice;
  const dstZone = points[order.dstpid]?.zone;
  let total_pieces = order.parcel?.total_pieces
    
  const [price, setPrice] = useState(formData.price) 
  const [sortPrice, setSortPrice] = useState(formData.priceSort) 
  const par = order.parcel?.sort && order.parcel?.sort?.length > 0 ? order.parcel?.sort : order.parcel?.unsort
  const [tempQty, setTempQty] = useState(par || [])
  const [zone, setZone] = useState(null)

  useEffect(() => {
    if (!sortPrice) {
      setSortPrice(formData.priceSort); 
    }
  }, [formData.priceSort]);

  useEffect(() => {
    if(!price) {
      setPrice(formData.price);
    } 
  }, [formData.price]);

  useEffect(() => {
    if(zone === null) {
      setZone(origZone && origZone.find(z => z.dest === dstZone)); 
    }
  }, [origZone, dstZone]);

  useEffect(() => {
    setZone(zone); 
  }, [zone]);
  
  useEffect(() => {
    if (label === sort) {
      if(!formData.parcel_sort || formData.parcel_sort.length === 0){
        form.setFieldValue('parcel_sort', formData.parcel_unsort)
      }
      if(!formData.priceSort){
        form.setFieldValue('priceSort', formData.price);
      }
    }
  }, [formData.parcel_unsort, formData.price]);

  useEffect(() => {
      const data = tempQty.map((q, index) => ({
        ...q,
        key: index
      }))

    setTempQty(data); 
  }, []);

  const handleChangeSummary = (e, name, fname) => {
    let ordData = []
    setTempQty((qty) => {
      const data = qty.map((q, index) => ({
        ...q,
        key: index
      }));
  
      const foundIndex = data.findIndex(item => item.key === name);
  
      if (foundIndex !== -1) {
        return ordData = data.map((item, index) => (index === foundIndex ? { ...item, [fname]: parseFloat(e) } : item));
      } else {
        return ordData = [
          ...data,
          {
            key: name,
            [fname]: parseFloat(e),
          },
        ];
      }
    }); 

    if (ordData.length > 0 && order.deliveryType === "p2p") {
        const circ =
          ordData.reduce((sum, p) => {
            const total = parseFloat((p.qty || 1)) * (parseFloat((p.length || 0)) + parseFloat((p.width || 0)) + parseFloat((p.height || 0)))
            return parseFloat(sum) + total;
          }, 0)

        const zonePrice = () => {
          if (circ > 120) {
            return zone && zone.price * 2
          } else {
            return zone && zone.price
          }
        }
        const prices = zonePrice()

        if(label === sort) {
          setSortPrice(prices)
          form.setFieldValue("priceSort", prices)
        } else {
          setPrice(prices)
          form.setFieldValue("price", prices)
        }
        }
  };

  const onRemove = (name) => {
    setTempQty(tempQty.filter(item => item.key !== name));
  }
  
  const volume = par => 
    Object.values(par).reduce((sum, p) => {
        const qty = parseFloat(p && p.qty) || 0;
        const length = parseFloat(p && p.length) || 0;
        const width = parseFloat(p && p.width) || 0;
        const height = parseFloat(p && p.height) || 0;

        return sum + (qty * ((length * width * height) / 1000000));
    }, 0).toFixed(6); 

  const volumeNew = volume(tempQty)
  const totalVolume = parseFloat(volumeNew)

  const weight = par => 
    Object.values(par).reduce((sum, p) => {
        const qty = parseFloat(p && p.qty) || 0;
        const weight = parseFloat(p && p.weight) || 0;

        return sum + (qty * weight);
    }, 0).toFixed(2); 
  

  const weightNew = weight(tempQty)
  const totalWeight = parseFloat(weightNew)

  const pieces = par =>
  Object.values(par).reduce((sum, p) => {
    const qty = parseFloat(p && p.qty) || 0;
    return parseFloat(sum) + qty;
  }, 0).toFixed(2);

  const piecesNew = pieces(tempQty)
  const totalPieces = parseFloat(piecesNew)
  const totalPiecesData = Object.values(tempQty).length ? totalPieces : total_pieces

  const handlePointChange = (e, name) => {

    const circ =
      tempQty.reduce((sum, p) => {
        const total = parseFloat((p.qty || 1)) * (parseFloat((p.length || 0)) + parseFloat((p.width || 0)) + parseFloat((p.height || 0)))
        return parseFloat(sum) + total;
      }, 0)

    const orig = name === "org" ? e : order.origpid;
    const dest = name === "dest" ? e : order.dstpid
    const origZone = points[orig];
    const dstZone = points[dest];
    const newZone = origZone.zonePrice.find(z => z.dest === dstZone.zone)
    setZone(newZone)

      const zonePrice = () => {
        if (circ > 120) {
          return newZone && newZone.price * 2
        } else {
          return newZone && newZone.price
        }
      }

      const prices = zonePrice()
      setPrice(prices)
      form.setFieldValue("price", prices)
  }

  const [addOn, setAddOn] = useState(0) 
  const [addOnSort, setAddOnSort] = useState(0) 
  const title = label === sort ? "Sort" : "Estimated"

  useEffect(() => {
    setAddOn(formData.priceInfo_unsort)
  }, [formData.priceInfo_unsort]);

  useEffect(() => {
    setAddOnSort(formData.priceInfo_sort)
  }, [formData.priceInfo_sort]);

  const addOnArr = addOn && addOn.reduce((acc, currentObj) => {
    return { ...acc, ...currentObj };
  }, {});

  const addOnSortArr = addOnSort && addOnSort.reduce((acc, currentObj) => {
    return { ...acc, ...currentObj };
  }, {});

  const totalPrice = label === sort ? (sortPrice + ( addOnSort && addOnSortArr.addon || 0))  : (price + (addOn && addOnArr.addon || 0)) 

  return (
    <div className={cs.container}>
      <Form
        form={form}
        name={formName}
        layout={"vertical"}
        initialValues={{
          ...form.getFieldsValue,
          sender_contactPrefix: "+60",
          receiver_contactPrefix: "+60",
        }}
      >
        <div className={cs["banner"]}>
                  <span className={cs["banner-trapezoid"]}>
                      <div className={cs["banner-trapezoid-sub"]}>Summary</div>
                  </span>
                  <span className={cs["banner-sub"]}>
                      <div>{title} Parcel Quantity: {totalPiecesData}</div>
                      <div>{title} Total Volume (m³): {totalVolume}</div>
                      <div>{title} Total Weight (kg): {totalWeight}</div>
                      <div>{title} Price: {totalPrice}</div>
                  </span>
              </div>
        <CardBox
          ref={(e) => (box3.current = e)}
          title={"Sender Information"}
        >
          <SenderInformation
            formData={formData}
            onClick={() => {
              setOrderOpen({
                first: false,
                second: true,
                third: false,
                fourth: false,
                fifth: false,
              });
            }}
            label={label}
            sort={sort}
            hubs={hubs}
            hubsAll={hubsAll}
          />
        </CardBox>
        <CardBox
          ref={(e) => (box4.current = e)}
          title={"Receiver Information"}
          open={orderOpen.second}
          order={"second"}
        >
          <ReceiverInformation
            formData={formData}
            onClick={() => {
              setOrderOpen({
                first: false,
                second: false,
                third: true,
                fourth: false,
                fifth: false,
              });
            }}
            label={label}
            sort={sort}
            hubs={hubs}
            hubsAll={hubsAll}
          />
        </CardBox>
        <CardBox
          ref={(e) => (box1.current = e)}
          title={"Delivery Instruction"}
          open={orderOpen.third}
          order={"third"}
        >
          <DeliveryInstruction
            form={form}
            formData={formData}
            onClick={() => {
              setOrderOpen({
                first: false,
                second: false,
                third: false,
                fourth: true,
                fifth: false,
              });
            }}
            label={label}
            sort={sort}
            hubs={hubs}
            points={points}
            order={order}
            handlePointChange={handlePointChange}
          />
        </CardBox>
        <CardBox
          ref={(e) => (box2.current = e)}
          title={"Parcel Information"}
          open={orderOpen.fourth}
          order={"fourth"}
        >
          <ParcelInformation
            formData={formData}
            onClick={() => {
              setOrderOpen({
                first: false,
                second: false,
                third: false,
                fourth: false,
                fifth: true,
              });
            }}
            handleSummary={handleChangeSummary}
            order={order}
            onRemove={onRemove}
            setImgAttach={setImgAttach}
            label={label}
            sort={sort}
          />
        </CardBox>
        <CardBox
          ref={(e) => (box5.current = e)}
          title={"Price Information"}
          open={orderOpen.fifth}
          order={"fifth"}
        >
          <PriceInformation
            form={form}
            formData={formData}
            onClick={() => {
              setOrderOpen({
                first: false,
                second: false,
                third: false,
                fourth: false,
                fifth: false,
                sixth:true,
              });
            }}
            label={label}
            parcelHandle = {tempQty}
            setPrice={setPrice}
            setAddOn={setAddOn}
          /> 
          </CardBox> 
        {order.status !== 'O0100' ?
        <CardBox
          ref={(e) => (box5.current = e)}
          title={"Sorting Information"}
          open={orderOpen.sixth}
          order={"sixth"}
        >
          <SortInformation
            form={form}
            formData={formData}
            handleSummary={handleChangeSummary}
            price={price}
            setPrice={setSortPrice}
            setAddOnSort={setAddOnSort}
            onRemove={onRemove}
          /> 
          </CardBox> : <></> }
      </Form>
    </div>
  );
};

export default OrderSubmissionForm;
