// React Thirty Party Library
import { message } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";

// Constants Library
import { ROUTES, COMMON_FEATURES } from "../../constants";

// Components Library
import { SearchVehicle, TableVehicle } from "./components";
// import { useTransactionData } from "./indexNew";

// Handler Library

// Services Library
import { REDUX as REDUX_UTIL } from "../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../services/redux/actions";

// Styles Library
import styles from "./index.module.css";
import { parseExcelDate } from "read-excel-file";

const { reduxStoreWrapper } = REDUX_UTIL;
const { ROUTE_URL } = ROUTES;
const { FEATURE_NAME } = COMMON_FEATURES;


const VehicleList = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = Object.values(useSelector(reduxStoreWrapper("customer.byID"))) || {};
  const vehicles = useSelector(reduxStoreWrapper("vehicle.byID")) || {};
  const loading = useSelector(reduxStoreWrapper("style.loading.vehicle"));

  const [selectedRow, setSelectedRow] = useState([]);

  const dataSource = Object.values(vehicles).map((t) => ({
    key: t.vid,
    movement: t.movement ?? 404,
    ...t,
  })) ;

  useEffect(() => {
    setfilteredData(dataSource);
  }, [dataSource]);


  useEffect(() => {
    const fetchData = async () => {
      await dispatch(REDUX_ACTION.v1_vehicle.get_all_vehicle_request()).then((response) => {
        messageApi.open({
          type: "loading",
          content: "Getting Vehicle Location...",
          duration: 0,
        });
        
        if (response.vehicles.length > 0) {
          const firstTenVehicles = response.vehicles.slice(0, 10);
          const remainingVehicles = response.vehicles.slice(10);
        }
      })
    };
  
    fetchData();
    return () => {};
  }, [dispatch]);

  const vec = dataSource.map((vecData) => {
    const matchedUser = user.find(
      (userData) => vecData.memid === userData.memid
    );

    return {
      ...vecData,
      name: matchedUser ? matchedUser.name : "",
    };
  }); 

  const [filteredData, setfilteredData] = useState(vec);


  useEffect(() => {
    if (filteredData !== vec) {
      setfilteredData(vec);
    }
  }, [vec]);

  useEffect(() => {
    setfilteredData(filteredData);
  }, [filteredData]);


  return (
    <>
      <div className={styles.container}>
        <SearchVehicle
          dataSource={vec}
          filteredData={filteredData}
          setfilteredData={setfilteredData}
          selectedRow={selectedRow}
        />
        <TableVehicle
          dataSource={vec}
          filteredData={filteredData}
          setfilteredData={setfilteredData}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          loading={loading}
        />
      </div>
    </>
  );
};

export default VehicleList;
