export const TABLE_USER_INDEX = {
  key: "key",
  User_ID: "memid",
  User_Name: "name",
  User_Status: "status",
  Verification_Status: "cm_verified",
  Wallet_Balance: "amount",
  Account_Manager_ID: "amid",
  Contact: "contact",
  Email: "email",
  Address: "address",
  State: "state",
  Postcode: "postcode",
  City:"city",
  Created_At: "createdAt",
  Remark: "remark",
  Action: "Action",
};

export const TABLE_USER_TITLE = {
  key: "key",
  User_ID: "Customer ID",
  User_Name: "Name",
  User_Status: "User Status",
  Role:"Role",
  Verification_Status: "Verification Status",
  Wallet_Balance: "Wallet Balance",
  Account_Manager_ID: "Account Manager ID / Name",
  Contact: "Contact",
  Email: "Email",
  Address: "Address",
  State:"State",
  Postcode_City:"Postcode / City",
  Comp_Name: "Company Name",
  Biz_Acc: "Business Account",
  Postpaid:"Postpaid",
  Created_At: "Created At",
  Remark: "User Remark",
  Action: "Action",
};

export const TABLE_ACCOUNT_MANAGER_INDEX = {
  acc_manager_id: "memid",
  acc_manager_name: "name",
  acc_manager_status: "status",
  user_role: "uroleid",
  total_customer: "total_customer",
  contact: "contact",
  email: "email",
  email_ver: "email_verified",
  createdAt: "createdAt",
  signUp_link: "signUpLink",
  qr_code : "asset",
  remark: "remark",
  Action: "Action",
};

export const TABLE_ACCOUNT_MANAGER_TITLE = {
  acc_manager_id: "Account Manager ID",
  acc_manager_name: "Name",
  acc_manager_status: "User Status",
  user_role: "User Role",
  total_customer: "Total Customer",
  contact: "Contact",
  email: "Email",
  createdAt: "Created At",
  signUp_link: "Sign Up Link",
  qr_code : "PDF QR Code",
  remark: "User Remark",
  Action: "Action",
};

export const OPTION_DAYS = {
  LAST_30_DAYS: "Last 30 Days",
  LAST_60_DAYS: "Last 60 Days",
  LAST_90_DAYS: "Last 90 Days",
  custom: "custom"
};

export const OPTION_DAYS_VALUE = {
  LAST_30_DAYS: 30,
  LAST_60_DAYS: 60,
  LAST_90_DAYS: 90,
  custom: ":custom"
};

export const OPTION_USER_STATUS = {
  Active: 1,
  Suspend: 0,
}

export const OPTION_VERIFICATION_STATUS = {
  Verified: 1,
  Unverified: 2,
};

export const OPTION_USER_STATUS_TITLE = {
  [OPTION_USER_STATUS.Active]: { name: "ACTIVE", colorTag: "green", status: 1 },
  [OPTION_USER_STATUS.Suspend]: { name: "SUSPEND", colorTag: "red", status: 0 },
};

export const OPTION_VERIFICATION_STATUS_TITLE = {
  [OPTION_VERIFICATION_STATUS.Verified]: { name: "Verified", colorTag: "#207261", status: 1 },
  [OPTION_VERIFICATION_STATUS.Unverified]: { name: "Unverified", colorTag: "#949494", status: 2 },
  
};

export const OPTION_ACCOUNT_MANAGER_STATUS = {
  Active: 1 ,
  Suspend: 0,
}

export const OPTION_ACCOUNT_MANAGER_STATUS_TITLE = {
  [OPTION_ACCOUNT_MANAGER_STATUS.Active]: { name: "ACTIVE", colorTag: "green", status: 1 },
  [OPTION_ACCOUNT_MANAGER_STATUS.Suspend]: { name: "SUSPEND", colorTag: "red", status: 0 },
}

export const OPTION_USER_ROLE = {
  Customer: "Customer",
  Hub_Operator: "Hub Operator",
};

export const USER_STATUS_ALL = "ALL";
export const USER_ROLE_ALL = "ALL";

export const SELECT_OPTION_DAYS = Object.keys(OPTION_DAYS).map((option) => ({
  label: OPTION_DAYS[option],
  value: OPTION_DAYS_VALUE[option],
}));



