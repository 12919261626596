// Third Party Lib
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, message } from "antd";

import { TEMPLATE } from "../../../../constants";

// Components
import { BulkHeader, BulkOrder, BulkTable } from "./components";

// Service
import { REDUX as REDUX_UTIL, TIME } from "../../../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../../../services/redux/actions";

// Styles
import styles from "../../../common.module.css";

const {
  ORDER_DATA_TITLE_SPLIT_SPECIAL_ARR,
  ORDER_DATA_DATE_SPECIAL_HDLR,
  ORDER_UPDATE_NECESSARY_FIELD,
  ORDER_UPDATE_NECESSARY_SR_FIELD,
  ORDER_UPDATE_NECESSARY_PARCEL_FIELD,
  ORDER_DATA_TITLE,
  DATA_FIELD
} = TEMPLATE.ORDER;

const { reduxStoreWrapper } = REDUX_UTIL;
const { dayJSMilliSecond } = TIME;

const BulkUpload = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const users = useSelector(reduxStoreWrapper("customer.byID"));
  const user = useSelector(reduxStoreWrapper("user"));
  const loadingSubmit = useSelector(
    reduxStoreWrapper("style.loading.order_list")
  );

  const [srcData, setSrcData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleButtonBack = () => navigate(-1);


  const sanityCheckedData = () => {
    let invalid = false;
    const invalidColumns = {};
    const rows = [];
  
    srcData.forEach((o, index) => {
      const invalidRowColumns = [];
  
      const srFieldKeysUsedSdr = new Set();
      const srFieldKeysUsedRcr = new Set();
      const parcelFieldKeysUsed = new Set();
      const fieldKeysUsed = new Set();
  
      Object.entries(o).forEach(([k, v]) => {

        if (k === 'sender') {
          Object.entries(v).forEach(([kc, vc]) => {
            if (ORDER_UPDATE_NECESSARY_SR_FIELD.includes(kc)) {
              srFieldKeysUsedSdr.add(kc);
              if (vc === undefined || vc === "") {
                invalidRowColumns.push(kc);
              } 
            }
          }); 
        }

        if (k === 'receiver') {
          Object.entries(v).forEach(([kc, vc]) => {
            if (ORDER_UPDATE_NECESSARY_SR_FIELD.includes(kc)) {
              srFieldKeysUsedRcr.add(kc);
              if (vc === undefined || vc === "") {
                invalidRowColumns.push(kc);
              } 
            }
          });
        }

        if (k === 'parcel') {
            if (Array.isArray(v.unsort)) {
              v.unsort.forEach((item) => {
                const keys = Object.keys(item);
                const values = Object.values(item);

                keys.forEach((kc, index) => {
                  const vc = values[index];

                  if (ORDER_UPDATE_NECESSARY_PARCEL_FIELD.includes(kc)) {
                    parcelFieldKeysUsed.add(kc);

                    if (vc === undefined || vc === "") {
                      invalidRowColumns.push(kc);
                    } 
                  }
                });
              });
          } else {
              console.error("data is not an array");
          }
        }
  
        if (ORDER_UPDATE_NECESSARY_FIELD.includes(k)) {
          fieldKeysUsed.add(k);
          if (v === undefined || v === "") {
            invalidRowColumns.push(k);
            
          }
        }
      });
  
      const unusedSRSdrKeys = ORDER_UPDATE_NECESSARY_SR_FIELD.filter((srKey) => !srFieldKeysUsedSdr.has(srKey));
      unusedSRSdrKeys.forEach((unusedKey) => {
        invalidRowColumns.push(`sender_${unusedKey}`);
      });

      const unusedSRRcrKeys = ORDER_UPDATE_NECESSARY_SR_FIELD.filter((srKey) => !srFieldKeysUsedRcr.has(srKey));
      unusedSRRcrKeys.forEach((unusedKey) => {
        invalidRowColumns.push(`receiver_${unusedKey}`);
      });

      const unusedParcelKeys = ORDER_UPDATE_NECESSARY_PARCEL_FIELD.filter((srKey) => !parcelFieldKeysUsed.has(srKey));
      unusedParcelKeys.forEach((unusedKey) => {
        invalidRowColumns.push(`parcel_${unusedKey}`);
      });
  
      const unusedFieldKeys = ORDER_UPDATE_NECESSARY_FIELD.filter((fieldKey) => !fieldKeysUsed.has(fieldKey));
      unusedFieldKeys.forEach((unusedKey) => {
        invalidRowColumns.push(unusedKey);
      });
  
      if (invalidRowColumns.length > 0) {
        invalid = true;
        invalidColumns[index] = invalidRowColumns;
        rows.push(index);
      }
    });
  
    return [invalid, invalidColumns, rows];
  };
  

  const uploadingData = srcData.map((ord) => {
    let od = {};

    Object.keys(ord).map((k) => {
      const value = ord[k];
      if (
        !ORDER_DATA_TITLE_SPLIT_SPECIAL_ARR.includes(k) &&
        k.includes("_")
      ) {
        const a = k.split("_");
        if (!Object.prototype.hasOwnProperty.call(od, a[0])) {
        od[a[0]] = {};
        
        if (k === "sender" || k === "receiver") {
            od[a[0]][a[1]] = String(value);
          } 
        } else if (k === "parcel_remark" ) {
          od[k] = value;
        }
      } else {
        if (ORDER_DATA_DATE_SPECIAL_HDLR.includes(k)) {
          od[k] = dayJSMilliSecond(value);
        } else {
          od[k] = value;
        }
      }
      return 1;
    });
 
    if (od.destDeliveryType === 'p2p') {
        if (od.origid && od.dstid) {
         od.origpid = od.origid
         od.dstpid = od.dstid
         delete od.origid
         delete od.dstid
        }
    } 

    if (od.origDeliveryType === 'emp1' || od.origDeliveryType === 'emp2') {
      delete od.origDeliveryType;
    } else {
      od.add_on = [od.origDeliveryType]
      delete od.origDeliveryType
    }
    
    od.updatedBy = user.memid;
    od.deliveryType = od.destDeliveryType
    od.dv = od.price 

    delete od.pointer;
    delete od.destDeliveryType
    delete od.price
    
    return od;
  });

  const onCompleteHLDR = () => {
    const [invalid, invalidColumnsByRow, invalidRows] = sanityCheckedData();

    if (srcData.length && !invalid) {

      const orders = uploadingData.map((ord) => {
        const matchingUser = Object.values(users).find(
          (u) => u.memid === ord.memid
        );
        return {
          uid: matchingUser.uid,
          memid: matchingUser.memid,
          name: matchingUser.name,
          order: ord,
        };
        
      });

      dispatch(
        REDUX_ACTION.v1_order.submit_bulk_order_request(orders, () => {
          handleButtonBack();
        })
      );
      
    } else if (srcData.length === 0) {
      message.error("Data Upload Required");
    } else {
      const errorMessages = invalidRows.map((row) => {
        const invalidColumnMSG = invalidColumnsByRow[row];
      
        if (!invalidColumnMSG || invalidColumnMSG.length === 0) {
          return null;
        }
      
        const errorColumns = invalidColumnMSG.map(column => {
          const columnName = ORDER_DATA_TITLE[DATA_FIELD[column]];
          return columnName;
        });
      
        return `Undefined Data in Row ${Number(row) + 1} in Column ${errorColumns.join(', ')}`;
      });
    
      const filteredErrorMessages = errorMessages.filter((errorMessage) => errorMessage !== null);
    
      filteredErrorMessages.forEach((errorMessage) => {
        message.error(errorMessage);
      });
    }
  }; 

  return (
    <div className={styles.container}>
      <BulkHeader
        handleButtonBack={handleButtonBack}
        loading={loadingSubmit}
        onCompleteHLDR={onCompleteHLDR}
      />
      <Row>
        <Col span={3} style={{marginTop: "80px"}}> Bulk Upload Order</Col>
        <Col span={20} style={{ marginLeft: "8px"}}>
          <>
            <BulkOrder setLoading={setLoading} setSrcData={setSrcData} loading={loading}/>
            <BulkTable
              srcData={srcData}
              uploadingData={uploadingData}
              loading={loading}
              setSrcData={setSrcData}
            />
          </>
        </Col>
      </Row>
    </div>
  );
};

export default BulkUpload;
