import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Form, message, Spin} from "antd";

// Constants Library
import { TEMPLATE, COMMON_FEATURES, ROUTES } from "../../../../constants";

// Components Library
import { ManageCreditHeader, ManageCreditBody } from "../../components";

// Services Library
import { REDUX as REDUX_UTIL, TIME } from "../../../../services/util";

import * as REDUX_ACTION from "../../../../services/redux/actions";

import classes from "../../../common.module.css";

const { lastNDay, latestTime } = TIME;
const { reduxStoreWrapper } = REDUX_UTIL;
const { OPTION_TYPE } = TEMPLATE.TRANSACTIONLIST;

const { ROUTE_URL } = ROUTES;

const ManageCreditIndex = ({billInv, customer, type, balanceInv, journal, billing}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [transactionType, setTransactionType] = useState(type);
  const [updatedAmount, setUpdatedAmount] = useState(0);
  const [typeAmount, setTypeAmount] = useState("-");
  const [inputTransaction, setInputTransaction] = useState("");
  const [uploadTransaction, setUploadTransaction] = useState([]);
  const [billAmount, setBillAmount] = useState(null);
  const [selectedBillid, setSelectedBillid] = useState("");
  const [selectedJournal, setSelectedjournal] = useState("");
  const [loadingBill, setLoadingBill] = useState(false);
  const [balanceAfter, setBalanceAfter] = useState(0);

  const currentBalance = balanceInv || 0;

  const loading = useSelector(
    reduxStoreWrapper("style.loading.transaction_list")
  );

  const user = useSelector(reduxStoreWrapper("user"));
  const admid = user?.memid;

  function handleButtonTxn() {
    navigate(ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.TRANSACTION_LIST]);
  }

  function handleButtonBack() {
    navigate(-1);
  } 
  
  useEffect(() => {

    const invoice = {
      uid: customer?.uid,
      startTime: lastNDay(90),
      endTime: latestTime,
      mode: "unpaid",
    };

    setLoadingBill(true)

    if (customer?.uid && type === "TPN") {
      dispatch(REDUX_ACTION.v1_billing.get_billing_invoice_uid_request(invoice, () => setLoadingBill(false)));
    }

  }, [dispatch]);

  const handleTransactionTypeChange = (e) => {
    setTransactionType(e.target.value);
    setBalanceAfter(currentBalance)
  };

  const handleAmountChange = (newAmount) => {
    let newBalanceAfter = currentBalance;

    if (
      transactionType === OPTION_TYPE.Topup ||
      transactionType === OPTION_TYPE.Refund
    ) {
      setTypeAmount("+");
      setUpdatedAmount(newAmount);
      newBalanceAfter = currentBalance + newAmount;
    } else if (transactionType === OPTION_TYPE.Deduction) {
      setTypeAmount("-")
      setUpdatedAmount(newAmount)
      newBalanceAfter = currentBalance - newAmount;
    }

    setBalanceAfter(newBalanceAfter);
  };

  const onChangeRemarks = (e) => {
    setInputTransaction(e.target.value);
  };

  const handleBillingId = (value) => {
    const [billid, amount, refid] = value.split(",");
    setSelectedBillid(billid);
    setBillAmount(parseFloat(amount));
    form.setFieldValue("payment", parseFloat(amount))
    setSelectedjournal(refid);
  };

  const amountTxn = () => {
    if (
      transactionType === OPTION_TYPE.Topup ||
      transactionType === OPTION_TYPE.Refund ||
      transactionType === OPTION_TYPE.Deduction
    ) {
      return updatedAmount;
    } else if (transactionType === OPTION_TYPE.Payment) {
      return billAmount;
    }
  };

  const amount = amountTxn();
  const totalBalance = type === "INV" ? balanceInv - billInv.amount : balanceInv - billAmount;

  const model = customer?.paymentModel ? customer?.paymentModel : "PM00"

  const validateTransaction = () => {

    let billingId;
    let journalId;
 
    const txn = {
      uid: customer?.uid,
      memid: customer?.memid,
      type: type === "INV" ? type : transactionType,
      action: typeAmount,
      amount: type === "INV" ? billInv.amount : amount,
      remark: inputTransaction,
      adpMemid: admid,
    };

    if (uploadTransaction) {
      txn.url = uploadTransaction;
    }

    if (selectedJournal !== "") {
      txn.jnid = selectedJournal;
    } else if (type === "INV") {
      txn.jnid = billInv.refid
    }

    if (type === "INV") {
      journalId = billInv.refid
    } else if (selectedJournal !== "") {
      journalId = selectedJournal
    }
    
    const selectedJn = journal.find(b => b.jnid === journalId);
    if (selectedJn) {
      txn.jnid = selectedJn.jnid;
      txn.url = selectedJn.url;
    }
    
    if (type === "INV") {
      billingId = billInv.billid;
    } else if (selectedBillid !== "") {
      billingId = selectedBillid
    }
    
    const selectedBilling = billing.find(b => b.billid === billingId);
    if (selectedBilling) {
      txn.billid = selectedBilling.billid;
      txn.ordid = selectedBilling.ordid;
    }

    if(transactionType === OPTION_TYPE.Topup|| 
      transactionType === OPTION_TYPE.Refund ||
      transactionType === OPTION_TYPE.Deduction && totalBalance >= 0 && model === 'PM00' ||
      transactionType === OPTION_TYPE.Deduction && model === 'PM01' ||
      transactionType === OPTION_TYPE.Payment && totalBalance >= 0 && model === 'PM00' || 
      transactionType === OPTION_TYPE.Payment && model === 'PM01') {
        dispatch(
          REDUX_ACTION.v1_transaction.create_transaction_request(
            txn,
            () => {
              handleButtonTxn();
            }
          )
        );
  } else if ((transactionType === OPTION_TYPE.Payment ||
    transactionType === OPTION_TYPE.Deduction
  ) && totalBalance < 0  && model === "PM00" ||  balanceInv === undefined ) {
    message.error(`Your current balance is not enough for this payment.`)
  }
}

  const handleComplete = async () => {
    await form.validateFields().then(() => validateTransaction());
  };

  const valueCustomer =
  customer 
      ? customer && customer?.memid + `/` + customer?.name + `/` + (customer?.paymentModel === "PM00" ? "Prepaid" : "Postpaid")
      : null;

const valueInvoice =
  type === "INV" && billInv 
      ? billInv.billid + "/" + billInv.amount
      : null;

 const valuePayment =
  type === "INV" && billInv 
      ? billInv.amount
      : billAmount;
 
  const initialValues = {
      memid: customer?.memid || "", 
      type: type || "", 
      billid: type === "INV" ? valueInvoice : "" ,
      payment: type === "INV" ? valuePayment : billAmount || ""
  }

  return (
    <Spin spinning={type === "TPN" && loadingBill}>
    <div className={classes.container}>
      <ManageCreditHeader
        handleButtonBack={handleButtonBack}
        loading={loading}
        handleComplete={handleComplete}
        transactionType={transactionType}
        updatedAmount={updatedAmount}
        currentBalance={currentBalance}
        filteredData={customer}
      />
      <Form form={form} layout="vertical" initialValues={initialValues} style={{ width: "800px" }}>
        <ManageCreditBody
          filteredData={customer}
          dataInv={billInv}
          transactionType={transactionType}
          handleTransactionTypeChange={handleTransactionTypeChange}
          handleAmountChange={handleAmountChange}
          onChangeRemarks={onChangeRemarks}
          setUploadTransaction={setUploadTransaction}
          handleBillingId={handleBillingId}
          billAmount={billAmount}
          currentBalance={currentBalance}
          balanceAfter={balanceAfter}
          updatedAmount={updatedAmount}
        />
      </Form>
    </div>
    </Spin>
  );
};

export default ManageCreditIndex;
