// Third Library 
import { List, Modal, Typography, message } from "antd";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Components 
import BtnModal from "../../components/BtnModal";
import { CommentInput, CommentList } from "./components";

// Constants 
import { TEMPLATE } from "../../../../constants";
import { REDUX as REDUX_UTIL } from "../../../../services/util";

// Actions 
import * as REDUX_ACTION from "../../../../services/redux/actions";

// Styles 
import styles from "./index.module.css";

const { Title } = Typography;
const { MODALS } = TEMPLATE.MODAL;
const { reduxStoreWrapper } = REDUX_UTIL;

const ModalComment = ({
    setmodal,
    isModalOpen,
    setIsModalOpen,
    extra = "",
}) => {
    const dispatch = useDispatch();

    const user = useSelector(reduxStoreWrapper("user"));
    const userList = useSelector(reduxStoreWrapper("customer.byID"));
    const comment = useSelector(reduxStoreWrapper("parkedOrder.byComment"));
  
    // useState for handle edit comment
    const [edit, setEdit] = useState(false);
    const [index, setIndex] = useState("");

    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        if(isModalOpen) {
            dispatch(REDUX_ACTION.v1_parkOrder.get_parked_order_comment_request(
                    extra.oparkid
                )
            );
        }
    }, [dispatch, isModalOpen])

    // initial form value 
    const [value, setValue] = useState({
        oparkid: extra.oparkid,
        ordid: extra.ordid,
        comment: "",
        commentedBy: user.memid,
    });
    const [editValue, setEditValue] = useState({})
    
    // if ok performed (Add Comment), one of these actions will triggered
    const handleOkButton = () => {
      
        // to show the loading popup 
        messageApi.open({
            type: "loading",
            content: "Updating comment...",
            duration: 0,
        });

        // submit 
        if(!edit) {
            // front-end error handling so I know if the user memid or oparkid not being rendered here      
            if(!value.oparkid || !value.commentedBy) {
                message.warning("something went wrong. aborting..")
                messageApi.destroy()
            } else {
                dispatch(REDUX_ACTION.v1_parkOrder.create_parked_order_comment_data(
                    value, 
                    () => messageApi.destroy() 
                ))
                setValue({
                    ...value,
                    comment: ''
                })
            }
        }   
        // edit  
        else {
            // front-end error handling so I know if the user memid or oparkid not being rendered here      
            if(!editValue.oparkid || !editValue.commentedBy) {
                message.warning("something went wrong. aborting..")
                messageApi.destroy()
            } else {
                dispatch(REDUX_ACTION.v1_parkOrder.edit_parked_order_comment_data(
                    editValue,
                    () => messageApi.destroy() 
                ))
                setEditValue({})
            }
            setEdit(false)
        }    
    }

    // since deleted comment is comment which had status = 0, then I filter the data which had the status === 1 
    const filteredDataByStatus = Array.isArray(comment[extra.oparkid]) && comment[extra.oparkid].filter(c => c.status === 1)

    return (
        <Modal
            open={isModalOpen}
            footer={null}
            onCancel={setIsModalOpen}
            width={480}
        >
            {contextHolder}
            <div className={styles["contents"]}>
                <Title level={5}>{MODALS[setmodal].title}</Title>

                <List
                    itemLayout="horizontal"
                    style={{ overflowY: "scroll", overflowX: "hidden", height: "30em"}}
                    dataSource={filteredDataByStatus || ""}
                    // cel stands for comment element
                    renderItem={(cel, i) => {
                        // firstName simply refer to user's firstname
                        const firstName = userList[cel.commentedBy] && userList[cel.commentedBy].name.split(" ")[0]
                        const userName = cel.commentedBy === user.memid ? "You" : firstName;

                        return (
                            <>
                            {/* 'edit validation' so the input form can be rendered in 
                            specific place where edit button is clicked */}
                                {edit && i === index ? (
                                    <CommentInput 
                                        value={editValue}
                                        setValue={setEditValue}
                                        handleOkButton={handleOkButton}
                                    />
                                ) : (
                                    <CommentList 
                                        userName={userName} 
                                        handleOkButton={handleOkButton}
                                        setIndex={setIndex}
                                        setEdit={setEdit}
                                        setValue={setEditValue}
                                        cel={cel} 
                                        i={i} 
                                    />
                                )}
                            </>
                        );
                    }}
                />

                {/* add comment */}
                <>
                    <CommentInput
                        value={value}
                        setValue={setValue}
                        handleOkButton={handleOkButton}
                     />

                    <BtnModal
                        setIsModalOpen={setIsModalOpen}
                        handleOkButton={handleOkButton}
                        htmlType="submit"
                        text1={"Cancel"}
                        text2={"Add Comment"}
                    />
                </>
            </div>
        </Modal>
    );
};

export default ModalComment;

