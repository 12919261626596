import * as API_CONSTANT from "../constants";

export const getTransactionTimeFrameAll = ({ startTime, endTime }) =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/transaction/get/timeframe/all`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      startTime,
      endTime,
    }),
  }).then((res) => res.json());

export const createTransaction = async (transactionData) => {
  const { url, jnid, billid, ordid, ...restData } = transactionData;

  const requestBody = {
    ...restData,
  };

  if (url) {
    requestBody.url = url;
  }
  if (jnid) {
    requestBody.jnid = jnid;
  }
  if (billid) {
    requestBody.billid = billid;
  }
  if (ordid) {
    requestBody.ordid = ordid;
  }

  const res = await fetch(
    `${API_CONSTANT.adp_v1_fe_path}/transaction/request`,
    {
      method: "POST",
      headers: API_CONSTANT.headers,
      body: JSON.stringify(requestBody),
    }
  );
  return await res.json();
};

export const updateTransaction = ({ txnid, items }) =>
  fetch(`${API_CONSTANT.adp_v1_fe_path}/transaction/update`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      txnid,
      items,
    }),
  }).then((res) => res.json());
