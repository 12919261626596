import * as ActionTypes from "../action-types";

const wallet_defaultState = {
  byID: {},
  allIDs: [],
};

export const wallet = (state = wallet_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.GET_CUSTOMER_WALLET: {
      const wallet = action.wallets;

      wallet &&
        wallet.length &&
        wallet.map((wallet) => {
          const { waid } = wallet;

          newState.byID[waid] = wallet;

          if (!newState.allIDs.includes(waid)) newState.allIDs.push(waid);

          return null;
        });

      return newState;
    }
    default: {
      return state;
    }
  }
};
