import dayjs from 'dayjs'
import { format as FNS_format } from 'date-fns'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import duration from 'dayjs/plugin/duration'
import moment from 'moment';

dayjs.extend(duration)
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(utc)

export const referenceStartTime = () => dayjs().startOf('day')
export const referenceEndTime = () => dayjs().add(1, 'days').startOf('day')
export const referenceEndOfDay = () => dayjs().endOf('day')

const today = dayjs();
export const todayStart = today.startOf('day').subtract(7, 'day'); // Start of the day
export const todayEnd = today.endOf('day')

export const dayjsDatetimeTranslate = (time) => {
    if (isNaN(time)) return `UNKNOWN`
    return dayjs(Number(time)).format("YYYY/MM/DD HH:mm:ss");
}

export const sanityCheckNumber = (number) => {
    return !isNaN(number) && number !== null && !isNaN(Number(number));
}

export const durationFormat = (duration) => {

    if (isNaN(duration)) return `N/A`

    const dayjsDuration = dayjs.duration(duration);

    const days = parseInt(dayjsDuration.asDays(), 10);
    const hrs = dayjsDuration.hours();
    const mins = dayjsDuration.minutes();
    const secs = dayjsDuration.seconds();

    return (
        days ?
            `${days} days ${hrs} hrs ${mins} mins` :
            hrs ?
                `${hrs} hrs ${mins} mins` :
                mins ?
                    `${mins} mins ${secs} secs` :
                    `${secs} secs`
    )
}

export const parseExportTime = (time = null) => (time && dayjs(Number(time)) || dayjs()).format("YYYYMMDD_HHmmss");

export const parseTime = time => {
    if (!time) return '-'
    return dayjs(Number(time)).format("YYYY/MM/DD HH:mm:ss");
}

export const parseTimeMin = time => {
    if (!time) return '-'
    return dayjs(Number(time)).format("YYYY/MM/DD HH:mm");
}

export const parseTimeMinDayFirst = time => {
    if (!time) return '-'
    return dayjs(Number(time)).format("DD/MM/YYYY HH:mm");
}

export const parseTimeFNS = time => {
    if (!time) return '-'
    return FNS_format(time, "yyyy-MM-dd HH:mm:ss");
}

export const parseHours = millisec => {
    if (isNaN(millisec) && !millisec) return 0
    return millisec / (60 * 60 * 1000);
}

export const parseYear = time => {
    if (!time) return '-'
    return dayjs(Number(time)).format("YYYY");
}


export const parseDate = (millisec) => {
    if (millisec === null || isNaN(Number(millisec))) return '-';

    return dayjs(millisec).format('DD/MM/YYYY');
}

export const parseDuration = (millisec) => {
    if (!sanityCheckNumber(millisec)) { return "-" }

    let seconds = (millisec / 1000).toFixed(0);
    let minutes = Math.floor(seconds / 60);
    let hours = 0;

    if (minutes > 59) {
        hours = Math.floor(minutes / 60);
        minutes = minutes - hours * 60;
    }

    seconds = Math.floor(seconds % 60);

    if (Number.isNaN(millisec)) {
        return " Not Available "
    }
    else if (hours > 0) {
        return `${hours} ${hours > 1 ? "hrs" : "hr"} ${minutes} ${minutes > 1 ? "mins" : "min"}`.trim()
    }
    else return `${minutes} ${minutes > 1 ? "mins" : "min"} ${seconds} ${seconds > 1 ? "secs" : "sec"}`.trim()
}

export const parseDurationHRS = (hrs) => {
    if (!sanityCheckNumber(hrs)) { return "-" }

    const millisec = hrs * 60 * 60 * 1000

    let seconds = (millisec / 1000).toFixed(0);
    let minutes = Math.floor(seconds / 60);
    let hours = 0;

    if (minutes > 59) {
        hours = Math.floor(minutes / 60);
        minutes = minutes - hours * 60;
    }

    seconds = Math.floor(seconds % 60);

    if (Number.isNaN(millisec)) {
        return " Not Available "
    }
    else if (hours > 0) { 
        return `${hours} ${hours > 1 ? "hrs" : "hr"} ${minutes} ${minutes > 1 ? "mins" : "min"}`.trim()
    }
    else return `${minutes} ${minutes > 1 ? "mins" : "min"} ${seconds} ${seconds > 1 ? "secs" : "sec"}`.trim()
}

export const timeDiff = (startTime, endTime) => {
    const duration = parseDuration(dayjs(Number(endTime)).utc().diff(dayjs(startTime).utc()));

    return duration;
}

export const dayDiffABS = (startTime, endTime) => {
    return Math.abs(dayjs.duration(startTime.diff(endTime)).asDays())
}

export const dayJSLocalize = (timeDayJS) => dayjs(timeDayJS.utc().valueOf())
export const dayJS = (time) => dayjs(time || null)
export const dayValue = (time) => dayjs(time, "DD/MM/YYYY").valueOf();
export const dayDatePick = (time) => dayjs(time, "DD/MM/YYYY");
export const isDayJS = (time) => dayjs.isDayjs(time)

export const currentTimeMilliSecond = () => dayjs().utc().valueOf()
export const dayJSMilliSecond = (time) => dayjs(time).utc().valueOf()

export const lastNDay = (n = 1) => dayjs().subtract(n, 'days').utc().valueOf()
export const latestTime = dayjs().utc().valueOf()

export const firstDayFilter = (n = 1) => dayjs().subtract(n, 'days').utc().format('YYYYMMDD');
export const lastDayFilter = dayjs().utc().format('YYYYMMDD');

export const getCurrentDateFormat = () => dayjs().format('YYYYMMDD')
export const getCurrentDateTimeFormat = () => dayjs().format('YYYYMMDD_HHmmss')

export const getEpochTimeForDay = (day) => {
      const currentDate = moment(); // atau moment(new Date()) untuk mendapatkan waktu saat ini
      const dayOfWeek = moment().day(day); // dayOfWeek akan berisi objek moment untuk hari yang dipilih
      const differenceInSeconds = dayOfWeek.diff(currentDate, 'seconds');
      
      // epoch time saat ini + selisih detik
      const epochTime = Math.floor(currentDate.unix() + differenceInSeconds);
  
      return epochTime;
}

export const getDayNamefromEpoch = (time, full) => {
      const epochValue = time * 1000; // Perhatikan bahwa nilai epoch dalam milidetik

      // Membuat objek Date dari nilai epoch
      const myDate = new Date(epochValue);
      
      // Mendapatkan indeks hari (0 hingga 6, di mana 0 adalah Minggu)
      const dayIndex = myDate.getDay();
      
      // Array dengan nama hari
      const daysOfWeek = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
      const daysOfWeekFull = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ]
      
      // Mendapatkan nama hari berdasarkan indeks
      const dayName = full ? daysOfWeekFull[dayIndex] : daysOfWeek[dayIndex]

      return dayName
}

export const getHoursNameFromEpoch = (time) => {
      const epochString = time;

      // Memisahkan rentang waktu menjadi dua nilai epoch
      const [startEpoch, endEpoch] = epochString.split('/').map(Number);
      
      // Membuat objek Date dari nilai epoch
      const startDate = new Date(startEpoch);
      const endDate = new Date(endEpoch);
      
      // Fungsi untuk mengonversi jam ke format 12 jam
      const format12Hour = (date) => {
        let hours = date.getHours();
        let minutes = date.getMinutes()

        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        const amPm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12 || 12;

        return `${hours}:${formattedMinutes}${amPm}`;
      };
      
      // Mengonversi waktu mulai dan waktu selesai ke format yang diinginkan
      const formattedStartTime = format12Hour(startDate);
      const formattedEndTime = format12Hour(endDate);

      // Mencetak hasil
      return formattedStartTime + '-' + formattedEndTime
}
export const getRangeFromEpochTime = (epochTime) => {
  const [start, end] = epochTime.split('/').map(Number);

  const startTime = moment(start);
  const endTime = moment(end);

  return [startTime, endTime];
};
