import * as API from "../../api";
import * as ActionTypes from "../action-types";
import { message } from "antd";

import { TIME } from '../../util'

// Constants and Config
import { APP_CONFIG } from "../../../config";

// Services
import * as Auth from "../../auth";
import { encryptFE } from "../../util";

// Redux Action
import { setLoadingCustomerList, unsetLoadingCustomerList } from "./style";
import { update_manager_account_success } from "./accountManager";

// Neccessary Actions

export const get_customer_all_request =
  () => (dispatch) => {
    dispatch(setLoadingCustomerList());

    API.getCustomerInfoAll()
      .then((info) => {
        const { status, msg, user } = info;

        if (status === 200) {
          dispatch(get_customer_all_success(user));
        } else {
          message.warning(msg);
        }
      })
      .finally(() => {
        dispatch(unsetLoadingCustomerList());
      });
  };


const get_customer_all_success = (customers) => {
  return {
    type: ActionTypes.GET_CUSTOMER_ALL,
    customers,
  };
};

export const customer_change_password_request = (items = {}, setIsPasswordChanged, cb) => dispatch => {
  const mkey = APP_CONFIG.APP_MASTER_KEY
  const password = encryptFE(
    JSON.stringify(`${mkey}#${items.password}`),
    items.uid
  );
  API.customerChangePassword({
    uid: items.uid,
    password: password
  }).then(info => {
    const { msg, status} = info

    if(status === 200) {
      setIsPasswordChanged(true)
      message.success(msg)
    } else {
      message.warning(msg)
    }
  }).finally(() => { cb() })
}



export const get_customer_list_thru_cm_request =
  ({hierLevel, hierCode, memid},) => (dispatch) => {

    API.getCustomerInfoThruCM({
      hierLevel, 
      hierCode
    }).then((info) => {
        const { status, msg, user } = info;

        if (status === 200) {
          dispatch(get_customer_list_thru_cm_success(memid, user));
        } else {
          message.warning(msg);
        }
      })
  };

  // action
export const add_account_manager_id = ({ uid, hierCode, hierLevel, cm = {} }) => dispatch => {
  return new Promise((resolve, reject) => {
    API.updateCustomerLinkToAccountManager({
      uid,
      hierCode,
      hierLevel,
      cm
    }).then(info => {
      const { status, msg, user } = info;

      if (status === 200) {
        message.success(msg);
        resolve(info); 
      } else {
        message.warning(msg);
        reject(new Error(msg)); 
      }
    }).catch(error => {
      reject(error); 
    });
  });
};


  
export const edit_customer_info_request = (user, cb = () => {}) => dispatch => {

  API.updateCustomerInfo({
    uid: user.uid,
    items: user
  }).then(info => {
    const { msg, status, user: newUser} = info

    if(status === 200) {
      dispatch(update_customer_success(newUser))
      message.success(msg)
    } else {
      message.warning(msg)
    }
  }).finally(() => { cb() })

}



  // ====================================================================


    export const verify_customer_request = user => dispatch => {
    API.updateCustomerInfo({
      uid: user.uid,
      items: {
        ...user, 
        cm_verified: 1
      }
    }).then(info => {
      const { msg, status, user: newUser} = info
      if(status === 200) {
        dispatch(update_customer_success(newUser))
        message.success(msg)
      } else {
        message.warning(msg)
      }
    })
  }

  export const update_user_list_status = (user, cb) => dispatch => {
    API.updateCustomerInfo({
      uid: user.uid,
      items: {...user}
    }).then(info => {
      const { msg, status, user: newUser} = info

      if(status === 200) {
        dispatch(update_customer_success(newUser))
        dispatch(update_manager_account_success(newUser))
        message.success(msg)
      } else {
        message.warning(msg)
      }
    })
    // .finally(() => { cb() })
  }

// export const update_user_list_status_suspend = user => dispatch => {
//   API.updateCustomerInfo({
//     uid: user.uid,
//     items: {
//       ...user,
//       status: 0
//     }
//   }).then(info => {
//     const { msg, status, user: newUser} = info

//     if(status === 200) {
//       dispatch(update_customer_success(newUser))
//       dispatch(update_manager_account_success(newUser))
//       message.success(msg)
//     } else {
//       message.warning(msg)
//     }
//   })
// }

export const create_user_list_remark_request = ({value: remark, newData: user}, setData, cb) => dispatch => {

  remark.createdAt = TIME.currentTimeMilliSecond()
  
  if(!Array.isArray(user.remark)) user.remark = []
  user.remark.push(remark)

  API.updateCustomerInfo({
    uid: user.uid, 
    items: user
  }).then(info => {
      const { status, msg, user: newUser} = info

      if(status === 200) {
        dispatch(update_customer_success(newUser))
        setData(newUser)
        message.success(msg)
      } else {
        message.warning(msg)
      }
    }).finally(() => cb())
}

export const update_user_list_remark_request = ({editValue: remark, newData: user}, setData, cb)  => dispatch => {

  const remarks = user.remark.map(p => {
    let newRemark

    if(p.createdAt === remark.createdAt) {
      newRemark = remark
    } else newRemark = p

    return newRemark
  })

  API.updateCustomerInfo({
    uid: user.uid,
    items: {
      ...user,
      remark: remarks
    }
  }).then(info => {
    const { msg, status, user: newUser} = info

    if(status === 200) {
      dispatch(update_customer_success(newUser))
      setData(newUser)
      message.success(msg)
    } else {
      message.warning(msg)
    }
  }).finally(() => cb())
}


export const delete_user_list_remark_request = ({cel: remark, newData: user}, setData, cb) => dispatch => {
  const newRemark = user.remark.filter(r => r.createdAt !== remark.createdAt)

  API.updateCustomerInfo({
    uid: user.uid,
    items: {
      ...user,
      remark: newRemark
    }
  }).then(info => {
    const { status, msg, user: newUser } = info

    if(status === 200) {
      dispatch(update_customer_success(newUser))
      setData(newUser)
      message.success("Remark successfully deleted")
    } else {
      message.warning(msg)
    }
  }).finally(() => cb())
}

export const user_list_change_password_request = ({uid, password}, setIsPasswordChanged, cb) => dispatch => {
  // Auth.change_password_user_list(
  //   user, 
  //   password
  // )
  const mkey = APP_CONFIG.APP_MASTER_KEY
  password = encryptFE(
    JSON.stringify(`${mkey}#${password}`),
    uid
  );

  API.updatePassword({
    uid,
    password
  }).then(info => {
    const {msg, status} = info

    if(status === 200) {
      setIsPasswordChanged(true)
      message.success(msg)
    } else {
      message.warning(msg)
    }
  }).finally(() => { cb() })
}

const update_customer_success = (customer) => {
  return {
    type: ActionTypes.UPDATE_CUSTOMER_INFO,
    customer
  }
}

const get_customer_list_thru_cm_success = (amid, customers) => {
  return {
    type: ActionTypes.GET_CUSTOMER_LIST_THRU_CM,
    amid,
    customers,
  };
};





