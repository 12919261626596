// Third Party Lib
import * as XLSX from "xlsx";

export const convertXLSXArr2JSON = (aoa, headerRowIndex) => {
    const header = aoa[headerRowIndex]
    const caoa = aoa.filter((d, index) => index != headerRowIndex)
    return (
        caoa
        &&
        caoa.length
        &&
        caoa.map(ca =>  Object.fromEntries(ca.map((a, i) => [header[i], a]).filter(a => a)))
        ||
        []
    )
}

export const readXLSXFile = (file) => {
    const wk = XLSX.read(file, { type: "binary" });
    const sn = wk.SheetNames[0];
    const ws = wk.Sheets[sn];
    const rd = XLSX.utils.sheet_to_json(ws, { header: 1 }).filter(td => td && td.length);

    return convertXLSXArr2JSON(rd, 0)
}