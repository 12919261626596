export const TABLE_ORDERLIST_INDEX = {
  key: "key",
  Order_ID: "ordid",
  Order_Status: "status",
  Bus_Info: "busid",
  plate: "plate",
  Origin: "origid",
  Destination: "dstid",
  Delivery_Instruction: "deliveryType",
  Price: "price",
  Invoice: "jnid",
  Parcel_Info: "parcel",
  volume: "volume",
  parameter: "parameter",
  customId: "memid",
  name: "name",
  contact: "contact",
  Last_Updated: "updatedAt",
  SO_Printed: "printedAt",
  Created_At: "createdAt",
  Actions: "ordid",
};

export const TABLE_ORDERLIST_TITLE = {
  key: "key",
  Order_ID: "Order ID",
  Order_Status: "Order Status",
  Customer_Instruction:"Customer Instruction",
  plate: "Vehicle / Bus Operator",
  Bus_Info: "Bus Info",
  Origin_Point:"Origin Point",
  Origin_Hub: "Origin Hub",
  Destination_Hub: "Destination Hub",
  Destination_Point:"Destination Point",
  Delivery_Instruction: "Delivery Instruction",
  Parcel_Type: "Parcel Type",
  Price: "Price (RM)",
  Invoice: "Invoice",
  Invoice_Status: "Invoice / Status",
  Parcel_Info: "Parcel Info",
  Volume:"Volume (m)",
  Parameter : "Quantity/ Volume(m3)/ Weight(kg)",
  customId: "Customer ID/ Name / Contact",
  customerId : "Customer ID",
  Orders_Remarks : "Parcel Remarks",
  Last_Updated: "Last Updated",
  SO_Printed: "SO Printed At",
  Status_Remarks: "Status Remarks",
  Created_At: "Created At",
  Actions: "Actions",
};

export const OPTION_DAYS = {
  LAST_30_DAYS: "Last 30 Days",
  LAST_60_DAYS: "Last 60 Days",
  LAST_90_DAYS: "Last 90 Days",
};

export const OPTION_DAYS_VALUE = {
  LAST_30_DAYS: 30,
  LAST_60_DAYS: 60,
  LAST_90_DAYS: 90,
};

export const OPTION_STATUS = {
  Submitted: "Submitted Succesfully",
  Pending: "Pending Self Lodge",
  Received: "Received At Origin Hub",
  Departed: "Departed From Origin Hub",
  Arrived: "Arrived & Ready For Self Collection",
  Collected: "Receiver Collected",
};

export const OPTION_DELIVERY = {
  HubToHub: "Hub to Hub",
  HubtoDelivery: "Hub to Delivery",
};

export const STATUS_DELIVERY = {
  Submitted: "Submitted Succesfully",
  Pending: "Pending Self Lodge",
  Received: "Received At Origin Hub",
  Departed: "Departed From Origin Hub",
  Arrived: "Arrived & Ready For Self Collection",
  Collected: "Receiver Collected",
};

export const OPTION_STATUS_ALL = "ALL";
export const OPTION_DELIVERY_ALL = "ALL";

export const SELECT_OPTION_DAYS = Object.keys(OPTION_DAYS).map((option) => ({
  label: OPTION_DAYS[option],
  value: OPTION_DAYS_VALUE[option],
}));

export const dummy = [
  { value: "Dummy A", label: "Dummy A" },
  { value: "Dummy B", label: "Dummy B" },
  { value: "Dummy C", label: "Dummy C" },
  { value: "Dummy D", label: "Dummy D" },
  { value: "Dummy E", label: "Dummy E" },
];