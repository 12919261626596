import { Tag } from "antd";
// Contanst
import { TEMPLATE } from "../../constants";

const {OPTION_HUB_STATUS,OPTION_HUB_STATUS_TITLE} = TEMPLATE.HUB

export const hubStatus = () => {
    let tree = [];
  
    if (OPTION_HUB_STATUS) {
      const allChildren = Object.values(OPTION_HUB_STATUS).map((hubstat) => {
        const { name, colorTag } = OPTION_HUB_STATUS_TITLE[hubstat];

        const title = (
          <Tag color={colorTag} key={hubstat}>
            {name}
          </Tag>
        );
        const key = `status#${hubstat}`;
        const value = `status#${hubstat}`;
  
        return {
          title: title,
          key: key,
          value: value,
        };
      });

  
      const allOption = {
        title: "ALL",
        key: "ALL",
        value: "ALL",
      };
  
      tree.push({ ...allOption, children: [...allChildren] });
    }
    const filterSelectedChildren = (node) => {
      if (node.value === "ALL") {
        return tree.flatMap((n) => n.children.map((child) => child.value));
      }
  
      return [node.value];
    };
  
    tree.filterSelectedChildren = filterSelectedChildren;
  
    return tree;
  };
