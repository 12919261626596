// Third Party Lib
import {
  S3Client,
  GetObjectCommand,
  PutObjectCommand,
} from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";

// Config
import { APP_CONFIG } from "../../../config";

const { AWS_SDK_CONFIG } = APP_CONFIG;

const s3 = new S3Client({
  credentials: AWS_SDK_CONFIG.credential,
  region: AWS_SDK_CONFIG.credential.region,
});

export const S3GetObject = (bucket, key) =>
  s3
    .send(
      new GetObjectCommand({
        Bucket: bucket,
        Key: key,
      })
    )
    .then((data) => data);

export const put2S3 = ({ bucket, key, file, acl = "public-read" }) =>
  s3.send(
    new PutObjectCommand({
      Bucket: bucket,
      Key: key,
      Body: file,
      ContentType: file.type,
      ACL: acl,
    })
  );

export const upload2S3 = ({ bucket, key, file, acl = "public-read" }) => {
  const uploadParams = {
    Bucket: bucket,
    Key: key,
    ContentType: file.type,
    Body: file,
    ACL: acl,
  };

  const S3UploadPipeline = new Upload({
    client: s3,
    params: uploadParams,
  });

  return S3UploadPipeline;
};
