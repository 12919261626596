export const ACTIONS_LABEL = {
  process_order: "process_order",
  sort_receiver_order: "sort_receiver_order",
  assign_to_manifest: "assign_to_manifest",
  receiver_by_receiver: "receiver_by_receiver",
  park_order: "park_order",
  cancel_order: "cancel_order",
  print_shipment_order: "print_shipment_order",
};

export const ACTIONS_LABEL_TITLE = {
  [ACTIONS_LABEL.process_order]: "Proceed Order",
  [ACTIONS_LABEL.sort_receiver_order]: "Sort / Received Order",
  [ACTIONS_LABEL.assign_to_manifest]: "Assign to Manifest",
  [ACTIONS_LABEL.receiver_by_receiver]: "Receiver by Receiver",
  [ACTIONS_LABEL.park_order]: "Park Order",
  [ACTIONS_LABEL.cancel_order]: "Cancel Order",
  [ACTIONS_LABEL.print_shipment_order]: "Print Shipment Order",
};

export const ACTIONS_LABEL_ORDER_FORM = {
  [ACTIONS_LABEL.process_order]: "Proceed Order",
  [ACTIONS_LABEL.sort_receiver_order]: "Sort Order",
};

// ========================================================================

import { IMG } from "../../../../../constants";

const { icon_box, icon_doc } = IMG.ICON.ORDER_LIST_FORM;

export const DATA_FIELD = {
  deliveryType: "deliveryType",
  add_on: "add_on",
  parcelType: "parcelType",
  parcel_weight: "parcel_weight",
  parcel_length: "parcel_length",
  parcel_width: "parcel_width",
  parcel_height: "parcel_height",
  parcel_desc: "parcel_desc",
  parcel_remark: "parcel_remark",
  origid: "origid",
  etd: "etd",
  price_doorstep_pick_up: "priceInfo_pickup",
  price_hub_to_hub: "priceInfo_h2h",
  price_doorstep_drop_off: "priceInfo_dropoff",
  price_value_added_services: "priceInfo_addon",
  dstid: "dstid",
  dst_detail: "dst_detail",
  orig_detail: "orig_detail",
  eta: "eta",
  receiver_name: "receiver_name",
  receiver_contact: "receiver_contact",
  receiver_email: "receiver_email",
  receiver_address: "receiver_address",
  receiver_postcode: "receiver_postcode",
  receiver_city: "receiver_city",
  receiver_state: "receiver_state",
  receiver_remark: "receiver_remark",
};

export const DATA_FIELD_VALUES = {
  [DATA_FIELD.deliveryType]: {
    h2h: "Hub to Hub",
    h2d: "Hub to Doorstep",
  },
  [DATA_FIELD.add_on]: {
    "serivce#dpu": "Doorstep Pick Up",
  },
  [DATA_FIELD.parcelType]: {
    "001": { src: icon_box, name: "Box" },
    "002": { src: icon_doc, name: "Document" },
  },
};

export const DATA_FIELD_TITLE = {
  [DATA_FIELD.deliveryType]: {
    h2h: "Sender self drop-off at hub and receiver self collect from hub.",
    h2d: "Sender self-drop off at hub and parcel delivery right to your receiver’s doorstep.",
  },
};

export const STATE = {
  JOHOR: {
    name: "Johor",
    code: "JHR",
  },
  KEDAH: {
    name: "Kedah",
    code: "KDH",
  },
  KELANTAN: {
    name: "Kelantan",
    code: "KTN",
  },
  KUALA_LUMPUR: {
    name: "WP Kuala Lumpur",
    code: "KUL",
  },
  SELANGOR: {
    name: "Selangor",
    code: "SGR",
  },
  LABUAN: {
    name: "WP Labuan",
    code: "LBN",
  },
  MELAKA: {
    name: "Melaka",
    code: "MLK",
  },
  NEGERI_SEMBILAN: {
    name: "Negeri Sembilan",
    code: "NSN",
  },
  PAHANG: {
    name: "Pahang",
    code: "PHG",
  },
  PENANG: {
    name: "Penang",
    code: "PNG",
  },
  PERAK: {
    name: "Perak",
    code: "PRK",
  },
  PERLIS: {
    name: "Perlis",
    code: "PLS",
  },
  PURTAJAYA: {
    name: "WP Putrajaya",
    code: "PJY",
  },
  SABAH: {
    name: "Sabah",
    code: "SBH",
  },
  SARAWAK: {
    name: "Sarawak",
    code: "SWK",
  },
  TERENGGANU: {
    name: "Terengganu",
    code: "TRG",
  },
};

export const STATE_EX = {
  OTHERS: {
    name: "Others",
    code: "OTH",
  },
};

export const STATE_WITH_EX = {
  ...STATE,
  ...STATE_EX,
};

export const STATE_NAME = Object.fromEntries(
  Object.keys(STATE_WITH_EX).map((k) => [k, k])
);

export const HUBS = {
  SPT: {
    hubid: "hub#spt",
    name: "Johor Bahru, Taman Sri Putri",
    code: "SPT",
    address: "No. 47, Jalan Besi 1, Taman Sri Putri, 81300 Skudai, Johor",
    url: "https://goo.gl/maps/AvsYyUBmoAxJz3hy9",
    coordinate: [1.5439621, 103.6571196],
    pic: [
      {
        name: "Ms Sharron",
        contact: "+6019-7284 428",
      },
      {
        name: "Mr Chai",
        contact: "+6016-7513 983",
      },
    ],
    biz_hrs: {
      day: "Mon-Sun",
      time: "8am-8pm",
    },
  },
  JHJ: {
    hubid: "hub#jhj",
    name: "Johor Bahru, Taman Johor Jaya",
    code: "JHJ",
    address:
      "A3, Johor Jaya Square, Jalan Dedap 1, Taman Johor Jaya, 81100 Johor Bahru, Johor",
    url: "https://goo.gl/maps/y83PfacZCrPk4UXSA",
    coordinate: [1.5431315, 103.7998213],
    pic: [
      {
        name: "Ms Sharron",
        contact: "+6019-7284 428",
      },
      {
        name: "Mr Chai",
        contact: "+6016-7513 983",
      },
    ],
    biz_hrs: {
      day: "Mon-Sun",
      time: "8am-8pm",
    },
  },
  BTW: {
    hubid: "hub#btw",
    name: "Butterworth, JM Hub",
    code: "BTW",
    address: "4786, Jalan Pantai, 1200 Butterworth, Penang",
    url: "https://goo.gl/maps/XFfhBejk9KovxiGi7",
    coordinate: [5.39847, 100.3659097],
    pic: [
      {
        name: "Mr Siva",
        contact: "+6012-5515 815",
      },
      {
        name: "Ms Lestari/Efa",
        contact: "+6012-4757 815",
      },
      {
        name: "Mr Jordan",
        contact: "+6012-4751 815",
      },
    ],
    biz_hrs: {
      day: "Mon-Sun",
      time: "8am-8pm",
    },
  },
  AMJ: {
    hubid: "hub#amj",
    name: "Ipoh, Terminal Meru Jaya",
    code: "AMJ",
    address:
      "Kompleks Terminal Amanjaya, 4-23, Persiaran Meru Raya 5, 30020 Ipoh, Perak",
    url: "https://goo.gl/maps/4cxANsf9yUfhT2dN7",
    coordinate: [4.669722, 101.073382],
    pic: [
      {
        name: "Mr Alfred",
        contact: "+6012-5514 182",
      },
    ],
    biz_hrs: {
      day: "Mon-Sun",
      time: "8am-5pm",
    },
  },
  TBS: {
    hubid: "hub#tbs",
    name: "KL, Terminal Bersepadu Selatan",
    code: "TBS",
    address: "Jln Terminal Selatan, Bandar Tasek Selatan, 57100 Kuala Lumpur, Kuala Lumpur",
    url: "https://goo.gl/maps/tZbFkYmzcCNm7bwk9",
    coordinate: [3.0780526, 101.7023251],
    pic: [],
    biz_hrs: {
      day: "*",
      time: "*",
    },
  },
  DUT: {
    hubid: "hub#dut",
    name: "KL, Hentian Duta",
    code: "DUT",
    address:
      "Counter 21-22,  Blok A, Mezzanine Floor Bangunan Hentian Duta Jalan, Persiaran Tuanku Syed Sirajuddin, 50480 Kuala Lumpur, Kuala Lumpur",
    url: "https://goo.gl/maps/HkLaLm4FYWUPshNT7",
    coordinate: [3.1713249, 101.6740107],
    pic: [
      {
        name: "Mr Qal",
        contact: "+6011-2801 5605",
      },
      {
        name: "Mr Kimi",
        contact: "+6011-1163 6077",
      },
      {
        name: "Mr Li",
        contact: "+6012-3010 360",
      },
      {
        name: "Office",
        contact: "+603-6211 3990",
      },
    ],
    biz_hrs: {
      day: "Mon-Sun",
      time: "8am-8pm",
    },
  },
  OTHERS: {
    hubid: "hub#others",
    name: "Others",
    code: "OTH",
    biz_hrs: {
      day: "*",
      time: "*",
    },
  },
};

export const HUBS_CODE = Object.fromEntries(
  Object.keys(HUBS).map((k) => [k, k])
);

export const HUBS_HUBID = Object.fromEntries(
  Object.values(HUBS).map((v) => [v.hubid, v])
);

export const HUBS_BY_STATE_ORG = {
  [STATE_NAME.JOHOR]: [HUBS_CODE.SPT, HUBS_CODE.JHJ],
  [STATE_NAME.PENANG]: [HUBS_CODE.BTW],
  [STATE_NAME.PERAK]: [HUBS_CODE.AMJ],
  [STATE_NAME.KUALA_LUMPUR]: [HUBS_CODE.TBS, HUBS_CODE.DUT],
};

export const HUBS_BY_STATE = {
  [STATE_NAME.JOHOR]: [HUBS_CODE.SPT, HUBS_CODE.JHJ],
  [STATE_NAME.PENANG]: [HUBS_CODE.BTW],
  [STATE_NAME.PERAK]: [HUBS_CODE.AMJ],
  [STATE_NAME.KUALA_LUMPUR]: [HUBS_CODE.TBS, HUBS_CODE.DUT],
  [STATE_NAME.OTHERS]: [HUBS_CODE.OTHERS],
};

export const SpecialFieldHandler = ["parcel", "sender", "receiver"];

export const PRICING_LIST = {
  columns: [
    {
      title: "L + W + H",
      key: "lwh",
      dataIndex: "lwh",
      align: "center",
    },
    {
      title: "Price (RM)",
      key: "price",
      dataIndex: "price",
      align: "center",
    },
  ],
  data: [
    {
      key: 1,
      lwh: "0 - 120",
      price: "19",
    },
    {
      key: 2,
      lwh: "121 -130",
      price: "29",
    },
    {
      key: 3,
      lwh: "131 - 135",
      price: "49",
    },
    {
      key: 4,
      lwh: "136 - 140",
      price: "69",
    },
    {
      key: 5,
      lwh: "141 - 145",
      price: "79",
    },
    {
      key: 6,
      lwh: "146 - 150",
      price: "89",
    },
    {
      key: 7,
      lwh: "151 - 155",
      price: "109",
    },
    {
      key: 8,
      lwh: "156 - 160",
      price: "129",
    },
  ],
};
