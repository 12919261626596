import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { ROUTES } from "../../../../../../constants"

import { REDUX as REDUX_UTIL} from "../../../../../../services/util";

import * as REDUX_ACTION from "../../../../../../services/redux/actions";

import SearchPoint from "./components/SearchPoint";
import TablePoint from "./components/TablePoint";
import { FEATURE_NAME } from "../../../../../../constants/common/features";

const { reduxStoreWrapper } = REDUX_UTIL;
const { ROUTE_URL } = ROUTES

const TableView = () =>{
    const dispatch = useDispatch()
    const navigate = useNavigate();
    
    const hubs = useSelector(reduxStoreWrapper("point.byID"));
    const loading = useSelector(reduxStoreWrapper("style.loading.hub"));

    const dataSource = Object.values(hubs).map((hub) => {
      return {
          key: hub.hubid,
          ...hub
      }});
  
    useEffect(() => {
      dispatch(
        REDUX_ACTION.v1_point.get_all_points_request()
      );
      dispatch(
        REDUX_ACTION.v1_zone.get_zone_all_request()
      );
      
  
      return () => {};
    }, [dispatch]);

    const [ filteredData, setFilteredData ] = useState(dataSource);
    

    useEffect(() => {
        if (filteredData !== dataSource) {
            setFilteredData(dataSource);
        }
    }, [dataSource]);

    useEffect(() => {
        setFilteredData(filteredData);
    }, [filteredData]);

    const NavigateToPage = (hubid, label, record) =>{
      navigate(ROUTE_URL[FEATURE_NAME.POINT_EDIT],
      {state : {hubid, label, record}})
    }
 
    const [selectedRow, setSelectedRow] = useState([]);

    return(
        <>
        <SearchPoint
          dataSource={dataSource}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          NavigateToPage= {NavigateToPage}
          selectedRow={selectedRow}
          />
        <TablePoint
          dataSource={dataSource}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          loading={loading}
          NavigateToPage= {NavigateToPage}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          />
        </>
    )
}

export default TableView
