import { Dropdown, Table, message} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useState } from 'react';

// Constants Library
import { TEMPLATE } from "../../../../../../../../constants";
 
// Components Library
import { TableList, ButtonLink } from "../../../../../../../../components";
import { ActionItems } from "./action"
import DrawerAssignedOperator from "../DrawerAssignedOperator";


// Handler Library
import { hubHDLR, tableHDLR } from "../../../../../../../../handlers";

// Services Library
import { REDUX as REDUX_UTIL } from "../../../../../../../../services/util";

import * as REDUX_ACTION from "../../../../../../../../services/redux/actions";

const { TABLE_POINT_TITLE, TABLE_POINT_INDEX, SELECT_OPTION_DAYS, TABLE_POINT_OPERATOR_TITLE, TABLE_POINT_OPERATOR_INDEX} = TEMPLATE.POINT;
const { POINT_SUMMARY_COL, EXPANDED_POINT_OPERATORS_LIST } = TEMPLATE.REPORT.POINT;
const { reduxStoreWrapper } = REDUX_UTIL


const TablePoint = (props) => {

    const {
        dataSource,
        filteredData,
        setFilteredData,
        loading,
        NavigateToPage,
        selectedRow,
        setSelectedRow
      } = props;

    const dispatch = useDispatch()

    const [isOpenAssign, setIsOpenAssign] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [data, setData] = useState({})

    const hubs = useSelector(reduxStoreWrapper("hub.byID"));
    const zones = useSelector(reduxStoreWrapper("zone.byID"));
    const userMemid = useSelector(reduxStoreWrapper("user.memid"))
    let operators = useSelector(reduxStoreWrapper("hubOperator.byHubid"))

    const expandedRowRender = (record) => {
        const {hubid, operatorCount} = record
        let loadTable = false

        if (!operators[hubid] && operatorCount !== 0) {
            dispatch(REDUX_ACTION.v1_hubOperator.get_operator_thru_hubid_request(hubid));
            loadTable = true
        }

        const columns = [
            ...EXPANDED_POINT_OPERATORS_LIST,
            {
                title:TABLE_POINT_OPERATOR_TITLE.actions,
                key: TABLE_POINT_OPERATOR_INDEX.actions,
                width:200,
                render: (record) => {
        
                    const {memid} = record

                    const onRemoveHubList = () => {
                        message.loading('Removing hub...', 0)

                        dispatch(REDUX_ACTION.v1_hubOperator.delete_operator_list_from_hub_list_request(memid, operators[hubid], hubid, () => message.destroy()));

                        loadTable = true
                    }
        
                    return <ButtonLink onClick={onRemoveHubList}>Remove</ButtonLink>
                }
            }
        ]

        return (
            <>
                <Table
                    columns={columns}
                    dataSource={operators[hubid] && operators[hubid].map(o => ({
                        key: o.memid,
                        ...o
                    }))}
                    pagination={false}
                    loading={loadTable}
                />
            </>
        )
    }

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    }; 

    const columns = [
    ...POINT_SUMMARY_COL({
        redux:{
          hubs,
          zones
        },
        func:{
            handleSearch
        }
    }),
    {
        title: TABLE_POINT_TITLE.actions,
        width: 100,
        fixed: "right",
        render: (_, record) => {
        return(
            <Dropdown
              menu={{
                    items: ActionItems(
                      record,
                      setIsOpenAssign,
                      setData,
                      NavigateToPage,
                      dispatch,
                      userMemid
                    )
              }}>
            <ButtonLink>Actions</ButtonLink>
            </Dropdown>
        )
        }
    },
    ]

    const sortedData = filteredData.sort((a, b) => b.updatedAt - a.updatedAt)

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const handleTableChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const sortFunctions = {
        createdAt: (a, b) => a.createdAt - b.createdAt,
        updatedAt: (a, b) => a.updatedAt - b.updatedAt,
    };
    
    const filtered = sortedData.filter((record) => {
        let include = true;
    
        Object.keys(filteredInfo).forEach((key) => {
            if (filteredInfo[key] && filteredInfo[key].length > 0) {
                include = include && filteredInfo[key].includes(record[key]);
            }
            });
        
            if (searchText) {
                if(searchedColumn === "memid") {
                    if ((typeof record.code === 'string' && record.code.includes(searchText)) || 
                        (typeof record.name === 'string' && record.name.includes(searchText))) {
                      include = true;
                    }
                  }
            } 

        return include;

    }).sort((a, b) => {
        const { columnKey } = sortedInfo;
        return sortFunctions[columnKey] ? sortFunctions[columnKey](a, b) : 0;
    });
    

    const onSelectRow = (record, selected) => {
        tableHDLR.selectRow(setSelectedRowKeys, setSelectedRow, selectedRow, selectedRowKeys, record, selected)
    };
 
    const rowSelection = {
        selectedRowKeys,
        onSelect: onSelectRow
    };

    return (
        <>
        <DrawerAssignedOperator isOpen={isOpenAssign} setIsOpen={setIsOpenAssign} data={data}/>
          <div style={{marginTop:'6.5rem'}}>
            <TableList.DataList
                dataSource={dataSource}
                filteredData={filtered}
                setFilteredData={setFilteredData}
                optionDay={SELECT_OPTION_DAYS}
                optionFirst={hubHDLR.hubStatus()}
                dataDate={TABLE_POINT_INDEX.created_at}
                defaultValue="CREATED AT"
                text1="POINT STATUS"
                columnData={columns}
                expandedRowRender={expandedRowRender}
                loading={loading}
                rowSelection={rowSelection}
                showFirstFilter={true}
                onChange={handleTableChange}
              />
          </div>   
        </>
    );
}

export default TablePoint
