// React Thirty Party Library
import { Tag } from "antd";
 
// Constant
import { TEMPLATE} from "../../../constants";

// Components
 
//handlers
import { filter } from "../../../handlers"

// Service
import { TIME } from "../../../services/util";

const {
  TABLE_CUSTOMER_WALLET_INDEX,
  TABLE_CUSTOMER_WALLET_TITLE,
  USER_ROLE_TITLE,
  OPTION_USER_STATUS,
  OPTION_USER_STATUS_TITLE,
} = TEMPLATE.CUSTOMERWALLET;

const { parseTimeMin } = TIME;

const { searchHDLR, filterHDLR } = filter

export const WALLET_SUMMARY_COL = ({func= {}, exportState = false}) => {

  const {handleSearch} = func

  return [
    {
      title: TABLE_CUSTOMER_WALLET_TITLE.Customer_Id,
      key: TABLE_CUSTOMER_WALLET_INDEX.Customer_Id,
      render: (record) => {
        const { memid, name } = record;
        return exportState ? (
          `${memid} ${name}`
        ) : (
          <span>
            {memid} / {name}
          </span>
        );
      },
      ...searchHDLR.getColumnSearchProps("memid", handleSearch)
    },
    {
      title: TABLE_CUSTOMER_WALLET_TITLE.User_Role,
      key: TABLE_CUSTOMER_WALLET_INDEX.User_Role,
      render: (record) => {
        const { uroleid } = record;
        const urole = USER_ROLE_TITLE[uroleid];
        return exportState ? urole : <span>{urole} </span>;
      },
    },
    {
      title: TABLE_CUSTOMER_WALLET_TITLE.User_Status,
      key: TABLE_CUSTOMER_WALLET_INDEX.User_Status,
      render: (record) => {
        const { status } = record;

        const statusKey = status
          ? OPTION_USER_STATUS.Active
          : OPTION_USER_STATUS.Suspend;
        const { name, colorTag } = OPTION_USER_STATUS_TITLE[statusKey];

        return exportState ? (
          name
        ) : (
          <>
            <Tag color={colorTag}>{name}</Tag>
          </>
        );
      },
      filters : filterHDLR.getColumnsFilterStatus(OPTION_USER_STATUS_TITLE),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: TABLE_CUSTOMER_WALLET_TITLE.Contact,
      key: TABLE_CUSTOMER_WALLET_INDEX.Contact,
      render: (record) => {
        const { contact } = record;
        return exportState ? contact : <span>{contact}</span>;
      },
    },
    {
      title: TABLE_CUSTOMER_WALLET_TITLE.Wallet_Balance,
      key: TABLE_CUSTOMER_WALLET_INDEX.Wallet_Balance,
      width: 150,
      render: (record) => {
        const { currency, balance } = record;
        return exportState ? (
          `${currency} ${balance}`
        ) : (
          <span>
            {currency} {balance || "-"}
          </span>
        );
      }, 
      sorter: (a, b) => a.balance - b.balance,
    },
    {
      title: TABLE_CUSTOMER_WALLET_TITLE.Last_Transaction_Date,
      key: TABLE_CUSTOMER_WALLET_INDEX.Last_Transaction_Date,
      width: 190,
      render: (record) => {
        const { lastTransaction } = record;
        return exportState ? (
          parseTimeMin(lastTransaction)
        ) : (
          <span>{parseTimeMin(lastTransaction)}</span>
        );
      },
      sorter: (a, b) => a.lastTransaction - b.lastTransaction,
    },
  ];
};
