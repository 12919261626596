import * as ActionTypes from "../action-types";

const accountManager_defaultState = {
  byID: {},
  allIDs: [],
}; 

export const accountManager = (state = accountManager_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.GET_ACCOUNT_MANAGER_LIST: {
      const accounts = action.manager;

      accounts &&
        accounts.length &&
        accounts.map((user) => {
          const {memid} = user;

          newState.byID[memid] = user;

          if (!newState.allIDs.includes(memid)) {
            newState.allIDs.push(memid);
          }
          return null;
        });

      return newState;
    }
    case ActionTypes.CREATE_ACCOUNT_MANAGER: {
      const accounts = action.manager;
      const { memid } = accounts;

      if (memid) {
        newState.byID[memid] = accounts;

        if (!newState.allIDs.includes(memid)) newState.allIDs.push(memid);
      }

      return newState;
    }
    case ActionTypes.UPDATE_ACCOUNT_MANAGER_INFO: {
      const user = action.manager

      const {memid} = user

      newState.byID[memid] = user

      return newState
    }
    default: {
      return state;
    }
  }
};
