// React Thirty Party Library
import { Result, Tag, Typography } from "antd";

// Constant
import { TEMPLATE } from "../../../constants";

// Components
import { ButtonLink } from "../../../components";

//handlers
import { filter } from "../../../handlers"

// Service
import { TIME } from "../../../services/util";

const { TABLE_HUB_OPERATOR_TITLE, OPTION_HUB_OP_STATUS_TITLE, OPTION_HUB_OP_STATUS} = TEMPLATE.HUB;

const { parseTimeMin } = TIME;

const { searchHDLR, filterHDLR } = filter

const { Text } = Typography;

export const HUB_OPERATOR_SUMMARY_COL = ({ func = {}, exportState = false }) => {
  const {handleSearch} = func

  return [
    {
      title: `${TABLE_HUB_OPERATOR_TITLE.hub_opid} / ${TABLE_HUB_OPERATOR_TITLE.name}`,
      render: (record) => {
        const { memid, name } = record;
        return exportState ? `${memid} / ${name}` : <span>{memid} / {name} </span>;
      },
      ...searchHDLR.getColumnSearchProps("memid", handleSearch)
    },
    {
      title: TABLE_HUB_OPERATOR_TITLE.status,
      width: 100,
      render: (record) => {
        const { status } = record; 

        const statusKey = status
          ? OPTION_HUB_OP_STATUS.Active
          : OPTION_HUB_OP_STATUS.Suspend;
        const { name, colorTag } = OPTION_HUB_OP_STATUS_TITLE[statusKey];

        return exportState ? (
          name
        ) : (
          <>
            <Tag color={colorTag}>{name}</Tag>
          </>
        );
      },
      filters: filterHDLR.getColumnsFilterStatus(OPTION_HUB_OP_STATUS_TITLE),
      onFilter: (value, record) => record.status === value
    },
    {
      title: TABLE_HUB_OPERATOR_TITLE.contact,
      render: (record) => {
        const { contact } = record;
        
        return exportState ? (
          contact
        ) : (
          <span>
            {contact}
          </span>
        );
      }, 
      ...searchHDLR.getColumnSearchProps("contact", handleSearch)
    },
    {
      title: TABLE_HUB_OPERATOR_TITLE.email,
      render: (record) => {
        const { email } = record;
        return exportState ? 
          email
         : 
          <span>{email}</span>
        
      },
      ...searchHDLR.getColumnSearchProps("email", handleSearch)
    },
    {
      title: TABLE_HUB_OPERATOR_TITLE.assignedHub,
      width: 100,
      render: (record) => {

        const { hubs } = record;

        const assign = hubs && hubs.map(h => {
          return h?.code
        })

        const hubAssigned = assign && assign.length > 1 ? assign.join(', ') : assign

        return exportState ? 
        hubAssigned
         : 
          <span>
            {hubAssigned}
          </span>
        ;
      },
    },
    {
      title: TABLE_HUB_OPERATOR_TITLE.created_at,
      render: (record) => {
        const { createdAt } = record;
        return exportState ?  parseTimeMin(createdAt) : <span> {parseTimeMin(createdAt)} </span>;
      },
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
        title: TABLE_HUB_OPERATOR_TITLE.last_update,
        render: (record) => {
          const { updatedAt } = record;
          return exportState ? parseTimeMin(updatedAt) : <span> {parseTimeMin(updatedAt)} </span>;
        },
        sorter: (a, b) => a.updatedAt - b.updatedAt,
      },
    {
      title: TABLE_HUB_OPERATOR_TITLE.remark,
      width: 100,
      render: (record) => {
        const { remark } = record;
        return exportState ? Array.isArray(remark) && remark[0] && remark[0].msg : <p> {Array.isArray(remark) && remark[0] && remark[0].msg || '-'} </p>
      },
    }
  ];
};
