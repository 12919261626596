import { Input } from 'antd'

import { BtnSubmit } from '../../../../../../components'

import classStyles from "../../index.module.css"

const RemarkTextArea = ({
    value, 
    setValue,
    handleOkButton,
    setShowTextArea
}) => {
  
    return (
        <div className={classStyles["text-area"]}>
            <Input.TextArea
                value={value.msg}
                onChange={(e) => setValue({
                    ...value,
                    msg: e.target.value
                })}
                placeholder="Type your remarks here..."
                autoSize={{
                    minRows: 10,
                    maxRows: 20,
                }}
            />
                <div className={classStyles["buttons"]}>
                <BtnSubmit
                    display="inline-block"
                    text="Save"
                    bgColor="var(--themeBackgroundColor)"
                    color="var(--creamColor)"
                    padding="15px 30px"
                    onClick={handleOkButton}
                />
                <BtnSubmit
                    display="inline-block"
                    text="Cancel"
                    bgColor="transparent"
                    color="var(--bs-gray-600)"
                    padding="9px 18px"
                    onClick={() => {
                        setShowTextArea(false)
                    }}
                />
            </div>
        </div>
    )
}

export default RemarkTextArea
