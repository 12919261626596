// React Thirty Party Library

// Constants Library
import { TEMPLATE } from "../../../../../../constants";

// Components Library
import { TableList, BtnSubmit } from "../../../../../../components";

// Handler Library
import { exportHDLR } from "../../../../../../handlers";

// Services Library
import { TIME } from "../../../../../../services/util";

// Redux Actions

// Styles Library
import classes from "../../../../../../components/index.module.css";

const { TABLE_CUSTOMER_WALLET_INDEX } = TEMPLATE.CUSTOMERWALLET;
const { BTN_IMG } = TEMPLATE.BTN;
const { WALLET_SUMMARY_COL } = TEMPLATE.REPORT.CUSTOMERWALLET;

const { parseExportTime } = TIME;

const SearchCustomerWallet = (props) => {
  const { filteredDataWallet, setFilteredDataWallet, dataSourceWallet, selectedRow} = props;

  const exportState = true;
  const template = WALLET_SUMMARY_COL({exportState});

  const options = [
    {
      value: TABLE_CUSTOMER_WALLET_INDEX.Customer_Id,
      label: "Customer ID",
      type: "text",
    },
    {
      value: TABLE_CUSTOMER_WALLET_INDEX.Last_Transaction_Date,
      label: "Custome date",
      type: "timeframe",
    },
  ];

  const exportData = selectedRow.length > 0 ? selectedRow : filteredDataWallet;

  return (
    <div className={classes.headerPage}>
      <TableList.SearchTableQuery
        dataSource={dataSourceWallet}
        setFilteredData={setFilteredDataWallet}
        options={options}
        defaultValue={TABLE_CUSTOMER_WALLET_INDEX.Customer_Id}
      />
      <BtnSubmit
        display="inline-block"
        text="Export"
        bgColor="transparent"
        color="#4CAF50"
        padding="9px 18px"
        onClick={() =>
          exportHDLR.exportWithTemplate({
            data: exportData,
            filename: `CustomerWallet_${parseExportTime()}`,
            template: template,
          })
        }
        showIcon={true}
        icon={BTN_IMG.EXPORT}
        icon_alt={"export"}
      />
    </div>
  );
};

export default SearchCustomerWallet;
