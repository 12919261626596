// Third Party Library 
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "antd";

// Components 
import { ButtonLink } from "../../../../components";
import TextArea from "./TextArea";
import CommentLists from "./CommentLists";

// Constants 
import { TEMPLATE } from "../../constants";

// Services Library
import { REDUX as REDUX_UTIL } from "../../services/util";

import * as REDUX_ACTION from "../../services/redux/actions";

// Styles 
import classStyles from "./index.module.css";

const { 
    reduxStoreWrapper 
} = REDUX_UTIL

const {
    ORDER_STATUS
} = TEMPLATE.ORDER

const DrawerComments = ({ 
    isOpen, 
    setIsOpen, 
    data 
}) => {

    const dispatch = useDispatch()

    let user = useSelector(reduxStoreWrapper("user"))

    const { ordid, status } = data

    // initializations
    const [ value, setValue ] = useState({});
    const [ showTextArea, setShowTextArea ] = useState(false)
    const [ showEditTextArea, setShowEditTextArea ] = useState(false)

    // useState for edit status. usable for determining the action in handleOkButton (whether Submit or Edit)
    const [edit, setEdit] = useState(false);

    // using useEffect to make sure the user data is available 
    useEffect(() => {
        isOpen && !value.commentedBy && setValue({
            ...value, 
            commentedBy: user.memid,
            active: 1
      })
    }, [isOpen, !value.commentedBy, user])
    
    // Comment drawer header 
    const CommentHeader = () => {
        return (
            <div className={classStyles["header"]}>
                <h1 className={classStyles["title"]} >Comments ({data.ordid})</h1>
                {/* {(status === ORDER_STATUS.PARKED) &&  */}
                <ButtonLink 
                    onClick={() => setShowTextArea(true) && setShowEditTextArea(false)}
                >
                    + Add Comment
                </ButtonLink>
            </div>
        )
    }

    // if ok performed (Save button), one of these actions will triggered
    const handleOkButton = () => {

        try {
            switch (edit) {
                // Submit 
                case false: {
                    dispatch(REDUX_ACTION.v1_order.create_order_comment_data({...value, ordid, orderStatus: status}))
                    setShowTextArea(false)
                    break;
                }
                // Edit 
                case true: {
                    dispatch(REDUX_ACTION.v1_order.edit_order_comment_data({...value, ordid, orderStatus: status}))
                    setShowEditTextArea(false)
                    break;
                }
            }
        } 
        // in general, as long as handleOkButton performed, these action will run
        finally {
            setEdit(false)
            setValue({...value, comment: '', url:''})
            setShowTextArea(false)
        }
    }

    // attributes used for CommentLists 
    const commentListsAttr = {
        ordid,
        setEdit,
        setShowEditTextArea,
        showTextArea,
        setShowTextArea,
        edit,
        showEditTextArea,
        handleOkButton,
        value, 
        setValue,
        ORDER_STATUS,
        status
    }

    return (
        <Drawer
        title={<CommentHeader />}
        placement="right"
        closable={false}
        width={500}
        onClose={() => setIsOpen(false)}
        open={isOpen}
    >
        {/* if user clicks the 'Add Comment' button (available on the top-right of drawer), then the TextArea will be shown  */}
        {showTextArea &&
            <TextArea
                value={value}
                setValue={setValue}
                handleOkButton={handleOkButton}
                setShowTextArea={setShowTextArea} 
            />
        }

        {/* List of Comments  */}
        <CommentLists 
            {...commentListsAttr}
        />
    </Drawer>
    );
};

export default DrawerComments;
