// Third-party Library
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Form, message } from 'antd'

// Constants
import { POINT } from '../../../../constants/templates';

// Components 
import { 
    PointCreateHeader,
    PointInformation,
    PointLocation,
    PointOperators,
    PICInformation 
} from "./components";

// Services 
import { REDUX as REDUX_UTIL } from "../../../../services/util";

import * as REDUX_ACTION from "../../../../services/redux/actions";

// Styles 
import classes from "../../common.module.css"

const { reduxStoreWrapper } = REDUX_UTIL

const { GET_BIZ_HRS_OBJ, GET_BIZ_HRS_ARR } = POINT

const PointForm = ({
    hubData
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const user = useSelector(reduxStoreWrapper("user"))

    const [ form ] = Form.useForm();
    const [ messageApi, contextHolder ] = message.useMessage();

    const onSubmit = () => {

        const { code, coordinate, biz_hrs, operator: ops, pic } = form.getFieldsValue()

        // value passed into API, with adjusted format
        const hubid = hubData ? hubData.hubid : `point#${code.toLowerCase()}` 
        const status = hubData ? hubData.status : 1

        const operators = {
            hubid,
            // op is Operator object array, consists of list of uid 
            operators: ops.map(op => ({
                uid: op
            }))
        }

        // value passed into API, with adjusted format
        const items = {
            ...form.getFieldsValue(),
            hubid, status,
            coordinate: {
                lat: parseFloat(coordinate.lat),
                lon: parseFloat(coordinate.lon)
            },
            type: 'P001',
            pic: pic.map(pic => ({ 
                name: pic.name, 
                contact: `${pic.contactPrefix}-${pic.contact}` 
            })),
            biz_hrs: GET_BIZ_HRS_ARR(biz_hrs),
        }

        delete items.operator

        //  Passing to redux action
        if(items.biz_hrs.length !== 0) {
            // to show the loading popup 
            messageApi.open({
                type: "loading",
                content: `${hubData ? "Updating" : "Adding new"} point`,
                duration: 0,
            });

              if(!hubData) {
                  dispatch(REDUX_ACTION.v1_point.create_new_point_request(
                      items, 
                      operators,
                      () => messageApi.destroy(),
                      () => navigate(-1)
                  ))
              } else {
                dispatch(REDUX_ACTION.v1_point.point_update_request(
                    {...items, updatedBy: user.memid },
                    operators,
                    () => messageApi.destroy(),
                    () => navigate(-1)
                ))
          }
        } else {
          message.error("Operation Hours cannot be empty. Please enter the corresponding information.")
        }
    }

    /*
        validate form function, triggered after click Create / Save
        if the required form still blank, the data won't be submitted 
    */
    const onSubmitValidateHDLR = async (e) => {

        e 
        && 
        await form.validateFields()
        .then(() => {
            onSubmit() // the e condition used for duplicated-calling prevention
        })
        .catch(() => {
            message.error("Fill the blank!")
        })
    }

    hubData && delete hubData.operator // temporary handling to delete operator variable in

    const initialValues = 
        hubData 
        && 
        {
            ...hubData,
            biz_hrs: GET_BIZ_HRS_OBJ(hubData),
            pic: hubData.pic.map(pic => ({
                name: pic.name,
                contact: pic.contact.split('-')[1],
                contactPrefix: pic.contact.split('-')[0]
            }))
        } 
        

    const title =  hubData ? "Edit Point" : "Create new point"
    const btnText = hubData ? "Save" : "Create"

    return (
        <div className={classes["container"]}>
            { contextHolder }
            <Form 
                form={form}
                name="form_point"
                layout="vertical"
                onSubmitCapture={onSubmitValidateHDLR}
                initialValues={initialValues}
            >
                <PointCreateHeader 
                    title={title}
                    btnText={btnText}
                    hubid={hubData?.hubid}
                    onSubmit={onSubmitValidateHDLR}
                />
                <div 
                    style={{
                        width: '40vw',
                        minWidth: '50em',
                        margin: '7em auto'
                    }}
                >
                    <PointInformation form={form} />
                    <PointLocation />
                    <PointOperators hubid={hubData?.hubid} />
                    <PICInformation />
                </div>
            </Form>

        </div>
    )
}

export default PointForm
