import { Drawer, Form, Select, message } from "antd";
import { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { BtnSubmit } from "../../../../../../components";

import { REDUX as REDUX_UTIL } from "../../../../../../services/util";

import * as REDUX_ACTION from "../../../../../../services/redux/actions";

import classStyles from "./index.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;

const DrawerBulkAssign = (props) => {
  const { isOpen, setIsOpen, data} = props
  
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedOrdid, setSelectedOrdid] = useState([]);
  
  const allOrder = useSelector(reduxStoreWrapper("order.byID"));
  const plate = useSelector(reduxStoreWrapper("vehicle.byID"));
  const user = useSelector(reduxStoreWrapper("user"));
  const admid = user.memid;
  
  const filteredOrderIds = Object.keys(allOrder).filter(orderId => {
    const orderData = allOrder[orderId];
    const { status } = orderData;
    return (
      status === "O0203" ||
      status === "O0300" ||
      status === "O0400" ||
      status === "O0503" ||
      status === "O1300" ||
      status === "O1400"
    );
  });

  const optionOrderId = filteredOrderIds.map(orderId => {
    return {
      label: orderId,
      value: orderId,
    };
  });
  
  const newData = data.filter(value => optionOrderId.find(option => option.value === value));
  
  const optionVehiclePlate = Object.values(plate)
    .map(vehicle => vehicle.plate)
    .filter((value, index, self) => self.indexOf(value) === index)
    .map(value => ({
      label: value,
      value: value,
  }));
  
  const handleOrdedId = (values) => {
    setSelectedOrdid(values);
  };

  const orderAssignVehicle = () => {
    const formData = form.getFieldsValue(); 
    
    const { plate } = formData

    messageApi.open({
      type: "loading",
      content: "Assign Bulk Vehicle...",
      duration: 0,
    });

    dispatch(
      REDUX_ACTION.v1_order.order_assign_vehicle({ orders: selectedOrdid, v_plate: plate, adpMemid: admid}, () => {
        messageApi.destroy();
      })
    );
  };

  const ActionButton = (
    <Form.Item style={{ paddingTop: "20px" }}>
      <div>
        <BtnSubmit
          display="inline-block"
          text="Assign"
          bgColor="var(--themeBackgroundColor)"
          color="var(--creamColor)"
          padding="9px 18px"
          onClick={() => {
            orderAssignVehicle()
            setIsOpen(false);
          }}
        />
        <BtnSubmit
          display="inline-block"
          text="Cancel"
          bgColor="transparent"
          color="var(--bs-gray-600)"
          padding="9px 18px"
          onClick={() => {
            form.resetFields();
            setIsOpen(false);
          }}
        />
      </div>
    </Form.Item>
  );

  return (
    <>
    {contextHolder}
    <Drawer
      title="Bulk Assign Vehicle"
      placement="right"
      closable={false}
      width={410}
      onClose={() => setIsOpen(false)}
      footer={ActionButton}
      open={isOpen}
      style={{
        textAlign: "center",
        fontSize: "20px",
      }}
    >
    <Form
      form={form}
      layout="vertical"
      initialValues={{ ordid: newData  || []}}
    >
        <label htmlFor="ordid"  className={classStyles.labelInput}>
            Order ID
        </label>
        <Form.Item
          name="ordid"
          rules={[
            {
              required: true,
              message: "Order ID is required",
            },
          ]}
          className={classStyles.materialTextField} 
        >
          
          <Select
            showSearch
            mode="multiple"
            maxTagCount={10}
            onChange={(values) => handleOrdedId(values)}
            options={optionOrderId}
          />
        </Form.Item>

        
        <label htmlFor="plate"  className={classStyles.labelInput}>
            Vehicle Plate
        </label>
        <Form.Item
          name="plate"
          rules={[
            {
              required: true,
              message: "Vehicle plate is required",
            },
          ]}
          className={classStyles.materialTextField} 
        >
          <Select
            options={optionVehiclePlate}  className={classStyles.optionDrawer}
          />
        </Form.Item> 
    </Form>
    </Drawer>
    </>
  );
};

export default DrawerBulkAssign;
