import * as API_CONSTANT from '../constants'

export const getAllHubs = () => (
    fetch(`${API_CONSTANT.adp_v1_fe_path}/hub/get/all`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({})
    })
    .then(res => res.json())
)

export const createHub = (items) => 
    fetch(`${API_CONSTANT.adp_v1_fe_path}/hub/create`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify(items)
    })
    .then(res => res.json())
    
export const updateHub = (data) =>
    fetch(`${API_CONSTANT.adp_v1_fe_path}/hub/update`, {
        method:'POST',
        headers: API_CONSTANT.headers,
        body:JSON.stringify(data)
    })
    .then(res => res.json())

export const getAllHubOperator = (role) => (
    fetch(`${API_CONSTANT.adp_v1_fe_path}/operator/get/all`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify(role)
    })
    .then(res => res.json())
)

export const hubAssignedOperator = (items) => (
    fetch(`${API_CONSTANT.adp_v1_fe_path}/hub/assign/operator`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify(items)
    })
    .then(res => res.json())
)

export const createHubOperator = (items) => 
fetch(`${API_CONSTANT.adp_v1_fe_path}/operator/create`, {
    method: 'POST',
    headers: API_CONSTANT.headers,
    body: JSON.stringify(items)
})
.then(res => res.json())

export const updateHubOperator = (items) => 
fetch(`${API_CONSTANT.adp_v1_fe_path}/operator/update`, {
    method: 'POST',
    headers: API_CONSTANT.headers,
    body: JSON.stringify(items)
})
.then(res => res.json())

export const getOperatorThruHUBID = (hubid) => 
fetch(`${API_CONSTANT.adp_v1_fe_path}/operator/get/thru/hubid`, {
    method: 'POST',
    headers: API_CONSTANT.headers,
    body: JSON.stringify({hubid})
})
.then(res => res.json())

export const getActiveHubs = () => (
    fetch(`${API_CONSTANT.adp_v1_fe_path}/hub/get/active`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify({})
    })
    .then(res => res.json())
)
