// Third-party Library
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List, Tag, Skeleton } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'; 

// Component 
import TextArea from '../TextArea';

// Services Library
import { REDUX as REDUX_UTIL, TIME } from "../../../services/util";

import * as REDUX_ACTION from "../../../services/redux/actions";

// Styles 
import classStyles from "../index.module.css";

const { 
    reduxStoreWrapper 
} = REDUX_UTIL

const CommentLists = ({
    ordid,
    setEdit,
    setShowEditTextArea,
    showTextArea,
    setShowTextArea,
    edit,
    showEditTextArea,
    value,
    setValue,
    handleOkButton,
    ORDER_STATUS, 
    status
}) => {
    const dispatch = useDispatch()

    let user = useSelector(reduxStoreWrapper("user"))

    const orderStatus = useSelector(reduxStoreWrapper("orderStatus.byID"))
    const loading = useSelector(reduxStoreWrapper('style.loading.order_comment'))
    const comments = useSelector(reduxStoreWrapper('order.byComment'))

    const [index, setIndex] = useState("")

    return (
        <Skeleton loading={loading} active>
            <List
                itemLayout="horizontal"
                dataSource={comments[ordid]?.filter(c => c.active && 1)}
                renderItem={(cel, i) => {

                    // Handler function for Edit and Delete 
                    const isEditHDLR = () => {
                        setIndex(i)
                        setEdit(true);
                        setValue(cel)
                        showTextArea && setShowTextArea(false) 
                        setShowEditTextArea(true)
                    }
                    const isDeleteHDLR = () => {
                        dispatch(REDUX_ACTION.v1_order.archive_order_comment_data(cel))
                    }

                    // Render the title of lists (name/memid) + edit/delete icons 
                    const Title = () => {

                        return (
                            <div className={classStyles["list-header"]}>
                                <>{cel.commentedBy} / {user.name}</>
                                {/* {(status === ORDER_STATUS.PARKED) &&  */}
                                <div>
                                    <EditOutlined 
                                        className={classStyles["icons"]} 
                                        onClick={isEditHDLR}
                                    />

                                    <DeleteOutlined 
                                        className={classStyles["icons"]} 
                                        onClick={isDeleteHDLR}
                                    />
                                </div>
                                {/* } */}
                            </div>
                        )
                    }

                    // Render the contents (the comments itself and/or attachment link) 
                    const Description = () => {
                        var url = cel.url 

                        return (
                            <>
                                Last updated at {TIME.parseTimeMin(cel.createdAt)}<br/><br/>
                                <Tag color={orderStatus[cel.orderStatus]?.colorTag} key={cel.orderStatus}>
                                    {orderStatus[cel.orderStatus]?.display}
                                </Tag><br/><br/>
                                {cel.comment}<br/><br/>
                                {url && url !== '-' && <a href={url} target="_blank">{url.substr(0, 20)}...</a>}
                            </>
                        )
                    }

                    // Main render
                    return (
                        <>
                            {edit && i === index && showEditTextArea ? 
                                <TextArea
                                    value={value}
                                    setValue={setValue}
                                    handleOkButton={handleOkButton}
                                    setShowTextArea={setShowEditTextArea} 
                                />
                                :
                                <List.Item style={{padding: "30px 0"}}>
                                        <List.Item.Meta
                                            title={<Title />}
                                            description={<Description />}
                                        />
                                </List.Item>
                            }
                        </>
                    )
            }}/>
        </Skeleton>
    )
}
export default CommentLists
