// Third-party Library
import React, { useState } from "react";
import { Steps } from "antd";
import { useDispatch, useSelector } from "react-redux";

// Constants
import { TEMPLATE } from "../../../../../../constants";

import { ButtonLink, ButtonModal } from "../../../../../../components";
import ModalImage from "../ModalImage/";

// Services
import { TIME, REDUX as REDUX_UTIL } from "../../../../../../services/util";

import * as REDUX_ACTION from "../../../../../../services/redux/actions";

const { ORDER_STATUS } = TEMPLATE.ORDER;
const { MODAL_ACTION } =  TEMPLATE.MODAL
const { reduxStoreWrapper } = REDUX_UTIL;

// Styles
import classes from "../../index.module.css";

const TimelineList = ({ orderLogs, orderStatuses, hubs, point, record, ordid }) => {
  const dispatch = useDispatch()
  const user = useSelector(reduxStoreWrapper("user"));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleModal = (record) => {
    setSelectedRecord(record);
    setIsModalOpen(true);
  };

    const statusModalAction = (status, record) => {
    switch (status) {
      case ORDER_STATUS.PENDING_SELF_LODGE:
        return MODAL_ACTION.update_status_self_lodge;
      case ORDER_STATUS.PENDING_SELF_LODGE_POINT:
        return MODAL_ACTION.update_status_self_lodge_point;
      case ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER:
        return MODAL_ACTION.update_status_pickup;

      case ORDER_STATUS.ARRIVED_AT_ORIGIN_POINT:
        return MODAL_ACTION.update_status_sort_point;

      case ORDER_STATUS.CHECK_OUT_FROM_POINT:
        return MODAL_ACTION.update_status_check_out_from_point;

      case ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB:
        return record.deliverType === "p2p" ? MODAL_ACTION.update_status_check_in_to_orighub : MODAL_ACTION.update_status_sort

      case ORDER_STATUS.DEPARTED_FROM_ORIGIN_HUB:
        return MODAL_ACTION.update_status_check_out_from_orighub;

      case ORDER_STATUS.ARRIVED_AT_DEST_HUB:
        return MODAL_ACTION.update_status_check_in_to_dsthub;

      case ORDER_STATUS.PENDING_OUTBOUND_TO_POINT:
        return MODAL_ACTION.update_status_check_out_from_dsthub;

      case ORDER_STATUS.PENDING_SELF_COLLECTION:
        return MODAL_ACTION.update_status_self_collect;
      case ORDER_STATUS.PENDING_DOORSTEP_DELIVERY:
        return MODAL_ACTION.update_status_pending_delivery;
      case ORDER_STATUS.PENDING_SELF_COLLECTION_POINT:
        return record.deliverType === "p2p" ? MODAL_ACTION.update_status_pending_delivery : MODAL_ACTION.update_status_self_collect

      case ORDER_STATUS.FULFILLED:
        return MODAL_ACTION.update_status_received_by_receiver;
      case ORDER_STATUS.PARKED:
        return MODAL_ACTION.update_status_park_order;
      default:
        return null;
    }
  };  

  const Title = ({ status, updatedAt, olog }) => {
    const statusNameTitle =
      orderStatuses[status] && orderStatuses[status].display;

    const statusNameHub = () => {
      if (status === "O0300" || status === "O0203") {
        return `${statusNameTitle} (${
          hubs[record.origid] && hubs[record.origid].code
        })`;
      } else if (status === "O0400" || status === "O0503") {
        return `${statusNameTitle} (${
          hubs[record.dstid] && hubs[record.dstid].code
        })`;
      } else if (status === "O1200" || status === "O1300") {
        return `${statusNameTitle} (${
          point[record.origpid] && point[record.origpid].code
        })`;
      } else if (status === "O1400" || status === "O1500") {
        return `${statusNameTitle} (${
          point[record.dstpid] && point[record.dstpid].code
        })`;
      }
    };

    const hubName = statusNameHub();
    const statusName =
      status === "O0300" || status === "O0400" || status === "O1200" || status === "O1400" || status === "O0203" || status === "O1300" || status === "O0503" || status === "O1500" ? hubName : statusNameTitle;

    return (
      <div className={classes["timeline-title"]}>
        <h1>{statusName}</h1>
        <p>{TIME.parseTimeMin(updatedAt)}</p>
        {ORDER_STATUS.PARKED && 
          <div>
            {statusModalAction(status, record) && (
              <ButtonModal
                model={"link"}
                setmodal={statusModalAction(status, record)}
                type="primary"
                style={{ width: "100%", height: "100%" }}
                onClick={(value) =>
                  dispatch(REDUX_ACTION.v1_orderLog.update_order_logs_thru_ordid_request({
                  ...olog, 
                  initiatedAt: value && value.initiatedAt,
                  updatedBy: value && user.memid,
                  remark: value && value.remark, 
                  attachment: value && value.attachment}))
                }
                extra={{ ordid }}
                data={olog}
              >
                Edit
              </ButtonModal>
              )}
          </div>
          }
      </div>
    );
  };

  const Description = ({ remark, attachment }) => {
    return (
      <div className={classes["timeline-subtitle"]}>
        <p>Remarks: {remark}</p>
        <p>
          Attachment:
          {attachment?.length > 0 ? (
            <ButtonLink onClick={() => handleModal(attachment)}>
              View
            </ButtonLink>
          ) : (
            "-"
          )}
        </p>
      </div>
    );
  };

  const ologItems =
    orderLogs &&
    orderLogs
      .map((ologItem) => {
        const { status, updatedAt, remark, attachment } = ologItem;

        return {
          title: <Title status={status} updatedAt={updatedAt} olog={ologItem}/>,
          description: <Description remark={remark} attachment={attachment} />,
        };
      })
      .reverse()

  return (
    <>
      <Steps
        direction="vertical"
        size="small"
        items={ologItems && ologItems}
        current={ologItems && ologItems.length}
      />
      <ModalImage
        isModalOpen={isModalOpen && selectedRecord}
        setIsModalOpen={() => setIsModalOpen(false)}
        dataOrder={selectedRecord}
      />
    </>
  );
};

export default TimelineList;
