// Third-party Library 
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react"
import { Select, Row, Col, Button } from "antd";
import { GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';
import { 
    SearchOutlined 
  } from "@ant-design/icons";

// Constants 
import { APP_CONFIG } from '../../../../config/';
import { ROOT_CONSTANT, TEMPLATE } from '../../../../constants';

// Services Library
import { REDUX as REDUX_UTIL, TIME } from "../../../../services/util";

// Actions
import * as REDUX_ACTION from "../../../../services/redux/actions";

// Styles 
import classes from './index.module.css'
import { ButtonLink } from "../../../../components";

const { parseTime, currentTimeMilliSecond } = TIME

const {
    icon_map_color
} = ROOT_CONSTANT.IMG.ICON.MODAL

const {
    reduxStoreWrapper
} = REDUX_UTIL

const MapViewVehicle = ({plate, vid}) => {
    const dispatch = useDispatch()
    
    const vehicles = useSelector(reduxStoreWrapper("vehicle.byPlate"));
    const [infoWindowOpen, setInfoWindowOpen] = useState(false);
    const [address, setAddress] = useState(null);

    const getVehicle = (plate, vid) => {
      dispatch(
              REDUX_ACTION.v1_vehicle.get_vehicle_thru_plate_request(
                plate,
                vid
              )
            );
    }

    const vehicleOpt = Object.values(vehicles)
    .map(v => {
      return {
        label: <ButtonLink onClick={() => getVehicle(plate)}>{v.plate}</ButtonLink>,
        value: v.vid
      }
    })

    useEffect(() => {
        dispatch(
          REDUX_ACTION.v1_vehicle.get_vehicle_thru_plate_request(plate, vid)
        );
    }, [dispatch, plate]);

    useEffect(() => {
      const interval = setInterval(() => {
        dispatch(
          REDUX_ACTION.v1_vehicle.get_vehicle_thru_plate_request(plate, vid)
        );
      }, 30000);

      return () => clearInterval(interval);
    }, [dispatch, plate, vid]);

    const vehicle = vehicles[plate]
  
    useEffect(() => {
      if (vehicle?.location?.lat && vehicle?.location?.lon) {
        getAddressFromCoordinates(vehicle.location.lat, vehicle.location.lon);
      }
    }, [vehicle]);
  
    const toggleInfoWindow = () => {
      setInfoWindowOpen(!infoWindowOpen);
    };
  
    const coordinates = {
      lat: vehicle?.location?.lat,
      lng: vehicle?.location?.lon,
    };
  
    //function to get the coordinate
    const getAddressFromCoordinates = (lat, lng) => {
      if (!window.google || !window.google.maps) {
        setTimeout(() => getAddressFromCoordinates(lat, lng), 100);
        return;
      }
  
      const geocoder = new window.google.maps.Geocoder();
      const latLng = { lat, lng };
  
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            setAddress(results[0].formatted_address);
          } else {
            setAddress("Address not found");
          }
        } else {
          setAddress("Geocoder failed due to: " + status);
        }
      });
    };
  
    //modal for detail vehicle
    const DetailsInfoWindow = () => {
      return (
        <div className={classes["infoWindow-container"]}>
          <Row
            style={{ fontSize: "20px", marginBottom: "20px" }}
            justify="space-between"
          >
            <Col>
              <h3>{vehicle?.plate}</h3>
              <p style={{ color: "GrayText" }}>
                Last updated at {parseTime(vehicle?.vehicleTime)}
              </p>
            </Col>
          </Row>
          <Col>
            <h5>Location</h5>
            <p>{address}</p>
          </Col>
        </div>
      );
    };  

    return (
        <div className={classes["container-map"]}>
            <div 
                style={{
                    marginBottom: "-20px",
                    display: "flex"
                }}>
            <Select
                size="large"
                defaultValue={vid}
                options={vehicleOpt}
               className={classes["select-map"]}
            />
             <Button
                icon={<SearchOutlined />}
                style={{width: "40px"}}
                className={classes["search-map"]}
            />
            </div>
              {
                APP_CONFIG.API_KEY.GOOGLE_MAP
                &&
                <LoadScript googleMapsApiKey={APP_CONFIG.API_KEY.GOOGLE_MAP}>
                    <GoogleMap
                        mapContainerStyle={{
                            width: '100%',
                            height: '84vh'
                        }}
                        center={coordinates}
                        zoom={16}
                    >
                        <Marker
                            position={coordinates}
                            onClick={toggleInfoWindow}
                        >
                            {infoWindowOpen && (
                            <InfoWindow onCloseClick={toggleInfoWindow}>
                               <DetailsInfoWindow />
                            </InfoWindow>
                            )}
                        </Marker>
                    </GoogleMap>
                </LoadScript>
              }
        </div>
    )
}

export default MapViewVehicle
