import * as ActionTypes from '../action-types'

const pointOperator_defaultState = {
    byID: {},
    allIDs: [],
    byHubid: {}
}

export const pointOperator = (state = pointOperator_defaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.GET_POINT_OPERATOR_ALL: {

            const operators = action.operators

            operators
            &&
            operators.length
            &&
            operators.map(operator => {

                const { memid } = operator

                newState.byID[memid] = operator

            //     if(!newState.allIDs.includes(memid)) newState.allIDs.push(memid)

            //     return null
            })
            

          

            return newState;
        }
        case ActionTypes.CREATE_POINT_OPERATOR: {
            const operator = action.hubs;
            const { memid } = operator;
      
            if (memid) {
              newState.byID[memid] = operator;
      
              if (!newState.allIDs.includes(memid)) newState.allIDs.push(memid);
            }
      
            return newState;
          }
          case ActionTypes.UPDATE_POINT_OPERATOR: {
            const operator = action.hubs
      
            const {memid} = operator
            
            newState.byID[memid] = operator
      
            return newState
          }
          case ActionTypes.GET_POINT_OPERATOR_THRU_HUBID: {

            const operators = action.operators
            // const memid = action.memid
            const hubid = action.hubid
      
            newState.byHubid[hubid] = operators;
      
            return newState;
        }

        case ActionTypes.DELETE_POINT_OPERATOR_LIST_FROM_HUBID: {
            const operator = action.operator
            const hubid = action.hubid
            
            newState.byHubid[hubid] = operator

            return newState
        }
        
        case ActionTypes.ASSIGNED_OPERATOR: {
            const hubid = action.hubid
            const operators = action.operators
      
            newState.byHubid[hubid] = operators
      
            return newState
          }
        
        default: {
            return state;
        }
    }
    
}
