// React Thirty Party Library
import { Tag } from "antd";

// Constant
import { TEMPLATE } from "../../../constants";

// Components
import { ButtonLink } from "../../../components";
 
//handlers
import { filter } from "../../../handlers"

// Service
import { TIME } from "../../../services/util";

import * as CONFIG from '../../../config'

const { TABLE_ACCOUNT_MANAGER_TITLE, TABLE_ACCOUNT_MANAGER_INDEX, OPTION_ACCOUNT_MANAGER_STATUS, OPTION_ACCOUNT_MANAGER_STATUS_TITLE, TABLE_USER_TITLE, TABLE_USER_INDEX, OPTION_USER_STATUS_TITLE,OPTION_USER_STATUS } =
  TEMPLATE.USERLIST;

const { parseTimeMin } = TIME;

const { searchHDLR, filterHDLR } = filter

export const ACCOUNTMANAGER_SUMMARY_COL = ({redux= {}, func= {}, exportState = false}) => {

const {customerData} = redux
const {handleSearch} = func

  return [
    {
      title:
        TABLE_ACCOUNT_MANAGER_TITLE.acc_manager_id +
        "/" +
        TABLE_ACCOUNT_MANAGER_TITLE.acc_manager_name,
      key: TABLE_ACCOUNT_MANAGER_INDEX.acc_manager_id,
      width: 250,
      render: (record) => {
        const { memid, name } = record;
        return exportState ? (
          `${memid} ${name}`
        ) : (
          <span>
            {memid} / {name}
          </span>
        );
      },
      ...searchHDLR.getColumnSearchProps("memid",handleSearch)
    },
    {
      title: TABLE_ACCOUNT_MANAGER_TITLE.acc_manager_status,
      key: TABLE_ACCOUNT_MANAGER_INDEX.acc_manager_status,
      width: 110,
      render: (record) => {
        const { status } = record;

        const statusKey = status
          ? OPTION_ACCOUNT_MANAGER_STATUS.Active
          : OPTION_ACCOUNT_MANAGER_STATUS.Suspend;
        const { name, colorTag } = OPTION_ACCOUNT_MANAGER_STATUS_TITLE[statusKey];

        return exportState ? (
          name
        ) : (
          <>
            <Tag color={colorTag}>{name}</Tag>
          </>
        );
      },
      filters: filterHDLR.getColumnsFilterStatus(OPTION_ACCOUNT_MANAGER_STATUS_TITLE),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: TABLE_ACCOUNT_MANAGER_TITLE.total_customer,
      key: TABLE_ACCOUNT_MANAGER_INDEX.total_customer,
      render: (record) => {

        const {hierCode, hierLevel} = record

        
        const customerUnderCM = customerData.filter((user) => {

          // if user hierLevel more than hierLevel accmanager, 
          // return user with hierCode started in specific accManager hierCode
          return user.hierLevel > hierLevel && user.hierCode.startsWith(hierCode);
        });

        return exportState ? customerUnderCM.length : <span> {customerUnderCM.length} </span>;
      }
    },
    {
      title: TABLE_ACCOUNT_MANAGER_TITLE.contact,
      key: TABLE_ACCOUNT_MANAGER_INDEX.contact,
      render: (record) => {
        const { contact } = record;
        return exportState ? contact : <span> {contact} </span>;
      },
    },
    {
      title: TABLE_ACCOUNT_MANAGER_TITLE.email,
      key: TABLE_ACCOUNT_MANAGER_INDEX.email,
      render: (record) => {
        const { email, email_verified } = record;
        return exportState ? (
          email
        ) : (
          <span>
            {email} &nbsp;
            {email_verified === true ? (
              <CheckCircleFilled
                style={{
                  color: "var(--bs-success)",
                }}
              />
            ) : (
              ""
            )}
          </span>
        );
      },
      ...searchHDLR.getColumnSearchProps("email", handleSearch)
    },
    {
      title: TABLE_ACCOUNT_MANAGER_TITLE.createdAt,
      key: TABLE_ACCOUNT_MANAGER_INDEX.createdAt,
      render: (record) => {
        const { createdAt } = record;
        return exportState ? (
          parseTimeMin(createdAt)
        ) : (
          <span> {parseTimeMin(createdAt)} </span>
        );
      },
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      title: TABLE_ACCOUNT_MANAGER_TITLE.signUp_link,
      key: TABLE_ACCOUNT_MANAGER_INDEX.signUp_link,
      width: 400,
      render: (record) => {
        const { memid } = record;

        const handleButtonClick = (memid) => {
          window.open(`https://${CONFIG.APP_CONFIG.CP_REFERRAL_URL}/${memid}/signup`, '_blank'); 
        };

        return exportState ? memid : 
        <ButtonLink onClick={() => handleButtonClick(memid)}>
        {`https://${CONFIG.APP_CONFIG.CP_REFERRAL_URL}/${memid}/signup`}
      </ButtonLink>;
      },
    },
    {
      title: TABLE_ACCOUNT_MANAGER_TITLE.qr_code,
      key: TABLE_ACCOUNT_MANAGER_INDEX.qr_code,
      width: 400,
      render: (record) => {
        const { asset = {} } = record;

        const handleButtonClick = () => {
          window.open(asset.qr.url, '_blank'); 
        };

        let qrCodeCondition = Object.values(asset).length !== 0

        return exportState ? asset.qr?.url : qrCodeCondition && <ButtonLink onClick={handleButtonClick}> {asset.qr.url} </ButtonLink>
      },
    },
    {
      title: TABLE_ACCOUNT_MANAGER_TITLE.remark,
      key: TABLE_ACCOUNT_MANAGER_INDEX.remark,
      render: (record) => {
        const { asset = {}, remark } = record;

        return exportState ? Array.isArray(remark) && remark[0]?.msg : <p> {Array.isArray(remark) && remark[0]?.msg || '-'} </p>
      },
    },
  ];
};


export const EXPANDED_CUSTOMER_LIST = [
  {
    title: TABLE_USER_TITLE.User_ID + "/" + TABLE_USER_TITLE.User_Name,
    key: TABLE_USER_INDEX.User_ID,
    width: 300,
    fixed: "left",
    render: (record) => {
      const { memid, name } = record;
      return (
        <span>
          {memid} / {name}
        </span>
      );
    },
  },
  {
    title: TABLE_USER_TITLE.User_Status,
    key: TABLE_USER_INDEX.User_Status,
    width: 150,
    fixed: "left",
    render: (record) => {
      const { status } = record;

        const statusKey = status
          ? OPTION_USER_STATUS.Active 
          : OPTION_USER_STATUS.Suspend;
        const { name, colorTag } = OPTION_USER_STATUS_TITLE[statusKey];

        let color, tag;
        if (!Object.hasOwnProperty.call(record, 'status')) {
          color = "green";
          tag = "ACTIVE";
        } else {
          color = colorTag
          tag = name
        }

        return (
          <>
            <Tag color={color}>{tag}</Tag>
          </>
        );
    },
  },
  {
    title: TABLE_USER_TITLE.Wallet_Balance,
    key: TABLE_USER_INDEX.Wallet_Balance,
    width: 180,
    render: (record) => {
      const { balance } = record;
      return <span> RM{balance} </span>;
    },
  },
  {
    title: TABLE_USER_TITLE.Contact,
    key: TABLE_USER_INDEX.Contact,
    width: 120,
    render: (record) => {
      const { contact } = record;
      return <span> {contact} </span>;
    },
  },
  {
    title: TABLE_USER_TITLE.Email,
    key: TABLE_USER_INDEX.Email,
    width: 250,
    render: (record) => {
      const { email, email_verified } = record;
      return (
        <span>
          {email} &nbsp;
          {email_verified === true ? (
            <CheckCircleFilled
              style={{
                color: "var(--bs-success)",
              }}
            />
          ) : (
            ""
          )}
        </span>
      );
    },
  },
  {
    title: TABLE_USER_TITLE.Created_At,
    key: TABLE_USER_INDEX.Created_At,
    width: 120,
    render: (record) => {
      const { createdAt } = record;
      return <span> {parseTimeMin(createdAt)} </span>;
    },
  },
];
