import { Drawer, Form, Input, Select, message } from "antd";
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { NumericInput, BtnSubmit } from "../../../../../../../components";

import { REDUX as REDUX_UTIL, TIME } from "../../../../../../../services/util";

import * as REDUX_ACTION from "../../../../../../../services/redux/actions";

import classStyles from "./index.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;

const DrawerEditHub = (props) => {
  const { isOpen, setIsOpen, data } = props

  const hub = Object.values(useSelector(reduxStoreWrapper("hub.byID")));

  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();

  const [selectedHub, setSelectedHub] = useState([]);
 
  const prefixContactSelector = (
    <Form.Item name="contactPrefix" noStyle>
      <Select style={{ width: 70 }}>
        <Select.Option value="+60">+60</Select.Option>
      </Select>
    </Form.Item>
  );

   const hubs = data?.hubs?.map(
    (d) => ({
      label: `${d?.code || ''}`,
      value: `${d?.code || ''}, ${d?.hubid || ''}`,
    })
  ) || []
  
  let contact = data.contact && (data.contact.includes('-') ? data.contact.split('-')[1] : data.contact)
  
  useEffect(() => {
    form.setFieldsValue({ ...data, contact, hubs: hubs });
  }, [isOpen]);
  
  const handleHub = (values) => {
    const selectedValues = values.map((value) => {
      const splitValues = value.split(", ");
      if (splitValues.length === 2) {
        const [code, hubid] = splitValues;
        return { code: code, hubid: hubid };
      }
      return null; 
    });
  
    // Filter null values from selectedValues
    const filteredSelectedValues = selectedValues.filter((value) => value !== null);
  
    setSelectedHub(filteredSelectedValues);
  };
  
  useEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
    });
  }, [selectedHub]);
  

  const handleSave = () => {
    const formData = { ...data, ...form.getFieldsValue() };
    const hubArray = selectedHub.map((s) => ({
      code: s.code,
      hubid: s.hubid,
      createdAt: TIME.latestTime,
    }));
    
    const opData = { 
      ...formData, 
      contact: `${formData.contactPrefix}-${formData.contact}`,
      hubs: hubArray
    };

    delete opData.contactPrefix

    const hubData = {uid: data && data.uid, items: opData }

    messageApi.open({
      type: "loading",
      content: "Updating...",
      duration: 0,
    });


    dispatch(REDUX_ACTION.v1_hubOperator.update_hub_operators_request(hubData, () => messageApi.destroy()));
    form.resetFields()
   
    setIsOpen(false);
  };

  const ActionButton = (
    <Form.Item style={{ paddingTop: "20px" }}>
      <div>
        <BtnSubmit
          display="inline-block"
          text="Save & back to list"
          bgColor="var(--themeBackgroundColor)"
          color="var(--creamColor)"
          padding="9px 18px"
          onClick={handleSave}
        />
        <BtnSubmit
          display="inline-block"
          text="Cancel"
          bgColor="transparent"
          color="var(--bs-gray-600)"
          padding="9px 18px"
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </div>
    </Form.Item>
  );

  return (
    <>
    {contextHolder}
    <Drawer
      title="Edit Hub Operator"
      placement="right"
      closable={false}
      width={410}
      onClose={() => {
        setIsOpen(false)
        form.resetFields()
      }}
      footer={ActionButton}
      open={isOpen}
      style={{
        textAlign: "center",
        fontSize: "20px",
      }}
    >
    <Form
      form={form}
      layout="vertical"
      initialValues={{ 
        // ...data, 
        // hubs: hubs,
        // contact: data.contact,
        // contact: contact,
        contactPrefix: "+60",
      }}
    >
      <Form.Item
          name="memid"
          label={<span>Hub Operator ID</span>}
          rules={[
            {
              required: true,
              message: "Hub Operator ID is required",
            },
          ]}
        >
          <Input className={classStyles.inputField} disabled/>
        </Form.Item>
        <Form.Item
          name="name"
          label={<span>Name</span>}
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
        >
          <Input className={classStyles.inputField} />
        </Form.Item>
        <Form.Item
          name="email"
          label={<span>Email</span>}
          rules={[
            {
              required: true,
              message: "Email is required",
              type: "email",
            },
          ]}
          
        >
          <Input className={classStyles.inputField} />
        </Form.Item>
        <Form.Item
          name="contact"
          label={<span>Contact</span>}
          rules={[
            {
              required: true,
              message: "Contact is required",
            },
          ]}
        >
          <Input 
            className={classStyles.inputField} 
            addonBefore={prefixContactSelector} 
          />
          {/* <NumericInput
            addonBefore={prefixContactSelector}
          /> */}
        </Form.Item> 
        <Form.Item
          name="hubs"
          label={<span>Assigned Hub</span>}
        >
        <Select
          showSearch
          mode="multiple"
          maxTagCount="responsive"
          onChange={handleHub}
        >
        {Array.isArray(hub) && hub.map((o) => (
          <Select.Option key={o.hubid} value={`${o && o.code}, ${o && o.hubid}`}>
            {o.code}
          </Select.Option>
        ))}
        </Select>
        </Form.Item>
      </Form>
    </Drawer>
    </>
  );
};

export default DrawerEditHub;
