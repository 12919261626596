import React from "react"; // useState
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import ReduxConfigureStore from "./configureStore";

import { ConfigProvider, theme, App as AntDApp } from "antd";

/**
 * Config & Constant
 */
import * as CONFIG from "../config";
import { THEME } from "../constants";

/**
 * Pages
 */

/**
 * Components
 */
import Initialiser from "./components/Initialiser";

/**
 * Service Componnent
 */
import * as Auth from "../services/auth";

/**
 * Container
 */
import MainApp from "../containers/main";

/**
 * Style
 */
import "./index.css";

/**
 * Configure Store
 */
const store = ReduxConfigureStore();

const ThemedAPP = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: THEME.THEME_COLOR,
        },
        algorithm: theme.defaultAlgorithm,
      }}
    >
      <AntDApp>
        <MainApp />
      </AntDApp>
    </ConfigProvider>
  );
};

const APPWapper = () => {
  switch (CONFIG.APP_INFO.APP_MAINTENANCE_MODE === "true") {
    case true: {
      return <></>;
    }
    case false:
    default: {
      return (
        <>
          <Initialiser />
          <Auth.AuthBackgroundTask />
          <ThemedAPP />
        </>
      );
    }
  }
};

const ConnectedApp = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>{APPWapper()}</BrowserRouter>
    </Provider>
  );
};

export default ConnectedApp;
