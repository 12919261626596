// import * as ActionTypes from '../action-types'

const orderState_defaultState = {
    byID: {},
    byStatus: {},
    bySorted: {
        SORTED: [],
        UNSORTED: []
    },
    allStatuses: []
}

export const orderState = (state = orderState_defaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        default: {
            return state;
        }
    }
}