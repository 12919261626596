import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { ROUTES } from "../../../../../../constants"

import { REDUX as REDUX_UTIL} from "../../../../../../services/util";

import * as REDUX_ACTION from "../../../../../../services/redux/actions";

import SearchHub from "../TableViewHub/components/SearchHub";
import TableHub from "../TableViewHub/components/TableHub";
import { FEATURE_NAME } from "../../../../../../constants/common/features";

const { reduxStoreWrapper } = REDUX_UTIL;
const { ROUTE_URL } = ROUTES

const TableViewHub = () =>{
    const dispatch = useDispatch()
    const navigate = useNavigate();
    
    const hubs = useSelector(reduxStoreWrapper("hub.byID"));
    const loading = useSelector(reduxStoreWrapper("style.loading.hub"));

    const dataSource = Object.values(hubs).map((hub) => {
      return {
          key: hub?.hubid,
          ...hub
      }});
  
    useEffect(() => {
      dispatch(
        REDUX_ACTION.v1_hub.get_all_hubs_request()
      );
  
      return () => {};
    }, [dispatch]);

    const [ filteredData, setFilteredData ] = useState(dataSource);
    

    useEffect(() => {
        if (filteredData !== dataSource) {
            setFilteredData(dataSource);
        }
    }, [dataSource]);

    useEffect(() => {
        setFilteredData(filteredData);
    }, [filteredData]);

    const NavigateToPage = (hubid, label, record) =>{
      navigate(ROUTE_URL[FEATURE_NAME.HUB_EDIT],
      {state : {hubid, label, record}})
    }

    const [selectedRow, setSelectedRow] = useState([]);

    return( 
        
      <>
        <SearchHub
          dataSource={dataSource}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          NavigateToPage= {NavigateToPage}
          selectedRow={selectedRow}
          
          />
        <TableHub 
          dataSource={dataSource}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          loading={loading}
          NavigateToPage= {NavigateToPage}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          />
        </>
    )
}

export default TableViewHub
