// React Thirty Party Library
import { useSelector } from "react-redux";

// Constants Library
import { TEMPLATE } from "../../../../../../constants";

// Components Library
import { BtnSubmit, TableList } from "../../../../../../components";

// Handler Library
import { exportHDLR } from "../../../../../../handlers";

// Services Library
import { TIME , REDUX as REDUX_UTIL} from "../../../../../../services/util";

// Redux Actions

// Styles Library
import classes from "../../../../../../components/index.module.css";

const { TABLE_ACCOUNT_MANAGER_INDEX, TABLE_ACCOUNT_MANAGER_TITLE } =
  TEMPLATE.USERLIST;
const { BTN_IMG } = TEMPLATE.BTN;
const { ACCOUNTMANAGER_SUMMARY_COL } = TEMPLATE.REPORT.ACCOUNTMANAGER;

const { parseExportTime } = TIME;
const { reduxStoreWrapper } = REDUX_UTIL;

const SearchAccManagerList = (props) => {
  const { setFilteredData, setIsOpen, dataSource, filteredData, selectedRow } = props;

  const customerData = Object.values(useSelector(reduxStoreWrapper("customer.byID")));

  const template = ACCOUNTMANAGER_SUMMARY_COL({redux: {customerData}, exportState:true});

  const options = [
    {
      value: TABLE_ACCOUNT_MANAGER_INDEX.acc_manager_id,
      label: TABLE_ACCOUNT_MANAGER_TITLE.acc_manager_id,
      type: "text",
    }, 
    {
      value: TABLE_ACCOUNT_MANAGER_INDEX.createdAt,
      label: "Custom Date",
      type: "timeframe",
    },
  ];

  const exportData = selectedRow.length > 0 ? selectedRow : filteredData;

  return (
    <div className={classes.headerPage}>
      <TableList.SearchTableQuery
        dataSource={dataSource}
        setFilteredData={setFilteredData}
        options={options}
        defaultValue={TABLE_ACCOUNT_MANAGER_INDEX.acc_manager_id}
      />
      <div>
        <BtnSubmit
          display="inline-block"
          text="Export"
          bgColor="transparent"
          color="#4CAF50"
          padding="9px 18px"
          onClick={() =>
            exportHDLR.exportWithTemplate({
              data: exportData,
              filename: `AccountManagerList_${parseExportTime()}`,
              template: template,
            })
          }
          showIcon={true}
          icon={BTN_IMG.EXPORT}
          icon_alt={"export"}
        />
        <BtnSubmit
          display="inline-block"
          text="Add account manager"
          bgColor="var(--themeBackgroundColor)"
          color="var(--creamColor)"
          padding="9px 18px"
          onClick={() => setIsOpen(true)}
          showIcon={false}
        />
      </div>
    </div>
  );
};

export default SearchAccManagerList;
