import { Row, Col, Card } from "antd"
import CommentProfile from "../CommentProfile";
import { useDispatch } from "react-redux";

import { IMG } from "../../../../../../../../constants";

import { TIME } from "../../../../../../services/util";

import * as REDUX_ACTION from "../../../../../../services/redux/actions";

import styles from "../../index.module.css";

const CommentList = ({ 
    cel, 
    userName, 
    setIndex, 
    setEdit, 
    setValue,
    i
}) => {
    const dispatch = useDispatch()

    return (
        <Row gutter={15} style={{ marginBottom: "10px" }} key={i}>
           <CommentProfile name={userName} />

            <Col span={20}>
                <Card style={{ width: "100%" }}>
                    <p>{cel.comment}</p>
                    <Row style={{ marginTop: "1em" }}>
                        <Col span={21}>
                            <label className={styles["label-comment"]}>
                                {/* {cel.createdAt ? `Commented on ${TIME.parseTimeMin(cel.updatedAt)}` : `Edited on ${TIME.parseTimeMin(cel.updatedAt)}`} */}
                                Commented on {TIME.parseTimeMin(cel.createdAt)}
                            </label>
                        </Col>
                        <Col span={3}>
                            {userName === "You" && (
                                <div className={styles["action-icon"]}>
                                    <input
                                        type="image"
                                        src={IMG.ICON.MODAL.icon_address_edit}
                                        alt="edit"
                                        onClick={() => {
                                            setIndex(i);
                                            setEdit(true);
                                            setValue(cel)
                                        }}
                                    />
                                    <input
                                        type="image"
                                        src={IMG.ICON.MODAL.icon_address_del}
                                        alt="del"
                                        onClick={() => dispatch(REDUX_ACTION.v1_parkOrder.archive_parked_order_comment_data(
                                            cel
                                        ))}
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                </Card>
            </Col>  
        </Row>
    );
};

export default CommentList;
