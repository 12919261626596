import * as API from "../../api"
import * as ActionTypes from "../action-types";

import { message } from 'antd'
import { batch } from "react-redux";

// Constants and Config

// Services

// Redux Action

// Neccessary Actions
import {
    setLoadingvehicle,
    unsetLoadingvehicle,
} from './style'

export const get_all_vehicle_request = () => dispatch => {

    dispatch(setLoadingvehicle())

      return new Promise((resolve, reject) => {
        API.getAllVehicle().then(info => {
          const { status, msg, vehicles } = info;
    
          if (status === 200) {
            dispatch(get_all_vehicle_success(vehicles))
            resolve(info); 
          } else {
            message.warning(msg);
            reject(new Error(msg)); 
          }
        }).catch(error => {
          reject(error); 
        }).finally(() => {
          dispatch(unsetLoadingvehicle())
        })
      });
}

const get_all_vehicle_success = (vehicle) => {
    return {
        type: ActionTypes.VEHICLE_GET_ALL,
        vehicle
    };
}

export const update_vehicle_request =
  (vehicle, cb) => (dispatch) => {
    dispatch(setLoadingvehicle());

    API.updateVehicle({
        vid: vehicle.vid,
        items: vehicle,
        updatedBy: vehicle.updatedBy
    })
      .then((info) => {
        const { status, vehicle: newVehicle, msg } = info;

        if (status === 200) {
          dispatch(update_vehicle_success(newVehicle));
          message.success("Update Vehicle Data Successfully");
        } else {
          message.warning(msg);
        }
      })
      .finally(() => {
        cb()
        dispatch(unsetLoadingvehicle());
      });
  };

const update_vehicle_success = (vehicle) => {
    return {
        type: ActionTypes.UPDATE_VEHICLE,
        vehicle
    };
}

export const update_status_vehicle_request =
  (vehicle) => (dispatch) => {
    dispatch(setLoadingvehicle());

    API.updateVehicle({
        vid: vehicle.vid,
        items: vehicle,
        updatedBy: vehicle.updatedBy
    })
      .then((info) => {
        const { status, vehicle: newVehicle, msg } = info;

        if (status === 200) {
          dispatch(update_status_vehicle_success(newVehicle));
          message.success("Update Vehicle Data Successfully");
        } else {
          message.warning(msg);
        }
      })
      .finally(() => {
        dispatch(unsetLoadingvehicle());
      });
  };

const update_status_vehicle_success = (vehicle) => {
    return {
        type: ActionTypes.UPDATE_STATUS_VEHICLE,
        vehicle
    };
}

export const create_vehicle =
  ({driver, plate, isTracking, remark, adpMemid}, cb) => (dispatch) => {
    // dispatch(setLoadingvehicle());

    API.createVehicle({
        driver,
        plate,
        isTracking,
        remark,
        adpMemid,
    })
      .then((info) => {
        const { status, vehicle, msg } = info;

        if (status === 200) {
          dispatch(create_vehicle_success(vehicle));
          message.success("Create Vehicle Data Successfully");
        } else {
          message.warning(msg);
        }
      })
      .finally(() => {
        cb()
        // dispatch(unsetLoadingvehicle());
      });
  };

export const create_vehicle_success = (vehicle) => {
    return {
      type: ActionTypes.CREATE_VEHICLE,
      vehicle,
    };
  };

  export const get_vehicle_thru_plate_request =
  (plate, vid, back = () => {}) =>
  (dispatch) => {
    dispatch(setLoadingvehicle());

    API.getVehicleThruPlate({
      plate,
    })
      .then((info) => { 
        const { status, msg, vehicle } = info;

        if (status === 200) {
          // message.success(msg);
          dispatch(get_vehicle_thru_plate_success(plate, vid, vehicle));
        } else {
          // message.warning(msg);
          back();
        }
      })
      .finally(() => {
        dispatch(unsetLoadingvehicle());
      });
  };

const get_vehicle_thru_plate_success = (plate, vid, vehicle) => {
  return {
    type: ActionTypes.GET_VEHICLE_THRU_PLATE,
    vid,
    plate,
    vehicle,
  };
};
