import { Tag } from "antd";
// Contanst
import { TEMPLATE } from "../../constants";

const { OPTION_USER_STATUS, OPTION_WALLET_BALANCE } = TEMPLATE.CUSTOMERWALLET;
const {
  OPTION_STATUS,
  OPTION_TYPE_TXN,
  OPTION_STATUS_TITLE,
  OPTION_TYPE_TITLE_TXN,
} = TEMPLATE.TRANSACTIONLIST; 

export const transactionStatus = () => {
  let tree = [];

  if (OPTION_STATUS) {
    const allChildren = Object.values(OPTION_STATUS).map((status) => {
      const { name, colorTag } = OPTION_STATUS_TITLE[status];

      const title = (
        <Tag color={colorTag} key={status}>
          {name}
        </Tag>
      );
      const key = `status#${status}`;
      const value = `status#${status}`;

      return {
        title: title,
        key: key,
        value: value,
      };
    });

    const allOption = {
      title: "ALL",
      key: "ALL",
      value: "ALL",
    };

    tree.push({ ...allOption, children: [...allChildren] });
  }
  const filterSelectedChildren = (node) => {
    if (node.value === "ALL") {
      return tree.flatMap((n) => n.children.map((child) => child.value));
    }

    return [node.value];
  };

  tree.filterSelectedChildren = filterSelectedChildren;

  return tree;
};

export const transactionType = () => {
  let tree = [];

  if (OPTION_TYPE_TXN) {
    const allChildren = Object.values(OPTION_TYPE_TXN).map((type) => {
      const title = OPTION_TYPE_TITLE_TXN[type];
      const key = `type#${type}`;
      const value = `type#${type}`;

      return {
        title: title,
        key: key,
        value: value,
      };
    });

    const allOption = {
      title: "ALL",
      key: "ALL",
      value: "ALL",
    };

    tree.push({ ...allOption, children: [...allChildren] });
  }
  const filterSelectedChildren = (node) => {
    if (node.value === "ALL") {
      return tree.flatMap((n) => n.children.map((child) => child.value));
    }

    return [node.value];
  };

  tree.filterSelectedChildren = filterSelectedChildren;

  return tree;
};

export const userStatus = () => {
  let tree = [];

  if (OPTION_USER_STATUS) {
    const allChildren = Object.keys(OPTION_USER_STATUS).map((status) => {
      const title = status;
      const key = `User_Status#${status}`;
      const value = `User_Status#${status}`;

      return {
        title: title,
        key: key,
        value: value,
      };
    });

    const allOption = {
      title: "ALL",
      key: "ALL",
      value: "ALL",
    };

    tree.push({ ...allOption, children: [...allChildren] });
  }
  const filterSelectedChildren = (node) => {
    if (node.value === "ALL") {
      return tree.flatMap((n) => n.children.map((child) => child.value));
    }

    return [node.value];
  };

  tree.filterSelectedChildren = filterSelectedChildren;

  return tree;
};

export const walletBallance = () => {
  let tree = [];

  if (OPTION_WALLET_BALANCE) {
    const allChildren = Object.keys(OPTION_WALLET_BALANCE).map((wallet) => {
      const title = wallet;
      const key = wallet;
      const value = wallet;

      return {
        title: title,
        key: key,
        value: value,
      };
    });

    tree.push(...allChildren);
  }

  return tree;
};
