import { useState } from "react";
import { Button, Tag } from "antd";

import { TEMPLATE } from "../../constants";

import { ButtonLink } from "../../components";

import {
	ModalStandard,
	ModalComment,
	ModalRemark,
	ModalStatusUpdate,
} from "./modules";

const {
	MODALS_STANDARD,
	MODALS_REMARK,
	MODALS_COMMENT,
	MODALS_UPDATE_STATUS,
} = TEMPLATE.MODAL;

// the commented code below are the example of how to use the modal

// dont forget to import the TEMPLATE, this TEMPLATE available inside v1

// beside being the reusable component
// the ButtonModal is a button without any styling
// so the styling itself can be customized

/* const { MODAL_ACTION } = TEMPLATE.MODAL; */

/* <ButtonModal
  setmodal={MODAL_ACTION.cancel_order}
  onClick={() => {
    console.log(MODAL_ACTION.cancel_order);
  }}
>
  test btn
</ButtonModal> */

const ButtonModal = (props) => {
	const {
		children,
		setmodal,
		onClick = () => {},
		extra = {},
		model = "",
		disabled,
		data,
	} = props;

	const [isModalOpen, setIsModalOpen] = useState(false);

	// filter the MODALS based on setmodal value
	const modals_standard = MODALS_STANDARD.filter((k) => k === setmodal)[0];
	const modals_remark = MODALS_REMARK.filter((k) => k === setmodal)[0];
	const modals_comment = MODALS_COMMENT;
	const modals_update_status = MODALS_UPDATE_STATUS.filter((k) => k === setmodal)[0];

	const handleModal = () => {
		setIsModalOpen(true);
	};

	const handleModalContext = (setmodal) => {
		switch (setmodal) {
			case modals_standard:
				return (
					<ModalStandard
						setmodal={setmodal}
						isModalOpen={isModalOpen}
						setIsModalOpen={() => setIsModalOpen(false)}
						onClick={onClick}
						extra={extra}
					/>
				);
			case modals_remark:
				return (
					<ModalRemark
						setmodal={setmodal}
						isModalOpen={isModalOpen}
						setIsModalOpen={() => setIsModalOpen(false)}
						onClick={onClick}
						extra={extra}
					/>
				);
			case modals_comment:
				return (
					<ModalComment
						setmodal={setmodal}
						isModalOpen={isModalOpen}
						setIsModalOpen={() => setIsModalOpen(false)}
						onClick={onClick}
						extra={extra}
					/>
				);
			case modals_update_status:
				return (
					<ModalStatusUpdate
						setmodal={setmodal}
						isModalOpen={isModalOpen}
						setIsModalOpen={() => setIsModalOpen(false)}
						onClick={onClick}
						extra={extra}
						data={data}
					/>
				);
			default:
				break;
		}
	};

	return (
		<>
			{handleModalContext(setmodal)}

			{(model === "link" && (
				<ButtonLink
					{...props}
					onClick={handleModal}
					disabled={disabled}
				>
					{children}
				</ButtonLink>
			)) ||
				(model === "tag" && <Tag color="orange">{children}</Tag>) || (
					<Button {...props} onClick={handleModal}>
						{children}
					</Button>
				)}
		</>
	);
};
export default ButtonModal;
