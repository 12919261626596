// Third Party Library 
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { 
    Drawer, 
    message 
} from "antd";

// Components 
import {
    RemarkTextArea,
    RemarkLists,
    RemarkHeader
} from './components'

// Services Library
import { 
    REDUX as REDUX_UTIL, 
    TIME 
} from "../../services/util";

const { 
    reduxStoreWrapper 
} = REDUX_UTIL

const DrawerRemark = ({ 
    isOpen, 
    setIsOpen, 
    data, 
    reduxData = [],
    reduxData2 = {},
    submitRemarkHDLR, 
    editRemarkHDLR,
    deleteRemarkHDLR,
    loading
}) => {
    
    // redux data used in this component
    const user = useSelector(reduxStoreWrapper("user"))

    // markup settings initialization
    const [ messageApi, contextHolder ] = message.useMessage();
    const [ showTextArea, setShowTextArea ] = useState(false)
    const [ showEditTextArea, setShowEditTextArea ] = useState(false)

    // 'edit' feature initialization 
    const [ edit, setEdit ] = useState(false);
    const [ index, setIndex ] = useState(0);

    // value initialization 
    const [ value, setValue ] = useState({})
    const [ editValue, setEditValue ] = useState({})
    
    const dataObj = reduxData2[data.memid]
    const dataArr = reduxData.find(r => r.memid === data.memid)
    let newData = reduxData.length > 0 ? dataArr : dataObj

    useEffect(() => {
        if(!value.createdBy && isOpen) {
            setValue({...value, createdBy: user.memid})
        }
    }, [value, isOpen])

    // dataSource used in Remark List. Needs some adjustment
    let dataSource = newData && Array.isArray(newData.remark) ? newData.remark.sort((a, b) => b.createdAt - a.createdAt) : []


    // handleOkButton function will be triggered if OK button clicked 
    const handleOkButton = () => {

        // to show the loading popup 
        messageApi.open({
            type: "loading",
            content: "Updating remark...",
            duration: 0,
        });

        switch (edit) {
            // submit 
            case false: {
                    submitRemarkHDLR({value, newData}, () => messageApi.destroy)
                    setShowTextArea(false)
                    setValue({})
                break;
            }
            // edit
            case true: {
                    editRemarkHDLR({editValue, newData}, () => messageApi.destroy )
                    setShowEditTextArea(false)
                    setEditValue({})
                    setEdit(false)
                break;
            }
                    
            default: 
                break;
        } 
    }

    // Simplify the reusable component 
    const remarkListAttr = {
        dataSource,
        user,
        setIndex,
        setEdit, 
        setEditValue,
        setShowEditTextArea,
        messageApi,
        deleteRemarkHDLR,
        newData,
        TIME,
        edit, 
        index,
        showEditTextArea,
        editValue,
        handleOkButton,
        loading
    }
    const RemarkTextAreaAttr = {
        value,
        setValue,
        handleOkButton,
        setShowTextArea,
    }

    // Main render 
    return (
        <Drawer
            title={<RemarkHeader setShowTextArea={setShowTextArea} />}
            placement="right"
            closable={false}
            width={500}
            onClose={() => setIsOpen(false)}
            open={isOpen}
        >
            {/* contextHolder is important for showing `Loading...` popup */}
            {contextHolder}
            {/* 
                if Add Remark link clicked,
                it will shows the TextArea for adding new remark
            */}
            {showTextArea &&
                <RemarkTextArea
                    {...RemarkTextAreaAttr}
                />
            }

            {/* list of remarks of clicked account */}
            <RemarkLists 
                {...remarkListAttr}
            />

        </Drawer>
    );
};

export default DrawerRemark;
