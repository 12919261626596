import { TIME } from "../../../services/util";

export const TABLE_POINT_TITLE = {
  point_code_name: "Point Code / Name",
  point_code: "Point Code",
  status: "Status",
  zone: "Zone",
  hub_name: "Hub",
  state: "State",
  pc: "Postcode/City",
  address: "Address",
  point_operators: "Point Operators",
  pic: "PIC Information",
  map_coor: "Map Coordinates",
  url:"Google Map Link",
  created_at: "Created At",
  last_update: "Last Updates By",
  actions: "Actions"
};

export const OPTION_DAYS = {
  LAST_30_DAYS: "Last 30 Days",
  LAST_60_DAYS: "Last 60 Days",
  LAST_90_DAYS: "Last 90 Days",
};

export const OPTION_DAYS_VALUE = {
  LAST_30_DAYS: 30,
  LAST_60_DAYS: 60,
  LAST_90_DAYS: 90,
};

export const OPTION_POINT_STATUS = {
  Active: 1,
  Inactive: 0,
  // Undefined: undefined,
} 

export const POINT_TYPE_CODE = {
  ESTABLISHED_POINT: 'H001',
  SIMPLE_POINT: 'H002',
  VIRTUAL_POINT: 'H003'
}

export const POINT_TYPE_TITLE = {
  [POINT_TYPE_CODE.ESTABLISHED_POINT]: "Physical Established Point",
  [POINT_TYPE_CODE.SIMPLE_POINT]: "Simple Point",
  [POINT_TYPE_CODE.VIRTUAL_POINT]: "Virtual Point"
}

export const OPTION_POINT_STATUS_TITLE = {
  [OPTION_POINT_STATUS.Active]: { name: "ACTIVE", colorTag: "success", status: 1 },
  [OPTION_POINT_STATUS.Inactive]: { name: "INACTIVE", colorTag: "default", status: undefined },
  // [OPTION_POINT_STATUS.Undefined]: { name: "ACTIVE", colorTag: "green", status: undefined },
};
export const SELECT_OPTION_DAYS = Object.keys(OPTION_DAYS).map((option) => ({
  label: OPTION_DAYS[option],
  value: OPTION_DAYS_VALUE[option],
}));
export const TABLE_POINT_INDEX = {
  point_code_name: "name",
  point_code: "code",
  status: "status",
  zone: "zonid",
  hub_name: "hubid",
  type: "type",
  state:"state",
  pc: "postcode",
  address: "address",
  point_operators: "pointOperator",
  pic: "pic",
  map_coor: "map",
  url:"url",
  created_at: "createdAt",
  last_update: "updatedBy",
};

export const TABLE_POINT_OPERATOR_TITLE = {
  point_opid: "Point Operator ID",
  name: "Name",
  status: "User Status",
  contact: "Contact",
  email: "Email",
  assignedPoint : "Assigned Point", 
  created_at: "Created At",
  last_update: "Last Updates At",
  remark: "Remark",
  actions: "Actions"
};

export const TABLE_POINT_OPERATOR_INDEX = {
  point_opid: "memid",
  name: "name",
  status: "status",
  contact: "contact",
  email: "email",
  assignedPoint : "assignedPoint",
  created_at: "createdAt",
  last_update: "updatedAt",
  actions: "actions"
};

export const OPTION_POINT_OP_STATUS = {
  Active: 1,
  Suspend: 0,
}

export const ACTIONS_LABEL = {
  create_point : "create_point",
  edit_point : "edit_point"
}

export const ACTIONS_LABEL_TITLE = {
  [ACTIONS_LABEL.create_point] : "Create Point",
  [ACTIONS_LABEL.edit_point] : "Edit Point"
}

export const OPTION_POINT_OP_STATUS_TITLE = {
  [OPTION_POINT_OP_STATUS.Active]: { name: "ACTIVE", colorTag: "green", status: 1 },
  [OPTION_POINT_OP_STATUS.Suspend]: { name: "SUSPEND", colorTag: "red", status: 0 },
};

export const GET_BIZ_HRS_OBJ = (hubData) => {

    /* 
        formatting Operation Hours initialValue
    */ 

    //  Listing days used
    const days = [ "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday" ]

    // Formatted day/hour initialization
    let bizHrsDay = {}
    let bizHrsHr = {}

    // fill the declared object above
    hubData 
    && 
    days.forEach((_, i) => {
        const bzHrsArr = hubData.biz_hrs[i]?.hour.split('/');
        const daysBiz = hubData.biz_hrs[i]?.day;

        const dayBiz = TIME.getDayNamefromEpoch(daysBiz, 1)

        if(dayBiz) {

            bizHrsDay[dayBiz] = hubData.biz_hrs[i]?.day
            bizHrsHr[dayBiz] = bzHrsArr?.map(hr => TIME.dayJS(parseInt(hr)))

        }
    });

    return { day: bizHrsDay, hour: bizHrsHr }
}

export const GET_BIZ_HRS_ARR = (biz_hrs) => {
  
    let bizHrs = []

    // Formatted biz_hrs
    Object.keys(biz_hrs.hour).forEach(dayBiz => {
      
      if(biz_hrs.day[dayBiz]) {

            const timeArrayEpoch = Object.values(biz_hrs.hour[dayBiz])?.map(hour => TIME.dayJSMilliSecond(hour))
            biz_hrs.hour[dayBiz] = timeArrayEpoch.join('/')

            bizHrs.push({
                day: biz_hrs.day[dayBiz],
                hour: biz_hrs.hour[dayBiz]
            })
        }
    })

    return bizHrs
}

export const GET_OPERATION_HOURS_LIST = (biz_hrs) => {
  const days = ["Mon", "Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"]

  let opHrs = []

  biz_hrs 
  &&
  days.forEach(day => {

      const opHr = {
          day,
          hour: biz_hrs.find(bz => day === TIME.getDayNamefromEpoch(bz.day))?.hour
      }
  
      opHrs.push(opHr)
  })

  return (
      <>
          {
              opHrs.map((bz, i) => (
                  <div key={i} style={{ display: 'grid', gridTemplateColumns: '30% 70%', width: '18em' }}>
                      <p>{bz.day}</p>
                      <p>{bz.hour ? TIME.getHoursNameFromEpoch(bz.hour) : 'Closed'}</p>
                  </div>
              ))
          }
      </>
  )
}
