export const SET_LOADING_VEHICLE = "V1_SET_LOADING_VEHICLE";
export const UNSET_LOADING_VEHICLE = "V1_UNSET_LOADING_VEHICLE";

export const SET_LOADING_GENERAL = "V1_SET_LOADING_GENERAL";
export const UNSET_LOADING_GENERAL = "V1_UNSET_LOADING_GENERAL";

export const SET_LOADING_USER_LIST = "V1_SET_LOADING_USER_LIST";
export const UNSET_LOADING_USER_LIST = "V1_UNSET_LOADING_USER_LIST";

export const SET_LOADING_BILLING_LIST = "V1_SET_LOADING_BILLING_LIST";
export const UNSET_LOADING_BILLING_LIST = "V1_UNSET_LOADING_BILLING_LIST";

export const SET_LOADING_TRANSACTION_LIST = "V1_SET_LOADING_TRANSACTION_LIST";
export const UNSET_LOADING_TRANSACTION_LIST =
  "V1_UNSET_LOADING_TRANSACTION_LIST";

export const SET_LOADING_CUSTOMER_WALLET = "V1_SET_LOADING_CUSTOMER_WALLET";
export const UNSET_LOADING_CUSTOMER_WALLET = "V1_UNSET_LOADING_CUSTOMER_WALLET";

export const SET_LOADING_ACCOUNT_MANAGER = "V1_SET_LOADING_ACCOUNT_MANAGER";
export const UNSET_LOADING_ACCOUNT_MANAGER = "V1_UNSET_LOADING_ACCOUNT_MANAGER";

export const SET_LOADING_CUSTOMER_LIST = "V1_SET_LOADING_CUSTOMER_LIST";
export const UNSET_LOADING_CUSTOMER_LIST = "V1_UNSET_LOADING_CUSTOMER_LIST";

// Order
export const SET_LOADING_ORDER_SUBMIT = "V1_SET_LOADING_ORDER_SUBMIT";
export const UNSET_LOADING_ORDER_SUBMIT = "V1_UNSET_LOADING_ORDER_SUBMIT";

export const SET_LOADING_ORDER_LIST = "V1_SET_LOADING_ORDER_LIST";
export const UNSET_LOADING_ORDER_LIST = "V1_UNSET_LOADING_ORDER_LIST";

export const SET_LOADING_ORDER_LOG = "V1_SET_LOADING_ORDER_LOG";
export const UNSET_LOADING_ORDER_LOG = "V1_UNSET_LOADING_ORDER_LOG";

export const SET_LOADING_ORDER_UPDATE = "V1_SET_LOADING_ORDER_UPDATE";
export const UNSET_LOADING_ORDER_UPDATE = "V1_UNSET_LOADING_ORDER_UPDATE";

export const SET_LOADING_ORDER_COMMENT =  "V1_SET_LOADING_ORDER_COMMENT"
export const UNSET_LOADING_ORDER_COMMENT = "V1_UNSET_LOADING_ORDER_COMMENT"

export const SET_LOADING_PARK_ORDER = "V1_SET_LOADING_PARK_ORDER";
export const UNSET_LOADING_PARK_ORDER = "V1_UNSET_LOADING_PARK_ORDER";

//hub
export const SET_LOADING_HUB_OPERATOR = "V1_SET_LOADING_HUB_OPERATOR";
export const UNSET_LOADING_HUB_OPERATOR = "V1_UNSET_LOADING_HUB_OPERATOR";

export const SET_LOADING_HUB = "V1_SET_LOADING_HUB";
export const UNSET_LOADING_HUB = "V1_UNSET_LOADING_HUB";


//zone
export const SET_LOADING_ZONE = "V1_SET_LOADING_ZONE";
export const UNSET_LOADING_ZONE = "V1_UNSET_LOADING_ZONE";

export const SET_LOADING_ZONE_PRICELIST = "V1_SET_LOADING_ZONE_PRICELIST";
export const UNSET_LOADING_ZONE_PRICELIST = "V1_UNSET_LOADING_ZONE_PRICELIST";
