//huboperator actions redux

import * as API from "../../api"
import * as ActionTypes from "../action-types";

import { message } from 'antd'

// Constants and Config
import { APP_CONFIG } from "../../../config";

// Services Library
import { TIME } from '../../util'
import { encryptFE } from "../../util";

// Redux Action

// Neccessary Actions
import {setLoadingHubOperator, unsetLoadingHubOperator} from "./style";

export const get_all_hub_operators_request = (role) => dispatch => {

    dispatch(setLoadingHubOperator())

    API
    .getAllHubOperator({role})
    .then(info => {

        const {
            status,
            operators,
            msg
        } = info

        if(status === 200) {

            dispatch(get_all_hub_operator_success(role, operators))
        } else {
            message.error(msg)
        }
    })
    .finally(() => {
        dispatch(unsetLoadingHubOperator());
    });
}

const get_all_hub_operator_success = (role, operators) => {
    return {
        type: ActionTypes.GET_HUB_OPERATOR_ALL,
        role, operators
    }; 
}

export const get_all_hub_operators_only_request = () => dispatch => {
    const role = 0
    API
    .getAllHubOperator({role})
    .then(info => {

        const {
            status,
            operators,
            msg
        } = info

        if(status === 200) {

            dispatch(get_all_hub_operator_only_success(operators))
        } else {
            message.error(msg)
        }
    })
    .finally(() => {
        // dispatch(unsetLoadingHubOperator());
    });
}

const get_all_hub_operator_only_success = (operators) => {
    return {
        type: ActionTypes.GET_HUB_OPERATOR_ONLY,
        operators
    }; 
}

export const create_hub_operators_request = (items, cb) => dispatch => {
    const {password}= items
    const mkey = APP_CONFIG.APP_MASTER_KEY
    items.password = encryptFE(
        JSON.stringify(`${mkey}#${password}`),
        APP_CONFIG.APP_MASTER_KEY
      );

    API.createHubOperator(items)
    .then(info => {

        const {
            status,
            operator,
            msg
        } = info

        if(status === 200 && operator.uroleid !== 'POP') {
            dispatch(create_hub_operator_success(operator))
            message.success("Hub Operator Account is created")
        } else if(status === 200 && operator.uroleid === 'POP') {
            dispatch(create_hub_operator_success(operator))
            message.success("Point Operator Account is created")
        } 
        else {
            message.error(msg)
        }
    }).finally(() => { cb() })
}

const create_hub_operator_success = (hubs) => {
    return {
        type: ActionTypes.CREATE_HUB_OPERATOR,
        hubs
    };
}

export const update_hub_operators_request = (items, cb) => dispatch => {

    // console.log(items)

    API.updateHubOperator(items)
    .then(info => {

        const {
            status,
            operator,
            msg
        } = info

        if(status === 200 && operator.memid.includes('POP')) {
            dispatch(update_hub_operator_success(operator))
            message.success("Point Operator Account is updated")
        } else if(status === 200 && operator.memid.includes('HOP')) {
            dispatch(update_hub_operator_success(operator))
            message.success("Hub Operator Account is updated")
        } 
        // if(status === 200 ){
        //     dispatch(update_hub_operator_success(operator))
        // }
        else {
            message.error(msg)
        }
    }).finally(() => { cb() })
}

export const create_hub_operators_remark_request = ({value: remark, newData: user}, cb = () => {}) => dispatch => {

    remark.createdAt = TIME.currentTimeMilliSecond()
    
    if(!Array.isArray(user.remark)) {
        user.remark = []
    }
    user.remark.push(remark)

    // const remarks = user && Array.isArray(user.remark) ? user.remark : [];
    // remarks.push(remark);

    // const data = {
    //     ...user,
    //     remarks
    // }

    API.updateHubOperator({
        uid: user.uid, 
        items: user
    }).then(info => {
        const { status, msg, operator: newUser} = info

        if(status === 200) {
            dispatch(update_hub_operator_success(newUser))
            message.success("Hub Operator remark added successfully")
        } else {
            message.warning(msg)
        }
    }).finally(() => cb())
}

export const update_hub_operators_remark_request = ({editValue: remark, newData: user}, cb = () => {}) => dispatch => {

    dispatch(setLoadingHubOperator())

    const remarks = user.remark.map(p => {
        let newRemark
    
        if(p.createdAt === remark.createdAt) {
          newRemark = remark
        } else newRemark = p
    
        return newRemark
      })
    
      API.updateHubOperator({
        uid: user.uid,
        items: {
          ...user,
          remark: remarks
        }
      }).then(info => {
        const { msg, status, operator: newUser} = info

        if(status === 200) {
          dispatch(update_hub_operator_success(newUser))
          message.success("Hub Operator remark updated successfully")
      } else {
          message.warning(msg)
      }

      }).finally(() => {
        cb()
        dispatch(unsetLoadingHubOperator())
    })
}
export const delete_hub_operators_remark_request = ({cel: remark, newData: user}, cb = () => {}) => dispatch => {
    const newRemark = user.remark.filter(r => r.createdAt !== remark.createdAt)

    API.updateHubOperator({
        uid: user.uid,
        items: {
          ...user,
          remark: newRemark
        }
      }).then(info => {
        const { status, msg, operator: newUser } = info

        if(status === 200) {
          dispatch(update_hub_operator_success(newUser))
          message.success("Hub Operator remark deleted successfully")
      } else {
          message.warning(msg)
      }
    
      }).finally(() => cb())
}

const update_hub_operator_success = (hubs) => {
    return {
        type: ActionTypes.UPDATE_HUB_OPERATOR,
        hubs
    };
}


export const get_operator_thru_hubid_request = (hubid, cb = () => {}) => dispatch => {

    dispatch(setLoadingHubOperator())

    API
    .getOperatorThruHUBID(hubid)
    .then(info => {

        const {
            status,
            operators,
            msg
        } = info

        if(status === 200) {
            dispatch(get_operator_thru_hubid_success(hubid, operators))
        } else {
            message.error(msg)
        }
    })
    .finally(() => {
        dispatch(unsetLoadingHubOperator());
        cb()
    });
// =======
    // .finally(() => {
    //     // dispatch(unsetLoadingHubOperator());
    //     if (cb && typeof cb === 'function') {
    //         cb(); 
    //     }
    // })
}

export const delete_operator_list_from_hub_list_request = (memid, operator, hubid, cb = () => {}) => dispatch => {
    const filteredOperatorListByID = operator.filter(op => op.memid !== memid)
    const operators = {
      hubid,
      operators: filteredOperatorListByID.map(op => ({
          uid: op.uid
      }))
    }

    API.hubAssignedOperator(
        operators
    ).then(info => {
        const { status, msg } = info

        if(status === 200) {
            dispatch(delete_operator_list_from_hub_list_success(hubid, filteredOperatorListByID))
            message.success(msg)
        } else {
            message.error(msg)
        }
    })
    .finally(() => {
      cb()
    })
}

const delete_operator_list_from_hub_list_success = (hubid, operator) => {
    return {
        type: ActionTypes.DELETE_HUB_OPERATOR_LIST_FROM_HUBID,
        hubid, operator
    }
}

const get_operator_thru_hubid_success = (hubid, operators) => {
    return {
        type: ActionTypes.GET_HUB_OPERATOR_THRU_HUBID,
        hubid, operators
    };
}
