import React from "react";
import { Routes, Route, useLocation } from "react-router";

// Constant
import { ROUTES, COMMON_FEATURES } from "../../../../constants";
import { ROOT_PAGES } from "../../../../pages";

// Components
// eslint-disable-next-line import/named
import { asyncComponent } from "../../../../components";
import {
  OrderList,
  OrderSort,
  OrderSummary,
  OrderBulkUpload,
  OrderTimeline
} from "../../../../pages/Order";
import {
  CustomerWalletTable,
  ManageCredit,
  ManageCreditInvoice,
} from "../../../../pages/CustomerWallet";
import TransactionList from "../../../../pages/TransactionList";
import BillingList from "../../../../pages/BillingList";
import { HubList, HubOperator, HubCreate } from "../../../../pages/Hub";
import { PointForm, PointList, PointOperator } from "../../../../pages/Point";
import { CustomerList, AccountManagerList } from "../../../../pages/UserList";
import MyAccount from "../../../../pages/MyAccount";
import OrderParkedList from "../../../../pages/OrderParkedList";
import ZoneList from "../../../../pages/Zone"
import Report from "../../../../pages/Report";
import VehicleList from "../../../../pages/VehicleList";
import { MapViewVehicle } from "../../../../pages/VehicleList/components";

// Modules

const AsyncNotFoundPage = asyncComponent(() => <ROOT_PAGES.NotFoundPage />);

const { ROUTE_URL } = ROUTES;
const { FEATURE_NAME } = COMMON_FEATURES;

const MainRoutes = () => {
  const location = useLocation();

  const { state = {} } = location;

  const ordid = state && state.ordid;
  const label = state && state.label;
  const mftid = state && state.mftid;
  const memid = state && state.memid;
  const billid = state && state.billid;
  const txnid = state && state.refid;
  const hubData = state && state.record 
  const status = state && state.status;
  const record = state && state.record;
  const plate = state && state.plate; 
  const vid = state && state.vid
  
  return (
    <Routes>
      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.DASHBOARD]}
        element={<OrderList />}
      />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.ORDER_LIST]}
        element={<OrderList />}
      />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.ORDER_SORT]}
        element={<OrderSort ordid={ordid} label={label} />}
      />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.ORDER_SUMMARY]}
        element={<OrderSummary ordid={ordid} />}
      />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.ORDER_PARKED_LIST]}
        element={<OrderParkedList />}
      />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.BULK_UPLOAD]}
        element={<OrderBulkUpload />}
      />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.ORDER_TIMELINE]}
        element={
          <OrderTimeline ordid={ordid} status={status} record={record} />
        }
      />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.TRANSACTION_LIST]}
        element={<TransactionList />}
      />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.CUSTOMER_WALLET_MANAGEMENT]}
        element={<CustomerWalletTable />}
      />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.MANAGE_CREDIT]}
        element={<ManageCredit memid={memid}/>}
      />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.MANAGE_CREDIT_INVOICE]}
        element={<ManageCreditInvoice memid={memid} billid={billid} />}
      />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.BILLING_LIST]}
        element={<BillingList txnid={txnid} />}
      />
      
      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.VEHICLE_LIST]}
        element={<VehicleList />}
      />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.CUSTOMER_LIST]}
        element={<CustomerList />}
      />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.ACCOUNT_MANAGER]}
        element={<AccountManagerList />}
      />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.HUB_LIST]}
        element={<HubList />}
      />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.HUB_CREATE]}
        element={<HubCreate />}
      />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.HUB_EDIT]}
        element={<HubCreate hubData={hubData} />}
      />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.HUB_OPERATOR_LIST]}
        element={<HubOperator />}
      />

        <Route
          exact
          path={ROUTE_URL[FEATURE_NAME.POINT_LIST]}
          element={<PointList />}
        />
        <Route
          exact
          path={ROUTE_URL[FEATURE_NAME.POINT_FORM]}
          element={<PointForm hubData={hubData}/>}
        />
        <Route
          exact
          path={ROUTE_URL[FEATURE_NAME.POINT_CREATE]}
          element={<PointForm />}
        />
        <Route
          exact
          path={ROUTE_URL[FEATURE_NAME.POINT_EDIT]}
          element={<PointForm hubData={hubData}/>}
        />

        <Route
          exact
          path={ROUTE_URL[FEATURE_NAME.POINT_OPERATOR_LIST]}
          element={<PointOperator />}
        />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.ZONE_PRICE_LIST]}
        element={<ZoneList />}
      />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.VEHICLE_LIST]}
        element={<VehicleList />}
      />


      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.VEHICLE_MAP]}
        element={<MapViewVehicle plate={plate} vid={vid}/>}
      />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.REPORT]}
        element={<Report />}
      />

      <Route
        exact
        path={ROUTE_URL[FEATURE_NAME.ACCOUNT_PROFILE]}
        element={<MyAccount />}
      />



      <Route element={<AsyncNotFoundPage />} />
    </Routes>
  );
};
export default MainRoutes;
