export const TABLE_HUB_TITLE = {
    hub_code_name: "Hub Code / Name",
    hub_code: "Hub Code",
    status: "Status",
    hub_name: "Hub Name",
    type: "Type",
    state: "State",
    pc: "Postcode/City",
    address: "Address",
    hub_operators: "Hub Operators",
    pic: "PIC Information",
    map_coor: "Map Coordinates",
    url:"Google Map Link",
    created_at: "Created At",
    last_update: "Last Updates By",
    actions: "Actions"
  };

  export const OPTION_DAYS = {
    LAST_30_DAYS: "Last 30 Days",
    LAST_60_DAYS: "Last 60 Days",
    LAST_90_DAYS: "Last 90 Days",
  };
  
  export const OPTION_DAYS_VALUE = {
    LAST_30_DAYS: 30,
    LAST_60_DAYS: 60,
    LAST_90_DAYS: 90,
  };
  
  export const OPTION_HUB_STATUS = {
    Active: 1,
    Inactive: 0,
    // Undefined: undefined,
  } 

  export const HUB_TYPE_CODE = {
    ESTABLISHED_HUB: 'H001',
    SIMPLE_HUB: 'H002',
    VIRTUAL_HUB: 'H003'
  }
  
  export const HUB_TYPE_TITLE = {
    [HUB_TYPE_CODE.ESTABLISHED_HUB]: "Physical Established Hub",
    [HUB_TYPE_CODE.SIMPLE_HUB]: "Simple Hub",
    [HUB_TYPE_CODE.VIRTUAL_HUB]: "Virtual Hub"
  }

  export const OPTION_HUB_STATUS_TITLE = {
    [OPTION_HUB_STATUS.Active]: { name: "ACTIVE", colorTag: "success", status: 1 },
    [OPTION_HUB_STATUS.Inactive]: { name: "INACTIVE", colorTag: "default", status: undefined },
    // [OPTION_HUB_STATUS.Undefined]: { name: "ACTIVE", colorTag: "green", status: undefined },
  };
  export const SELECT_OPTION_DAYS = Object.keys(OPTION_DAYS).map((option) => ({
    label: OPTION_DAYS[option],
    value: OPTION_DAYS_VALUE[option],
  }));
  export const TABLE_HUB_INDEX = {
    hub_code_name:"name",
    hub_code: "code",
    status: "status",
    hub_name: "name",
    type: "type",
    state:"state",
    pc: "postcode",
    address: "address",
    hub_operators: "hubOperator",
    pic: "pic",
    map_coor: "map",
    url:"url",
    created_at: "createdAt",
    last_update: "updatedBy",
  };

  export const TABLE_HUB_OPERATOR_TITLE = {
    hub_opid: "Hub Operator ID",
    name: "Name",
    status: "User Status",
    contact: "Contact",
    email: "Email",
    assignedHub : "Assigned Hub", 
    created_at: "Created At",
    last_update: "Last Updates At",
    remark: "Remark",
    actions: "Actions"
  };

  export const TABLE_HUB_OPERATOR_INDEX = {
    hub_opid: "memid",
    name: "name",
    status: "status",
    contact: "contact",
    email: "email",
    assignedHub : "assignedHub",
    created_at: "createdAt",
    last_update: "updatedAt",
    actions: "actions"
  };

  export const OPTION_HUB_OP_STATUS = {
    Active: 1,
    Suspend: 0,
  }

  export const ACTIONS_LABEL = {
    create_hub : "create_hub",
    edit_hub : "edit_hub"
  }

  export const ACTIONS_LABEL_TITLE = {
    [ACTIONS_LABEL.create_hub] : "Create Hub",
    [ACTIONS_LABEL.edit_hub] : "Edit Hub"
  }

  export const OPTION_HUB_OP_STATUS_TITLE = {
    [OPTION_HUB_OP_STATUS.Active]: { name: "ACTIVE", colorTag: "green", status: 1 },
    [OPTION_HUB_OP_STATUS.Suspend]: { name: "SUSPEND", colorTag: "red", status: 0 },
  };

  export const GET_HUB_LIST = ({ hubs, hubsIDs }) => {

      const hubMap = {}

      hubsIDs.forEach(hubid => {
          const hubState = hubs[hubid]?.state

          if(hubState !== undefined){
              if (!hubMap[hubState]) {
                  hubMap[hubState] = []
              }
              hubMap[hubState].push({
                  label: hubs[hubid].name,
                  value: hubid
              })
          }
      })

      // hubsList -- used for hubid OptionGroup
      const hubsList = Object.keys(hubMap).map(hubState => ({
          label: hubState,
          options: hubMap[hubState]
      }))

      return hubsList
  }
