// React Thirty Party Library
import { Select, Row, Col, DatePicker, Form, message, Input } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from "react";

// Constants Library
import { TEMPLATE } from "../../../../constants";

// Components Library
import { TableList, BtnSubmit } from "../../../../components";
import  FilterSelect from "../../../../components/TableList/FilterSelect";

// Handler Librarys
import { exportHDLR, reportHDLR } from "../../../../handlers";

// Services Library
import { TIME, REDUX as REDUX_UTIL } from "../../../../services/util";

import * as REDUX_ACTION from "../../../../services/redux/actions";

// Redux Actions

// Styles Library
import "./index.css"
import style from "../../index.module.css"

const { RangePicker } = DatePicker

const {lastNDay, dayJSMilliSecond, currentTimeMilliSecond} = TIME;
const { reduxStoreWrapper } = REDUX_UTIL
const {
  REPORT_TYPE_MODE,
  INPUT_FIELD_INDEX,
} = TEMPLATE.REPORT_HISTORY

const SearchReport = () => {
  const dispatch = useDispatch()

    const [ form ] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage();

    const hub = useSelector(reduxStoreWrapper('hub.byID'))
    const point = useSelector(reduxStoreWrapper('point.byID'))
    const orders = useSelector(reduxStoreWrapper("order.allIDs"))
    const orderStatuses = useSelector(reduxStoreWrapper("orderStatus.byID"))

    const PORGID = "ipick";

    useEffect(() => {
      dispatch(
        REDUX_ACTION.v1_order.get_order_timeframe_thru_porgid_last_90_days_request(
          [PORGID]
        )
      );
  
      return () => {};
    }, [dispatch]);

    const onFinish = (e) => {

        const { hubid, type, date, ordids = [], statuses = [] } = e

        const items = {
            hubs: hubid || [], // requirement in Hub/Point portal
            ordids: ordids.includes('all') ? [] : ordids,
            statuses: statuses.includes('all') ? [] : statuses,
            startTime: date?.length && dayJSMilliSecond(date[0]) || lastNDay(90),
            endTime: date?.length && dayJSMilliSecond(date[1]) || currentTimeMilliSecond(),
        }

        dispatch(REDUX_ACTION.v1_report.reset_report())

        messageApi.loading('Generate report...', 0)

        dispatch(REDUX_ACTION.v1_report.generate_report_request(items, type, (reports) => {
          messageApi.destroy(); // Destroy loading message when report generation is completed
          if (reports && reports.length > 0) {
            message.success('Report generated successfully');
          } else {
            message.error('No report'); // Show error message if report is empty
          }
        }))
    }

    const [ mode, setMode ] = useState('') // to remove the status column if the report if scan log

    const filterHub = (e) => {
      const result = [];
      
      for (const pointKey in e) {
          const { name, hubid } = e[pointKey];
          result.push({ label : name, value : hubid });
        }
        
        return result;
    };

    const simplifiedPoints = filterHub(point);
    const simplifiedHubs = filterHub(hub);
    const hubsPointOption = [{label:'HUB', options : simplifiedHubs}, {label:'POINT', options: simplifiedPoints}]

    return ( 
      <>
      {contextHolder}
        <Form
            form={form}
            onFinish={onFinish}
            style={{ minWidth: '75em', width: '100%' }}
        >
            <Row justify={'center'} gutter={15}>
                <Col span={4} style={{ position: 'relative' }}>
                    <label htmlFor="type" className={style["label"]} >Report</label>
                    <Form.Item name='type' rules={[{ required: true }]}>
                        <Select 
                            size="large"
                            options={REPORT_TYPE_MODE}
                            onChange={e => setMode(e)}
                        />
                    </Form.Item>
                </Col>
                <Col span={4} style={{ position: 'relative' }}>
                    <label htmlFor={'hubs'} className={style["label"]}>{'Hub/ Point'}</label>
                    <Form.Item name='hubid'>
                        <Select
                            mode="multiple" 
                            className={style['form-input']}
                            options={hubsPointOption}
                        />
                    </Form.Item>
                </Col>
                <Col span={4} style={{ position: 'relative' }}>
                    <label htmlFor={'ordids'} className={style["label"]}>{'Order ID'}</label>
                    <Form.Item name={'ordids'}>
                        <Select 
                            mode="multiple"
                            size="large"
                            options={INPUT_FIELD_INDEX(orders)}
                        />
                    </Form.Item>
                </Col>
                {
                    mode !== 'scan'
                    &&
                    <Col span={4} style={{ position: 'relative' }}>
                        <label htmlFor={'statuses'} className={style["label"]}>{'Status'}</label>
                        <Form.Item name={'statuses'}>
                            <Select 
                                mode="multiple"
                                size="large"
                                options={INPUT_FIELD_INDEX(orderStatuses, 'display', 'status')}
                            />
                        </Form.Item>
                    </Col>
                }
                <Col span={5} style={{ position: 'relative' }}>
                    <label htmlFor="date" className={style["label"]} >Date Range</label>
                    <Form.Item name='date'>
                        <RangePicker size="large" />
                    </Form.Item>
                </Col>
                    <BtnSubmit 
                        text={'Search'}
                        padding="9px 25px"
                        bgColor="var(--themeBackgroundColor)"
                        color="var(--creamColor)"
                    />
            </Row>
        </Form>
        </>
    )
};

export default SearchReport;
