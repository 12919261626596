import { Tag } from "antd";

// Contanst

export const orderStatusesTreeStructure = (orderStatues) => {
  let tree = [];

  orderStatues &&
    Object.keys(orderStatues) &&
    Object.keys(orderStatues).length &&
    Object.entries(orderStatues).map(([status, os]) => {
      const { type, display, colorTag = "default" } = os;

      if (type === "ROOT") {
        const rootNode = {
          title: (
            <Tag color={colorTag} key={status}>
              {display}
            </Tag>
          ),
          key: `status#${status}`,
          value: `status#${status}`,
        };
  
        tree.push(rootNode);
        return; 
      }

      const node = {
        title: (
          <Tag color={colorTag} key={status}>
            {display}
          </Tag>
        ),
        key: `status#${status}`,
        value: `status#${status}`,
      };

      const branch = tree.find((t) => t.value === `type#${type}` && t.type !== "ROOT") || {};

      const children = [...(branch.children || []), node];
      const uniquwChildren = [
        ...new Map(children.map((m) => [m.key, m])).values(),
      ];

      if (branch && Object.keys(branch).length) {
        tree = tree.map(
          (b) =>
            (b.key === `type#${type}` && { ...b, children: uniquwChildren }) ||
            b
        );
      } else {
        tree.push({
          title: type,
          key: `type#${type}`,
          value: `type#${type}`,
          children: uniquwChildren,
        });
      }
    });

  return tree;
};

export const orderOrigin = (hubs) => {
  let tree = [];

  if (hubs) {
    const allChildren = Object.keys(hubs).map((origin) => {
      const key = `origid#${origin}`;
      const value = `origid#${origin}`;

      return {
        title: hubs[origin] && hubs[origin].code,
        key: key,
        value: value,
      };
    });

    const allOption = {
      title: "ALL",
      key: "ALL",
      value: "ALL",
    };

    tree.push({ ...allOption, children: [...allChildren] });
  }
  const filterSelectedChildren = (node) => {
    if (node.value === "ALL") {
      return tree.flatMap((n) => n.children.map((child) => child.value));
    }

    return [node.value];
  };

  tree.filterSelectedChildren = filterSelectedChildren;

  return tree;
};

export const orderDestination = (hubs) => {
  let tree = [];

  if (hubs) {
    const allChildren = Object.keys(hubs).map((dstid) => {
      const key = `dstid#${dstid}`;
      const value = `dstid#${dstid}`;

      return {
        title: hubs[dstid] && hubs[dstid].code,
        key: key,
        value: value,
      };
    });

    if (allChildren.length > 0) {
      const allOption = {
        title: "ALL",
        key: "ALL",
        value: "ALL",
        children: allChildren,
      };

      tree.push(allOption);
    }
  }

  return tree;
};
