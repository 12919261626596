// React Thirty Party Library
import React, {useState}  from "react";
import { Card, Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

// Constants Library
import { TEMPLATE } from "../../../../../../constants";

// Components Library
import { ButtonLink } from "../../../../../../components";

// Handler Library
import {
  templateHDLR,
  xlsx_handler,
  uploadHDLR,
} from "../../../../../../handlers";

// Services Library
import { TIME, REDUX as REDUX_UTIL } from '../.../../../../../../../services/util'

// Redux Actions

// Styles Library
import classes from "../../../../../common.module.css";
import "./index.css";

const { Dragger } = Upload;

const { 
  ORDER_DATA_TITLE_BY_NAME, 
  ORDER_DATA_TITLE_SPLIT_SPECIAL_ARR,
  ORDER_DATA_DATE_SPECIAL_HDLR,
  FILE2ORDER_DATA_CONVERTER,
  STATE_BYID
} =
  TEMPLATE.ORDER;

const { reduxStoreWrapper } = REDUX_UTIL

const { dayValue } = TIME

const OrderBulk = ({ setLoading, setSrcData}) => {

  const hubs = useSelector(reduxStoreWrapper('hub.byID'))
  const points = useSelector(reduxStoreWrapper('point.byID'))
  const customers = Object.values(useSelector(reduxStoreWrapper("customer.byID")));

  const [errorMessages, setErrorMessages] = useState([]);
  const [msgValid, setMsgValid] = useState(false);

      const validateHubOrPoint = (od, hubsOrPoints, destProp, origProp) => {
        const dest = od[destProp]
        const orig = od[origProp]

        const pointOr = hubsOrPoints === points && hubsOrPoints[dest] && hubsOrPoints[orig].zone
        const pointDs = hubsOrPoints === points && hubsOrPoints[dest] && hubsOrPoints[dest].zone

        const matchedDest = Object.values(hubsOrPoints).find((h) => dest === h.hubid);
        const matchedOrig = Object.values(hubsOrPoints).find((h) => orig === h.hubid);

        if (matchedDest && matchedDest.status === 0) {
            const errorMessage = `The ${hubsOrPoints === hubs ? 'Hub' : 'Point'} ${hubsOrPoints[dest]?.code} is inactive`;
            setErrorMessages(prevMessages => [...prevMessages, errorMessage]);
            delete od[destProp];
        }

        if (matchedOrig && matchedOrig.status === 0) {
            const errorMessage = `The ${hubsOrPoints === hubs ? 'Hub' : 'Point'} ${hubsOrPoints[orig]?.code} is inactive`;
            setErrorMessages(prevMessages => [...prevMessages, errorMessage]);
            delete od[origProp];
        }

        if ((pointOr && pointDs && (pointOr === pointDs)) !== false) {
          const errorMessage = `The Point ${hubsOrPoints[orig]?.code} and Point ${hubsOrPoints[dest]?.code} is in the same zone. Please choose another point`;
          setErrorMessages(prevMessages => [...prevMessages, errorMessage]);
          delete od[destProp];
        }
      }
      const validateInstructions = (od) => {

          const errorMessage = `The destination delivery instruction must be PICK UP AT POINT`
          setErrorMessages(prevMessages => [...prevMessages, errorMessage]);
          delete od.destDeliveryType

          if (hubs[od.dstid].code) {
            const errorMessage = `${hubs[od.dstid]?.code} is not point`
            setErrorMessages(prevMessages => [...prevMessages, errorMessage]);
            delete od.dstid
          } else if (hubs[od.origid].code) {
            const errorMessage = `${hubs[od.origid]?.code} is not point`
            setErrorMessages(prevMessages => [...prevMessages, errorMessage]);
            delete od.origid
          }
      }
      
      if (errorMessages.length > 0 && !msgValid) {
        const uniqueErrorMessages = new Set();
      
        errorMessages.forEach((errorMessage) => {
          if (!uniqueErrorMessages.has(errorMessage)) {
            message.error(errorMessage);
            uniqueErrorMessages.add(errorMessage);
            setMsgValid(true)
          }
        });
      }
      

  const FOConverter = FILE2ORDER_DATA_CONVERTER(hubs, points, STATE_BYID)

  const handleDownload = () => templateHDLR.getOrderTemplateXLSXFromS3();

  const handleFile = (file) => {
    setLoading(true);
  
    uploadHDLR.decodeFile(file, (d) => {
      const ftData = xlsx_handler.readXLSXFile(d);
      const oRawData = ftData.map((ftd) =>
        Object.fromEntries(
          Object.entries(ftd).map((d) => [ORDER_DATA_TITLE_BY_NAME[d[0]], d[1]])
        )
      );

      const oData = oRawData.reduce((acc, ord, index) => {
        let od = {};

        const cleanString = (str) => typeof str === 'string' ? str.trim() : str;
  
        Object.keys(ord).forEach((k) => {

          let value = cleanString(ord[k]);

          if(Object.keys(FOConverter).includes(k)) {
            value = FOConverter[k](value)
          }

          if (
            !ORDER_DATA_TITLE_SPLIT_SPECIAL_ARR.includes(k) &&
            (
              k.includes("_")
              &&
              (
                k.includes("sender")
                ||
                k.includes("receiver")
                ||
                k.includes("parcel")
              )
            )
          ) {
            const a = k.split("_");
            if (!Object.prototype.hasOwnProperty.call(od, a[0])) 
            od[a[0]] = {};
            od[a[0]][a[1]] = value;
            
          } else {
            if(ORDER_DATA_DATE_SPECIAL_HDLR.includes(k)) {
              od[k] = dayValue(value)
            } else {
              od[k] = value;
            }
          }

          return 1;
        });

        od.pointer = index + 1

        const isValid = customers.some((o) => {
          return od.memid === o.memid;
        });
  
        if (isValid) {
          acc.push(od);
        } else {
          message.error(`The Customer ID ${od.memid} doesn't exist`);
        }
        
        if (od.destDeliveryType === 'h2h' || od.destDeliveryType === 'h2d') {
          validateHubOrPoint(od, hubs, 'dstid', 'origid');
        }
        
        if (od.destDeliveryType === 'p2p') {
          validateHubOrPoint(od, points, 'dstid', 'origid');
        }

        if (od.origDeliveryType === "emp2" && od.destDeliveryType !== 'p2p') {
          validateInstructions(od);
        } 

        od.remark = od.parcel.remark
  
        od.parcel.unsort = [{
          height: od.parcel.height,
          length: od.parcel.length,
          weight: od.parcel.weight,
          qty: od.parcel.quantity,
          width: od.parcel.width}]
          
        // Remove the properties from od.parcel
        delete od.parcel.height;
        delete od.parcel.length;
        delete od.parcel.weight;
        delete od.parcel.quantity;
        delete od.parcel.width;
        delete od.parcel.remark;
        
        return acc;
      }, []);

      setSrcData(oData);
      setLoading(false);
    });
  };
  

  return (
    <div style={{marginTop: "80px"}}>
      <Card className={classes.CardStep}>
        <div>
          Step 1:&nbsp;
          <ButtonLink class_name={classes.linkbtn} onClick={handleDownload}>
            Download
          </ButtonLink>
          &nbsp;Template
        </div>
      </Card>

      <div>
        <Dragger
          style={{ backgroundColor: "#F4F2FF", padding: "25px 0" }}
          accept=".xlsx, .xls"
          multiple={false}
          beforeUpload={(file) => {
            handleFile(file);
            return false;
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <div className="ant-upload-hint">
            Step 2: Drag or drop file here to upload or&nbsp;
            <ButtonLink class_name={classes.linkbtn}>Browse</ButtonLink>
          </div>
        </Dragger>
      </div>
    </div>
  );
};

export default OrderBulk;
