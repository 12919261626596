import * as API from "../../api"
import * as ActionTypes from "../action-types";

import { message } from 'antd'

export const get_all_point_operators_only_request = () => dispatch => {
    const role = 1
    API
    .getAllHubOperator({role})
    .then(info => {

        const {
            status,
            operators,
            msg
        } = info

        if(status === 200) {

            dispatch(get_all_point_operator_only_success(operators))
        } else {
            message.error(msg)
        }
    })
    .finally(() => {
        // dispatch(unsetLoadingHubOperator());
    });
}

const get_all_point_operator_only_success = (operators) => {
    return {
        type: ActionTypes.GET_POINT_OPERATOR_ALL,
        operators
    }; 
}