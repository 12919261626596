// React Thirty Party Library
import { Layout as LayoutAntd } from "antd";

// Constants Library

// Components Library

// Handler Library

// Services Library

// Redux Actions

// Styles Library
import "../../index.css";

import "./index.css";

const { Content } = LayoutAntd;

const LayoutPages = (props) => {
  return (
    <div className="app-frame">
      <Content className="app-content">{props.children}</Content>
    </div>
  );
};

export default LayoutPages;
