// Third Party Lib
import React, {useEffect, useState}  from "react";
import {
  Button,
  Modal,
  message,
  Upload
} from "antd";


import { uploadHDLR } from "../../../../../../handlers";

import classes from "../../index.module.css";

const { 
  getBase64, 
  uploadParcelAttachmentRequest
} = uploadHDLR;

 
const ModalUpload = ({
  open,
  loading,
  handleModalSubmit,
  handleModalUploadCancel,
  setParcelImg,
  items: initialItems,
  pointer
}) => {
  const [items, setItems] = useState(initialItems);

  useEffect(() => {
    setItems(initialItems);
  }, [open, initialItems]);

    const validateFileType = (file) => {
        const fileTypes = [
            "image/jpeg",
            "image/jpg",
            "image/png",
            "application/pdf",
        ];
        return fileTypes.includes(file.type);
      };
    
      const handleBeforeUpload = (file) => {
        if(!validateFileType(file)) {
            message.error(`File type ${file.type} is not supported.`);
            return false;
        } else {
          return true; 
        }
      }
    
      const handleChange = (info) => {
        const { status, originFileObj, response = {} } = info.file;
    
        if (status === "uploading") {
            return;
        }
        if (status === "done") {
            getBase64(originFileObj, () => {
                const { Location: url } = response;
                setParcelImg((p) => {
                  const updatedImages = [...(p[pointer] || []), url];
          
                  return {
                    ...p,
                    [pointer]: updatedImages,
                  };
                });
                
            });
        }
      }


const img = items
  .map((url, index) => ({
      uid: index,
      name: `attachment-${index}`,
      url,
      status: "done",
}));

  return (
    <>
    <Modal
    centered
    width={550}
    title='Upload Image'
    open={open}
    onOk={handleModalSubmit}
    onCancel={handleModalUploadCancel}
    footer={[
      <Button
        key="submit"
        type="primary"
        loading={loading}
        onClick={handleModalSubmit}
      >
        Ok
      </Button>,
    ]}
    className={classes.formEdit}
  >
      <div className={classes.formColumn}>
        <Upload
        key={pointer}
        beforeUpload={handleBeforeUpload}
        onChange={handleChange}
        customRequest={uploadParcelAttachmentRequest}
        listType="picture-card"
        defaultFileList={items && img}
        >
        {items && img.length < 5 && '+ Upload'}
        </Upload>
      </div>
  </Modal>
    </>
  );
};

export default ModalUpload;
