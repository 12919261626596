import * as ActionTypes from "../action-types";

export const setLoadingvehicle = () => {
  return {
    type: ActionTypes.SET_LOADING_VEHICLE,
  };
};

export const unsetLoadingvehicle = () => {
  return {
    type: ActionTypes.UNSET_LOADING_VEHICLE,
  };
};

export const setLoadingGeneral = () => {
  return {
    type: ActionTypes.SET_LOADING_GENERAL,
  };
};

export const unsetLoadingGeneral = () => {
  return {
    type: ActionTypes.UNSET_LOADING_GENERAL,
  };
};

export const setLoadingBillingList = () => {
  return {
    type: ActionTypes.SET_LOADING_BILLING_LIST,
  };
};

export const unsetLoadingBillingList = () => {
  return {
    type: ActionTypes.UNSET_LOADING_BILLING_LIST,
  };
};

export const setLoadingUserList = () => {
  return {
    type: ActionTypes.SET_LOADING_USER_LIST,
  };
};
export const unsetLoadingUserList = () => {
  return {
    type: ActionTypes.UNSET_LOADING_USER_LIST,
  };
};

export const setLoadingAccountManagerList = () => {
  return {
    type: ActionTypes.SET_LOADING_ACCOUNT_MANAGER,
  };
};
export const unsetLoadingAccountManagerList = () => {
  return {
    type: ActionTypes.UNSET_LOADING_ACCOUNT_MANAGER,
  };
};

export const setLoadingCustomerList = () => {
  return {
    type: ActionTypes.SET_LOADING_CUSTOMER_LIST,
  };
};
export const unsetLoadingCustomerList = () => {
  return {
    type: ActionTypes.UNSET_LOADING_CUSTOMER_LIST
  };
};

export const setLoadingTransactionList = () => {
  return {
    type: ActionTypes.SET_LOADING_TRANSACTION_LIST,
  };
};

export const unsetLoadingTransactionList = () => {
  return {
    type: ActionTypes.UNSET_LOADING_TRANSACTION_LIST,
  };
};

export const setLoadingCustomerWallet = () => {
  return {
    type: ActionTypes.SET_LOADING_CUSTOMER_WALLET,
  };
};

export const unsetLoadingCustomerWallet = () => {
  return {
    type: ActionTypes.UNSET_LOADING_CUSTOMER_WALLET,
  };
};

// Order

export const setLoadingOrderSubmit = () => ({
  type: ActionTypes.SET_LOADING_ORDER_SUBMIT,
});
export const unsetLoadingOrderSubmit = () => ({
  type: ActionTypes.UNSET_LOADING_ORDER_SUBMIT,
});

export const setLoadingOrderList = () => ({
  type: ActionTypes.SET_LOADING_ORDER_LIST,
});
export const unsetLoadingOrderList = () => ({
  type: ActionTypes.UNSET_LOADING_ORDER_LIST,
});

export const setLoadingOrderLog = () => ({
  type: ActionTypes.SET_LOADING_ORDER_LOG,
});
export const unsetLoadingOrderLog = () => ({
  type: ActionTypes.UNSET_LOADING_ORDER_LOG,
});

export const setLoadingOrderUpdate = () => ({
  type: ActionTypes.SET_LOADING_ORDER_UPDATE,
});
export const unsetLoadingOrderUpdate = () => ({
  type: ActionTypes.UNSET_LOADING_ORDER_UPDATE,
});

export const setLoadingParkOrder = () => ({
  type: ActionTypes.SET_LOADING_PARK_ORDER,
});
export const unsetLoadingParkOrder = () => ({
  type: ActionTypes.UNSET_LOADING_PARK_ORDER,
});

export const setLoadingOrderComment = () => {
  return {
    type: ActionTypes.SET_LOADING_ORDER_COMMENT
  }
}

export const unsetLoadingOrderComment = () => {
  return {
    type: ActionTypes.UNSET_LOADING_ORDER_COMMENT
  }
}

// Hub 

export const setLoadingHubOperator = () => ({
  type: ActionTypes.SET_LOADING_HUB_OPERATOR,
});
export const unsetLoadingHubOperator = () => ({
  type: ActionTypes.UNSET_LOADING_HUB_OPERATOR,
});

export const setLoadingHub = () => ({
  type: ActionTypes.SET_LOADING_HUB,
});
export const unsetLoadingHub = () => ({
  type: ActionTypes.UNSET_LOADING_HUB,
});

export const setLoadingZone = () => ({
  type: ActionTypes.SET_LOADING_ZONE,
});
export const unsetLoadingZone = () => ({
  type: ActionTypes.UNSET_LOADING_ZONE,
});

export const setLoadingZonePriceList = () => ({
  type: ActionTypes.SET_LOADING_ZONE_PRICELIST,
});
export const unsetLoadingZonePriceList = () => ({
  type: ActionTypes.UNSET_LOADING_ZONE_PRICELIST,
});