// Third-party Library 
import { Tabs } from 'antd';

import { UnorderedListOutlined,CalendarOutlined } from '@ant-design/icons'

// Constants 

// Components 
import {
    MapView,
    TableView
} from "./components"

// Styles 
import classes from "../../common.module.css";

const PointList = () => {

    const items = [
        {
            key: '1',
            label: (<span><UnorderedListOutlined/> Table View</span>),
            children: <TableView/>,
        },
        {
            key: '2',
            label: (<span><CalendarOutlined/> Map View</span>),
            children: <MapView />
        },
    ];
    
    return (
        <div className={classes.container}>
            <Tabs defaultActiveKey="1" items={items}  tabBarStyle={{position: 'fixed', width: '100%', zIndex: 5, backgroundColor: 'white', marginTop: '-1.1rem', paddingBottom:'1rem', paddingTop: '0.5rem'}}/>
        </div>
    )
}

export default PointList;
