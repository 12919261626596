import { 
    List
} from "antd";
import { 
    EditOutlined, 
    DeleteOutlined 
} from '@ant-design/icons';

// Components 
import RemarkTextArea from "../RemarkTextArea";

// Styles 
import classStyles from "../../index.module.css";

const RemarkLists = ({
    dataSource,
    user,
    setIndex,
    setEdit, 
    setEditValue,
    setShowEditTextArea,
    messageApi,
    deleteRemarkHDLR,
    newData,
    TIME,
    edit, 
    index,
    showEditTextArea,
    editValue,
    handleOkButton,
    loading
}) => {

    return (
        <List
            loading={loading}
            itemLayout="horizontal"
            dataSource={dataSource}
            renderItem={(cel, i) => {
                
                const Title = () => {
                    return (
                        <div className={classStyles["list-header"]}>
                            <>{cel.createdBy} / {user.name}</>
                            <div>
                                <EditOutlined 
                                    className={classStyles["icons"]}
                                    onClick={() => {
                                        setIndex(i);
                                        setEdit(true);
                                        setEditValue(cel)
                                        setShowEditTextArea(true)
                                    }}
                                /> 
                                <DeleteOutlined 
                                    className={classStyles["icons"]} 
                                    onClick={() => {
                                        messageApi.open({
                                            type: "loading",
                                            content: "Deleting remark...",
                                            duration: 0,
                                        });
                                        deleteRemarkHDLR({cel, newData}, () => messageApi.destroy)
                                    }}
                                />
                            </div>
                        </div>
                    )
                }

                const Description = () => {
                    return (
                        <>
                            Last updated at {TIME.parseTimeMin(cel.createdAt)}<br/><br/>
                            {cel.msg}
                        </>
                    )
                }


                return (
                    <>
                        {edit && i === index && showEditTextArea ? 
                            <RemarkTextArea
                                value={editValue}
                                setValue={setEditValue}
                                handleOkButton={handleOkButton}
                                setShowTextArea={setShowEditTextArea} 
                            />
                            :
                            <List.Item style={{padding: "30px 0"}}>
                                <List.Item.Meta
                                    title={<Title />}
                                    description={<Description />}
                                />
                            </List.Item>
                        }
                    </>
                )
            }}
        />
    )
}

export default RemarkLists
