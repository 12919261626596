// Third Party Lib
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spin, Table, Tabs, Form  } from "antd";

// Constants
import { TEMPLATE } from "../../../../../../constants";

// Components Library
import OrderModal from "../OrderModal";
import ModalUpload from "../OrderModal/image"

// Handler
import { colHDLR } from "./handlers";

// Service
import {
  REDUX as REDUX_UTIL,
  CapitalizeFChar,
  TIME,
} from "../../../../../../services/util";

// Styles Library
import classes from "./index.module.css";

const { DATA_FIELD, DATA_FIELD_VALUES } = TEMPLATE.ORDER;

const { STATE } = TEMPLATE.STATE;

const { BulkColumns, ParcelColumns, SRColumns } = colHDLR;

const { dayJS, dayJSMilliSecond } = TIME;

const { reduxStoreWrapper } = REDUX_UTIL;

const BulkTable = ({ srcData, uploadingData, loading, setSrcData }) => {

  const activeHubs = useSelector(reduxStoreWrapper("hub.byStatus"));
  const hubs = useSelector(reduxStoreWrapper('hub.byID'))
  const points = useSelector(reduxStoreWrapper('point.byID'))

  const [form] = Form.useForm();

  const [modalState, setModalState] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalType, setModalType] = useState("");
  const [selPointer, setSelPointer] = useState(0);
  const [selectedFormCol, setSelectedFormCol] = useState([]);
  const [btnText, setBtnText] = useState("");
  const [parcel, setParcel] = useState("");
  const [modalImage, setModalImage] = useState(false);
  const [parcelImg, setParcelImg] = useState({})
  const [selectedUploadCol, setSelectedUploadCol] = useState([]);



  const origidData = srcData.map((datas) => {
    return datas.origid
  })
  const OrderSummaryTotalWeight = () => {
    if (srcData && srcData.length > 0) {
      return srcData.reduce((total, s) => {
        if (s.parcel && s.parcel.unsort) {
          return total + s.parcel.unsort.reduce((sum, p) => parseFloat(sum) + parseFloat(p.weight), 0);
        } else {
          return total;
        }
      }, 0).toFixed(2);
    } else {
      return 0;
    }
  };
  const OrderSummaryTotalVolume = () => {
    if (srcData && srcData.length > 0) {
      return srcData.reduce((totalVolume, s) => {
        if (s.parcel && s.parcel.unsort) {
          return totalVolume + s.parcel.unsort.reduce((sum, p) => parseFloat(sum) + (parseFloat(p.length) * parseFloat(p.width) * parseFloat(p.height)), 0);
        } else {
          return totalVolume;
        }
      }, 0).toFixed(2);
    } else {
      return 0;
    }
  };

  const totalVolume = OrderSummaryTotalVolume()
  const totalWeight = OrderSummaryTotalWeight()

  const v = form.getFieldValue('origid')

  const optionsHub =
    Object.values(activeHubs)
      .map((p) => ({
        label: `(${activeHubs[p.hubid].code}) ${activeHubs[p.hubid].name}`,
        value: p.hubid
      })) 
    
  const optionsPoint =
    Object.values(points)
      .filter(p => p.status === 1)
      .map((p) => ({
        label: `(${points[p.hubid].code}) ${points[p.hubid].name}`,
        value: p.hubid
      })) 

  const optionsDestDeliveryType = Object.keys(DATA_FIELD_VALUES[DATA_FIELD.destDeliveryType]).map((deliveryType) => ({
    label: DATA_FIELD_VALUES[DATA_FIELD.destDeliveryType][deliveryType],
    value: deliveryType,
  }));

  const optionsOrigDeliveryType = Object.keys(DATA_FIELD_VALUES[DATA_FIELD.origDeliveryType]).map((deliveryType) => ({
    label: DATA_FIELD_VALUES[DATA_FIELD.origDeliveryType][deliveryType],
    value: deliveryType,
  }));


  const optionsState = Object.values(STATE).map((s) => ({
    label: s.name,
    value: s.code,
  }));

  const handleColDelete = (key) => {
    setSrcData(srcData.filter((sd, index) => index !== key));
  };

  const handleModalCancel = () => {
    setModalState(false);
    setModalLoading(false);
  }; 

  const handleModalUpload = (rowData) => {
    const { pointer } = rowData

    setModalImage(true)
    setSelPointer(pointer)
    setModalType("order")
    const data = parcelImg[pointer] || []
    setSelectedUploadCol(data);
  }

  const handleModalUploadCancel = () => {
    setModalImage(false);
  };

  const handleEditModal = (rowData) => {
    const { pointer } = rowData;
    const initialValues = {};
    Object.values(DATA_FIELD).forEach((item) => {
      initialValues[item] = rowData[item] !== undefined ? rowData[item] : undefined;
    });

    const optionsDestPoint =
    Object.values(points)
      .filter(p =>
        p.status === 1 &&
        p.hubid !== points[initialValues.origid]?.hubid &&
        p.zone !== points[initialValues.origid]?.zone
      )
      .map((p) => ({
        label: `(${points[p.hubid].code}) ${points[p.hubid].name}`,
        value: p.hubid
      }))
      const optionsDestHub =
      Object.values(activeHubs)
      .filter(p =>
        p.status === 1 &&
        p.hubid !== activeHubs[initialValues.origid]?.hubid)
        .map((p) => ({
          label: `(${activeHubs[p.hubid].code}) ${activeHubs[p.hubid].name}`,
          value: p.hubid
        }))

    const optionHubPoint = (initialValues.destDeliveryType === 'p2p' || initialValues.origDeliveryType === 'emp2')
      ? optionsPoint
      : optionsHub;

    const optionHubPointDest = (initialValues.destDeliveryType === 'p2p' || initialValues.origDeliveryType === 'emp2')
      ? optionsDestPoint
      : optionsDestHub;


    form.setFieldsValue({
      ...initialValues,
      eta: dayJS(rowData.eta),
      etd: dayJS(rowData.etd)
    });
    setModalState(true);
    setSelPointer(pointer);
    setModalType("order");
    setSelectedFormCol(
      BulkColumns(
        {
          hubs,
          points,
          optionsDestDeliveryType,
          optionHubPoint,
          optionHubPointDest,
          optionsOrigDeliveryType,
        },
        {
          handleColDelete,
          handleEditModal,
        }
      )
    );
    setModalTitle("Order Information");
    setBtnText("Edit")
  };
  const handleEditSRModal = (rowData) => {
    const { key, pointer } = rowData;

    const initialValues = {};
    Object.values(DATA_FIELD).forEach((item) => {
      initialValues[item] = rowData[item] !== undefined ? rowData[item] : undefined;
    });

    form.setFieldsValue(initialValues);

    setModalState(true);
    setSelPointer(pointer);
    setModalType(key);
    setSelectedFormCol(SRColumns({ optionsState, handleEditSRModal }));
    setModalTitle(`${CapitalizeFChar(key)} Information`);
    setBtnText("Edit")
  };

  const handleEditParcelModal = (rowData) => {
    const { key, pointer } = rowData;

    form.setFieldsValue(rowData);

    setModalState(true);
    setSelPointer(pointer);
    setParcel("edit")
    setModalType(key);
    setSelectedFormCol(ParcelColumns({ handleEditParcelModal, handleAddParcelModal }));
    setModalTitle('Parcel Information');
    setBtnText("Edit")
  };

  const handleAddParcelModal = (rowData) => {
    const { key, pointer } = rowData;

    form.resetFields()
    setModalState(true);
    setSelPointer(pointer);

    const maxKey = Math.max(...parcelInfo(rowData).map((data) => data.key), 0);
    setModalType(maxKey + 1);
    setParcel("add")
    setSelectedFormCol(ParcelColumns({ handleEditParcelModal, handleAddParcelModal, handleParcelDelete }));
    setModalTitle('Parcel Information');
    setBtnText("Add")
  };

  const handleParcelDelete = (record) => {
    const { key, pointer } = record

    const parcelData = parcelInfo(record)


    const newSrcData = srcData.map((sd) => {
      let nd = JSON.parse(JSON.stringify(sd));
      const { pointer: sdPointer } = sd;
      if (sdPointer === pointer) {
        nd.parcel.unsort = parcelData.filter((sd, index) => index !== key);
      }
      return nd;
    });

    setSrcData(newSrcData);
  };

  const parcelInfo = (rowData) => {
    const filteredData = srcData
      .filter(s => s.pointer === rowData.pointer && s.parcel && s.parcel.unsort);

    if (filteredData.length > 0) {
      const resultArray = filteredData.map(({ parcel }) => Object.entries(parcel.unsort || {}).map(([key, value]) => ({ key: Number(key), pointer: rowData.pointer, ...value })));
      return resultArray.flat();
    } else {
      return [];
    }
  };
  const handleModalSubmit = () => {
    setModalLoading(true);

    let newData;
    const formData = form.getFieldsValue();

    if (parcel && parcel === "edit") {
      newData = {
        [modalType]: formData,
      }
    }

    const newSrcData = srcData.map((sd) => {
      let nd = JSON.parse(JSON.stringify(sd));
      const { pointer } = sd;

      if (pointer === selPointer) {
        if (modalType === "order") {
          nd = {
            ...nd,
            ...formData,
            eta: dayJSMilliSecond(formData.eta),
            etd: dayJSMilliSecond(formData.etd)
          }
          if (parcelImg && parcelImg[pointer]) {
            nd.parcel = {
              ...nd.parcel,
              img: parcelImg[pointer],
            };
          }
        } else if (modalType === "sender" || modalType === "receiver") {
          nd[modalType] = formData;
        } else {
          if (parcel && parcel === "add") {
            nd.parcel.unsort = [...Object.values(nd.parcel.unsort), formData];
          } else if (parcel && parcel === "edit") {
            const updatedParcelUnsort = nd.parcel.unsort.map((item, index) => {
              if (index === modalType) {
                return { ...item, ...formData }
              }
              return item;
            });

            nd.parcel.unsort = updatedParcelUnsort;
          }
        }
      }

      return nd;
    });

    setSrcData(newSrcData);
    setModalLoading(false);

    if (parcelImg) {
      setModalImage(false)
    }
    setModalState(false)
  };

  const getExpandedRowRender = (rowData) => {

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",

          flex: 1,
          backgroundColor: "#e8e8e8",
        }}
      >
        <div
          style={{
            marginTop: 10,
            marginLeft: 30,
            marginRight: 30,
            marginBottom: 10,
          }}
        >
          <Tabs
            items={[
              {
                key: 0,
                label: "Parcel",
                children: (
                  <Table
                    dataSource={parcelInfo(rowData)}
                    columns={ParcelColumns({ handleEditParcelModal, handleAddParcelModal, handleParcelDelete })}
                  />
                ),
              },
              {
                key: 1,
                label: "Sender",
                children: (
                  <Table
                    dataSource={[
                      {
                        key: "sender",
                        pointer: rowData.pointer,
                        ...rowData.sender,
                      },
                    ]}
                    columns={SRColumns({ optionsState, handleEditSRModal })}
                  />
                ),
              },
              {
                key: 2,
                label: "Receiver",
                children: (
                  <Table
                    dataSource={[
                      {
                        key: "receiver",
                        pointer: rowData.pointer,
                        ...rowData.receiver,
                      },
                    ]}
                    columns={SRColumns({ optionsState, handleEditSRModal })}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Spin spinning={loading}>
        <>
          {srcData.length > 0 && (
            <>
              <div className={classes.cardSummaryBulk}>
                <div className={classes.square}>
                  <span>SELECT SUMMARY</span>
                </div>
                <div className={classes.totalSummary}>
                  <span style={{ marginRight: 7 }}>
                    TOTAL ORDERS: {srcData.length}
                  </span>
                  <span style={{ marginRight: 7 }}>
                    TOTAL WEIGHT (kg): {totalWeight}
                  </span>
                  <span>
                    &nbsp;TOTAL VOLUME (m3): {totalVolume}
                    &nbsp;
                  </span>
                </div>
              </div>
              <Table
                className={classes.tableBulk}
                dataSource={srcData.map((sd, index) => ({ key: index, ...sd }))}
                columns={BulkColumns(
                  {
                    hubs,
                    points,
                    optionsDestDeliveryType,
                    optionsOrigDeliveryType,
                  },
                  {
                    handleColDelete,
                    handleEditModal,
                    handleModalUpload
                  }
                )}
                expandable={{
                  rowExpandable: () => true,
                  expandedRowRender: (record) => getExpandedRowRender(record),
                }}
                pagination={false}
                scroll={{ y: 200, x: 2100 }}
              />
            </>
          )}
        </>
      </Spin>
      <OrderModal
        open={modalState}
        form={form}
        title={modalTitle}
        loading={modalLoading}
        handleModalEdit={handleModalSubmit}
        handleModalCancel={handleModalCancel}
        items={selectedFormCol}
        btnText={btnText}
      />
      <ModalUpload
        open={modalImage}
        form={form}
        loading={modalLoading}
        handleModalSubmit={handleModalSubmit}
        handleModalUploadCancel={handleModalUploadCancel}
        setParcelImg={setParcelImg}
        items={selectedUploadCol}
        pointer={selPointer}
      />
    </>
  );
};

export default BulkTable;
