import { Drawer, Form, Input, message } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from 'react';
import { useDispatch} from "react-redux";

import { BtnSubmit } from "../../../../components";

// Redux actions 
import * as REDUX_ACTION from "../../services/redux/actions";

import classStyles from "./index.module.css";

const DrawerChangePass = (props) => {
  const { isOpen, setIsOpen, data, page, loading } = props

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [ isPasswordChanged, setIsPasswordChanged ] = useState(false)

  const handleSave = () => {
    const formData = { ...data, ...form.getFieldsValue() };
    const password = form.getFieldValue('password')
    const uid = data.uid

    messageApi.open({
      type: "loading",
      content: "Updating...",
      duration: 0,
    });

    page === "customer" && dispatch(REDUX_ACTION.v1_customerList.customer_change_password_request(formData, setIsPasswordChanged, () => messageApi.destroy())) ||
    page === "operator" && dispatch(REDUX_ACTION.v1_customerList.user_list_change_password_request({uid, password}, setIsPasswordChanged, () => messageApi.destroy()))
   
    // setIsOpen(false);
  };

  const ActionButton = (
    <Form.Item style={{ paddingTop: "20px" }}>
      <div>
        <BtnSubmit
          display="inline-block"
          text="Save password"
          bgColor="var(--themeBackgroundColor)"
          color="var(--creamColor)"
          padding="9px 18px"
          onClick={handleSave}
          loading={loading}
        />
        <BtnSubmit
          display="inline-block"
          text="Cancel"
          bgColor="transparent"
          color="var(--bs-gray-600)"
          padding="9px 18px"
          onClick={() => {
            isPasswordChanged && setIsPasswordChanged(false)
            setIsOpen(false);
          }}
        />
      </div>
    </Form.Item>
  );

  const ChangePasswordForm = () => {
    return (
      <Form
      form={form}
      layout="vertical"
      style={{textAlign: 'left'}}
    >

    <Form.Item
      name="password"
      label={<span>New Password</span>}
      rules={[
        {
          required: true,
          message: "Password is required"
        },
        {
          required: true,
          message: "Password must be 8 in length",
          min: 8,
        },
      ]}
      hasFeedback
      
    >
      <Input.Password className={classStyles.inputField} />
    </Form.Item>
    <Form.Item
      name="confirmPassword"
      label={<span>Confirm Password</span>}
      rules={[
        {
          required: true,
          message: "Password is required"
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue("password") === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error("Confirm password doesnt match password!")
            );
          },
        }),
      ]}
      hasFeedback
      
    >
      <Input.Password className={classStyles.inputField} />
    </Form.Item>
      </Form>
    )
  }

  const ChangePasswordSuccess = () => {
    const {name, memid} = data
    return (
      <div style={{display: 'flex', height: "100%", justifyContent: 'center', alignItems: 'center', fontSize: '18px', margin: '0 8px'}}>
        <div style={{display: "flex", flexDirection: 'column', gap: '10px'}}>
        <CheckCircleOutlined style={{color: 'var(--themeColor)', fontSize: '30px', justifyContent: 'center'}}/>
        <b>Password Changed!</b>
        <div>The password for customer {data?.memid || '-'}/{name} has been changed successfully.</div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    setIsPasswordChanged(false)
    form.setFieldsValue({ password: '', confirmPassword: ''})
  }, [!isOpen])

  return (
    <>
    {contextHolder}
    <Drawer
      title="Change Password"
      placement="right"
      closable={false}
      width={410}
      onClose={() => setIsOpen(false)}
      footer={!isPasswordChanged && ActionButton}
      open={isOpen}
      style={{
        textAlign: "center",
        fontSize: "20px",
      }}
    >
      {isPasswordChanged ? 
        <ChangePasswordSuccess />
        :
        <ChangePasswordForm />
      }
    </Drawer>
    </>
  );
};

export default DrawerChangePass;
