import { useState } from "react";
import { Upload, Button, message } from "antd";
import { PaperClipOutlined } from '@ant-design/icons'

// Handler
import { uploadHDLR } from "../../../handlers";

// Constants 
import { ROOT_CONSTANT } from "../../../constants"

// Styles
import classStyles from "../index.module.css"

const {
    icon_clip
} = ROOT_CONSTANT.IMG.ICON.MODAL

const { 
    getBase64, 
    put2AssetS3OPCommentAttachmentRequest 
} = uploadHDLR;

const CommentAttachment = ({ 
    value,
    setValue
}) => {
    const [loading, setLoading] = useState(false);

    const validateFileType = (file) => {
        const fileTypes = [
            "image/jpeg",
            "image/jpg",
            "image/png",
            "application/pdf",
        ];
        return fileTypes.includes(file.type);
    };

    const handleBeforeUpload = (file) => {

        if(!validateFileType(file)) {
            message.error(`File type ${file.type} is not supported.`);
            return false;
        } else {
            return true;
        }
    }

    const handleChange = (info) => {
        const { name, status, originFileObj, response = {} } = info.file;

        if (status === "uploading") {
            setLoading(true);
            return;
        }
        if (status === "done") {
            getBase64(originFileObj, () => {
                const { Location: url } = response;
                setLoading(false);
                setValue({...value, url})
            });
        }
    }

    return (
        <>
            <Upload
                beforeUpload={handleBeforeUpload}
                onChange={handleChange}
                showUploadList={false}
                customRequest={put2AssetS3OPCommentAttachmentRequest}
                loading={loading}
            >
                <img src={icon_clip} alt="attachment" className={classStyles["attachment-img"]} />
            </Upload>

            {/* 
                temporary solution until i get better solution.  
                
                this meant to fix the bug where the uploaded attachment
                keep attached in Upload component antd
            */}
            {value.url && 
                <span style={{ fontSize: '15px', color: 'var(--themeColor)', display: 'flex', gap: '8px', margin: '8px' }}>
                    <PaperClipOutlined />
                    <a href={value.url} target="_blank">{value.url.length > 50 ? value.url.substr(0, 50)+"..." : value.url}</a>
                    <button onClick={() => setValue({...value, url: ''})} style={{backgroundColor: 'transparent'}}>x</button>
                </span>
            }
        </>
    )
}

export default CommentAttachment
