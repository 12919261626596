import { combineReducers } from "redux";

// V1 Reducer
import { billing } from "./billing";
import { hub } from "./hub";
import { point } from "./point";
import { order } from "./order";
import { orderLog } from "./orderLog";
import { orderState } from "./orderState";
import { orderStatus } from "./orderStatus";
import { transaction } from "./transaction";
import { style } from "./style";
import { user } from "./user";
import { userRoles } from "./userRoles";
import { parkedOrder } from "./parkOrder";
import { wallet } from "./wallet";
import { journalNote } from "./journalNote";
import { accountManager } from "./accountManager";
import { customer } from "./customer";
import { hubOperator } from "./hubOperator"
import { pointOperator } from "./pointOperator"
import {zone} from "./zone"
import {report} from "./report"
import {vehicle} from "./vehicle"

export const v1Reducer = combineReducers({
  billing,
  hub,
  point,
  order,
  orderLog,
  orderState,
  orderStatus,
  style,
  transaction,
  user,
  userRoles,
  parkedOrder,
  wallet,
  journalNote,
  accountManager,
  customer,
  hubOperator,
  pointOperator,
  zone,
  report,
  vehicle,
});
