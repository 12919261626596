// Third Party Library 
import { useState } from "react"
import { Modal, Button, DatePicker, Input, message } from "antd"

// Components 
import StatusUpdateAttachment from "./StatusUpdateAttachment"
import ModalStandard from "../ModalStandard"

// Constants 
import { ROOT_CONSTANT, TEMPLATE } from "../../../../constants"

// Services
import { TIME } from "../../../../services/util"

import './index.css'
import classes from "../ModalStatusUpdate/"

const { 
    icon_clip
} = ROOT_CONSTANT.IMG.ICON.MODAL

const { MODALS } = TEMPLATE.MODAL;

const {
    currentTimeMilliSecond,
    dayJSMilliSecond,
    dayJS
} = TIME

const ModalStatusUpdate = ({
    setmodal, 
    isModalOpen,
    setIsModalOpen,
    onClick,
    extra,
    data
}) => {
    const newSetmodal = "confirmation_" + setmodal

    // value initialization 
    const [ isModalConfirmOpen, setIsModalConfirmOpen ] = useState(false)
    const [ statusinitiatedAttachment, setStatusinitiatedAttachment ] = useState(data && data.attachment || []) 
    const [ val, setVal ] = useState({
        remark: (data && data.remark) || ''
    })
    const [ edit, setEdit] = useState(false)

    const content =
    (extra &&
        Object.keys(extra).length &&
        MODALS[setmodal].content && MODALS[setmodal].content(extra)) ||
        MODALS[setmodal].content && MODALS[setmodal].content;

    // show the Modal Confirmation if Ok button in ModalStatusUpdate clicked 
    const confirmOkModalHDLR = () => {
        if(!data) {
            isOk()
            setIsModalOpen(false)
        } else {
            setIsModalConfirmOpen(true)
            setEdit(false)
            setIsModalOpen(false)
        }
    }

    // run Ok action (what will ok do). it will passed the Ok action together with its value
    const isOk = () => {
        onClick({
            remark: val?.remark,
            initiatedAt: dayJSMilliSecond(val?.initiatedAt),
            attachment: statusinitiatedAttachment
        })
    }
    
    // the Ok Button layout. because in some action the Button will differ (Edit or Save/Proceed)
    const OkButton = () => {
        if(data && !edit) {
            return (
                <Button 
                    className="yes-button"
                    onClick={() => setEdit(true)}
                >
                    Edit
                </Button>
            )
        } else {
            return (
                <Button 
                    className="yes-button"
                    onClick={() => confirmOkModalHDLR()}
                >
                    {data ? "Save": 'Yes, Proceed'}
                </Button>
            )
        }
    }

    const checkInitiatedTimeHDLR = (e) => {
        if (e > dayJS(currentTimeMilliSecond())) {
            message.warning("declared initiated time must be lower than the current time")
        } 
        else if (data && e <= data.initiatedAt) {
            message.warning("declared initiated time must be higher than the previous initiated time")
        } 
        else {
            setVal({ ...val, initiatedAt: e})
        }
    } 

    // Attributes for Components 
    const datePickerAttr = {
        showTime: true, 
        placeholder: "Now",
        format: "YYYY-MM-DD HH:mm", 
        size: "large",
        style: {width: "100%", marginBottom: "20px"},
        value: val.initiatedAt ? dayJS(val.initiatedAt) : data && dayJS(data.initiatedAt) || null,
        onChange: e => setVal({ ...val, initiatedAt: e}),
        defaultValue: data && data.initiatedAt ? dayJS(data.initiatedAt) : null,
        disabled: data && !edit,
    }
    const textAreaRemarkAttr = {
        name: "remark",
        placeholder: "Add status remark",
        onChange: e => setVal({ ...val, remark: e.target.value}),
        autoSize: {
            minRows: 3,
            maxRows: 4,
        },
        defaultValue: data && data.remark ? data && data.remark : null,
        disabled: data && !edit
    }

    return (
        <>
            <ModalStandard 
                setmodal={newSetmodal}
                isModalOpen={isModalConfirmOpen}
                setIsModalOpen={() => setIsModalConfirmOpen(false)}
                onClick={(val) =>  isOk(val)}
                extra={extra}
            />
            <Modal 
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
                width={450}
            >
                <div className="content-container">
                    <img src={MODALS[setmodal].img} alt="" />
                    <h3>{MODALS[setmodal].title}</h3><br />
                    <p className={classes.textCard}>{content}</p>
                </div>

                <div className="inputs">
                    <DatePicker 
                        {...datePickerAttr}
                    />
                    <Input.TextArea 
                        {...textAreaRemarkAttr}
                    />
                    <div className="textarea">
                        <Input.TextArea
                             autoSize= {{
                                minRows: 6,
                                maxRows: 10,
                            }}
                        />
                        <StatusUpdateAttachment 
                            setStatusUpdatedAttachment={setStatusinitiatedAttachment}
                            statusinitiatedAttachment={statusinitiatedAttachment}
                            icon_clip={icon_clip}
                            data={data}
                            edit={edit}
                        />
                    </div>
                </div>

                <div className="buttons">
                    <OkButton />
                    <Button 
                        className="no-button"
                        onClick={setIsModalOpen}
                    >
                        {data ? 'Cancel' : 'No, Back to List'}
                    </Button>
                </div>
                
            </Modal>
        </>
    )
}

export default ModalStatusUpdate
