
import boxIcon from "../../../../../assets/images/box.svg";
import docIcon from "../../../../../assets/images/doc.svg";

// import { useSelector} from "react-redux";
import { REDUX as REDUX_UTIL, TIME } from "../../../services/util";

// const { reduxStoreWrapper } = REDUX_UTIL

// const hubs = useSelector(reduxStoreWrapper("hub.byID"));

export const DATA_FIELD = {
  memid: "memid",
  origDeliveryType: "origDeliveryType",
  destDeliveryType: "destDeliveryType",
  deliveryType: "deliveryType",
  add_on: "add_on",
  parcelType: "parcelType",
  parcel_quantity: "parcel_quantity",
  parcel_weight: "parcel_weight",
  parcel_length: "parcel_length",
  parcel_width: "parcel_width",
  parcel_height: "parcel_height",
  parcel_desc: "parcel_desc",
  parcel_remark: "parcel_remark",
  origpid: "origpid",
  origid: "origid",
  dtd: "dtd",
  etd: "etd",
  sender_name: "sender_name",
  sender_contact: "sender_contact",
  sender_email: "sender_email",
  sender_address: "sender_address",
  sender_postcode: "sender_postcode",
  sender_city: "sender_city",
  sender_state: "sender_state",
  sender_remark: "sender_remark",
  destpid: "destpid",
  dstid: "dstid",
  dst_detail: "dst_detail",
  orig_detail: "orig_detail",
  dta: "dta",
  eta: "eta",
  receiver_name: "receiver_name",
  receiver_contact: "receiver_contact",
  receiver_email: "receiver_email",
  receiver_address: "receiver_address",
  receiver_postcode: "receiver_postcode",
  receiver_city: "receiver_city",
  receiver_state: "receiver_state",
  receiver_remark: "receiver_remark",
  priceInfo_pickup: "priceInfo_pickup",
  priceInfo_h2h: "priceInfo_h2h",
  priceInfo_dropoff: "priceInfo_dropoff",
  priceInfo_addon: "priceInfo_addon",
  price: "price",
  price_sort: "priceSort",
  updatedBy: "updatedBy",
  state: "state",
  sorted_weight: "parcel_sort_weight",
  sorted_length: "parcel_sort_length",
  sorted_width: "parcel_sort_width",
  sorted_height: "parcel_sort_height",
  sorted_pricePickup: "priceInfo_sort_pickup",
  sorted_priceH2h: "priceInfo_sort_h2h",
  sorted_priceDropoff: "priceInfo_sort_dropoff",
  sorted_priceAddon: "priceInfo_sort_addon",
  parcel: "parcel",
  origin: "origin",
  destination: "destination",
  sender: "sender",
  receiver: "receiver",
  name: "name",
  email: "email",
  contact: "contact",
  address:"address",
  postcode: "postcode",
  city: "city",
  attachment:"img",
  remark: "remark"
};

export const ORDER_DATA_TITLE = {
  [DATA_FIELD.memid]: "Customer ID*",
  [DATA_FIELD.origDeliveryType]: "Origin Delivery Instruction*",
  ORIGIN_INFORMATION: "Origin Delivery Instruction",
  [DATA_FIELD.origid]: "Origin Hub/ Point*",
  [DATA_FIELD.etd]: "DTD*",
  DESTINATION_INFORMATION: "Destination Delivery Instruction",
  [DATA_FIELD.destDeliveryType]: "Destination Delivery Instruction*",
  [DATA_FIELD.dstid]: "Destination Hub/ Point*",
  [DATA_FIELD.eta]: "DTA*",
  [DATA_FIELD.add_on]: "Additional Delivery Service",
  [DATA_FIELD.parcel_remark]: "Parcel Remarks",
  // [DATA_FIELD.parcelType]: "Parcel Type",
  PARCEL_DIMENSION: "Parcel Dimension",
  [DATA_FIELD.parcel_quantity]: "Quantity (Pieces)*",
  [DATA_FIELD.parcel_weight]: "Parcel Weight (kg)*",
  [DATA_FIELD.parcel_length]: "Parcel Length (cm)*",
  [DATA_FIELD.parcel_width]: "Parcel Width (cm)*",
  [DATA_FIELD.parcel_height]: "Parcel Height (cm)*",
  [DATA_FIELD.priceInfo_pickup]: "Pickup Price (RM)",
  [DATA_FIELD.priceInfo_h2h]: "Hub to Hub Price (RM)",
  [DATA_FIELD.priceInfo_dropoff]: "Drop Off Price (RM)",
  [DATA_FIELD.priceInfo_addon]: "Add On Service Price (RM)",
  [DATA_FIELD.price]: "Declared Value (RM)",
  SENDER_INFORMATION: "Sender Information",
  [DATA_FIELD.sender_name]: "Sender Name*",
  [DATA_FIELD.sender_contact]: "Sender Contact*",
  [DATA_FIELD.sender_email]: "Sender Email",
  [DATA_FIELD.sender_address]: "Sender Address*",
  [DATA_FIELD.sender_postcode]: "Sender Postcode*",
  [DATA_FIELD.sender_city]: "Sender City*",
  [DATA_FIELD.sender_state]: "Sender State*",
  [DATA_FIELD.sender_remark]: "Sender Remarks",
  RECEIVER_INFORMATION: "Receiver Information",
  [DATA_FIELD.receiver_name]: "Receiver Name*",
  [DATA_FIELD.receiver_contact]: "Receiver Contact*",
  [DATA_FIELD.receiver_email]: "Receiver Email",
  [DATA_FIELD.receiver_address]: "Receiver Address*",
  [DATA_FIELD.receiver_postcode]: "Receiver Postcode*",
  [DATA_FIELD.receiver_city]: "Receiver City*",
  [DATA_FIELD.receiver_state]: "Receiver State*",
  [DATA_FIELD.receiver_remark]: "Receiver Remarks",
  [DATA_FIELD.attachment] : "Images",
};

export const ACTIONS_LABEL = {
  process_order: "process_order",
  sort_receiver_order: "sort_receiver_order",
  assign_to_manifest: "assign_to_manifest",
  receiver_by_receiver: "receiver_by_receiver",
  park_order: "park_order",
  cancel_order: "cancel_order",
  print_shipment_order: "print_shipment_order",
};

export const ACTIONS_LABEL_TITLE = {
  [ACTIONS_LABEL.process_order]: "Proceed Order",
  [ACTIONS_LABEL.sort_receiver_order]: "Sort / Received Order",
  [ACTIONS_LABEL.assign_to_manifest]: "Assign to Manifest",
  [ACTIONS_LABEL.receiver_by_receiver]: "Receiver by Receiver",
  [ACTIONS_LABEL.park_order]: "Park Order",
  [ACTIONS_LABEL.cancel_order]: "Cancel Order",
  [ACTIONS_LABEL.print_shipment_order]: "Print Shipment Order",
};

export const ACTIONS_LABEL_ORDER_FORM = {
  [ACTIONS_LABEL.process_order]: "Proceed Order",
  [ACTIONS_LABEL.sort_receiver_order]: "Sort Order",
};

export const ORDER_UPDATE_NECESSARY_FIELD = [
  DATA_FIELD.memid,
  DATA_FIELD.origDeliveryType,
  DATA_FIELD.origid,
  DATA_FIELD.etd,
  DATA_FIELD.destDeliveryType,
  DATA_FIELD.dstid,
  DATA_FIELD.eta,
  DATA_FIELD.sender,
  DATA_FIELD.receiver,
  DATA_FIELD.parcel
];

export const ORDER_UPDATE_NECESSARY_SR_FIELD = [
  DATA_FIELD.name,
  DATA_FIELD.contact,
  DATA_FIELD.address,
  DATA_FIELD.postcode,
  DATA_FIELD.city,
  DATA_FIELD.state,
];

export const ORDER_UPDATE_NECESSARY_PARCEL_FIELD = [
  'weight',
  'height',
  'length',
  'width',
  'qty',
];

export const ORDER_DATA_TITLE_BY_NAME = Object.fromEntries(
  Object.keys(ORDER_DATA_TITLE).map((k) => [ORDER_DATA_TITLE[k], k])
);

export const ORDER_DATA_TITLE_SPLIT_SPECIAL_ARR = [DATA_FIELD.add_on];
export const ORDER_DATA_DATE_SPECIAL_HDLR = [DATA_FIELD.eta, DATA_FIELD.etd];
export const ORDER_DATA_HUB = [DATA_FIELD.origid, DATA_FIELD.dstid];

export const DATA_FIELD_VALUES = {
  [DATA_FIELD.deliveryType]: {
    h2h: "Hub to Hub",
    h2d: "Hub to Hub",
    p2p: "Point to Point",
  },
  [DATA_FIELD.add_on]: {
    "svc#dpu": "Doorstep Pick Up",
  },
  [DATA_FIELD.parcelType]: {
    "001": { src: boxIcon, name: "Box" },
    "002": { src: docIcon, name: "Document" },
  },
  [DATA_FIELD.destDeliveryType]: {
    h2h: "Pick up at hub",
    h2d: "Doorstep drop off",
    p2p: "Pick up at point",
  },
  [DATA_FIELD.origDeliveryType]: {
    "emp1": "Drop off at hub",
    "svc#dpu": "Doorstep pick up",
    "emp2": "Drop off at point",
  },
};

export const FILE2ORDER_DATA_CONVERTER = (hubs, points, states) => ({
  [DATA_FIELD.origid]: (code) =>
    Object.keys(hubs).find((hubid) => hubs[hubid].code === code) ? 
    Object.keys(hubs).find((hubid) => hubs[hubid].code === code) :
    Object.keys(points).find((hubid) => points[hubid].code === code),
  [DATA_FIELD.dstid]: (code) =>
    Object.keys(hubs).find((hubid) => hubs[hubid].code === code) ? 
    Object.keys(hubs).find((hubid) => hubs[hubid].code === code) :
    Object.keys(points).find((hubid) => points[hubid].code === code),
  // [DATA_FIELD.add_on]: (name) =>
  //   Object.keys(DATA_FIELD_VALUES[DATA_FIELD.add_on]).find(
  //     (ao) => DATA_FIELD_VALUES[DATA_FIELD.add_on][ao] === name
  //   ),
  // [DATA_FIELD.deliveryType]: (name) =>
  //   Object.keys(DATA_FIELD_VALUES[DATA_FIELD.deliveryType]).find(
  //     (dt) => DATA_FIELD_VALUES[DATA_FIELD.deliveryType][dt] === name
  //   ),
    [DATA_FIELD.origDeliveryType]: (name) =>
    Object.keys(DATA_FIELD_VALUES[DATA_FIELD.origDeliveryType]).find(
      (ao) => DATA_FIELD_VALUES[DATA_FIELD.origDeliveryType][ao] === name
    ),
  [DATA_FIELD.destDeliveryType]: (name) =>
    Object.keys(DATA_FIELD_VALUES[DATA_FIELD.destDeliveryType]).find(
      (dt) => DATA_FIELD_VALUES[DATA_FIELD.destDeliveryType][dt] === name
    ),
  [DATA_FIELD.parcelType]: (name) =>
    Object.keys(DATA_FIELD_VALUES[DATA_FIELD.parcelType]).find(
      (pt) =>
        DATA_FIELD_VALUES[DATA_FIELD.parcelType][pt] &&
        DATA_FIELD_VALUES[DATA_FIELD.parcelType][pt].name === name
    ),
  [DATA_FIELD.sender_state]: (name) =>
    Object.keys(states).find((code) => states[code].name === name),
  [DATA_FIELD.receiver_state]: (name) =>
    Object.keys(states).find((code) => states[code].name === name),
});

export const DATA_FIELD_TITLE = {
  [DATA_FIELD.deliveryType]: {
    h2h: "Sender self drop-off at hub and receiver self collect from hub.",
    h2d: "Sender self-drop off at hub and parcel delivery right to your receiver’s doorstep.",
  },
};

export const STATE_TITLE = {
  JOHOR: "Johor",
  KEDAH: "Kedah",
  KELANTAN: "Kelantan",
  KUALA_LUMPUR: "WP Kuala Lumpur",
  SELANGOR: "Selangor",
  LABUAN: "WP Labuan",
  MELAKA: "Melaka",
  NEGERI_SEMBILAN: "Negeri Sembilan",
  PAHANG: "Pahang",
  PENANG: "Penang",
  PERAK: "Perak",
  PERLIS: "Perlis",
  PUTRAJAYA: "WP Putrajaya",
  SABAH: "Sabah",
  SARAWAK: "Sarawak",
  TERENGGANU: "Terengganu"
}

export const STATE = {
  [STATE_TITLE.JOHOR]: {
    name: "Johor",
    code: "JHR",
  },
  [STATE_TITLE.KEDAH]: {
    name: "Kedah",
    code: "KDH",
  },
  [STATE_TITLE.KELANTAN]: {
    name: "Kelantan",
    code: "KTN",
  },
  [STATE_TITLE.KUALA_LUMPUR]: {
    name: "WP Kuala Lumpur",
    code: "KUL",
  },
  [STATE_TITLE.SELANGOR]: {
    name: "Selangor",
    code: "SGR",
  },
  [STATE_TITLE.LABUAN]: {
    name: "WP Labuan",
    code: "LBN",
  },
  [STATE_TITLE.MELAKA]: {
    name: "Melaka",
    code: "MLK",
  },
  [STATE_TITLE.NEGERI_SEMBILAN]: {
    name: "Negeri Sembilan",
    code: "NSN",
  },
  [STATE_TITLE.PAHANG]: {
    name: "Pahang",
    code: "PHG",
  },
  [STATE_TITLE.PENANG]: {
    name: "Penang",
    code: "PNG",
  },
  [STATE_TITLE.PERAK]: {
    name: "Perak",
    code: "PRK",
  },
  [STATE_TITLE.PERLIS]: {
    name: "Perlis",
    code: "PLS",
  },
  [STATE_TITLE.PUTRAJAYA]: {
    name: "WP Putrajaya",
    code: "PJY",
  },
  [STATE_TITLE.SABAH]: {
    name: "Sabah",
    code: "SBH",
  },
  [STATE_TITLE.SARAWAK]: {
    name: "Sarawak",
    code: "SWK",
  },
  [STATE_TITLE.TERENGGANU]: {
    name: "Terengganu",
    code: "TRG",
  },
};

export const STATE_BYID = Object.fromEntries(
  Object.values(STATE).map((state) => [
    state.code, 
    { name: state.name, code: state.code },
  ])
);

export const STATE_EX = {
  Others: {
    name: "Others",
    code: "OTH",
  },
};

export const STATE_WITH_EX = {
  ...STATE,
  ...STATE_EX,
};

export const STATE_NAME = Object.fromEntries(
  Object.keys(STATE_WITH_EX).map((k) => [k, k])
);

export const HUBS = {
  OTHERS: {
    hubid: "hub#others",
    name: "Others",
    code: "OTH",
    biz_hrs: {
      day: "*",
      time: "*",
    },
  },
};

export const ORDER_DATA_VALUES = (hubs) => ({
  [DATA_FIELD.deliveryType]: {
    "Hub to Hub": "h2h",
    "Hub to Doorstep": "h2d",
  },
  [DATA_FIELD.add_on]: Object.fromEntries(
    Object.entries(DATA_FIELD_VALUES[DATA_FIELD.add_on]).map(([key, value]) => [
      value,
      key,
    ])
  ),
  [DATA_FIELD.parcelType]: Object.fromEntries(
    Object.entries(DATA_FIELD_VALUES[DATA_FIELD.parcelType]).map(
      ([key, value]) => [value.name, key]
    )
  ),
  [DATA_FIELD.origid]: Object.fromEntries(
    Object.values(hubs).map((v) => [v.code, v.hubid])
  ),
  [DATA_FIELD.dstid]: Object.fromEntries(
    Object.values(hubs).map((v) => [v.code, v.hubid])
  ),
  [DATA_FIELD.state]: Object.fromEntries(
    Object.values(STATE).map((v) => [v.name, v.code])
  ),
})

export const HUBS_CODE = (hubs) => Object.fromEntries(
  Object.keys(hubs).map((k) => [k.code, k.code])
);

export const HUBS_HUBID = (hubs) => Object.fromEntries(
  Object.values(hubs).map((v) => [v.hubid, v])
);

export const HUBS_BY_STATE_ORG = {
  [STATE_NAME.JOHOR]: [HUBS_CODE.SPT, HUBS_CODE.JHJ],
  [STATE_NAME.PENANG]: [HUBS_CODE.BTW],
  [STATE_NAME.PERAK]: [HUBS_CODE.AMJ],
  [STATE_NAME.KUALA_LUMPUR]: [HUBS_CODE.TBS, HUBS_CODE.DUT],
};

// export const HUBS_BY_STATE = {
//   [STATE_NAME.JOHOR]: [HUBS_CODE.SPT, HUBS_CODE.JHJ],
//   [STATE_NAME.PENANG]: [HUBS_CODE.BTW],
//   [STATE_NAME.PERAK]: [HUBS_CODE.AMJ],
//   [STATE_NAME.KUALA_LUMPUR]: [HUBS_CODE.TBS, HUBS_CODE.DUT],
// };

// export const options = (hubs) => hubs.reduce((acc, s) => {
//   if (!acc[s.state]) {
//     acc[s.state] = {
//       name: STATE[s && s.state].name,
//       code: STATE[s && s.state].code,
//     };
//   }
//   return acc;
// }, {});

export const HUBS_BY_STATE = (hubs) => hubs.reduce((acc, h) => {
  const state = h.state;

  if (!acc[state]) {
    acc[state] = [];
  }

  const existingHub = acc[state].find((hub) => hub.hubid === h.hubid);

  if (!existingHub) {
    acc[state].push({
      status: h.status,
      code: h.code,
      name: h.name,
      hubid: h.hubid,
    });
  }

  return acc;
}, {});

export const SpecialFieldHandler = ["parcel", "sender", "receiver", "priceInfo"];
export const SpecialFieldHandlerProceed = ["receiver", "sender"];
export const SpecialFieldHandlerSort = ["parcel", "priceInfo"];

export const PRICING_LIST = {
  columns: [
    {
      title: "L + W + H",
      key: "lwh",
      dataIndex: "lwh",
      align: "center",
    },
    {
      title: "Price (RM)",
      key: "price",
      dataIndex: "price",
      align: "center",
    },
  ],
  data: [
    {
      key: 1,
      lwh: "0 - 120",
      price: "19",
    },
    {
      key: 2,
      lwh: "121 -130",
      price: "29",
    },
    {
      key: 3,
      lwh: "131 - 135",
      price: "49",
    },
    {
      key: 4,
      lwh: "136 - 140",
      price: "69",
    },
    {
      key: 5,
      lwh: "141 - 145",
      price: "79",
    },
    {
      key: 6,
      lwh: "146 - 150",
      price: "89",
    },
    {
      key: 7,
      lwh: "151 - 155",
      price: "109",
    },
    {
      key: 8,
      lwh: "156 - 160",
      price: "129",
    },
  ],
};

export const ORDER_STATUS = {
  SUBMITTED: "O0100",
  PENDING_SELF_LODGE: "O0201",
  PENDING_PICK_UP_FROM_CUSTOMER: "O0202",
  PENDING_SELF_LODGE_POINT: "O1200",
  CHECK_OUT_FROM_POINT: "O0203",
  ARRIVED_AT_ORIGIN_HUB: "O0300",
  ARRIVED_AT_ORIGIN_POINT: "O1300",
  DEPARTED_FROM_ORIGIN_HUB: "O0400",
  PENDING_SELF_COLLECTION: "O0501",
  PENDING_SELF_COLLECTION_POINT: "O1500",
  PENDING_DOORSTEP_DELIVERY: "O0502",
  ARRIVED_AT_DEST_HUB: "O0503",
  PENDING_OUTBOUND_TO_POINT:'O1400',
  FULFILLED: "O0600",
  COMPLETED: "O2000",
  CLOSED: "O4100",
  PARKED: "O4200",
  CANCELLED: "O4300",
};

export const inTransit = [
 "O0203", "O0300", "O0400", "O0503", "O1300", "O1400"
]

export const PICKUP_ORDER_ON = Object.fromEntries(
  Object.entries(ORDER_STATUS).filter(([key, value]) => {
    return value !== ORDER_STATUS.PENDING_SELF_LODGE &&
           value !== ORDER_STATUS.PENDING_SELF_COLLECTION;
  })
);
 
export const PICKUP_ORDER_OFF = Object.fromEntries(
  Object.entries(ORDER_STATUS).filter(([key, value]) => {
    return value !== ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER &&
           value !== ORDER_STATUS.PENDING_DOORSTEP_DELIVERY ;
  })
);

export const GET_OPERATION_HOURS_ARR = (biz_hrs) => {
  const days = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"]

  let opHrs = []

  biz_hrs 
  &&
  days.forEach(day => {

      const opHr = {
          day,
          hour: biz_hrs.find(bz => day === TIME.getDayNamefromEpoch(bz.day))?.hour
      }
  
      opHrs.push(opHr)
  })

  return opHrs

}

export const PARCELS_TOTAL_CALCULATION = (parcels) => {

  const total_volumes = parcels.reduce((total, parcel) => {
      const parcelVolume = parcel.width * parcel.height * parcel.length;
      const parcelTotal = parcelVolume * (parcel.qty || 1) ;

      return total + parcelTotal; 
  }, 0) / 1000000 || 0

  const total_pieces = parcels.reduce((total, parcel) => {
      return total + parcel.qty;
  }, 0) || 1
  
  const total_weights = parcels.reduce((total, parcel) => {
      const parcelWeight = parcel.weight * (parcel.qty || 1);
      return total + parcelWeight;
  }, 0) || 0

  return { total_volumes, total_pieces, total_weights }

}