// React Thirty Party Library
import { useState } from "react";
import { useNavigate } from "react-router";
import { Tag, Typography, Switch } from "antd";

// Constant
import { TEMPLATE, COMMON_FEATURES, ROUTES } from "../../../constants";

// Components
import { ButtonLink } from "../../../components";
import { ButtonModal } from "../../../components";

//handlers
import { filter, reactHDLR } from "../../../handlers"

// Service
import { TIME, REDUX as REDUX_UTIL } from "../../../services/util";
import { TABLE_BILLING_INDEX, TABLE_BILLING_TITLE } from "../billing";

const {
  TABLE_VEHICLE_INDEX,
  TABLE_VEHICLE_TITLE,
  OPTION_VEHICLE_STATUS,
  OPTION_VEHICLE_STATUS_TITLE,
  OPTION_STATUS,
  OPTION_STATUS_TITLE,
} = TEMPLATE.VEHICLE_LIST; 

const { FEATURE_NAME } = COMMON_FEATURES;

const { ROUTE_URL } = ROUTES;

const { parseTimeMin } = TIME;

const { searchHDLR, filterHDLR } = filter

const { Text } = Typography;

export const VEHICLE_SUMMARY_COL = ({ func = {}, exportState = false, redux = {} }) => {
  const { handleModal, NavigateOrderSummary, handleSearch } = func;
  const [modalVisible, setModalVisible] = useState(false);

  const navigate = useNavigate();

  const toggleDrawer = () => {
    setModalVisible(!modalVisible);
  };

  return [
    {
      title: TABLE_VEHICLE_TITLE.Plate,
      key: TABLE_VEHICLE_INDEX.Plate,
      width: 180,
      fixed: "left",
      render: (record) => {
        const { plate } = record;
        return exportState ? plate : <Text>{plate} </Text>;
      },
      ...searchHDLR.getColumnSearchProps("plate", handleSearch)
    },
    {
      title: TABLE_VEHICLE_TITLE.Status,
      key: TABLE_VEHICLE_INDEX.Status,
      render: (record) => {
        const { status } = record;
        
        const statusKey = status 
        ? OPTION_VEHICLE_STATUS.Active
        : OPTION_VEHICLE_STATUS.Inactive;

        const { name, colorTag } = OPTION_VEHICLE_STATUS_TITLE[statusKey]
        return exportState ? (
          name
        ) : (
          <>
            <Tag color={colorTag} key={statusKey}>{name}</Tag>
          </>
        );
        
      },
      filters : filterHDLR.getColumnsFilterStatus(OPTION_VEHICLE_STATUS_TITLE),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: TABLE_VEHICLE_TITLE.isTracking,
      // key: TABLE_USER_INDEX.Postcode,
      width: 200,
      render: (record) => {
        const { isTracking } = record;

        return (
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            checked={isTracking === 1}
            disabled // disable switch karena hanya menampilkan nilai
          />
        );
      },
    },
    {
      title: TABLE_VEHICLE_TITLE.Driver,
      key: TABLE_VEHICLE_INDEX.Driver,
      render: (record) => {
        const { driver } = record;

        return exportState ? driver : <>{driver} </>;
      },
      ...searchHDLR.getColumnSearchProps(
        TABLE_VEHICLE_INDEX.Driver,
        handleSearch
      ),
    },
    {
      title: TABLE_VEHICLE_TITLE.Movement_Status,
      key: TABLE_VEHICLE_INDEX.Movement_Status,
      render: (record) => {
        const { cell_signal, ignition, speed } = record;

        const movementKey = 
                !cell_signal && !ignition && !speed && OPTION_STATUS.NULL || // if signal, ignition, and speed undefined = NULL (not registered at gpsfleet)
                cell_signal <= 0 && OPTION_STATUS.DISCONNECTED || //if signal is not exist = disconnected
                ignition === 0 && OPTION_STATUS.PARKING || // if engine is not on = parking
                speed === 0 && OPTION_STATUS.IDLING || // if speed = 0, mark as idling
                OPTION_STATUS.MOVING // if else is false, then its moving

        const { name, colorTag } = OPTION_STATUS_TITLE[movementKey]
        return exportState ? name :
        <>
            <Tag color={colorTag}>{name}</Tag>
          </>
      },
      filters : filterHDLR.getColumnsFilterStatus(OPTION_STATUS_TITLE),
      onFilter: (value, record) => record.movement === value,
    },
    {
      title: TABLE_VEHICLE_TITLE.Duration,
      key: TABLE_VEHICLE_INDEX.Duration,
      render: (record) => {
        const { vehicleTime } = record;

        const formatted_duration = vehicleTime && TIME.timeDiff(vehicleTime, TIME.currentTimeMilliSecond()) || ""

        return exportState ? formatted_duration : <>{formatted_duration} </>;
      },
      ...searchHDLR.getColumnSearchProps(
        TABLE_VEHICLE_INDEX.Duration,
        handleSearch
      ),
    },
    {
      title: TABLE_VEHICLE_TITLE.Speed,
      key: TABLE_VEHICLE_INDEX.Speed,
      render: (record) => {
        const { speed } = record;

        const format_speed = speed !== undefined && `${speed} km/h` || speed
        return exportState ? format_speed : <>{format_speed} </>;
      },
      ...searchHDLR.getColumnSearchProps(
        TABLE_VEHICLE_INDEX.Speed,
        handleSearch
      ),
    },
    {
      title: TABLE_VEHICLE_TITLE.Location,
      key: TABLE_VEHICLE_INDEX.Location,
      render: (record) => {
        const { location } = record;
        const loc = (
          <p>
            {location?.lat && location?.lon
              ? <>
                  {location?.lat}, {location?.lon}
                </>
              : ""}
          </p>
        );
        
        return exportState ? loc : <>{loc} </>;
      },
      ...searchHDLR.getColumnSearchProps(
        TABLE_VEHICLE_INDEX.Location,
        handleSearch
      ),
    },
    {
      title: TABLE_VEHICLE_TITLE.Remarks,
      key: TABLE_VEHICLE_INDEX.Remarks,
      render: (record) => {
        const { remark } = record;
        return exportState ? remark : <span>{remark} </span>;
      },
    },
    {
      title: TABLE_VEHICLE_TITLE.CreatedAt,
      key: TABLE_VEHICLE_INDEX.CreatedAt,
      render: (record) => {
        const { createdAt } = record;
        return exportState ? (
          parseTimeMin(createdAt)
        ) : (
          <span>{parseTimeMin(createdAt)} </span>
        );
      },
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      title: TABLE_VEHICLE_TITLE.UpdatedAt,
      key: TABLE_VEHICLE_INDEX.UpdatedAt,
      render: (record) => {
        const { updatedAt } = record;
        return exportState ? (
          parseTimeMin(updatedAt)
        ) : (
          <span>{parseTimeMin(updatedAt)} </span>
        );
      },
      sorter: (a, b) => a.updatedAt - b.updatedAt,
    },
  ];
};
