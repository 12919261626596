// Components 
import { 
    ButtonLink 
} from "../../../../components";

// Styles 
import classStyles from "../../index.module.css";

const RemarkHeader = ({
    setShowTextArea
}) => {
    return (
        <div className={classStyles["header"]}>
            <h1 className={classStyles["title"]} >Edit Remark</h1>
            <ButtonLink onClick={() => setShowTextArea(true)}>+ Add Remark</ButtonLink>
        </div>
    )
}

export default RemarkHeader
