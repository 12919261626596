// React Thirty Party Library
import {Tag} from 'antd'

// Components 

// Constant
import { TEMPLATE } from "../../../constants";

// Services Library
import * as REDUX_ACTION from "../../../services/redux/actions";

//handlers
import { filter } from "../../../handlers"

import { TIME } from "../../../services/util";

const { filterHDLR, searchHDLR } = filter
const { parseTimeMin } = TIME;


const {
    TABLE_ZONE_TITLE, TABLE_ZONE_INDEX, TABLE_EXPAND_ZONE_PRICELIST_TITLE, TABLE_EXPAND_ZONE_PRICELIST_INDEX, OPTION_PRICE_STATUS, OPTION_PRICE_STATUS_TITLE
} = TEMPLATE.ZONEPRICELIST

export const ZONE_SUMMARY_COL = ({redux = {}, func = {},  exportState = false}) => {

    return [
        // {
        //     title: TABLE_ZONE_TITLE.zone_id,
        //     key: TABLE_ZONE_INDEX.zone_id,
        //     fixed: "left",
        //     render:(record)=> {
        //         const { zoneid } = record;
        //         return exportState ? "" : <p>{""}</p>
        //     },
        //     // ...searchHDLR.getColumnSearchProps("code", handleSearch)
        // },
        {
            title: TABLE_ZONE_TITLE.zone_name,
            key: TABLE_ZONE_INDEX.zone_name,
            render:(record)=> {
                const { name } = record;
                return exportState ? name : <p>{name}</p>
            },

        },
        {
            title: TABLE_ZONE_TITLE.active,
            key: TABLE_ZONE_INDEX.active,
            render:(record)=> {
                const { active } = record;
                return exportState ? active : <p>{active}</p>
            },
        },
        {
            title: TABLE_ZONE_TITLE.inactive,
            key: TABLE_ZONE_INDEX.inactive,
            render:(record)=> {
                const { inactive } = record;
                return exportState ? inactive : <p>{inactive}</p>
            },
        },
        {
            title: TABLE_ZONE_TITLE.createdAt,
            key: TABLE_ZONE_INDEX.createdAt,
            width:190,
            render:(record)=>{
                const { createdAt } = record
                return exportState ? parseTimeMin(createdAt) : <p>{TIME.parseTimeMin(createdAt)}</p>
            },
            sorter: (a, b) => a.createdAt - b.createdAt,
        },
        {
            title: TABLE_ZONE_TITLE.updated,
            width: 180,
            render:(record)=>{
              const { updatedAt, updatedBy } = record
              
              return exportState ? `${parseTimeMin(updatedAt)}, ${updatedBy}` : 
              <>
                <p>{updatedBy || '-'}</p>
                <p>{TIME.parseTimeMin(updatedAt)}</p>
              </>
          },
          sorter: (a, b) => a.updatedAt - b.updatedAt,
        },
        {
            title: TABLE_ZONE_TITLE.remark,
            key: TABLE_ZONE_INDEX.remark,
            width: 190,
            render:(record) => {
                const { remark } = record
                return exportState ? remark : <p>{remark || "-"}</p>
            },
        },
    ]
};

export const ZONE_PRICELIST_SUMMARY_COL = ({redux = {},  exportState = false}) => {

    const {zone} = redux

    return [
        {
            title: TABLE_EXPAND_ZONE_PRICELIST_TITLE.zone_from,
            key: TABLE_EXPAND_ZONE_PRICELIST_INDEX.zone_from,
            render:(record) => {
                const { origin } = record;
                const originZone = zone.find(z => z.zoneid === origin)
                return exportState ? originZone.name : <p>{originZone.name}</p>
            },
        },
        {
            title: TABLE_EXPAND_ZONE_PRICELIST_TITLE.zone_to,
            key: TABLE_EXPAND_ZONE_PRICELIST_INDEX.zone_to,
            render:(record) => {
                const { dest } = record;
                const destinationZone = zone.find(z => z.zoneid === dest)
                return exportState ? destinationZone.name : <p>{destinationZone.name}</p>
            },
        },
        {
            title: TABLE_EXPAND_ZONE_PRICELIST_TITLE.status,
            key: TABLE_EXPAND_ZONE_PRICELIST_INDEX.status,
            render:(record) => {
                const { status } = record;
                const { name, colorTag } = OPTION_PRICE_STATUS_TITLE[status];
                return exportState ? (
                    name
                  ) : (
                    <>
                      <Tag color={colorTag} key={status}>{name}</Tag>
                    </>
                  );
            },
        },
        {
            title: TABLE_EXPAND_ZONE_PRICELIST_TITLE.price,
            key: TABLE_EXPAND_ZONE_PRICELIST_INDEX.price,
            render:(record) => {
                const { price } = record;
                return exportState ? price : <p>{price}</p>
            },
        },
        {
            title: TABLE_EXPAND_ZONE_PRICELIST_TITLE.updated,
            key: TABLE_EXPAND_ZONE_PRICELIST_INDEX.updated,
            render:(record)=>{
              const { updatedAt, } = record
              
              return exportState ? `${parseTimeMin(updatedAt)}` : 
              <>
                <p>{TIME.parseTimeMin(updatedAt)}</p>
              </>
          },
        },
    ]
};