import { useDispatch } from "react-redux";
import { message } from "antd";
// Constant
import { TEMPLATE } from "../../../../../../constants";

// Componets
import { ButtonLink, ButtonModal } from "../../../../../../components";

import * as REDUX_ACTION from "../../../../../../services/redux/actions";

const { MODAL_ACTION } = TEMPLATE.MODAL;

export const ActionItems = (
    record, 
    dispatch, 
    setIsOpen,
    setData,
	setIsOpenEditAccManager
  ) => {
	  return [
	{
		key: 0,
		label: 
    <ButtonLink 
      onClick={() => { 
        message.loading("Generating QR Code...")
        dispatch(REDUX_ACTION.v1_accountManager.generate_qr_cm_request(record.uid, () => message.destroy())
		)}}>
		Generate Referral QR Code</ButtonLink>,
	},
	{
		key: 1,
		label: (
			<ButtonLink  
			onClick={() => {
        		setIsOpenEditAccManager(true)
				const recordCM = { ...record };
				delete recordCM.totalCX;
				setData(recordCM);
      }}>
				Edit Account Manager
			</ButtonLink>
		),
	},
	{
		key: 2,
		label: (
			<ButtonLink  onClick={() => {
        setIsOpen(true)
        setData(record)
      }}>
				Edit Remarks
			</ButtonLink>
		),
	},
	{
		key: 3,
		label: (
      <>
      {record.status === 0 || !record.status ? 
			<ButtonModal
				model="link"
				extra={record}
				setmodal={MODAL_ACTION.reactivate_user}
				onClick={() => {
                    dispatch(
                        REDUX_ACTION.v1_customerList.update_user_list_status({...record, status: 1
						})
                    );
				}}
			>
				Activate
			</ButtonModal>
      :
      <ButtonModal
        model="link"
        extra={record}
        setmodal={MODAL_ACTION.suspend_user}
                onClick={() => {
                    dispatch(
                        REDUX_ACTION.v1_customerList.update_user_list_status({...record, status: 0
						})
                    );
        }}
      >
        Suspend
      </ButtonModal>
      }
      </>
		)
	},
]
}
