import * as API from "../../api"
import * as ActionTypes from "../action-types";

import { message } from 'antd'

// Constants and Config

// Services

// Redux Action
import {
    setLoadingOrderLog,
    unsetLoadingOrderLog
 } from './style'

import { TIME } from "../../util";

// Neccessary Actions

export const get_order_logs_thru_ordid_request = (ordid) => dispatch => {
  dispatch(setLoadingOrderLog())
  return new Promise((resolve, reject) => {
  API
  .getOrderLogsByORDID({ordid})
  .then(info => {

      const {
          status,
          orderLogs,
          msg
      } = info

      if(status === 200) {
          dispatch(get_order_logs_thru_ordid_success(ordid, orderLogs))
          resolve(info); 
      } else {
          message.error(msg)
          reject(new Error(msg)); 
      }

      dispatch(unsetLoadingOrderLog())

  })
  .catch(() => {
      dispatch(unsetLoadingOrderLog())
  })
  .finally(() => {
      dispatch(unsetLoadingOrderLog())
  })
});
}

export const update_order_logs_thru_ordid_request = (items) => dispatch => {

  const updatedAt = TIME.currentTimeMilliSecond()
  const completedAt = updatedAt

  const newItems = {
      ...items,
      updatedAt,
      completedAt: items.completedAt === 0 ? completedAt : items.completedAt
  }

  API.updateOrderLogsByORDID({
    ordid: items.ordid,
    items: newItems
  }).then(info => {
    
    const {msg, status} = info

    if(status === 200) {
      message.success(msg)
    } else {
      message.warning(msg)
    }
  })
}

const get_order_logs_thru_ordid_success = (ordid, orderLogs) => {
    return {
        type: ActionTypes.ORDER_LOG_GET_THRU_ORDID,
        ordid,
        orderLogs
    };
}
