// React Thirty Party Library
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";

// Constants Library
import { ROUTES, COMMON_FEATURES } from "../../constants";

// Components Library
import SearchBilling from "./components/SearchBilling";
import TableBilling from "./components/TableBilling";

// Handler Library

// Services Library
import { REDUX as REDUX_UTIL } from "../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../services/redux/actions";

// Styles Library
import classes from "../common.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;
const { ROUTE_URL } = ROUTES;
const { FEATURE_NAME } = COMMON_FEATURES;

const BillingList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const journals = useSelector(reduxStoreWrapper("journalNote.byID"))
  const users = useSelector(reduxStoreWrapper("customer.byID"));
  const billing = useSelector(reduxStoreWrapper("billing.byID"));
  const loading = useSelector(reduxStoreWrapper("style.loading.billing"));

  const bills = Object.values(billing).map((b) => {
    const matched = users[b.memid]
    const matchedJn = journals[b.refid]
    return {
      ...b,
      key: b.billid,
      name: matched && matched.name,
      url: matchedJn && matchedJn.url
    };
  }); 

  const [filteredData, setFilteredData] = useState(bills);
  const [selectedRow, setSelectedRow] = useState([]);
  const PORGID = "ipick"

  useEffect(() => {
    dispatch(
      REDUX_ACTION.v1_billing.get_billing_timeframe_thru_90days_request()
    );
    dispatch(
      REDUX_ACTION.v1_order.get_order_timeframe_thru_porgid_last_90_days_request(
        [PORGID]
      )
    );

    return () => {};
  }, [dispatch]);



  useEffect(() => {
    if (filteredData !== bills) {
      setFilteredData(bills);
    }
  }, [bills]);

  useEffect(() => {
    setFilteredData(filteredData);
}, [filteredData]);

  const NavigateTransactionId = (txnid) => {
    navigate(ROUTE_URL[FEATURE_NAME.TRANSACTION_LIST], { state: { txnid } });
  };

  const NavigateOrderSummary = (ordid) => {
    navigate(ROUTE_URL[FEATURE_NAME.ORDER_SUMMARY], {
      state: { ordid },
    });
  };

  return (
    <div className={classes.container}>
      <SearchBilling
        dataSource={bills}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        selectedRow={selectedRow}
      />
      <TableBilling
        dataSource={bills}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        NavigateTransactionId={NavigateTransactionId}
        NavigateOrderSummary={NavigateOrderSummary}
        loading={loading}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
    </div>
  );
};

export default BillingList;
