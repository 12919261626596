// Third-party Library
import { useEffect, useState } from "react";
import { Table, Result } from "antd"
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

// Constant
import { TEMPLATE, ROOT_CONSTANT } from "../../../../constants"

// Components Library
import { BtnSubmit } from "../../../../components";

// Handler Librarys
import { exportHDLR, tableHDLR } from "../../../../handlers";

// Services Library
import { TIME, REDUX as REDUX_UTIL } from "../../../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../../../services/redux/actions";

const { reduxStoreWrapper } = REDUX_UTIL
const { parseExportTime } = TIME;

const {
    STATUS_LOG_COLUMN, 
    SCAN_LOG_COLUMN
} = TEMPLATE.REPORT.REPORT_HISTORY

const TableReport = ({NavigateOrderSummary}) => {
    const dispatch = useDispatch()

    const user = useSelector(reduxStoreWrapper("users"))
    const hub = useSelector(reduxStoreWrapper("hub.byID"))
    const point = useSelector(reduxStoreWrapper('point.byID'))
    const hubOperator = useSelector(reduxStoreWrapper('hubOperator.byID'))
    const pointOperator = useSelector(reduxStoreWrapper('pointOperator.byID'))
    const orderStatuses = useSelector(reduxStoreWrapper("orderStatus.byID"))
    const report = useSelector(reduxStoreWrapper('report'))
    const loading = useSelector(reduxStoreWrapper('style.loading.report'))

    const { byCreatedAt: reports, rtype: type } = report

    const [ filteredData, setFilteredData ] = useState([])
    const [ selectedRowKeys, setSelectedRowKeys ] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);

    // initialization for dataSource. since it did not immediately got as initialValue 
    useEffect(() => {
        setFilteredData(Object.values(reports))
    }, [reports])

    useEffect(() => {
        dispatch(REDUX_ACTION.v1_hubOperator.get_all_hub_operators_only_request());
        dispatch(REDUX_ACTION.v1_pointOperator.get_all_point_operators_only_request());
    
      }, [dispatch]);
 
    // handler for search, for filtering data shown in table
    const handleSearch = (name, keyword) => {

        const filteredKeyword = Object.values(reports).filter(report => report[name]?.includes(keyword))

        setFilteredData(filteredKeyword)
    };

    // to determine the column, whether Status or Scan Log
    const SELECTED_COLUMN = (xp) => {  // xp is export param. truthy for export
        switch(type) {
            case "status": {
                return STATUS_LOG_COLUMN({
                    redux: {
                        user,
                        hubOperator,
                        pointOperator,
                        hub,
                        point,
                        orderStatuses
                    },
                    func: {
                        NavigateOrderSummary,
                        handleSearch,
                    },
                    exportState: xp ? true : false
                })
            }
            case "scan": {
                return SCAN_LOG_COLUMN({
                    redux: {
                        point,
                        hub,
                        orderStatuses,
                        hubOperator,
                        pointOperator
                    },
                    func: {
                        NavigateOrderSummary,
                        handleSearch
                    },
                    exportState: xp ? true : false
                })
            }
            default:
                break;
        }
    }

    const onSelectRow = (record, selected) => {
        tableHDLR.selectRow(setSelectedRowKeys, setSelectedRow, selectedRow, selectedRowKeys, record, selected)
      };
    
      const rowSelection = {
        selectedRowKeys,
        onSelect: onSelectRow
      };

    // selected data used for exporting selected order
    const selectedData = selectedRow?.length > 0 && selectedRow || Object.values(reports)

    return (
        <> 
            {
                loading
                &&
                <Result
                    icon={<LoadingOutlined style={{ fontSize: 96, color: '#F08800', marginTop: '25vh' }} spin/>}
                    title="Loading..."
                />
                ||
                (
                    type
                    &&
                    <div style={{ margin: '1.5em auto' }}>
                        <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '1.5em' }}>
                            <BtnSubmit
                                text={"Export"}
                                bgColor="transparent"
                                color={"#4CAF50"}
                                padding="9px 18px"
                                onClick={() =>
                                    exportHDLR.exportWithTemplate({
                                        data: selectedData,
                                        filename: `${type === 'status' ? 'STATUS' : 'SCAN'}_LOG_REPORT_SUMMARY_${parseExportTime()}`,
                                        template: SELECTED_COLUMN(1)
                                })}
                                showIcon={true}
                                icon={ROOT_CONSTANT.IMG.ICON.icon_export}
                                icon_alt={"export"}
                            />
                        </div>
                        <Table 
                            columns={SELECTED_COLUMN().map((c) => ({
                                ...c,
                                textWrap: "word-break",
                                ellipsis: true,
                            }))}
                            dataSource={filteredData.map(data => ({ key: data.createdAt, ...data }))}
                            pagination={{
                                position: ['topRight']
                            }}
                            rowSelection={rowSelection}
                            scroll={{
                                x: 1200
                            }}
                        />
                    </div>
                )
            }
        </>
    )
}

export default TableReport