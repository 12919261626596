// React Thirty Party Library
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { message } from "antd";

// Constants Library
import { ROUTES, COMMON_FEATURES } from "../../../../constants";

// Components Library
import { SearchOrderlist, TableOrderlist } from "./component";

// Handler Library
import { reactHDLR } from "../../../../handlers";

// Services Library
import { REDUX as REDUX_UTIL } from "../../../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../../../services/redux/actions";

// Styles Library
import styles from "../../../common.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;
const { ROUTE_URL } = ROUTES;
const { FEATURE_NAME } = COMMON_FEATURES;

const OrderList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [isOpenAddBulkAssign, setIsOpenAddBulkAssign] = useState(false);

  const PORGID = "ipick";

  const orders = useSelector(reduxStoreWrapper("order.byID"));
  const uid = useSelector(reduxStoreWrapper("user.uid"));
  const memid = useSelector(reduxStoreWrapper("user.memid"))
  const billings = Object.values(useSelector(reduxStoreWrapper("billing.byID")));
  const journalNote = useSelector(reduxStoreWrapper("journalNote.byID"));
  const point = useSelector(reduxStoreWrapper("point.byID"));
  const customers = useSelector(reduxStoreWrapper("customer.byID"));
  const loading = useSelector(reduxStoreWrapper("style.loading.order_list"));
  const vehicle = useSelector(reduxStoreWrapper("vehicle.byPlate"))

  const [messageApi, contextHolder] = message.useMessage();


  const dataSource = Object.values(orders).map((o) => {
    
    const matchedBill = billings.find((b) => o.ordid === b.ordid);
    const jnid = matchedBill && matchedBill.refid
    const status = matchedBill &&  matchedBill.status
  
    const matchedJnid = journalNote[jnid]
    const url = matchedJnid && matchedJnid.url

    const matchedPointOrig = point[o.origpid]
    const origid = matchedPointOrig !== undefined ? matchedPointOrig.parentHub : o.origid

    const matchedPointDest = point[o.dstpid]
    const dstid = matchedPointDest !== undefined ? matchedPointDest.parentHub : o.dstid 

    const matchedUser = customers[o.memid]
    const name = matchedUser && matchedUser.name
    const contact = matchedUser && matchedUser.contact
    
    const matchedVehicle = vehicle[o.v_plate]
    const driver = matchedVehicle ? matchedVehicle.driver : "Driver not found"

      return {
        ...o,
      origid: o.origpid ?  origid : o.origid, 
      dstid: o.dstpid ? dstid : o.dstid,
      key: o.ordid,
      statusBill: status,
      jnid: jnid,
      url: url,
      name: name,
      contact: contact,
      v_driver: driver,
     }
  });

  const [filteredData, setFilteredData] = useState(dataSource);

  useEffect(() => {
    dispatch(
      REDUX_ACTION.v1_order.get_order_timeframe_thru_porgid_last_90_days_request(
        [PORGID]
      )
    );
    dispatch(
      REDUX_ACTION.v1_hubOperator.get_all_hub_operators_request(0)
    );
    dispatch(
      REDUX_ACTION.v1_billing.get_billing_timeframe_thru_90days_request()
    )

    dispatch(REDUX_ACTION.v1_vehicle.get_all_vehicle_request());
    dispatch(REDUX_ACTION.v1_customerList.get_customer_all_request());

    return () => {};
  }, [dispatch]);

  useEffect(() => {
    if (filteredData !== dataSource) {
      setFilteredData(dataSource);
    }
  }, [dataSource]);

  useEffect(() => {
    setFilteredData(filteredData);
  }, [filteredData]);

  const SORenavigate = (soid) => {
    messageApi.open({
      type: "loading",
      content: "Getting shipment order...",
      duration: 0,
    });

    dispatch(
      REDUX_ACTION.v1_shipmentOrder.get_shipment_order_thru_soid_request(
        soid,
        (res) => {
          const { status, url } = res;

          if (status === 200) {
            reactHDLR.openInNewTab(url);
          }

          messageApi.destroy();
        }
      )
    );
  };

  const PrintSO = (ordid) => {
    messageApi.open({
      type: "loading",
      content: "Getting shipment order...",
      duration: 0,
    });

    dispatch(
      REDUX_ACTION.v1_shipmentOrder.get_shipment_order_thru_ordid_request(
        ordid,
        (res) => {
          const { status, url } = res;

          if (status === 200) {
            reactHDLR.openInNewTab(url);
          }

          messageApi.destroy();
        }
      )
    );
  };

  const UpdateOrder = (item) => {
    messageApi.open({
      type: "loading",
      content: "Update order...",
      duration: 0,
    });

    dispatch(
      REDUX_ACTION.v1_order.update_order_request({ ...item, uid, memid }, () => {
        messageApi.destroy();
      })
    );
  };

  const NavigateOrderSort = (ordid, label) => {
    navigate(ROUTE_URL[FEATURE_NAME.ORDER_SORT], {
      state: { ordid, label },
    });
  };

  const NavigateOrderSummary = (ordid) => {
    navigate(ROUTE_URL[FEATURE_NAME.ORDER_SUMMARY], { state: { ordid } });
  };

  const NavigateBulkUpload = () => {
    navigate(ROUTE_URL[FEATURE_NAME.BULK_UPLOAD]);
  };

  const NavigateOrderTimeline = (ordid, status, record = {}) => {
    navigate(ROUTE_URL[FEATURE_NAME.ORDER_TIMELINE], {
      state: { ordid, status, record },
    });
  };

  const [selectedRow, setSelectedRow] = useState([]);

  return (
    <>
      {contextHolder}
      <div className={styles.container}>
        <SearchOrderlist
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          dataSource={dataSource}
          NavigateBulkUpload={NavigateBulkUpload}
          selectedRow={selectedRow}
          setIsOpenAddBulkAssign={setIsOpenAddBulkAssign}
        />
        <TableOrderlist
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          dataSource={dataSource}
          loading={loading}
          SORenavigate={SORenavigate}
          PrintSO={PrintSO}
          UpdateOrder={UpdateOrder}
          NavigateOrderSort={NavigateOrderSort}
          NavigateOrderSummary={NavigateOrderSummary}
          NavigateOrderTimeline={NavigateOrderTimeline}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          isOpenAddBulkAssign={isOpenAddBulkAssign}
          setIsOpenAddBulkAssign={setIsOpenAddBulkAssign}
        />
      </div>
    </>
  );
};

export default OrderList;
