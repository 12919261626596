import { Space, Form, InputNumber, Row, Col, Button } from "antd";

// Constants
import { TEMPLATE } from "../../../../../../../../constants";

// Components

// Styles
import coms from "../common.module.css";

const { DATA_FIELD, ACTIONS_LABEL_TITLE, ACTIONS_LABEL} = TEMPLATE.ORDER;

const PriceInformation = (props) => {
  const { form, formData, onClick, label, parcelHandle, setPrice, setAddOn } = props;

  const sort = ACTIONS_LABEL_TITLE[ACTIONS_LABEL.sort_receiver_order];

  const origpid = formData.origpid

  const handleChange = (e, field) => {
    field === 1 &&  form.setFieldValue("price", e)
    field === 1 ? setPrice(e): setAddOn([{addon: e}])
  }

  return (
    <>
      <Space direction={"vertical"} style={{ width: "100%" }}>
      <Row>
        <Col span={8}>
          <Form.Item
            label={"Estimated Price"}
            name={DATA_FIELD.price}
          >
            <InputNumber min={0} addonBefore="RM" style={{ width: "15vw" }} 
            disabled={ label === sort && true || (origpid && parcelHandle.length > 0)}
            onChange={(e) => handleChange(e, 1)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
        <Form.List name={"priceInfo_unsort"}>
        {(fields, {add, remove}) => {
          return (
            <>
              {fields.map((field, i) => {
                const { name } = field
                return (
                    <Form.Item label="Value Added Services" name={[name, "addon"]} rules={[{ required: true }]} >
                    <InputNumber
                      min={0} 
                      addonBefore="RM" style={{ width: "15vw" }} 
                      disabled={label === sort ? true : false}
                      onChange={(e) => handleChange(e, 2)}
                      />
                    </Form.Item>
                )
              })}
            </>
          )
        }}
        </Form.List>
        </Col>
      </Row>
        {label === 'Proceed Order' ? <></> :<div className={coms.nextBTN}>
      <Button type="primary" onClick={() => onClick()}>
        Next
      </Button>
    </div>}
      </Space>
    </>
  );
};

export default PriceInformation;
