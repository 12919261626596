// React Thirty Party Library
import { Button } from 'antd'

// Constants Library
import { IMG } from "../../../../../../../../constants";

// Components Library
import { ButtonLink, BtnSubmit } from "../../../../../../components";

// Handler Library

// Services Library

// Redux Actions

// Styles Library
import classes from "../../../../../../components/index.module.css";

const { icon_arrow } = IMG.ICON;

const BulkHeader = ({ handleButtonBack, onCompleteHLDR, loading }) => {
  return (
    <>
      <div className={classes.headerPage}>
        <div>
          <ButtonLink
            onClick={handleButtonBack}
            style={{ display: "inline-block" }}
          >
            <img
              src={icon_arrow}
              className={classes.filterIcon}
              alt=""
              style={{ marginBottom: "-2px" }}
            />
          </ButtonLink>
          Bulk Upload
        </div>
        <div>
          <BtnSubmit
            display="inline-block"
            text="Cancel"
            bgColor="transparent"
            color="var(--bs-gray-600)"
            padding="9px 18px"
            onClick={handleButtonBack}
            showIcon={false}
          />
          <Button
            type="primary"
            loading={loading}
            onClick={onCompleteHLDR}
          >
            Confirm Upload
          </Button>
        </div>
      </div>
    </>
  );
};

export default BulkHeader;
