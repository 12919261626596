import { Row, Col, Input } from "antd"

import CommentProfile from "../CommentProfile"

const CommentInput = ({ handleOkButton, setValue, value  }) => {

    return (
        <Row gutter={15} style={{ marginTop: "1em" }}>
            <CommentProfile name="You" />

            <Col span={20}>
                <Input.TextArea
                    value={value.comment}
                    onChange={(e) => setValue({
                        ...value,
                        comment: e.target.value
                    })}
                    onPressEnter={handleOkButton}
                    autoSize={{
                        minRows: 3,
                        maxRows: 5,
                    }}
                />
            </Col>
        </Row>
    );
};

export default CommentInput;
