// Third-party Library 
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react"
import { Select, Card, Row, Col, Avatar, Tooltip, Skeleton } from "antd";
import { GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';

// Constants 
import { APP_CONFIG } from '../../../../../../config/';
import { ROOT_CONSTANT, TEMPLATE } from '../../../../../../constants';

// Services Library
import { REDUX as REDUX_UTIL } from "../../../../../../services/util";

// Actions
import * as REDUX_ACTION from "../../../../../../services/redux/actions";

// Styles 
import classes from '../../../../common.module.css'

const {
    icon_map_color
} = ROOT_CONSTANT.IMG.ICON.MODAL

const {
    reduxStoreWrapper
} = REDUX_UTIL

const {
    GET_OPERATION_HOURS_LIST
} = TEMPLATE.POINT

const MapViewHub = () => {
    const dispatch = useDispatch()

    const [hub, setHub] = useState('hub#dut')

    const hubs = useSelector(reduxStoreWrapper('hub.byID'))
    const hubsIDs = useSelector(reduxStoreWrapper('hub.allIDs'))
    const hubOperatorByID = useSelector(reduxStoreWrapper(`hubOperator.byHubid.${hub}`))
    
    const [infoWindowOpen, setInfoWindowOpen] = useState(false);
    const loading = !Array.isArray(hubOperatorByID)
    
    const stateName = hubs[hub]?.address.split(', ').pop()
    // OptionGroup -- set options value for its child 
    const hubMap = {}
    
    hubsIDs.forEach(hubid => {
        const hubState = hubs[hubid].state

        if(hubState !== undefined){
            if (!hubMap[hubState]) {
                hubMap[hubState] = []
            }
            hubMap[hubState].push({
                label: hubs[hubid].name,
                value: hubid
            })
        }
    })

    // OptionGroup -- Main OptionGroup value
    const hubList = Object.keys(hubMap).map(hubState => ({
        label: hubState,
        options: hubMap[hubState]
    }))
    const days = ['Monday','Tuesday','Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

    const coordinates = {
        lat: parseFloat(hubs[hub]?.coordinate.lat),
        lng: parseFloat(hubs[hub]?.coordinate.lon)
    };

    const DetailsInfoWindow = () => {

        !hubOperatorByID && dispatch(REDUX_ACTION.v1_hubOperator.get_operator_thru_hubid_request(hub));

        return (
            <div className={classes["infoWindow-container"]}>
                <Row style={{fontSize: '20px', marginBottom: '20px'}} justify='space-between'>
                    <Col>
                        <h3>{hubs[hub].code} ({hubs[hub].name})</h3>
                    </Col>
                    <Col>
                        <h3>{hubs[hub].state ? hubs[hub].state.toUpperCase() : stateName.toUpperCase()}</h3>
                    </Col>
                </Row>
                <Card style={{fontSize: '13px'}}>
                    <p style={{ lineHeight: '18px', color: '#868686'}}>{hubs[hub].address}</p>
                    <span style={{display: 'flex', margin: '15px 0', gap: '10px'}}>
                        <img style={{height: '15px'}} src={icon_map_color} alt="" />
                        <a href={hubs[hub].url} target="_blank">{hubs[hub].url}</a>
                    </span>
                </Card>
                <div className={classes["infoWindow-detail-container"]}>
                    <h6>HUB OPERATOR</h6>

                    <Avatar.Group
                        maxCount={2}
                        maxStyle={{
                            color: '#f56a00',
                            backgroundColor: '#fde3cf',
                        }}
                    >
                        {hubOperatorByID && hubOperatorByID.map((hubOp, i) => (
                            <Tooltip title={hubOp.name} key={i}>
                                {loading && 
                                <Skeleton.Avatar active />
                                }
                                <Avatar
                                    style={{
                                        backgroundColor: (i % 2 && '#f56a00') || (i % 3 && '#87d068') || ''
                                    }}
                                >
                                    {hubOp?.name?.charAt(0)}
                                </Avatar>
                            </Tooltip>
                        ))}
                    </Avatar.Group>
                </div>
                <div className={classes["infoWindow-detail-container"]}>
                    <h6>PIC INFORMATION</h6>
                    <ul style={{ listStyle: 'square', paddingLeft: '18px'}}>
                        {hubs[hub].pic.map((pic, i) => (
                            <li key={i} style={{margin: '5px'}}>{pic.name} {pic.contact}</li>
                        ))}
                    </ul>
                </div>
                <div className={classes["infoWindow-detail-container"]}>
                    <h6>OPERATING HOURS</h6>
                    {
                        Array.isArray(hubs[hub].biz_hrs) 
                        &&
                        GET_OPERATION_HOURS_LIST(hubs[hub].biz_hrs)
                        ||
                        `${hubs[hub].biz_hrs.day} ${hubs[hub].biz_hrs.time} `
                    }
                     
                </div>  
            </div>
        )
    }

    const toggleInfoWindow = () => {
        setInfoWindowOpen(!infoWindowOpen)
    }

    return (
        <div className={classes["container-map"]}>
            <Select
                size="large"
                style={{
                    width: 280,
                    marginBottom: "15px",
                    top: '.5em',
                    left: '10px'
                }}
                defaultValue={hub}
                onChange={(val) => {setHub(val); setInfoWindowOpen(false)}}
                options={hubList}
            />
              {
                APP_CONFIG.API_KEY.GOOGLE_MAP
                &&
                <LoadScript googleMapsApiKey={APP_CONFIG.API_KEY.GOOGLE_MAP}>
                    <GoogleMap
                        mapContainerStyle={{
                            width: '100%',
                            height: '80vh'
                        }}
                        center={coordinates}
                        zoom={8}
                    >
                        <Marker
                            position={coordinates}
                            onClick={toggleInfoWindow}
                        >
                            {infoWindowOpen && (
                            <InfoWindow onCloseClick={toggleInfoWindow}>
                               {hubs && <DetailsInfoWindow />}
                            </InfoWindow>
                            )}
                        </Marker>
                    </GoogleMap>
                </LoadScript>
              }
        </div>
    )
}

export default MapViewHub
