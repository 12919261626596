// React Thirty Party Library
import { useNavigate } from "react-router";
import { Tag, Typography } from "antd";

// Constant
import { TEMPLATE, COMMON_FEATURES, ROUTES } from "../../../constants";

// Components
import { ButtonLink } from "../../../components";

//handlers
import { filter, reactHDLR } from "../../../handlers"

// Service
import { TIME, REDUX as REDUX_UTIL } from "../../../services/util";

const {
  TABLE_TRANSACTION_INDEX,
  TABLE_TRANSACTION_TITLE,
  OPTION_STATUS,
  OPTION_STATUS_TITLE,
  OPTION_TYPE_TXN,
  OPTION_TYPE_TITLE_TXN,
} = TEMPLATE.TRANSACTIONLIST; 

const { FEATURE_NAME } = COMMON_FEATURES;

const { ROUTE_URL } = ROUTES;

const { parseTimeMin } = TIME;

const { searchHDLR, filterHDLR } = filter

const { Text } = Typography;

export const TRANSACTION_SUMMARY_COL = ({ func = {}, exportState = false, redux = {} }) => {
  const { handleModal, NavigateOrderSummary, handleSearch } = func;

  const navigate = useNavigate();

  const { billing, journals, users } = redux;

  return [
    {
      title: TABLE_TRANSACTION_TITLE.Transaction_Id,
      key: TABLE_TRANSACTION_INDEX.Transaction_Id,
      width: 180,
      fixed: "left",
      render: (record) => {
        const { txnid } = record;
        return exportState ? txnid : <Text>{txnid} </Text>;
      },
      ...searchHDLR.getColumnSearchProps("txnid", handleSearch)
    },
    {
      title: TABLE_TRANSACTION_TITLE.Customer_Id,
      key: TABLE_TRANSACTION_INDEX.Customer_Id,
      width: 200,
      fixed: "left",
      render: (record) => {
        const { memid, name } = record;

        return exportState ? (
          `${memid} / ${name}`
        ) : (
          <span>
            {memid} / {name}
          </span>
        );
      },
      ...searchHDLR.getColumnSearchProps("memid", handleSearch)
    },
    {
      title: TABLE_TRANSACTION_TITLE.Transacted_At,
      key: TABLE_TRANSACTION_INDEX.Transacted_At,
      render: (record) => {
        const { createdAt } = record;
        return exportState ? (
          parseTimeMin(createdAt)
        ) : (
          <span>{parseTimeMin(createdAt)} </span>
        );
      },
      sorter: (a, b) => a.createdAt - b.createdAt,
    },
    {
      title: TABLE_TRANSACTION_TITLE.Transaction_Status,
      key: TABLE_TRANSACTION_INDEX.Transaction_Status,
      render: (record) => {
        const { status } = record;

        const name = OPTION_STATUS_TITLE[status] && OPTION_STATUS_TITLE[status].name;
        const colorTag = OPTION_STATUS_TITLE[status] && OPTION_STATUS_TITLE[status].colorTag

        return exportState ? (
          name
        ) : (
          <>
            <Tag color={colorTag} key={status}>
              {name}
            </Tag>
          </>
        )
      },
      filters : filterHDLR.getColumnsFilterStatus(OPTION_STATUS_TITLE),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: TABLE_TRANSACTION_TITLE.Transaction_Type,
      key: TABLE_TRANSACTION_INDEX.Transaction_Type,
      render: (record) => {
        const { type } = record;
        const typeTxn = OPTION_TYPE_TITLE_TXN[type] && OPTION_TYPE_TITLE_TXN[type];
        return exportState ? typeTxn : <span>{typeTxn} </span>;
      },
      filters : filterHDLR.getColumnsFilterType(OPTION_TYPE_TXN, OPTION_TYPE_TITLE_TXN),
      onFilter: (value, record) => record.type === value,
    },
    {
      title: TABLE_TRANSACTION_TITLE.Before_Transaction,
      key: TABLE_TRANSACTION_INDEX.Before_Transaction,
      render: (record) => {
        const { currency, amount_before } = record;
        return exportState ? (
          `${currency} ${amount_before}`
        ) : (
          <span>
            {currency}
            {amount_before}
          </span>
        );
      },
    },
    {
      title: TABLE_TRANSACTION_TITLE.Amount,
      key: TABLE_TRANSACTION_INDEX.Amount,
      render: (record) => {
        const { currency, action, amount } = record;
        return exportState ? (
          `${action} ${currency} ${amount}`
        ) : (
          <span>
            {action}
            RM
            {amount}
          </span>
        );
      },
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: TABLE_TRANSACTION_TITLE.Wallet_Balance,
      key: TABLE_TRANSACTION_INDEX.Wallet_Balance,
      render: (record) => {
        const { action, amount, amount_before, currency } = record;

        const total =
          action === "+"
            ? parseFloat(amount_before) + parseFloat(amount)
            : parseFloat(amount_before) - parseFloat(amount);

        return exportState ? (
          `${currency} ${total}`
        ) : (
          <span>
            {currency}
            {total}
          </span>
        );
      },
    },
      {
      title: TABLE_TRANSACTION_TITLE.Order_ID,
      key: TABLE_TRANSACTION_INDEX.Order_ID,
      render: (record) => {
        const { ordid } = record; 

        const order = () => {
          if (ordid === undefined || ordid === "" || ordid === "-") {
            return (
              <Text disabled>{"-"}</Text>
            );
          } else {
            return (
              <ButtonLink onClick={()=>NavigateOrderSummary(ordid)}>{ordid}</ButtonLink>
            );
        };
      }

      const orderID = order()

        return exportState ? (
          ordid
        ) : (
          orderID
        );
      },
      ...searchHDLR.getColumnSearchProps("ordid", handleSearch)
    },
    {
      title: TABLE_TRANSACTION_TITLE.Billing_ID,
      key: TABLE_TRANSACTION_INDEX.Billing_ID,
      render: (record) => {
        const { jnid, url = {} } = record;

        const handleButtonClick = () => {
          window.open(url.jn, '_blank'); 
        };

        const journal = () => {
          if (!url.jn || url.jn === undefined || url.jn === "" || url.jn === "-") {
            return (
              <Text disabled>{jnid}</Text>
            );
          } else {
            return (
              <ButtonLink onClick={handleButtonClick}>
                {jnid}
              </ButtonLink>
            );
        };
      }

      const journalid = journal()

      return exportState ? (
        url.jn
      ) : (
        journalid
      );
    }, 
    ...searchHDLR.getColumnSearchProps("jnid", handleSearch)
    },
    {
      title: TABLE_TRANSACTION_TITLE.Attachment,
      key: TABLE_TRANSACTION_INDEX.Attachment,
      render: (record) => {
        const { url = {}} = record;

        let urls = []
        if (Array.isArray(url.attachment) && url.attachment.length > 0){
         urls = url.attachment.map((obj) => obj.attachment)
        }
         
        if (exportState === true) {
          if (Array.isArray(url)){
            return urls
          } else {
            return url.attachment
          }
        } else {
          if (url.attachment === "-" || url.attachment === undefined) {
            return "";
          } else if (Array.isArray(url.attachment) && url.attachment.length > 0){
            return (
              <ButtonLink onClick={() => handleModal(urls)} target="_blank">
                View
              </ButtonLink>
            );
          } else {
            return (
              <ButtonLink onClick={() => handleModal(url.attachment)} target="_blank">
                View
              </ButtonLink>
            );
          }
        }
      },
    },
    {
      title: TABLE_TRANSACTION_TITLE.Transacted_By,
      key: TABLE_TRANSACTION_INDEX.Transacted_By,
      render: (record) => {
        const { transactedBy } = record;
        return exportState ? transactedBy : <span>{transactedBy} </span>;
      },
    },
    {
      title: TABLE_TRANSACTION_TITLE.Remarks,
      key: TABLE_TRANSACTION_INDEX.Remarks,
      render: (record) => {
        const { remark } = record;
        return exportState ? remark : <span>{remark} </span>;
      },
    },
  ];
};
