import { ReactComponent as icon_order_management } from "../../assets/images/sidebar/order-management.svg";
import { ReactComponent as icon_trip_manifest } from "../../assets/images/sidebar/trip-manifest.svg";
import { ReactComponent as icon_customer_wallet } from "../../assets/images/sidebar/customer-wallet.svg";
import { ReactComponent as icon_transaction_list } from "../../assets/images/sidebar/transaction-list.svg";
import { ReactComponent as icon_billing_list } from "../../assets/images/sidebar/billing-list.svg";
import { ReactComponent as icon_user_list } from "../../assets/images/sidebar/user-list.svg";
import { ReactComponent as icon_hub_list } from "../../assets/images/sidebar/hub-icon.svg"
import { ReactComponent as icon_point } from "../../assets/images/sidebar/point.svg";
import { ReactComponent as icon_report } from "../../assets/images/sidebar/chart-histogram.svg";
import { ReactComponent as icon_my_account } from "../../assets/images/sidebar/my-account.svg";
import { ReactComponent as icon_logout } from "../../assets/images/sidebar/logout.svg";
import { ReactComponent as icon_vehicle_list } from '../../assets/images/sidebar/bus-two.svg';

import icon_export from "../../assets/images/excel.svg";
import icon_arrow from "../../assets/images/Arrow.svg";
import icon_remarks from "../../assets/images/remarks.svg";
import icon_photo from "../../assets/images/photo.svg";
import { ReactComponent as icon_filter } from "../../assets/images/Filter.svg";
import icon_order_cancel from "../../assets/images/cancelOrder.svg";
import icon_logo from "../../assets/logo/IPick_Logo.png";

import logo_ipick from "../../assets/logo/logo.png";

import icon_box from "../../assets/images/orderlist-icon/box.svg";
import icon_doc from "../../assets/images/orderlist-icon/doc.svg";
import icon_pin from "../../assets/images/orderlist-icon/pin-destination.svg";

import icon_arrow_bottom from "../../assets/images/modal-icon/arrow-bottom.svg";
import icon_arrow_right from "../../assets/images/modal-icon/arrow-right.svg";
import icon_cancel_trip_manifest from "../../assets/images/modal-icon/cancel.svg";
import icon_cancel_order from "../../assets/images/modal-icon/cancel-order.svg";
import icon_park_order from "../../assets/images/modal-icon/park-order.svg";
import icon_park_resolved from "../../assets/images/modal-icon/park-resolved.svg";
import icon_user_no from "../../assets/images/modal-icon/user-no.svg";
import icon_user_yes from "../../assets/images/modal-icon/user-yes.svg";
import icon_checklist from "../../assets/images/modal-icon/checklist.svg";
import icon_logout_modal from "../../assets/images/modal-icon/logout.svg";
import icon_comment from "../../assets/images/modal-icon/icon_comment.svg";
import icon_link from "../../assets/images/modal-icon/icon_link.svg";
import icon_map_color from '../../assets/images/modal-icon/map-color.svg';
import icon_confirmation from '../../assets/images/modal-icon/confirmation.svg';
import icon_show_hub from '../../assets/images/modal-icon/show_hub_icon.svg';
import icon_hide_hub from '../../assets/images/modal-icon/hide_hub_icon.svg';
import icon_deactivate_zone from '../../assets/images/modal-icon/deactivate-zone.svg';
import icon_activate_zone from '../../assets/images/modal-icon/activate-zone.svg';


import icon_edit from "../../assets/images/my-account/edit.svg";
import icon_profile from "../../assets/images/my-account/profile-icon.svg";
import icon_changePass from "../../assets/images/my-account/change-pass-icon.svg";
import icon_address_edit from "../../assets/images/my-account/edit-address.svg";
import icon_address_del from "../../assets/images/my-account/delete-address.svg";
import icon_update_status from "../../assets/images/modal-icon/status-update.svg"
import icon_clip from "../../assets/images/clip.svg"
import icon_comment_parcel from '../../assets/images/comment.svg'

import IMG_MY_ACCOUNT_DEFAULT from "../../assets/images/my-account/default-profile-photo.svg";

import icon_active_map from "../../assets/images/map-active.png"
import icon_inactive_map from "../../assets/images/map-inactive.png"

export const ICON = {
  logo_ipick,
  icon_logo,
  SIDEBAR: {
    icon_order_management,
    icon_trip_manifest,
    icon_customer_wallet,
    icon_transaction_list,
    icon_billing_list,
    icon_user_list,
    icon_hub_list,
    icon_point,
    icon_report,
    icon_my_account,
    icon_logout,
    icon_vehicle_list,
  },
  MY_ACCOUNT: {
    icon_edit,
    icon_profile,
    icon_changePass,
  },
  MODAL: {
    icon_logout_modal,
    icon_user_yes,
    icon_user_no,
    icon_arrow_bottom,
    icon_arrow_right,
    icon_cancel_trip_manifest,
    icon_cancel_order,
    icon_park_order,
    icon_park_resolved,
    icon_checklist,
    icon_order_cancel,
    icon_address_edit,
    icon_address_del,
    icon_comment,
    icon_link,
    icon_update_status,
    icon_clip,
    icon_map_color,
    icon_confirmation,
    icon_hide_hub,
    icon_show_hub,
    icon_deactivate_zone,
    icon_activate_zone
  },
  icon_pin,
  icon_export,
  icon_filter,
  icon_comment_parcel,
  ORDER_LIST_FORM: {
    icon_box,
    icon_doc,
  },
  icon_order_cancel,
  icon_remarks,
  icon_photo,
  icon_arrow,
  icon_active_map,
  icon_inactive_map
};

export const PIC = {
	IMG_MY_ACCOUNT_DEFAULT,
};
