import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, message } from "antd";

import { ButtonModal, TableList, ButtonLink } from "../../../../components";
import { DrawerComments } from "../../../../components";
import ProgressTable from "../../../Order/modules/OrderList/component/ProgressTable";

import {
  TEMPLATE,
} from "../../../../constants";

import * as REDUX_ACTION from "../../../../services/redux/actions";

// Handler Library
import { parkOrderHDLR } from "../../../../handlers";

// Services Library 
import { REDUX as REDUX_UTIL, TIME } from "../../../../services/util";

const { reduxStoreWrapper } = REDUX_UTIL;

const { TABLE_PARK_ORDER_INDEX, TABLE_PARK_ORDER_TITLE, SELECT_OPTION_DAYS, OPTION_PARK_ORDER_STATUS } =
  TEMPLATE.PARKORDER;
const { ORDER_STATUS } = TEMPLATE.ORDER;
const { MODAL_ACTION } = TEMPLATE.MODAL;
const { PARKORDER_SUMMARY_COL } = TEMPLATE.REPORT.PARKORDER;

const TableParkedList = ({ filteredData, setFilteredData, dataSource, loading, selectedRow,
  setSelectedRow}) => {
  
  const dispatch = useDispatch();
  let user = useSelector(reduxStoreWrapper("user"));
  const userList = useSelector(reduxStoreWrapper("customer.byID"));
  const order = useSelector(reduxStoreWrapper("order.byID"))
  const hubs = useSelector(reduxStoreWrapper("hub.byID"));
  const comments = useSelector(reduxStoreWrapper("order.byComment"))

  const [messageApi, contextHolder] = message.useMessage();

  const [isOpenComment, setIsOpenComment] = useState(false)
  const [data, setData] = useState({})

  const columns = [
    ...PARKORDER_SUMMARY_COL({
      redux: {
        user,
        userList,
        comments
      },
      func: {
        setData,
        setIsOpenComment
      }
    }),
    {
      title: TABLE_PARK_ORDER_TITLE.actions,
      key: TABLE_PARK_ORDER_INDEX.actions,
      width: 240,
      fixed: "right",
      render: (record) => {
        const {ordid, status} = record

        return (
        <div style={{width: "150px"}}>
        <Row>
        {status === OPTION_PARK_ORDER_STATUS.TO_BE_RESOLVED ? 
          <Col span={12}>
            <ButtonModal
              model="link"
              setmodal={MODAL_ACTION.update_status_mark_as_resolved}
              onClick={(value) => {
                messageApi.open({
                  type: "loading",
                  content: "Updating status...",
                  duration: 0,
              });

                dispatch(
                  REDUX_ACTION.v1_parkOrder.update_parked_order_status({
                    ...record,
                    remark: value.remark,
                    attachment: value.attachment,
                    updatedAt: value && value.initiatedAt
                  },
                  () => messageApi.destroy()
                  )
                );
              }}
              extra={record}
            >
              Mark as <br />
              Resolved
            </ButtonModal>
          </Col>
          :
           <Col span={12} style={{color: "#dee0e3"}}>

           Mark as <br />
           Resolved
           </Col>
          }
          <Col span={12}>

            <ButtonLink
              onClick={ () =>{ 

                const poStatus = status === OPTION_PARK_ORDER_STATUS.TO_BE_RESOLVED ? ORDER_STATUS.PARKED : ORDER_STATUS.CLOSED

                  !comments[ordid] &&
                   dispatch(REDUX_ACTION.v1_order.get_order_comment_request(ordid))
                    setIsOpenComment(true)

                setData({ordid, status: poStatus})

              }}
            >
              View/ Add <br />
              Comment
            </ButtonLink>
          </Col>
          </Row>
        </div>
        )}
    },
  ];

  const expandedRowRender = (record) => {

    const { ordid, status } = record;

    let newRecord = {
      ...order[ordid],
      status: status === OPTION_PARK_ORDER_STATUS.TO_BE_RESOLVED ? ORDER_STATUS.PARKED : ORDER_STATUS.CLOSED
    }

    return (
      <ProgressTable record={newRecord} hubs={hubs} />
    );
  };

  const onSelectRow = (newSelectedRowKeys, newSelectedRow) => {
    setSelectedRow(newSelectedRow);
  };

  const rowSelection = {
    selectedRow,
    onChange: onSelectRow,
  };


  return (
    <div>
      {contextHolder}
      <DrawerComments data={data} isOpen={isOpenComment} setIsOpen={setIsOpenComment}/>
      <TableList.DataList
        dataSource={dataSource}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        optionDay={SELECT_OPTION_DAYS}
        optionFirst={parkOrderHDLR.parkOrderStatus()}
        dataDate={TABLE_PARK_ORDER_INDEX.createdAt}
        defaultValue="DATE"
        text1="PARK STATUS"
        loading={loading}
        showFirstFilter={true}
        showSecondFilter={false}
        showThirdFilter={false}
        columnData={columns}
        x={2000}
        expandedRowRender={expandedRowRender}
        rowSelection={rowSelection}
      />
    </div>
  );
};

export default TableParkedList;
