import * as ActionTypes from "../action-types";

const parkedOrder_defaultState = {
	byID: {},
	byComment: {},
	allIDs: [],
};

export const parkedOrder = (state = parkedOrder_defaultState, action) => {
	let newState = JSON.parse(JSON.stringify(state));

	if (!action) return newState;

	switch (action.type) {
		case ActionTypes.GET_PARKED_ORDER: 
        case ActionTypes.CREATE_PARKED_ORDER: {
			const parkedOrder = action.parkedOrder;

			parkedOrder &&
				parkedOrder.length &&
				parkedOrder.map((parkedOrder) => {
					const parkedorderid = parkedOrder.oparkid;

					newState.byID[parkedorderid] = parkedOrder;

					if (!newState.allIDs.includes(parkedorderid))
						newState.allIDs.push(parkedorderid);

					return null;
				});
			return newState;
		}

		case ActionTypes.UPDATE_PARKED_ORDER_STATUS: {
            const parkedOrder = action.parkedOrder;
            const { oparkid } = parkedOrder;
            
            newState.byID[oparkid] = parkedOrder;

            if (!newState.allIDs.includes(oparkid))
                newState.allIDs.push(oparkid);

            return newState;
		}

    case ActionTypes.GET_PARKED_ORDER_COMMENT: {
        const oparkcmt = action.oparkcmt;

        oparkcmt &&
            oparkcmt.length &&
            oparkcmt.map((cmt) => {
            const oparkid = cmt.oparkid;

            newState.byComment[oparkid] = oparkcmt;
            
            if (!newState.allIDs.includes(oparkid)) {
              newState.allIDs.push(oparkid);
            }
            
            return null;
            });
        return newState;
		}

    case ActionTypes.CREATE_PARKED_ORDER_COMMENT: {
        const oparkcmt = action.oparkcmt
        const { oparkid } = oparkcmt

        if(!newState.byComment[oparkid]) {
            newState.byComment[oparkid] = []
        }
            
        newState.byComment[oparkid].push(oparkcmt)

        return newState
    }

    case ActionTypes.EDIT_PARKED_ORDER_COMMENT: 
    case ActionTypes.ARCHIVE_PARKED_ORDER_COMMENT: {
        const items = action.oparkcmt
        const { oparkid, oparkcmtid } = items

        newState.byComment[oparkid].map((oparkcmt , i) => {
            if(oparkcmtid === oparkcmt.oparkcmtid) {
                newState.byComment[oparkid][i] = items
            }
        })
        return newState
    }

		default: {
			return state;
		}
	}
};
