// React Thirty Party Library
import React, { useState, useEffect } from "react";
import { useNavigate} from "react-router";
import { useSelector } from "react-redux";

// Constants Library
import { TEMPLATE, COMMON_FEATURES, ROUTES  } from "../../../../constants";

// Components Library
import { TableList, ButtonLink } from "../../../../components";

// Handler Library
import { billingHDLR, tableHDLR } from "../../../../handlers";

// Services Library 

// Redux Actions 

// Styles Library

const { TABLE_BILLING_TITLE, TABLE_BILLING_INDEX, SELECT_OPTION_DAYS, BILLING_TYPE, BILLING_STATUS } = TEMPLATE.BILLING;
const { BILLING_SUMMARY_COL } = TEMPLATE.REPORT.BILLING;

const { FEATURE_NAME } = COMMON_FEATURES;

const { ROUTE_URL } = ROUTES;

const TableBilling = (props) => {
  const {
    dataSource,
    filteredData,
    setFilteredData,
    NavigateOrderSummary,
    loading,
    selectedRow,
    setSelectedRow
  } = props;

  const navigate = useNavigate()
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
 
  const NavigateManageCredit = (memid, billid) => {
    navigate(ROUTE_URL[FEATURE_NAME.MANAGE_CREDIT_INVOICE], { state: { memid, billid } });
  };

  
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const columns = [
  ...BILLING_SUMMARY_COL({ func: { NavigateOrderSummary, handleSearch } }),
  {
    title: TABLE_BILLING_TITLE.action,
    key: TABLE_BILLING_INDEX.action,
    width: 100,
    fixed: "right",
    render: (record) => {
      const { type, status, billid, memid } = record;

      const actionPay = () => {
        if (type === BILLING_TYPE.Invoice && status === BILLING_STATUS.Pending) {
        return (
          <ButtonLink onClick={() => NavigateManageCredit(memid, billid)}>
            Pay
          </ButtonLink>
        );
      }
      }

      const pay = actionPay()

      return (
        <>{pay}</>
      );
    },
  },
]

  const sortedData = filteredData.sort((a, b) => b.updatedAt - a.updatedAt);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const sortFunctions = {
    updatedAt: (a, b) => a.updatedAt - b.updatedAt,
  };
  
  const filtered = sortedData.filter((record) => {
    let include = true;
  
    Object.keys(filteredInfo).forEach((key) => {
      if (filteredInfo[key] && filteredInfo[key].length > 0) {
        include = include && filteredInfo[key].includes(record[key]);
      }
    });
  
    if (searchText) {
      if(searchedColumn === "billid") {
        if ((typeof record.billid === 'string' && record.billid.includes(searchText))) {
          include = true;
        }
      }
      if(searchedColumn === "memid") {
        if ((typeof record.memid === 'string' && record.memid.includes(searchText)) || 
            (typeof record.name === 'string' && record.name.includes(searchText))) {
          include = true;
        }
      }
      if(searchedColumn === "ordid") {
        if ((typeof record.ordid === 'string' && record.ordid.includes(searchText))) {
          include = true;
        }
      }
    }    

    return include;

  }) 
  .sort((a, b) => {
    const { columnKey } = sortedInfo;
    if (sortFunctions[columnKey]) {
      return sortFunctions[columnKey](a, b);
    }
    return 0;
  });

  const onSelectRow = (record, selected) => {
    tableHDLR.selectRow(setSelectedRowKeys, setSelectedRow, selectedRow, selectedRowKeys, record, selected)
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelectRow
  };


  return (
    <>
      <TableList.DataList
        dataSource={dataSource}
        filteredData={filtered}
        setFilteredData={setFilteredData}
        optionDay={SELECT_OPTION_DAYS}
        optionFirst={billingHDLR.billingStatus()}
        optionSecond={billingHDLR.billingType()}
        dataDate={TABLE_BILLING_INDEX.Billing_Date}
        defaultValue="Transaction Date"
        text1="Billing Status"
        text2="Billing Type"
        columnData={columns}
        loading={loading}
        rowSelection={rowSelection}
        showFirstFilter={true}
        showSecondFilter={true}
        onChange={handleTableChange}
      />
    </>
  );
};

export default TableBilling;
