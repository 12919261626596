// React Thirty Party Library
import { Tag } from "antd";

// Components 
import { ButtonLink } from "../../../components";

// Constant
import { TEMPLATE } from "../../../constants";
import { TABLE_HUB_OPERATOR_TITLE, TABLE_HUB_OPERATOR_INDEX } from "../hub";

// Services Library
import * as REDUX_ACTION from "../../../services/redux/actions";

//handlers
import { filter } from "../../../handlers"

import { TIME } from "../../../services/util";

const { filterHDLR, searchHDLR } = filter
const { parseTimeMin } = TIME;


const {
    TABLE_HUB_TITLE,
    TABLE_HUB_INDEX,
    OPTION_HUB_STATUS,
    OPTION_HUB_STATUS_TITLE,
    HUB_TYPE_CODE,
    HUB_TYPE_TITLE,
    OPTION_HUB_OP_STATUS_TITLE, 
    OPTION_HUB_OP_STATUS
} = TEMPLATE.HUB
const {
    STATE_TITLE
} = TEMPLATE.ORDER

export const HUB_SUMMARY_COL = ({redux = {}, func = {},  exportState = false}) => {

    const {hubs} = redux
    const {handleSearch} = func

    return[
        {
            title: TABLE_HUB_TITLE.hub_code_name,
            key: TABLE_HUB_INDEX.hub_code_name,
            width: 200,
            fixed: "left",
            render:(record)=>{
                const { code, name } = record;
                return exportState ? `${code} / ${name}` : <p>{code} / {name}</p>
            },
            ...searchHDLR.getColumnSearchProps("name", handleSearch)
        },
        {
            title: TABLE_HUB_TITLE.status,
            key:TABLE_HUB_INDEX.status,
            width:120,
            fixed: "left",
            render:(record)=>{
                const { status }= record;

                const statusKey = status 
                ? OPTION_HUB_STATUS.Active
                : OPTION_HUB_STATUS.Inactive;

                const { name, colorTag } = OPTION_HUB_STATUS_TITLE[statusKey];
                return exportState ? name :
                <>
                    <Tag color={colorTag}>{name}</Tag>
                </>
            },
            filters: filterHDLR.getColumnsFilterStatus(OPTION_HUB_STATUS_TITLE),
            onFilter: (value, record) => record.status === value

        },
        {
            title: TABLE_HUB_TITLE.type,
            key:TABLE_HUB_INDEX.type,
            width:180,
            render:(record)=>{
                const { type }= record;
7
                return exportState ? HUB_TYPE_TITLE[type] : <p>{HUB_TYPE_TITLE[type] || '-'}</p>
            },
            filters : filterHDLR.getColumnsFilterType(HUB_TYPE_CODE, HUB_TYPE_TITLE),
            onFilter: (value, record) => record.type === value,
        },
        {
            title: TABLE_HUB_TITLE.state,
            key: TABLE_HUB_INDEX.state,
            width:180,
            render: (record)=>{
              const { state } = record

                return exportState ? state : <p>{state || '-'}</p>
            },
            filters : filterHDLR.getColumnsFilterState(STATE_TITLE),
            onFilter: (value, record) => record.state === value,
        },
        {
            title: TABLE_HUB_TITLE.pc,
            key: TABLE_HUB_INDEX.pc,
            width:180,
            render: (record)=>{

              const { postcode, city } = record

                return exportState ?  `${postcode}, ${city}` : <>
                    <p>{postcode || '-'} </p>
                    <p>{city}</p>
                </>
            }
        },
        {
            title: TABLE_HUB_TITLE.address,
            key: TABLE_HUB_INDEX.address,
            width:200,
            render:(record) =>{
                const {address} = record;
                return exportState ? address : <p>{address}</p>
            }
        },
        {
            title: TABLE_HUB_TITLE.hub_operators,
            key: TABLE_HUB_INDEX.hub_operators,
            width:180,
            render: (record) =>{
                const {operatorCount} = record;
                return exportState ? operatorCount : <p>{operatorCount}</p>
            },
        },
        {
            title: TABLE_HUB_TITLE.pic,
            key:TABLE_HUB_INDEX.pic,
            width:200,
            render:(record)=>{
                const { pic= [] } = record
                return pic.map((data, i)=>{
                    const name = data.name
                    const contact = data.contact

 
                    return (exportState ? `name ${name}` :
                    
                        <p key={i}>{name} ({contact})</p>
                    )
                })
            }
        },
        {
            title: TABLE_HUB_TITLE.map_coor,
            key:TABLE_HUB_INDEX.map_coor,
            width:180,
            render:(record)=>{
                const { coordinate } = record;
                return exportState ? `${coordinate && coordinate.lat}/
                ${coordinate && coordinate.lon}` :
                <>
                    <p>{coordinate && coordinate.lat}</p>
                    <p>{coordinate && coordinate.lon}</p>
                </> 
            }
        },
        {
            title: TABLE_HUB_TITLE.url,
            key: TABLE_HUB_INDEX.url,
            width:200,
            render:(record)=>{
                const { url } = record
                return exportState ? url : <a href={url}>{url}</a>
            }
        },
        {
            title: TABLE_HUB_TITLE.created_at,
            key: TABLE_HUB_INDEX.created_at,
            width:190,
            render:(record)=>{
                const { createdAt } = record
                return exportState ? parseTimeMin(createdAt) : <p>{TIME.parseTimeMin(createdAt)}</p>
            },
            sorter: (a, b) => a.createdAt - b.createdAt,
        },
        {
            title: TABLE_HUB_TITLE.last_update,
            width:180,
            render:(record)=>{
              const { updatedAt, updatedBy } = record
              
              return exportState ? `${parseTimeMin(updatedAt)}, ${updatedBy}` : 
              <>
                <p>{updatedBy || '-'}</p>
                <p>{TIME.parseTimeMin(updatedAt)}</p>
              </>
          },
          sorter: (a, b) => a.updatedAt - b.updatedAt,
        }
    ]
};

export const EXPANDED_HUB_OPERATORS_LIST = [
    {
        title:TABLE_HUB_OPERATOR_TITLE.name + "/" + TABLE_HUB_OPERATOR_TITLE.hub_opid,
        key: TABLE_HUB_OPERATOR_INDEX.hub_opid,
        width:200,
        render: (record) => {
            const { memid, name } = record;
            return  <span>{memid} / {name} </span>;
          },
    },
    {
        title:TABLE_HUB_OPERATOR_TITLE.status,
        key: TABLE_HUB_OPERATOR_INDEX.status,
        width:200,
        render: (record) => {
            const { status } = record;

            const statusKey = status
          ? OPTION_HUB_OP_STATUS.Active
          : OPTION_HUB_OP_STATUS.Suspend;
          
        const { name, colorTag } = OPTION_HUB_OP_STATUS_TITLE[statusKey];

        return (
          <>
            <Tag color={colorTag}>{name}</Tag>
          </>
        )},
    },
    {
        title:TABLE_HUB_OPERATOR_TITLE.contact,
        key: TABLE_HUB_OPERATOR_INDEX.contact,
        width:200,
        render: (record) => {
            const { contact } = record;
            return  <span> {contact} </span>;
          },
    },
    {
        title:TABLE_HUB_OPERATOR_TITLE.created_at,
        key: TABLE_HUB_OPERATOR_INDEX.created_at,
        width:200,
        render: (record) => {
            const { createdAt } = record;
            return  <span>{TIME.parseTimeMin(createdAt)} </span>;
          },
    },
]
