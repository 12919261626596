// React Thirty Party Library
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Constants Library

// Components Library
import { SearchTransaction, TableTransaction } from "./components";

// Handler Library

// Services Library
import { REDUX as REDUX_UTIL } from "../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../services/redux/actions";

// Styles Library
import styles from "./index.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;


const TransactionList = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector(
    reduxStoreWrapper("style.loading.transaction_list")
  );
  const journals = Object.values(
    useSelector(reduxStoreWrapper("journalNote.byID"))
  );
  const billings = Object.values(
    useSelector(reduxStoreWrapper("billing.byID"))
  );
  const user = Object.values(useSelector(reduxStoreWrapper("customer.byID")));
  const transactions = useSelector(reduxStoreWrapper("transaction.byID"));
  const dataSourceTransaction = Object.values(transactions).map((t) => ({
    key: t.txnid,
    ...t,
  }));

  useEffect(() => {
    setFilteredDataTransaction(dataSourceTransaction);
  }, [dataSourceTransaction]);

  const txn = dataSourceTransaction.map((txnData) => {
    const matchedUser = user.find(
      (userData) => txnData.memid === userData.memid
    );
    const matchedBill = billings.find((bill) => txnData.billid === bill.billid);
    const matchedJournal = journals.find(
      (jn) => matchedBill && matchedBill.refid === jn.jnid
    );

    return {
      ...txnData,
      name: matchedUser ? matchedUser.name : "",
      url: matchedJournal ? matchedJournal.url : "",
      jnid: matchedBill ? matchedBill.refid : "",
      ordid: matchedBill ? matchedBill && matchedBill.ordid : ""
    };
  }); 

  const [selectedRow, setSelectedRow] = useState([]);
  const [filteredDataTransaction, setFilteredDataTransaction] = useState(txn);

  useEffect(() => {
    dispatch(
      REDUX_ACTION.v1_transaction.get_transaction_timeframe_90days_request()
    );
    dispatch(
      REDUX_ACTION.v1_billing.get_billing_timeframe_thru_90days_request()
    ) 
    dispatch(
      REDUX_ACTION.v1_order.get_order_timeframe_thru_porgid_last_90_days_request(
        ["ipick"]
      )
    );

    return () => {};
  }, [dispatch]);

  useEffect(() => {
    if (filteredDataTransaction !== txn) {
      setFilteredDataTransaction(txn);
    }
  }, [txn]);

  useEffect(() => {
    setFilteredDataTransaction(filteredDataTransaction);
  }, [filteredDataTransaction]);

  return (
    <>
      <div className={styles.container}>
        <SearchTransaction
          dataSourceTransaction={txn}
          filteredDataTransaction={filteredDataTransaction}
          setFilteredDataTransaction={setFilteredDataTransaction}
          selectedRow={selectedRow}
        />
        <TableTransaction
          dataSourceTransaction={txn}
          filteredDataTransaction={filteredDataTransaction}
          setFilteredDataTransaction={setFilteredDataTransaction}
          loading={loading}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
        />
      </div>
    </>
  );
};

export default TransactionList;
